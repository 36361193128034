<template>
	<div class="p-grid crud-demo" >
		<i class="bi bi-journal-bookmark" style="color: #d46900;fontSize: 1.5rem"> Convalidación</i>
		<div class="p-col-12 p-md-12" v-if="!mostrar && !hist">
			<div class="card">
				<Card>
					<template #title>
					</template>
					<template #content>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-2">
								<Button label="Histórico" icon="bi bi-archive" class="p-button-info p-mr-2" @click="hist=true"/>
							</div>	
							<div class="p-field p-col-2"></div>	
							<div class="p-field p-col-5">
								<InputText type="number" min="0"  style="width:150px;" v-model="buscar" required="true" placeholder="Cédula" @keyup.enter="Buscar" v-if="opcion"/>
								<InputText type="text" v-model="buscar" required="true" placeholder="Apellido o Nombre" @keyup.enter="Buscar" v-if="!opcion"/>
							</div>	
							<div class="p-field p-col-1">
								<InputSwitch v-model="opcion" v-tooltip.bottom="'Cédula <-> Nombre'"/>				
							</div>	
							<div class="p-field p-col-2">
								<Button label="Buscar" icon="pi pi-search" class="p-button-success p-mr-2" @click="Buscar"/>
							</div>	
						</div>			
					</template>
					<template #footer>
					</template>
				</Card>
			</div>
		</div>

		<div class="p-col-12 p-md-12" v-if="estudiantes.length>0 && ver && !hist">
			<div class="card">
				<Card>
					<template #title>
						<h5 class="p-m-0">Estudiantes</h5>
					</template>
					<template #content>
						<DataTable :value="estudiantes" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
							<template #header>
								<div class="table-header">
									<span class="p-input-icon-left">
										<i class="pi pi-search" />
										<InputText v-model="filters['global']" placeholder="Buscar..." />
									</span>
								</div>
							</template>
							<Column field="nucleo" header="Extensión" :sortable="true">
								<template #body="slotProps">
									{{slotProps.data.nucleo}}
								</template>
							</Column>
							<Column field="id" header="N° Cédula" :sortable="true">
								<template #body="slotProps">
									{{slotProps.data.nac}}{{slotProps.data.id}}
								</template>
							</Column>
							<Column field="nombre" header="Nombre" :sortable="true">
								<template #body="slotProps">
									{{slotProps.data.nombre}}
								</template>
							</Column>
							<Column field="apellido" header="Apellido" :sortable="true">
								<template #body="slotProps">
									{{slotProps.data.apellido}}
								</template>
							</Column>
							<Column field="activo" header="Estatus">
								<template #body="slotProps">
									<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
									<span class="p-tag p-tag-danger" v-else>No Activo</span>
								</template>
							</Column>
							<Column header="Opciones">
								<template #body="slotProps">
									<Button icon="pi pi-arrow-right" class="p-button-rounded p-button-success p-mr-2" @click="Seleccion(slotProps.data)" v-tooltip.bottom="'Seleccionar'"/>
								</template>
							</Column>
						</DataTable>
					</template>
					<template #footer>
					</template>
				</Card>
			</div>
		</div>


		<div class="p-col-12 p-md-12" v-if="!ver && mostrar">
			<div class="card">
				<Card>
					<template #title>
						<h5 class="p-m-0">Estudiante</h5>
					</template>
					<template #content>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-10">
								Nombre y Apellido: {{seleccion.nombre}} {{seleccion.apellido}}
							</div>	
							<div class="p-field p-col-2">
								Cédula: {{seleccion.nac}}{{seleccion.id}}
							</div>	
							<div class="p-field p-col-8">
								Extensión: {{seleccion.nucleo}}
							</div>	
							<div class="p-field p-col-2">
								<Button label="Procesar" icon="bi bi-gear" class="p-button-info p-mr-2" @click="Procesar"/>
							</div>	
							<div class="p-field p-col-2">
								<Button label="Cerrar" icon="pi pi-times" class="p-button-danger p-mr-2" @click="mostrar=false"/>
							</div>	
						</div>			
					</template>
					<template #footer>
					</template>
				</Card>
			</div>
		</div>

		<div class="p-col-12 p-md-6" v-if="!ver && mostrar">
			<div class="card">
				<Card>
					<template #title>
						Origen: 
						<Dropdown v-model="plan1" :options="matriculas" optionLabel="plan" placeholder="Seleccione ..." @change="Selec1()"/>
					</template>
					<template #content>
						<DataTable :value="plan1.calificaciones" responsiveLayout="scroll">
							<Column field="periodo" header="Período" headerStyle="width: 100px"></Column>
							<Column field="asignatura" header="asignatura">
								<template #body="slotProps">
									{{slotProps.data.cod}} {{slotProps.data.asignatura}}
								</template>
							</Column>
							<Column field="definitiva" header="Calif" headerStyle="width: 70px"></Column>
						</DataTable>
					</template>
					<template #footer>
					</template>
				</Card>
			</div>
		</div>

		<div class="p-col-12 p-md-6" v-if="!ver && mostrar">
			<div class="card">
				<Card>
					<template #title>
						Destino: 
						<Dropdown v-model="plan2" :options="destinos" optionLabel="plan" placeholder="Seleccione ..." v-if="plan1"  @change="Selec2()"/>
					</template>
					<template #content>
						<DataTable :value="convalidaciones" responsiveLayout="scroll">
							<Column field="periodo" header="Período" headerStyle="width: 100px"></Column>
							<Column field="asignatura" header="asignatura">
								<template #body="slotProps">
									{{slotProps.data.cod}} {{slotProps.data.asignatura}}
								</template>
							</Column>
							<Column field="definitiva" header="Calif" headerStyle="width: 70px"></Column>
						</DataTable>
					</template>
					<template #footer>
					</template>
				</Card>
			</div>
		</div>

		<div class="p-col-12 p-md-12" v-if="hist">
			<div class="card">
				<Card>
					<template #title>
						Extensión: 
						<Dropdown v-model="nucleo" :options="nucleos" optionLabel="nombre" placeholder="Seleccione la Extensión ..." @change="Historico()"/>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-danger p-mr-2" @click="hist=false"/>
					</template>
					<template #content>
						<DataTable :value="historicos" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
								:rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
							<template #header>
								<div class="table-header">
									<h5 class="p-m-1">Historial</h5>
									<span class="p-input-icon-left">
										<i class="pi pi-search"/>
										<InputText v-model="filters['global']" placeholder="Buscar..." />
									</span>
								</div>
							</template>
							<Column header="Fecha y Hora" :sortable="true">
								<template #body="slotProps" >
									{{slotProps.data.fecha_reg}}
								</template>
							</Column>
							<Column header="Extensión" :sortable="true">
								<template #body="slotProps" >
									{{slotProps.data.extension}}
								</template>
							</Column>
							<Column header="Plan de Estudio" :sortable="true">
								<template #body="slotProps" >
									{{slotProps.data.planest}}
								</template>
							</Column>
							<Column field="id" header="N° Cédula" :sortable="true">
								<template #body="slotProps">
									{{slotProps.data.estudiante}}
								</template>
							</Column>
							<Column field="nombre" header="Nombre" :sortable="true">
								<template #body="slotProps">
									{{slotProps.data.nombre}}
								</template>
							</Column>
							<Column field="apellido" header="Apellido" :sortable="true">
								<template #body="slotProps">
									{{slotProps.data.apellido}}
								</template>
							</Column>
							<Column field="total" header="Asignaturas" :sortable="true">
								<template #body="slotProps">
									{{slotProps.data.total}}
								</template>
							</Column>
							<Column field="created" header="Usuario" :sortable="true">
								<template #body="slotProps">
									{{slotProps.data.created}}
								</template>
							</Column>
						</DataTable>

					</template>
					<template #footer>
					</template>
				</Card>
			</div>
		</div>



	</div>


</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Evaluacion','Convalidacion');

	export default {
		data() {
			return {
				buscar: null,
				opcion: true,
				estudiantes: [],
				seleccion: {},
				ver: false,
				mostrar: false,
				matriculas: [],
				selectedCustomers: {},
				filters: {},
				plan1: {calificaciones: []},
				plan2: null,
				destinos: [],
				convalidaciones: [],
				hist: false,
				nucleo: null,
				nucleos: [],
				historicos: [],

			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				Consulta.Ini().then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.nucleos = result.consult.nucleos;				
					this.$store.commit('Loading');
				}); 
			},
			Historico() {
				this.historicos = [];
				if (this.nucleo){
					Consulta.Procesar('Historico',{
						nucleo: 	this.nucleo,
					}).then(response => {
						//this.$store.state.error = response;
						this.historicos = response.result;
						this.$store.commit('Loading');
					}); 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail:'Debes colocar los datos solicitados', 
						life: 3000
					});    
				}
			},
			Buscar() {
				this.estudiantes = [];
                this.seleccion = {};
                this.ver = false;
				this.mostrar = false;
				this.plan1 = {calificaciones: []};
				this.plan2 = null;
				this.convalidaciones = [];
				if (this.buscar){
					Consulta.Procesar('Buscar',{
						id: 	this.buscar,
					}).then(response => {
						//this.$store.state.error = response;
						this.estudiantes = response.result;
						this.ver = true;
						this.$store.commit('Loading');
					}); 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail:'Debes colocar los datos solicitados', 
						life: 3000
					});    
				}
			},
			Seleccion(estudiante) {
                this.seleccion = {};
                this.ver = false;
				this.mostrar = false;
				this.matriculas = [];
				this.seleccion = {...estudiante};
				Consulta.Procesar('Seleccion',{
					id: 	estudiante.id,
					nucleo: estudiante.nucleo_id,
				}).then(response => {
					//this.$store.state.error = response;
					this.matriculas = response.result.matriculas;
					this.mostrar = true;
					this.$store.commit('Loading');
				}); 
			},
			Selec1(){
				this.$store.commit('Loading');
				this.plan2 = null;
				this.destinos = [];
				this.destinos = this.matriculas.filter(val => val.id !== this.plan1.id);
				this.$store.commit('Loading');
			},
			Selec2() {
				this.convalidaciones = [];
				Consulta.Procesar('Destino',{
					orignen:	this.plan1,
					destino: 	this.plan2,
				}).then(response => {
					//this.$store.state.error = response;
					this.convalidaciones = response.result.convalidaciones;
					this.$store.commit('Loading');
				}); 
			},
			Procesar() {
				if(this.convalidaciones.length>0){
					Consulta.Procesar('Procesar',{
						orignen:	this.plan1,
						destino: 	this.plan2,
						convalidaciones: 	this.convalidaciones,
					}).then(response => {
						//this.$store.state.error = response;
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Información Procesada', 
								life: 3000
							});
							this.$store.commit('Loading');
							this.Buscar();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: 'No fue procesada la solicitud', 
								life: 3000
							});
							this.$store.commit('Loading');
						}
					}); 				
				}
			},



		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
