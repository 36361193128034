<template>
	<div class="p-grid p-fluid">
		<div class="p-col-12 p-lg-12">
			<h3 class="centerText">Calificaciones</h3>
		</div>
		<div class="p-col-4 p-lg-4">
			<label>Extensión</label>
			<Dropdown v-model="nucleo" :options="nucleos" optionLabel="nombre" placeholder="Seleccione..."/>
		</div>
		<div class="p-col-3 p-lg-3">
			<label>Período</label>
			<Dropdown v-model="periodo" :options="periodos" optionLabel="per" placeholder="Seleccione..."/>
		</div>
		<div class="p-col-2 p-lg-2">
            <Button label="Buscar" icon="pi pi-search" class="p-button-success p-mr-2 p-mb-2" @click="Buscar" />
		</div>
		<div class="p-col-2 p-lg-2" v-if="total1>0">
			<h4 class="centerText">Total: {{total1}}</h4>
		</div>

		<div class="p-col-4 p-lg-4" v-if="total1>0">
			<strong>Planes de Evaluación</strong>
			<Knob :v-model="parseFloat(total2*100/total1)" :min="0" :max="100" :valueTemplate="parseFloat(total2*100/total1).toFixed(1)+'%'"  readonly="false" />
		</div>
		<div class="p-col-4 p-lg-4" v-if="total1>0">
			<strong>Calificaciones</strong>
			<Knob :v-model="parseFloat(total3*100/total1)" :min="0" :max="100" :valueTemplate="parseFloat(total3*100/total1).toFixed(1)+'%'"  readonly="false" />
		</div>
		<div class="p-col-4 p-lg-4" v-if="total1>0">
			<strong>Actas Cerradas</strong>
			<Knob :v-model="parseFloat(total4*100/total1)" :min="0" :max="100" :valueTemplate="parseFloat(total4*100/total1).toFixed(1)+'%'"  readonly="false" />
		</div>

		<div class="p-col-12 p-lg-12">
			<div class="card">
				<Chart type="horizontalBar" :data="Data1" />
			</div>
		</div>
		<div class="p-col-12 p-lg-12">
			<div class="card">
				<Chart type="horizontalBar" :data="Data2" />
			</div>
		</div>
	</div>
	
</template>

<script>
import API from "../service/API";
const Consulta = new API('Estadistica','EstadCalificaciones');

export default {
	data() {
		return {
			nucleos: [{nombre: 'Todas', code: 0}],
			nucleo: null,
			periodos: [],
			periodo: null,
			Data1: {},
			Data2: {},
			total1: 0,
			total2: 0,
			total3: 0,
			total4: 0,
		}
	},
	created() {
		this.$store.commit('Validar',this.$route);
		this.Mostrar();
	},
	methods: {
		Mostrar() {
			Consulta.Ini().then(result => {
				//this.$store.state.error = result;
				this.insert = result.insert;
				this.edit = result.edit;
				this.del = result.delete;
                this.periodos = result.consult.periodos;
				if(result.consult.nucleos.length>1){
					result.consult.nucleos.forEach(element => {
						this.nucleos.push(element);
					});
					this.nucleo = this.nucleos[0];
				} else {
					this.nucleos = result.consult.nucleos;
					this.nucleo = this.nucleos[0];
				}
				this.$store.commit('Loading');
			}); 
		},
		Buscar() {
			this.Data1 = {};
			this.Data2 = {};
			this.total1 = 0
			this.total2 = 0
			this.total3 = 0
			Consulta.Procesar('Buscar',{
				periodo: this.periodo.code,
				nucleo: this.nucleo.code,
			}).then(response => {
				//this.$store.state.error = response;	
				this.Data1 = response.result.data1;
				this.Data2 = response.result.data2;
				this.total1 = response.result.total1;
				this.total2 = response.result.total2;
				this.total3 = response.result.total3;
				this.total4 = response.result.total4;
				this.$store.commit('Loading');  
			});
		},

	}
}
</script>

<style scoped>

</style>
