<template>
	<div>
        <Dialog header="Calificaciones" v-model:visible="openCalificaciones" @update:visible="close" :style="{width: size}" :modal="true" :maximizable="true" style="background: #727376;position: absolute;">
            <div class="p-fluid">
				<br>				
				<DataTable :value="calificaciones" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
                        <h4>{{matricula.plan}}</h4>
					</template>
					<Column field="sem" header="Semestre" :sortable="true">
						<template #body="slotProps">{{slotProps.data.sem}}</template>
					</Column>
					<Column field="periodo" header="Periodo" :sortable="true">
						<template #body="slotProps">{{slotProps.data.periodo}}</template>
					</Column>
					<Column field="cod" header="Código" :sortable="true">
						<template #body="slotProps">{{slotProps.data.cod}}</template>
					</Column>
					<Column field="asignatura" header="Asignatura" :sortable="true">
						<template #body="slotProps">{{slotProps.data.asignatura}}</template>
					</Column>
					<Column field="calif1" header="C01">
						<template #body="slotProps">{{slotProps.data.calif1}}</template>
					</Column>
					<Column field="calif2" header="C02">
						<template #body="slotProps">{{slotProps.data.calif2}}</template>
					</Column>
					<Column field="calif3" header="C03">
						<template #body="slotProps">{{slotProps.data.calif3}}</template>
					</Column>
					<Column field="calif4" header="C04">
						<template #body="slotProps">{{slotProps.data.calif4}}</template>
					</Column>
					<Column field="total" header="Acumulado">
						<template #body="slotProps">{{slotProps.data.acumulativa}}</template>
					</Column>
					<Column field="extra" header="Eval Extra">
						<template #body="slotProps">{{slotProps.data.extra}}</template>
					</Column>
					<Column field="nota" header="Definitiva" :sortable="true">
						<template #body="slotProps">
							<strong v-if="slotProps.data.aprobada && slotProps.data.tipoasig==7">Aprobado</strong>
							<strong v-else-if="slotProps.data.aprobada && slotProps.data.tipo_id==5">Equivalencia</strong>
							<strong v-else>{{slotProps.data.definitiva}}</strong>
							<i class="pi pi-check" v-if="slotProps.data.aprobada"></i>
                            <i class="pi pi-times" v-else></i>
						</template>
					</Column>
				</DataTable>
			</div>	
            <template #footer>
                <Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="close"/>
            </template>
        </Dialog>
	</div>
</template>

<script>

export default {
    props: {
		display: {
			type: Boolean,
			default: false,
		},
        matricula: {
            type: Object,
            default: () => {}
        },
        calificaciones: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
			openCalificaciones: false,
            size: '90vw',
            visibleFull: false
		}
	},
    created() {      
        if (this.$store.state.mobile){this.size = '90vw';}
    },
    methods: {
        close() {
            this.openCalificaciones = false;
            this.$emit('close-calificaciones', event);
        },
        onMenuToggle(event) {
            this.$emit('close-calificaciones', event);
        },	
	},
    watch: {
		display(newValue) {
			this.openCalificaciones = newValue;
		}
	},


}
</script>