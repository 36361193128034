<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<Dropdown v-model="opcion" :options="opciones" optionLabel="nombre" placeholder="Seleccione..."/>
						<div v-if="opcion">
							<InputText type="number" min="0" v-model="buscar" required="true" placeholder="Cédula" v-if="opcion.code==1"/>
							<InputText v-model="fecha1" type="date" v-if="opcion.code==2"/>
							<InputText v-model="fecha2" type="date" v-if="opcion.code==2"/>
						</div>
					</template>				
					<template v-slot:right>
						<Button label="Buscar" icon="pi pi-search" class="p-button-success p-mr-2" @click="Buscar" v-if="opcion"/>
					</template>
				</Toolbar>
				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm" v-if="!detalles">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Pagos: {{products.length}}</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="nucleo" header="ID">
						<template #body="slotProps">
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="fecha" header="Fecha" :sortable="true">
						<template #body="slotProps">
							{{convfech(slotProps.data.fecha)}}
						</template>
					</Column>
					<Column field="estudiante" header="Estudiante">
						<template #body="slotProps">
							{{slotProps.data.estudiante}}
						</template>
					</Column>
					<Column field="referencia" header="N° de referencia bancaria">
						<template #body="slotProps">
							{{slotProps.data.nbanco}}  <br>
							{{slotProps.data.referencia}} <i class="pi pi-search" @click="IMG(url+'pagos/'+slotProps.data.img)" v-if="slotProps.data.tipo>1"></i>
						</template>
					</Column>
					<Column field="montobs" header="Monto">
						<template #body="slotProps">
							<strong v-if="slotProps.data.moneda==1">Ref.</strong>
							<img :src="'images/moneda'+slotProps.data.moneda+'.png'" width="15"/>
							{{(parseFloat(slotProps.data.montobs)/parseFloat(slotProps.data.tasa)).toFixed(2)}}
						</template>
					</Column>
					<Column field="monto" header="Monto Bs">
						<template #body="slotProps">
							Bs. {{new Intl.NumberFormat("de-DE").format((slotProps.data.montobs))}}
						</template>
					</Column>
					<Column header="Tasa">
						<template #body="slotProps">
							Bs. {{new Intl.NumberFormat("de-DE").format((slotProps.data.tasa))}}
						</template>
					</Column>
					<Column field="factura" header="Recibo" :sortable="true">
						<template #body="slotProps">
							N° {{slotProps.data.factura}} <br>
							<span class="p-tag p-tag-success" v-if="slotProps.data.verificado">Verificado</span>
							<span class="p-tag p-tag-danger" v-else>Por Verificar</span>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>

						<OverlayPanel ref="op1" appendTo="body" :showCloseIcon="true">
							<img style="width: 350px;" :src="imagen+'.jpg'" @click="VIMG(imagen+'.jpg')"/>
						</OverlayPanel>

		
	</div>
</template>

<script>
	import API from "../service/API";
	import PDF from "../service/PDF";
	const Consulta = new API('Pagos','PagosRecib');

	export default {
		data() {
			return {
				opcion: null,
				opciones: [
					{nombre: 'Buscar por Cédula', code: 1},
					{nombre: 'Buscar por Fechas', code: 2},
				],
				fecha1: null,
				fecha2: null,
				nucleo: null,
				nucleos: [],
				pendiente: {nombre: 'Por Verificar', code: 0},
				pendientes:  [
					{nombre: 'Por Verificar', code: 0},
					{nombre: 'Verificados', code: 1},
				],
				buscar: null,
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				detalles: null,
				product: {},
				estudiante: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				pagos_pend: [],
				facturas: [],
				ajuste: [],
				imagen: null,
				deleteDialog: false,
				pago: {},
				bancos: [],
				banco_destino: {code: 3, nombre: 'Banco Mercantil'},
				tipos: [],
				Dialog: false,
				periodo: null,
				periodos: []
			}
		},
		productService: null,
		created() {
			this.url = process.env.VUE_APP_API;
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.fecha1 = this.$store.state.fechaini;
			this.fecha2 = this.$store.state.fechaini;
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				Consulta.Ini().then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.nucleos = result.consult.nucleos;
					this.bancos = result.consult.bancos;
					this.tipos = result.consult.tipos;		
					this.periodos = result.consult.periodos;		
					this.$store.commit('Loading');
				}); 
			},
			Ver(){
				const doc = new PDF();
				doc.Factura(this.detalles);
			},
			Ver2(){
				const doc = new PDF();
				doc.Recibo(this.detalles);
			},
			VIMG(data){
				window.open(data, '_blank');
			},
			onMenuToggle(event) {
				this.$emit('menu-toggle', event);
			},
			Buscar() {
				this.detalles = null;
                this.pagos_pend = [];
				this.facturas = [];
				this.ver = false;
				if ((this.buscar && this.opcion.code<=2) || (this.fecha1<=this.fecha2)){
					Consulta.Procesar('Buscar',{
						id: 	this.buscar,
						opcion: this.opcion,
						fecha1: this.fecha1,
						fecha2: this.fecha2,
					}).then(response => {
						//this.$store.state.error = response;
						this.$store.commit('Loading');
						this.products = response.result;
						
					}); 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail:'Debes colocar los datos solicitados', 
						life: 3000
					});    
				}
			},

			confirmDelete(pago) {
				this.pago = pago;
				this.deleteDialog = true;
			},
			Del() {
				if (this.pago){
					Consulta.Procesar('EliminarPago',{
						id: 	this.pago.id,
					}).then(response => {
						//this.$store.state.error =response;
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Información Procesada', 
								life: 3000
							});
							this.detalles.pagos = this.detalles.pagos.filter(val => val.id !== this.pago.id);
							this.pago = {};
							this.deleteDialog = false;
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: 'No fue procesada la solicitud', 
								life: 3000
							});
						}
						this.$store.commit('Loading');
					}); 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail:'Debes Verificar', 
						life: 3000
					});    
				}
			},


			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}
				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
			convfech(fecha){
				return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
			},
			IMG(imegan){
				this.imagen = imegan;
				this.toggle1(event);
			},
			toggle1(event) {
				this.$refs.op1.toggle(event);
			},


		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
