<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12" v-if="!seleccion">
			<div class="card">
				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Carga Académica Actual: {{products.length}}</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="periodo" header="Período" :sortable="true">	
						<template #body="slotProps">
							<strong style="color: blue;" v-if="slotProps.data.periodo_act">{{slotProps.data.periodo}}</strong>
							<strong style="color: red;" v-else>{{slotProps.data.periodo}}</strong>						
						</template>
					</Column>
					<Column header="Plan de Estudio" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.plan}} {{slotProps.data.codplan}}
						</template>
					</Column>
					<Column field="asignatura" header="Asignatura" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.cod}} {{slotProps.data.asignatura}}			
						</template>
					</Column>
					<Column field="secf" header="Sección" :sortable="true">	
						<template #body="slotProps">
							{{slotProps.data.secf}}<br>
						</template>
					</Column>
					<Column field="porcent" header="Plan de Evaluación" :sortable="true">	
						<template #body="slotProps">
							{{slotProps.data.porcent}}%<br>
							<span class="p-tag p-tag-warning" v-if="slotProps.data.porcent<100">Pendiente {{parseFloat(100-slotProps.data.porcent)}}%</span>
						</template>
					</Column>
					
					<Column header="Opciones">
						<template #body="slotProps">
							<span class="p-overlay-badge p-mr-4">
								<Button icon="pi pi-file-pdf" class="p-button-rounded p-button-info" v-tooltip.bottom="'Registro de Calificaciones'" @click="VerPDF(slotProps.data)"/>
								<span class="p-badge">{{slotProps.data.inscritostotal}}</span>
							</span>
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="Editar(slotProps.data)" v-if="edit && slotProps.data.porcent==100 && slotProps.data.inscritostotal!=slotProps.data.cerradas && slotProps.data.inscritostotal>0" v-tooltip.bottom="'Editar'"/>
							<i class="pi pi-lock" style="fontSize: 2rem" v-if="slotProps.data.inscritostotal==slotProps.data.cerradas"></i>
							<!--<Button icon="pi pi-upload" class="p-button-rounded p-button-help p-mr-2" @click="VerXLS(slotProps.data)"/>-->
						</template>
					</Column>
					
				</DataTable>
			</div>
		</div>
		<div class="p-col-12" v-if="seleccion">
			<div class="card">
				<Card>
					<template #title>
						<h5>Registro de Calificaciones</h5>
					</template>
					<template #content>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12 p-md-2">
								Período: <strong>{{seleccion.periodo}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-8">
								Plan de Estudio: <strong>{{seleccion.plan}} {{seleccion.codplan}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-2">
								<Dropdown id="state" v-model="corte" :options="cortes" optionLabel="nombre" placeholder="Seleccione..." v-if="esc_eval!=3"></Dropdown>
							</div>
							<div class="p-field p-col-12 p-md-2">
								Código: <strong>{{seleccion.cod}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-8">
								Asignatura: <strong>{{seleccion.asignatura}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-2">
								Sección: <strong>{{seleccion.secf}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-2">
								<Button label="Guardar" style="width: 110px;" icon="pi pi-save" class="p-button-secondary p-mr-2" @click="Guardar(false)" v-if="seleccion.inscripcion.length>0"/>
							</div>
							<div class="p-field p-col-12 p-md-2">
								<Button label="Cancelar" style="width: 110px;" icon="pi pi-times" class="p-button-danger p-mr-2" @click="seleccion=null"/>
							</div>
							<div class="p-field p-col-12 p-md-2" v-if="seleccion && corte.code==0">
								<Button label="Cerrar Acta" style="width: 130px;" icon="pi pi-unlock" class="p-button-success p-mr-2" @click="ProcesarDialog = true" v-if="seleccion.inscritostotal!=seleccion.cerradas"/>
							</div>
						</div>
					</template>
				</Card>
			</div>
		</div>
		<div class="p-col-12" v-if="seleccion && corte.code>0 && corte.code<5">
			<div class="card">
				<DataTable :value="seleccion.inscripcion" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Carga Académica Actual: {{products.length}}</h5>
							<Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver()" v-if="seccion"/>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="plan_estudio" header="Plan" :sortable="true">	
						<template #body="slotProps">
							{{slotProps.data.plan_estudio}}	{{slotProps.data.codplan}}		
						</template>
					</Column>
					<Column field="estudiante" header="Nº Cédula" :sortable="true" headerStyle="width: 8rem;">	
						<template #body="slotProps">
							{{slotProps.data.estudiante}}				
						</template>
					</Column>
					<Column field="estudiante" header="Apellidos y Nombres" :sortable="true">	
						<template #body="slotProps">
							{{slotProps.data.apellido}}, {{slotProps.data.nombre}}			
						</template>
					</Column>
					<Column :header="corte.nombre" :sortable="true" headerStyle="width: 8rem;">	
						<template #body="slotProps">
							<InputNumber id="minmax" v-model="slotProps.data.calif1" mode="decimal" :min="0" :max="100" v-if="corte.code==1 && !slotProps.data.inas1" Style="max-width: 6rem;" :disabled="slotProps.data.cerrada>0"/>
							<small v-if="corte.code==1 && slotProps.data.calif1>25" style="color: red;">No puede exceder de 25</small>
							<InputNumber id="minmax" v-model="slotProps.data.calif2" mode="decimal" :min="0" :max="100" v-if="corte.code==2 && !slotProps.data.inas2" Style="max-width: 6rem;" :disabled="slotProps.data.cerrada>0"/>
							<small v-if="corte.code==2 && slotProps.data.calif2>25" style="color: red;">Existe una diferencia en el pago</small>
							<InputNumber id="minmax" v-model="slotProps.data.calif3" mode="decimal" :min="0" :max="100" v-if="corte.code==3 && !slotProps.data.inas3" Style="max-width: 6rem;" :disabled="slotProps.data.cerrada>0"/>
							<small v-if="corte.code==3 && slotProps.data.calif3>25" style="color: red;">Existe una diferencia en el pago</small>
							<InputNumber id="minmax" v-model="slotProps.data.calif4" mode="decimal" :min="0" :max="100" v-if="corte.code==4 && !slotProps.data.inas4" Style="max-width: 6rem;" :disabled="slotProps.data.cerrada>0"/>
							<small v-if="corte.code==4 && slotProps.data.calif4>25" style="color: red;">Existe una diferencia en el pago</small>
							<i class="pi pi-lock" v-if="slotProps.data.cerrada>0"></i>
						</template>
					</Column>
					<Column header="Inasistente" headerStyle="width: 8rem;">	
						<template #body="slotProps">
							<InputSwitch v-model="slotProps.data.inas1" v-if="corte.code==1"/>
							<InputSwitch v-model="slotProps.data.inas2" v-if="corte.code==2"/>
							<InputSwitch v-model="slotProps.data.inas3" v-if="corte.code==3"/>
							<InputSwitch v-model="slotProps.data.inas4" v-if="corte.code==4"/>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
		<div class="p-col-12" v-if="seleccion && corte.code==5">
			<div class="card">
				<DataTable :value="seleccion.inscripcion" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Carga Académica Actual: {{products.length}}</h5>
							<Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver()" v-if="seccion"/>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="plan_estudio" header="Plan de Estudio" :sortable="true">	
						<template #body="slotProps">
							{{slotProps.data.plan_estudio}} {{slotProps.data.codplan}}			
						</template>
					</Column>
					<Column field="estudiante" header="Nº Cédula" :sortable="true" headerStyle="width: 8rem;">	
						<template #body="slotProps">
							{{slotProps.data.estudiante}}				
						</template>
					</Column>
					<Column field="estudiante" header="Apellidos y Nombres" :sortable="true">	
						<template #body="slotProps">
							{{slotProps.data.apellido}}, {{slotProps.data.nombre}}			
						</template>
					</Column>
					<Column header="Acumulativa" headerStyle="width: 8rem;">	
						<template #body="slotProps">
							{{(parseFloat(slotProps.data.calif1)+parseFloat(slotProps.data.calif2)+parseFloat(slotProps.data.calif3)+parseFloat(slotProps.data.calif4)).toFixed(0)}}
							({{Definitiva(parseFloat(slotProps.data.calif1)+parseFloat(slotProps.data.calif2)+parseFloat(slotProps.data.calif3)+parseFloat(slotProps.data.calif4))}}
							<i class="pi pi-check" v-if="Definitiva(parseFloat(slotProps.data.calif1)+parseFloat(slotProps.data.calif2)+parseFloat(slotProps.data.calif3)+parseFloat(slotProps.data.calif4))>=aprob"></i>
                            <i class="pi pi-times" v-else></i>)
						</template>
					</Column>
					<Column header="Definitiva" headerStyle="width: 8rem;">	
						<template #body="slotProps">
							{{Final(parseFloat(slotProps.data.calif1)+parseFloat(slotProps.data.calif2)+parseFloat(slotProps.data.calif3)+parseFloat(slotProps.data.calif4),slotProps.data.extra)}}
							<i class="pi pi-check" v-if="Final(parseFloat(slotProps.data.calif1)+parseFloat(slotProps.data.calif2)+parseFloat(slotProps.data.calif3)+parseFloat(slotProps.data.calif4),slotProps.data.extra)>=aprob"></i>
                            <i class="pi pi-times" v-else></i>
						</template>
					</Column>
					<Column :header="corte.nombre" :sortable="true">	
						<template #body="slotProps">
							<InputNumber id="minmax" v-model="slotProps.data.extra" mode="decimal" :min="0" :max="20" Style="max-width: 6rem;"
								:disabled="slotProps.data.cerrada>0"
								v-if="corte.code==5 && Definitiva(parseFloat(slotProps.data.calif1)+parseFloat(slotProps.data.calif2)+parseFloat(slotProps.data.calif3)+parseFloat(slotProps.data.calif4))<10 && Definitiva(parseFloat(slotProps.data.calif1)+parseFloat(slotProps.data.calif2)+parseFloat(slotProps.data.calif3)+parseFloat(slotProps.data.calif4))>6"/>
							<small v-if="corte.code==5 && slotProps.data.extra>20" style="color: red;">No puede exceder de 20</small>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
		<div class="p-col-12" v-if="seleccion && corte.code==0 && esc_eval!=3">
			<div class="card">
				<DataTable :value="seleccion.inscripcion" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Carga Académica Actual: {{products.length}}</h5>
							<Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver()" v-if="seccion"/>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="plan_estudio" header="Plan de Estudio" :sortable="true">	
						<template #body="slotProps">
							{{slotProps.data.plan_estudio}} {{slotProps.data.codplan}}			
						</template>
					</Column>
					<Column field="estudiante" header="Nº Cédula" :sortable="true" headerStyle="width: 8rem;">	
						<template #body="slotProps">
							{{slotProps.data.estudiante}}				
						</template>
					</Column>
					<Column field="estudiante" header="Apellidos y Nombres" :sortable="true">	
						<template #body="slotProps">
							{{slotProps.data.apellido}}, {{slotProps.data.nombre}}			
						</template>
					</Column>
					<Column header="C01" headerStyle="width: 4rem;">
						<template #body="slotProps">
							<div v-if="slotProps.data.inas1">
								INA
							</div>
							<div v-else>
								{{slotProps.data.calif1}}
							</div>
						</template>
					</Column>
					<Column header="C02" headerStyle="width: 4rem;">	
						<template #body="slotProps">
							<div v-if="slotProps.data.inas2">
								INA
							</div>
							<div v-else>
								{{slotProps.data.calif2}}
							</div>
						</template>
					</Column>
					<Column header="C03" headerStyle="width: 4rem;">	
						<template #body="slotProps">
							<div v-if="slotProps.data.inas3">
								INA
							</div>
							<div v-else>
								{{slotProps.data.calif3}}
							</div>
						</template>
					</Column>
					<Column header="C04" headerStyle="width: 4rem;">	
						<template #body="slotProps">
							<div v-if="slotProps.data.inas4">
								INA
							</div>
							<div v-else>
								{{slotProps.data.calif4}}
							</div>
						</template>
					</Column>
					<Column header="Acumulativa" headerStyle="width: 8rem;">	
						<template #body="slotProps">
							{{(parseFloat(slotProps.data.calif1)+parseFloat(slotProps.data.calif2)+parseFloat(slotProps.data.calif3)+parseFloat(slotProps.data.calif4)).toFixed(0)}}
						</template>
					</Column>
					<Column header="Extra" headerStyle="width: 4rem;">	
						<template #body="slotProps">
							{{slotProps.data.extra}}
						</template>
					</Column>
					<Column header="Definitiva" headerStyle="width: 8rem;">	
						<template #body="slotProps">
							{{Final(parseFloat(slotProps.data.calif1)+parseFloat(slotProps.data.calif2)+parseFloat(slotProps.data.calif3)+parseFloat(slotProps.data.calif4),slotProps.data.extra)}}
							<i class="pi pi-check" v-if="Final(parseFloat(slotProps.data.calif1)+parseFloat(slotProps.data.calif2)+parseFloat(slotProps.data.calif3)+parseFloat(slotProps.data.calif4),slotProps.data.extra)>=aprob"></i>
                            <i class="pi pi-times" v-else></i>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>

		<div class="p-col-12" v-if="seleccion && esc_eval==3">
			<div class="card">
				<DataTable :value="seleccion.inscripcion" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Carga Académica Actual: {{products.length}}</h5>
							<Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver()" v-if="seccion"/>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="plan_estudio" header="Plan de Estudio" :sortable="true">	
						<template #body="slotProps">
							{{slotProps.data.plan_estudio}} {{slotProps.data.codplan}}			
						</template>
					</Column>
					<Column field="estudiante" header="Nº Cédula" :sortable="true" headerStyle="width: 8rem;">	
						<template #body="slotProps">
							{{slotProps.data.estudiante}}				
						</template>
					</Column>
					<Column field="estudiante" header="Apellidos y Nombres" :sortable="true">	
						<template #body="slotProps">
							{{slotProps.data.apellido}}, {{slotProps.data.nombre}}			
						</template>
					</Column>
					<Column header="Definitiva" headerStyle="width: 12rem;">	
						<template #body="slotProps">
							<Dropdown id="state" v-model="slotProps.data.calif1" :options="califcuali" optionLabel="nombre" placeholder="Seleccione..." :disabled="slotProps.data.inas1==true"></Dropdown>
						</template>
					</Column>
					<Column header="Inasistente" headerStyle="width: 8rem;">	
						<template #body="slotProps">
							<InputSwitch v-model="slotProps.data.inas1"/>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>


				<Dialog v-model:visible="ProcesarDialog" :style="{width: size}" header="Confirmar Cierre de Acta" :modal="true" :maximizable="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quiere cerrar las calificaciones?</span>
					</div>
					<template #footer>
						<Button label="Sí" icon="pi pi-check" class="p-button-secondary" @click="Guardar(true)" />
						<Button label="No" icon="pi pi-times" class="p-button-danger" @click="ProcesarDialog = false"/>
					</template>
				</Dialog>


	</div>

</template>

<script>
	import API from "../service/API";
	import PDF from "../service/PDF";
	import XLS from "../service/XLS";
	const Consulta = new API('AreaDocente','DocCalif');

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				productDialog: false,
				productDialog2: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				HoraDocDialog: false,
				product: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				horariosec: [],
				asignaturas: [],
				horario: [],
				horariodoc: [],
				asigdoc: [],
				hora: null,
				escala: [],
				esc_eval: 0,
				aprob: 0,
				corte: {nombre: 'Resumen', code: 0},
				cortes: [
					{nombre: 'Resumen', code: 0},
					{nombre: 'Corte 01', code: 1},
					{nombre: 'Corte 02', code: 2},
					{nombre: 'Corte 03', code: 3},
					{nombre: 'Corte 04', code: 4},
					{nombre: 'Extra', code: 5},
				],
				califcuali: [
					{nombre: 'Aprobado', code: 20},
					{nombre: 'Reprobado', code: 0},
				],
				seleccion: null,
				ProcesarDialog: false
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.products = [];
				this.$store.state.loading = true;
				this.esc_eval = 0;
				this.seleccion = null;
				Consulta.Ini().then(result => {
					//this.$store.state.error = result.consult.oferta;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.products = result.consult.oferta;
					this.$store.commit('Loading');
				}); 
			},
			VerPDF(data){
				if(data.inscritostotal!=data.cerradas){
					Consulta.Procesar('Ver',{
						oferta: data.id
						}).then(response => {
							//this.$store.state.error =response;
							if (response.result) {
								var dat = data;
								dat['inscripcion'] = response.result;
								const doc = new PDF();
								doc.ActaCalificaciones(dat);
							} 
							this.$store.commit('Loading');
						});
				} else {
					Consulta.Procesar('DocCalif',{
						caso: 	'VerCALIF',
						oferta: data.id
						}).then(response => {
							//this.$store.state.error =response;
							if (response.result) {
								const doc = new PDF();
								doc.ActaCalificacionesFINAL(response.result);
							} 
							this.$store.commit('Loading');
						});
				}
			},
			VerXLS(data){
				const xls = new XLS();
				xls.ActaCalificaciones(data);
			},
			Editar(data){
				this.esc_eval = 0;
				this.corte = {nombre: 'Resumen', code: 0};
				Consulta.Procesar('Editar',{
					oferta: data
					}).then(response => {
						//this.$store.state.error =response;
						if (response.result) {
							this.esc_eval = data.escala;
							var dat = data;
							dat['inscripcion'] = response.result.inscripcion;
							dat['escala'] = response.result.escala;
							dat['aprob'] = response.result.aprob;
							this.seleccion = dat;
							this.escala = response.result.escala;
							this.aprob = response.result.aprob;
							if(this.esc_eval == 3){
								this.seleccion.inscripcion.forEach(element => {
									if(element.calif1>0){
										element.calif1 = {nombre: 'Aprobado', code: 20};
									} else {
										element.calif1 = {nombre: 'Reprobado', code: 0};
									}
								});  
							}
							this.$store.commit('Loading');
						} 
					});
			},
			Definitiva(acum){
				if(this.escala.length>0){
					for (let i = 0; i < this.escala.length; i++) {
						if (this.escala[i].inferior <= acum && acum <= this.escala[i].superior) {
							return this.escala[i].calif;
						}
					}
				} else {
					return 0;
				}
			},
			Final(acum,extra){
				if(extra>0){
					if(this.Definitiva(acum)==7 && extra>=13){return 10;}
					else if(this.Definitiva(acum)==8 && extra>=12){return 10;}
					else if(this.Definitiva(acum)==9 && extra>=11){return 10;}
					else {return this.Definitiva(acum);}
				} else {
					return this.Definitiva(acum);
				}
			},
			Guardar(cerrar){
				if (this.validateForm()){
					this.ProcesarDialog = false;
					Consulta.Procesar('UPD',{
						seleccion: this.seleccion,
						cerrar: cerrar,
						esc_eval: this.esc_eval,
						}).then(response => {
							//this.$store.state.error =response;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.$store.commit('Loading');
								this.Mostrar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
								this.$store.commit('Loading');
							}
						});
				}
			},
			validateForm() {
				var defect = true;
				for (let i = 0; i < this.seleccion.inscripcion.length; i++) {
					if (this.seleccion.inscripcion[i].calif1>25 || this.seleccion.inscripcion[i].calif2>25
						|| this.seleccion.inscripcion[i].calif3>25 || this.seleccion.inscripcion[i].calif4>25) {
						defect = false;
					}
				}
				if(!defect){
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail: 'Las calificaciones no pueden exceder de 25, por favor revise...', 
						life: 3000
					});
				}
				return defect;
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
				convfech(fecha){
					return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
				},

		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
