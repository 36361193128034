<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<DataTable :value="products" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Documentos Subidos</h5>
							<Button icon="pi pi-refresh" class="p-button-rounded p-button-success p-mr-2" @click="Mostrar"/>
						</div>
					</template>
					<Column field="nombre" header="Descripción">
						<template #body="slotProps">
							{{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="jpg" header="JPG" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.jpg}}
						</template>
					</Column>
					<Column field="arch" header="Archivos" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.arch}}
						</template>
					</Column>
					<Column field="pend" header="Pendientes" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.pend}}
						</template>
					</Column>
					<Column header="Opciones" headerStyle="width: 100px">
						<template #body="slotProps">
							<Button icon="pi pi-cog" class="p-button-rounded p-button-success p-mr-2" @click="Procesar(slotProps.data.id)" v-if="slotProps.data.cant!=slotProps.data.arch" v-tooltip.bottom="'Procesar'"/>
						</template>
					</Column>
				</DataTable>


			</div>
		</div>
	</div>
</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Configuracion','Limpiar');

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				products: [
					{id: 0, nombre: 'Soporte de Pago', pend: 0, jpg: 0, arch: 0},
					{id: 1, nombre: 'Soporte de Documentos', pend: 0, jpg: 0, arch: 0},
				],
				product: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				seleccion: null,
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				Consulta.Ini().then(result => {
					//this.$store.state.error = result.consult;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.products[0].pend = result.consult.pagos.pend;
					this.products[0].jpg = result.consult.pagos.jpg;
					this.products[0].arch = result.consult.pagos.arch;
					this.products[1].pend = result.consult.doc.pend;
					this.products[1].jpg = result.consult.doc.jpg;
					this.products[1].arch = result.consult.doc.arch;
					this.$store.commit('Loading');
				}); 
			},
			Procesar(id) {
				Consulta.Procesar('Procesar',{
					id: 	id
				}).then(response => {
					//this.$store.state.error = response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Mostrar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
					this.$store.commit('Loading');
				});
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
