<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<InputText type="number" min="0" v-model="vieja" required="true" placeholder="Cédula a Cambiar" class="p-mr-2" :disabled="valido"/>
						<InputText type="number" min="0" v-model="nueva" required="true" placeholder="Cédula Nueva" class="p-mr-2" :disabled="valido"/>
						<Button label="Verificar" icon="pi pi-search" class="p-button-success p-mr-2 p-mb-2" @click="Verificar" />
						<Button label="Cancelar" icon="pi pi-times-circle" class="p-button-danger p-mr-2 p-mb-2" @click="Cancelar" v-if="valido"/>
					</template>
					<template v-slot:right>
						<Button label="Procesar" icon="pi pi-fast-forward" class="p-button-secondary p-mr-2 p-mb-2" @click="Procesar" v-if="valido"/>
					</template>
				</Toolbar>
				<br><br>
				<div class="p-grid"  v-if="mostrar">
					<div class="p-col-4">
						Cédula a Cambiar: {{ vieja }}
						<div v-if="datos">Apellido: {{ datos.apellido }}</div>
						<div v-if="datos">Nombre: {{ datos.nombre }}</div>
					</div>
					<div class="p-col-4">
						<DataTable :value="products" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers" dataKey="id" :rowHover="true">
							<Column field="nombre" header="Tabla">
								<template #body="slotProps">
									{{slotProps.data.nombre}}
								</template>
							</Column>
							<Column field="cant" header="Registros"  headerStyle="width: 100px">
								<template #body="slotProps">
									{{slotProps.data.cant}}
								</template>
							</Column>
						</DataTable>
					</div>
					<div class="p-col-4">
						<h5><strong>{{ mensaje }}</strong></h5>
					</div>
				</div>
				<Dialog v-model:visible="productDialog" :style="{width: size}" header="Usuario" :modal="true" class="p-fluid" :maximizable="true">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-2">
								<label for="name">N° Cédula</label>
								<InputNumber id="cedula" v-model="product.id" integeronly required="true" min="0" disable="true" autofocus v-if="!product.id"/>
								<InputText id="cedula" v-model="product.id" placeholder="Disabled" :disabled="true" v-else></InputText>						
							</div>
							<div class="p-col-5">
								<label for="name">Nombre</label>
								<InputText id="name" v-model.trim="product.nombre" required="true"/>
								<small class="p-invalid" v-if="submitted && !product.nombre">Name is required.</small>
							</div>
							<div class="p-col-5 ">
								<label for="name">Apellido</label>
								<InputText id="name" v-model.trim="product.apellido" required="true"/>
								<small class="p-invalid" v-if="submitted && !product.nombre">Name is required.</small>
							</div>
							<div class="p-col-6 p-md-8">
								<label for="name">Correo</label>
								<InputText id="name" v-model.trim="product.correo" required="true"/>
								<small class="p-invalid" v-if="submitted && !product.nombre">Name is required.</small>
							</div>
							<div class="p-col-4">
								<label class="p-mb-3">Extensión</label>
								<Dropdown id="state" v-model="product.nucleo" :options="nucleos" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-2">
								<h6 style="margin-top: 0" v-if="product.activo">Activo</h6>
								<h6 style="margin-top: 0" v-else>No Activo</h6>
								<InputSwitch v-model="product.activo" />
							</div>
							<div class="p-field" v-if="product.roles">
								<h5>Permisología</h5>
								<div class="p-grid">
									<div class="p-col-6"  v-for="(stats, index) in product.roles" :key="index">
										<div class="p-grid">
											<div class="p-col-8">
												{{stats.nombre}}
											</div>
											<div class="p-col-4">
												<InputSwitch :id="index" v-model="product.roles[index].activo" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="hideDialog"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}" header="Confirmar" :modal="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button label="Sí" icon="pi pi-check" class="p-button-secondary" @click="deleteProduct" />
						<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Configuracion','Cedulas');

	export default {
		data() {
			return {
				nuevo: false,
				vieja: null,
				datos: null,
				nueva: null,
				valido: false,
				mostrar: false,
				mensaje: null,

				buscar: null,
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				products: [],
				productDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				nucleos: null
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
		},
		mounted() {
			
		},
		methods: {
			Cancelar() {
				this.valido = false;
				this.mostrar = false;
				this.mensaje = null;
				this.datos = null;
				this.nueva = null;
				this.vieja = null;
			},
			Verificar() {
				this.valido = false;
				this.mostrar = false;
				this.mensaje = null;
				this.datos = null;
				if (this.vieja && this.nueva && this.vieja != this.nueva){
					Consulta.Procesar('Verificar',{
						nueva: 	this.nueva,
						vieja: 	this.vieja,
					}).then(response => {
						//this.$store.state.error = response;
						this.products = response.result.tablas;
						this.datos = response.result.datos;
						if(!response.result.datos){
							this.mensaje = 'La cédula a cambiar, no existe en el sistema. Verifique por favor.'
						} else if(response.result.nueva.length>0){
							this.mensaje = 'La cédula nueva, ya existe en el sistema. Verifique por favor.'
						} else {
							this.valido = true;
						}
						this.mostrar = true;
						this.$store.commit('Loading');
					}); 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail:'Debes colocar los datos solicitados', 
						life: 3000
					});    
				}
			},
			Procesar() {
				if (this.vieja && this.nueva && this.valido){
					Consulta.Procesar('Procesar',{
						nueva: 	this.nueva,
						vieja: 	this.vieja,
					}).then(response => {
						//this.$store.state.error = response;
						if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Cancelar();
						}
						this.$store.commit('Loading');
					}); 
				} 
			},

		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
