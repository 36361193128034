<template>
	<div class="p-grid">
		<div class="p-col-5">
			<div class="p-text-left">
				<h2><strong style="color: #575756;">{{url}}</strong></h2>
				<h1 v-if="$store.state.mantenimiento">
					<strong style="color: #720000;">Sistema en Mantenimiento</strong>
				</h1>
			</div>
			<div class="p-grid p-justify-center">
				<img style="width: 100%;" alt="logo" src="images/banner.jpg">
			</div>	

		</div>

		<div class="p-col-7">
			<div class="p-grid p-justify-center">
				<img style="width: 100%;" alt="logo" src="images/nuevo.jpg">
			</div>	
		</div>


	</div>
</template>

<script>
	export default {
		data() {
			return {
			}
		},
		created() {
			this.url = process.env.VUE_APP_NOMBRE;
		},
		methods: {
		},
			components: {
			}

	}
</script>

<style scoped lang="scss">
	p {
		line-height: 1.5;
		margin: 0;
	}

	::v-deep(.p-card .p-card-title) {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 0;
	}
</style>
