<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<InputText type="number" min="0" v-model="buscar" required="true" placeholder="Cédula" @keyup.enter="Buscar" v-if="opcion"/>
						<InputText type="text" v-model="buscar" style="width:250px;" required="true" placeholder="Apellido o Nombre" @keyup.enter="Buscar" v-if="!opcion"/>
						<InputSwitch v-model="opcion" v-tooltip.bottom="'Cédula <-> Nombre'"/>				
					</template>
					<template v-slot:right>
						<Button label="Buscar" icon="pi pi-search   " class="p-button-success p-mr-2" @click="Buscar"/>
					</template>			
				</Toolbar>
				<DataTable :value="matriculas" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters" v-if="matriculas.length>0">
					<template #header>
						<h4>
							{{estudiante.nac}}{{estudiante.id}} {{estudiante.apellido}}, {{estudiante.nombre}} ({{estudiante.nucleo}})		
						</h4>
					</template>
					<Column field="plan" header="Plan de Estudio" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.plan}}
						</template>
					</Column>
					<Column field="periodo" header="Período" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.periodo}}
						</template>
					</Column>
					<Column field="turno" header="Turno" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.turno}}
						</template>
					</Column>
					<Column field="seccion" header="Sección" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.sec}}
						</template>
					</Column>
					<Column field="activo" header="Estatus">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
                            <br><br>							
                            <span class="p-tag p-tag-success" v-if="slotProps.data.graduado">Graduado</span>
						</template>
					</Column>
					<Column field="activo" header="TUC">
						<template #body="slotProps">
							{{slotProps.data.tuc}} ({{slotProps.data.tasig+'/'+slotProps.data.materias}})
						</template>
					</Column>
					<Column field="planes" header="Consultar">
						<template #body="slotProps">
							<span class="p-overlay-badge p-mr-5" @click="openCalificaciones(slotProps.data)" v-if="!slotProps.data.graduado || slotProps.data.tasig!=slotProps.data.materias">
								<i class="pi pi-pencil" style="font-size: 2em"></i>
								<span class="p-badge">{{slotProps.data.calificaciones}}</span>
							</span>
						</template>
					</Column>
				</DataTable>
				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm" v-else>
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Estudiantes</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="nucleo" header="Extensión" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nucleo}}
						</template>
					</Column>
					<Column field="id" header="N° Cédula" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nac}}{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="nombre" header="Nombre" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="apellido" header="Apellido" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.apellido}}
						</template>
					</Column>
					<Column field="activo" header="Estatus">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
						</template>
					</Column>
					<Column header="Opciones">
						<template #body="slotProps">
							<Button icon="pi pi-arrow-right" class="p-button-rounded p-button-success p-mr-2" @click="VerMatricula(slotProps.data)" v-tooltip.bottom="'Seleccionar'"/>
						</template>
					</Column>
				</DataTable>

			</div>
		</div>
		<div class="p-col-12" v-if="ver">
			<div class="card">
                <Button label="Agregar Calificación" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="openNew" v-if="insert"/>
                <Button label="Agregar Equivalencia Externa" icon="pi pi-plus" class="p-button-info p-mr-2 p-mb-2" @click="openEq" v-if="insert"/>
				<DataTable :value="calificaciones" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
                        <h4>{{matricula.plan}}</h4>
                        Nº Asignaturas: {{calificaciones.length}}
					</template>
                  
					<Column field="sem" header="Semestre" :sortable="true">
						<template #body="slotProps">{{slotProps.data.sem}}</template>
					</Column>
					<Column field="periodo" header="Periodo" :sortable="true">
						<template #body="slotProps">{{slotProps.data.periodo}}</template>
					</Column>
					<Column field="cod" header="Código" :sortable="true">
						<template #body="slotProps">{{slotProps.data.cod}}</template>
					</Column>
					<Column field="asignatura" header="Asignatura" :sortable="true">
						<template #body="slotProps">{{slotProps.data.asignatura}}</template>
					</Column>
					<Column field="total" header="Acumulado">
						<template #body="slotProps">{{slotProps.data.acumulativa}}</template>
					</Column>
					<Column field="extra" header="Eval Extra">
						<template #body="slotProps">{{slotProps.data.extra}}</template>
					</Column>
					<Column field="nota" header="Definitiva" :sortable="true">
						<template #body="slotProps">
                            {{slotProps.data.definitiva}}
                            <i class="pi pi-check" v-if="slotProps.data.aprobada"></i>
                            <i class="pi pi-times" v-else></i>
                        </template>
					</Column>
					<Column field="nota" header="Tipo" :sortable="true">
						<template #body="slotProps">{{slotProps.data.tipo}}</template>
					</Column>
					<Column field="nota" header="Usuario" :sortable="true">
						<template #body="slotProps">{{slotProps.data.created}}</template>
					</Column>
					<Column header="Opciones">
						<template #body="slotProps">
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteProduct(slotProps.data)" v-if="del && slotProps.data.tipo_id>0 && !slotProps.data.graduado" v-tooltip.bottom="'Eliminar'"/>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}" header="Confirmar" :modal="true" :maximizable="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button label="Sí" icon="pi pi-check" class="p-button-secondary" @click="deleteProduct" />
						<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="califDialog" :style="{width: size}" :header="'Agregar Calificación'" :modal="true" class="p-fluid" :maximizable="true">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-12">
								<label class="p-mb-2">Asignatura</label>
								<Dropdown v-model="product.asignatura" :options="asignaturas" optionLabel="nombre" placeholder="Selecciona..." @change="validacion()"></Dropdown>
							</div>
							<div class="p-col-8">
								<label class="p-mb-2">Período</label>
								<Dropdown v-model="product.periodo" :options="periodos" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-4">
								<label class="p-mb-2">Calificación</label>
								<Dropdown v-model="product.nota" :options="notas" optionLabel="nombre" placeholder="Selecciona..." v-if="product.asignatura"></Dropdown>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="Guardar()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger"  @click="califDialog = false"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="EquiDialog" :style="{width: size}" :header="'Agregar Calificación por Equivalencia Externa'" :modal="true" class="p-fluid" :maximizable="true">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-8">
								<label class="p-mb-2">Asignatura</label>
								<Dropdown v-model="product.asignatura" :options="asignaturas" optionLabel="nombre" placeholder="Selecciona..." @change="validacion()"></Dropdown>
							</div>
							<div class="p-col-4">
								<label class="p-mb-2">Período</label>
								<Dropdown v-model="product.periodo" :options="periodos" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="GuardarEq()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger"  @click="EquiDialog = false"/>
					</template>
				</Dialog>

	</div>


</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Evaluacion','Modificacion');

	export default {
		data() {
			return {
				nuevo: false,
				buscar: null,
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				matriculas: [],
				product: {},
				estudiante: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '40vw',
				matriculaDialog: false,
				matricula: {},
				calificaciones: [],
				ver: false,
				deleteProductDialog: false,
                califDialog: false,
				EquiDialog: false,
                asignaturas: [],
                notas: [],
                escala: [],
                periodos: [],
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
		},
		mounted() {
			
		},
		methods: {
			onMenuToggle(event) {
				this.$emit('menu-toggle', event);
			},
			openCalificaciones(matricula) {
                this.matricula = matricula;
				this.$store.commit('Mobile');
				this.matricula = {...matricula};					
				Consulta.Procesar('Calificaciones',{
					id: 	this.matricula.id,
				}).then(response => {
					//this.$store.state.error =response;
                    this.insert = response.insert;
                    this.edit = response.edit;
                    this.del = response.delete;
					this.calificaciones = response.result;
                    this.ver = true;
					this.$store.commit('Loading');
				}); 
			},
			Buscar() {
				this.matriculas = [];
                this.matricula = {};
                this.ver = false;
				if (this.buscar){
					Consulta.Procesar('Buscar',{
						id: 	this.buscar,
					}).then(response => {
						//this.$store.state.error =response;
						this.products = response.result;
						this.$store.commit('Loading');
					}); 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail:'Debes colocar los datos solicitados', 
						life: 3000
					});    
				}
			},
			VerMatricula(estudiante) {
                this.matricula = {};
                this.ver = false;
				this.estudiante = {};
				this.estudiante = {...estudiante};
				if (estudiante.planes>0){
					Consulta.Procesar('Matricula',{
						id: 	this.estudiante.id,
						nucleo: 	this.estudiante.nucleo_id,
					}).then(response => {
						//this.$store.state.error = response.result;
						this.matriculas = response.result;
						this.$store.commit('Loading');
					}); 
				} else {
					this.newMat(estudiante);
				}
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			deleteProduct() {
				Consulta.Procesar('DEL',{
					id: 	this.product.id,
                    matricula: 	this.matricula.id,
				}).then(response => {
                    //this.$store.state.error = response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
                        this.calificaciones = response.result;
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
					this.$store.commit('Loading');
				});
				this.deleteProductDialog = false;
				this.product = {};
			},
			openNew() {
				this.asignaturas = [];
				this.escala = [];
				this.notas = [];
				Consulta.Procesar('openCalificacion',{
                    matricula: 	this.matricula,
				}).then(response => {
					//this.$store.state.error = response;
					this.asignaturas = response.result.asignaturas;
					this.escala = response.result.escala;
					this.periodos = response.result.periodos;
                    this.califDialog = true;
					this.$store.commit('Loading');
				}); 
			},
			openEq() {
				this.asignaturas = [];
				this.escala = [];
				this.notas = [];
				Consulta.Procesar('openCalificacion',{
                    matricula: 	this.matricula,
				}).then(response => {
					//this.$store.state.error = response;
					this.asignaturas = response.result.asignaturas;
					this.escala = response.result.escala;
					this.periodos = response.result.periodos;
                    this.EquiDialog = true;
					this.$store.commit('Loading');
				}); 
			},
			validacion() {
                this.notas = [];
                if(this.product.asignatura.cuantitativa){
                    this.escala.forEach(element => {
                        if(this.product.asignatura.escala==element.escala){
                            this.notas.push({
                                'nombre': element.nombre,
                                'code': element.nombre
                            });
                        }
                    });  
                } else {
                    this.notas = [
                        {'nombre': 'Aprobado', 'code': 1},
                        {'nombre': 'Reprobado', 'code': 0}
                    ]
                }
            },
			Guardar() {
				Consulta.Procesar('INS',{
					calificacion: 	this.product,
                    matricula: 	this.matricula,
				}).then(response => {
                    //this.$store.state.error = response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
                        this.calificaciones = response.result;
                        this.califDialog = false;
                        this.product = {};
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
					this.$store.commit('Loading');
				});
			},
			GuardarEq() {
				Consulta.Procesar('INSEQUIV',{
					calificacion: 	this.product,
                    matricula: 	this.matricula,
				}).then(response => {
                    //this.$store.state.error = response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
                        this.calificaciones = response.result;
                        this.EquiDialog = false;
                        this.product = {};
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
					this.$store.commit('Loading');
				});
			},

			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}
				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
