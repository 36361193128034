<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
                    <InputText type="number" min="0" v-model="buscar" required="true" placeholder="Cédula" @keyup.enter="Buscar" v-if="opcion"/>
                    <InputText type="text" v-model="buscar" style="width:250px;" required="true" placeholder="Apellido o Nombre" @keyup.enter="Buscar" v-if="!opcion"/>
					<Button label="Buscar" icon="pi pi-search   " class="p-button-success p-mr-2" @click="Buscar" />
                    <InputSwitch v-model="opcion" v-tooltip.bottom="'Cédula <-> Nombre'"/>				
					</template>
					
					<template v-slot:right>
					</template>
				
				</Toolbar>
				<DataTable :value="matriculas" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters" v-if="matriculas.length>0">
					<template #header>
						<h4>
							{{estudiante.nac}}{{estudiante.id}} {{estudiante.apellido}}, {{estudiante.nombre}} ({{estudiante.nucleo}})		
						</h4>
					</template>
					<Column field="plan" header="Plan de Estudio" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.plan}}
						</template>
					</Column>
					<Column field="periodo" header="Período" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.periodo}}
						</template>
					</Column>
					<Column field="sec" header="Sección" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.sec}}
						</template>
					</Column>
					<Column field="planes" header="Calificaciones">
						<template #body="slotProps">
							<span class="p-overlay-badge p-mr-5" @click="openCalificaciones(slotProps.data)">
								<i class="bi bi-journal-bookmark" style="font-size: 2em"></i>
								<span class="p-badge">{{slotProps.data.calificaciones}}</span>
							</span>
						</template>
					</Column>
					<Column field="activo" header="Estatus">
						<template #body="slotProps">
								<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
						</template>
					</Column>
					<Column field="beca" header="Beca">
						<template #body="slotProps">
						<span class="p-tag p-tag-success" v-if="slotProps.data.beca>0">{{becas[slotProps.data.beca].nombre}}</span>
						<span class="p-tag p-tag-danger" v-else>No Aplica</span>
						</template>
					</Column>
					<Column header="Opciones">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editMat(slotProps.data)" v-if="edit" v-tooltip.bottom="'Editar'"/>
						</template>
					</Column>
				</DataTable>
				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm" v-else>
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Estudiantes</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<!--<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>-->
					<Column field="nucleo" header="Extensión" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nucleo}}
						</template>
					</Column>
					<Column field="id" header="N° Cédula" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nac}}{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="nombre" header="Nombre" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="apellido" header="Apellido" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.apellido}}
						</template>
					</Column>
					<Column field="activo" header="Estatus">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
						</template>
					</Column>
					<Column header="Opciones">
						<template #body="slotProps">
							<Button icon="pi pi-play" class="p-button-rounded p-button-info p-mr-2" @click="openMatricula(slotProps.data)" v-if="edit" v-tooltip.bottom="'Mostrar'"/>
						</template>
					</Column>
				</DataTable>


				<Dialog v-model:visible="matriculaDialog" :style="{width: size}" :header="'Tipo de Beca '+titulo" :modal="true" class="p-fluid" :maximizable="true">
					<br>
					<h4>{{estudiante.nac}}{{estudiante.id}} {{estudiante.apellido}}, {{estudiante.nombre}} ({{estudiante.nucleo}})</h4>
					<div class="p-field">
						<div class="p-grid">
							<div class="p-col-12">
								<label class="p-mb-2"></label>
								<div v-for="(stats, index) in becas" :key="index">
									<RadioButton id="beca" name="subcateg" :value="stats.code" v-model="matricula.beca"/>
									<strong> {{stats.nombre}}<br><br></strong>
								</div>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveMat()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger"  @click="matriculaDialog = false"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>
	<Calificaciones :calificaciones="calificaciones" :matricula="matricula" :display="displayCalificaciones" @aprob="onMenuToggle" @close-calificaciones="close"/>



</template>

<script>
	import API from "../service/API";
	import Calificaciones from '../components/VerCalificaciones.vue';
	const Consulta = new API('Prosecusion','Becas');

	export default {
		components: {
		'Calificaciones': Calificaciones
		},
		data() {
			return {
				nuevo: false,
				buscar: null,
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				matriculas: [],
				nac: [
					{code: 'V', nombre: 'Venezolano(a)'},
					{code: 'E', nombre: 'Extranjero(a)'}
				],
				productDialog: false,
				deleteProductDialog: false,
				deleteMatriculaDialog: false,
				deleteProductsDialog: false,
				product: {},
				estudiante: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '40vw',
				becas: [],
				matriculaDialog: false,
				matricula: {},
				displayCalificaciones: false,
				calificaciones: [],
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			onMenuToggle(event) {
				this.$emit('menu-toggle', event);
			},
				openCalificaciones(matricula) {
					this.$store.commit('Mobile');
					this.matricula = {...matricula};
					Consulta.Procesar('Calificaciones',{
						id: 	this.matricula.id,
					}).then(response => {
						//this.$store.state.error =response.result;
						this.calificaciones = response.result;
						this.displayCalificaciones = true;
						this.$store.commit('Loading');
					}); 
				},
				close() {
					this.matricula = {};
					this.displayCalificaciones = false;
				},
			Mostrar() {
				this.matriculas = [];
				Consulta.Ini().then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.becas = result.consult.becas;
					this.$store.commit('Loading');
				}); 
			},
			Buscar() {
				this.matriculas = [];
				if (this.buscar){
					Consulta.Procesar('Buscar',{
						id: 	this.buscar,
					}).then(response => {
						//this.$store.state.error =response;
						this.products = response.result;
						this.$store.commit('Loading');
					}); 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail:'Debes colocar los datos solicitados3s', 
						life: 3000
					});    
				}
			},
			openMatricula(estudiante) {
				this.estudiante = {};
				this.estudiante = {...estudiante};
				if (estudiante.planes>0){
					Consulta.Procesar('Matricula',{
						id: 	this.estudiante.id,
						nucleo: 	this.estudiante.nucleo_id,
					}).then(response => {
						//this.$store.state.error = response.result.periodos;
						this.matriculas = response.result.matriculas;
						this.$store.commit('Loading');
					}); 
				} else {
					this.newMat(estudiante);
				}
			},
			editMat(matricula) {
				this.titulo = '(Editar)';
				this.matricula = {};
				this.matricula = {...matricula};
				this.matriculaDialog = true;
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			cerrar() {
				this.matriculaDialog = false;
			},
			saveMat() {
				if (this.matricula.beca == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Tipo de Beca', life: 5000});
				} else {
					this.buscar = this.estudiante.id;
					if (this.matricula.id) {
						Consulta.Procesar('UPD',{
							id: 	this.matricula.id,
							beca: this.matricula.beca,
						}).then(response => {
							//this.$store.state.error =response;
							this.$store.commit('Loading');
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.openMatricula(this.estudiante);
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
							this.matriculaDialog = false;
						});
					}
					this.productDialog = false;
					this.product = {};
				}
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
