<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Carga Académica Actual: {{products.length}}</h5>
							<!--Total de Horas: {{totalhoras}}-->
							<Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver()" v-tooltip.bottom="'Ver Horario'" v-if="products.length>0"/>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="periodo" header="Período" :sortable="true">	
						<template #body="slotProps">
							<strong style="color: blue;" v-if="slotProps.data.periodo_act">{{slotProps.data.periodo}}</strong>
							<strong style="color: red;" v-else>{{slotProps.data.periodo}}</strong>						
						</template>
					</Column>
					<Column header="Plan de Estudio" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.plan}} {{slotProps.data.codplan}}
						</template>
					</Column>
					<Column field="cod" header="Código" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.cod}}
						</template>
					</Column>
					<Column field="asignatura" header="Asignatura" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.asignatura}}
						</template>
					</Column>
					<Column field="secf" header="Sección" :sortable="true">	
						<template #body="slotProps">
							{{slotProps.data.secf}}<br>
						</template>
					</Column>
					<Column header="Ver">
						<template #body="slotProps">
							<span class="p-overlay-badge p-mr-4" v-if="slotProps.data.fusionsec.length==0">
								<Button icon="pi pi-file-pdf" class="p-button-rounded p-button-info" v-tooltip.bottom="'Inscritos'" @click="VerINS(slotProps.data)"/>
								<span class="p-badge">{{slotProps.data.inscritos}}</span>
							</span>
							<span class="p-tag p-tag-warning" v-if="slotProps.data.fusionsec.length>0" @click="verFusion(slotProps.data.fusionsec)">Fusionada</span>
							<Button icon="pi pi-upload" class="p-button-rounded p-button-help p-mr-2" @click="VerXLS(slotProps.data)" v-if="slotProps.data.fusionsec.length==0"/>
						</template>
					</Column>
				</DataTable>
				<br>
				<Sidebar v-model:visible="verfusionDialog" position="top">
					<h4>Principal de la Fusión en la Carrera: <strong>{{fusion.codplan}} {{fusion.plan}}</strong></h4>
					<h5>Sección: <strong>S{{fusion.sem}}{{fusion.secc}}</strong>,  Asignatura: <strong>{{fusion.asignatura}} ({{fusion.cod}})</strong></h5>
				</Sidebar>

			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	import PDF from "../service/PDF";
	import XLS from "../service/XLS";
	const Consulta = new API('AreaDocente','DocAcad');

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				productDialog: false,
				productDialog2: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				HoraDocDialog: false,
				product: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '80vw',
				horariosec: [],
				asignaturas: [],
				horario: [],
				horariodoc: [],
				asigdoc: [],
				hora: null,
				totalhoras: 0,
				fusionDialog: false,
				seleccion: {},
				fusion: null,				
				verfusionDialog: false,
				docente: null,
				cruce: [],
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.$store.state.loading = true;	
				this.totalhoras = 0;
				Consulta.Ini().then(result => {
					//this.$store.state.error = result.consult.oferta[0];
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.products = result.consult.oferta;
					this.products.forEach(element => {
						if(element.fusionsec.length==0){
							this.totalhoras = this.totalhoras + element.horas;
						}
					});
					this.$store.commit('Loading');
				}); 
			},
			Ver(){
				Consulta.Procesar('Horario',{}).then(response => {
						//this.$store.state.error =response;
						if (response.result) {
							this.$store.commit('Loading');
							const doc = new PDF();
							doc.VerHorarioD(response.result.docente,response.result.asignaturas,response.result.horario);
						} 
					});
			},
			VerINS(data){
				Consulta.Procesar('Inscritos',{
					oferta: data.id
					}).then(response => {
						//this.$store.state.error =response.result[0];
						if (response.result) {
							var dat = data;
							dat['inscripcion'] = response.result;
							const doc = new PDF();
							doc.Inscritos(dat);
						} 
						this.$store.commit('Loading');
					});
			},
			VerXLS(data){
				Consulta.Procesar('Inscritos',{
					oferta: data.id
					}).then(response => {
						//this.$store.state.error =response.result[0];
						if (response.result) {
							var dat = data;
							dat['inscripcion'] = response.result;
							this.$store.commit('Loading');
							const xls = new XLS();
							xls.InscritosDoc(dat);
						} 
					});
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			verFusion(data) {
				this.fusion = data[0];
				this.verfusionDialog = true;
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
				convfech(fecha){
					return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
				},

		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
