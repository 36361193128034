<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
                        <Dropdown v-model="nucleo" :options="nucleos" optionLabel="nombre" placeholder="Seleccione la Extensión ..." @change="Limpiar"/>
                        <Dropdown v-model="periodo" :options="periodos" optionLabel="per" placeholder="Seleccione el Período ..." v-if="nucleo"/>
                        <Dropdown v-model="turno" :options="turnos" optionLabel="nombre" placeholder="Seleccione el Turno ..." v-if="periodo"/>
                        <Dropdown v-model="dia" :options="dias" optionLabel="nombre" placeholder="Seleccione el Día ..." v-if="periodo"/>
					</template>
					<template v-slot:right>
						<Button icon="pi pi-upload" class="p-button-rounded p-button-help p-mr-2" @click="VerXLS()" v-if="docentes.length>0"/>
						<Button label="Control de Asistencia" icon="pi pi-file-pdf" class="p-button-primary p-mr-2" @click="Asistencia()" v-if="docentes.length>0"/>
						<Button label="Buscar" icon="pi pi-search" class="p-button-success p-mr-2" @click="Buscar()" v-if="periodo"/>
					</template>			
				</Toolbar>
				<DataTable :value="docentes" :paginator="true" scrollHeight="400px" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters" v-if="docentes.length>0">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-1">Horario de Docentes: {{docentes.length}}</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column header="Extensión" :sortable="true">
						<template #body="slotProps" >
							{{slotProps.data.nucleo}}
						</template>
					</Column>
					<Column field="id" header="N° Cédula" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="nombre" header="Nombre" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="apellido" header="Apellido" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.apellido}}
						</template>
					</Column>
					<Column header="Consultar">
						<template #body="slotProps">
							<Button icon="pi pi-calendar-minus" class="p-button-rounded p-button-success p-mr-1" @click="VerHorario(slotProps.data,2)" v-tooltip.bottom="'Horario'"/>
							<Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="VerPDF(slotProps.data,2)" v-tooltip.bottom="'PDF'"/>
							{{slotProps.data.horas}} horas
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
	</div>

	<Horario :asignaturas="asignaturas" :cruce="cruce" :horario="horario" :display="openHorario" @aprob="onMenuToggle" @close-horario="close"/>

</template>

<script>
	import API from "../service/API";
	import Horario from '../components/VerHorario.vue';
	import PDF from "../service/PDF";
	import XLS from "../service/XLS";
	const Consulta = new API('Consulta','ConsHorarioD');

	export default {
		components: {
			'Horario': Horario,
		},
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
                nucleo: null,
                nucleos: [],
                periodos: [],
                periodo: null,
                turno: {"nombre": "TODOS LOS TURNOS", "code": 0},
                turnos: [],
                dia: {"nombre": "TODOS LOS DÍAS", "code": 0},
                dias: [],
                aulas: [],
                docentes: [],
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				cruce: [],
				horario: [],
				asignaturas: [],
				openHorario: false
			}
		},
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
            this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
            Mostrar() {
                this.aulas = [];
                this.docentes = [];
                this.turnos = [];
                this.dias = [];
                Consulta.Ini().then(result => {
                    //this.$store.state.error = result.consult.turnos;
                    this.insert = result.insert;
                    this.edit = result.edit;
                    this.del = result.delete;
                    this.nucleos = result.consult.nucleos;
                    this.periodos = result.consult.periodos;
                    this.turnos = [{"nombre": "TODOS LOS TURNOS", "code": 0}].concat(result.consult.turnos);
                    this.dias = [{"nombre": "TODOS LOS DÍAS", "code": 0}].concat(result.consult.dias);
                    this.$store.commit('Loading');
                }); 
            },
			Asistencia(){
				this.$store.commit('Mobile');
				Consulta.Procesar('AsistenciaDOC',{
					periodo: this.periodo.code,
						nucleo: this.nucleo.code,
						turno: this.turno.code,
						dia: this.dia.code,
				}).then(response => {
					//this.$store.state.error =response.result[0];
					this.$store.commit('Loading');
					const doc = new PDF();
					doc.AsistenciaDOC(this.nucleo,this.periodo,this.turno,this.dia,response.result);
				}); 
			},
			VerXLS(){
				this.$store.commit('Mobile');
				Consulta.Procesar('AsistenciaDOC',{
					periodo: this.periodo.code,
						nucleo: this.nucleo.code,
						turno: this.turno.code,
						dia: this.dia.code,
				}).then(response => {
					//this.$store.state.error =response.result[0];
					this.$store.commit('Loading');
					const xls = new XLS();
					xls.AsistenciaDOC(this.nucleo,this.periodo,this.turno,this.dia,response.result);
				}); 
			},
            Limpiar() {
                this.docentes = [];
            },
            Buscar() {
                if(this.periodo && this.nucleo){
                    this.docentes = [];
					Consulta.Procesar('Buscar',{
						periodo: this.periodo.code,
						nucleo: this.nucleo.code,
						turno: this.turno.code,
						dia: this.dia.code,
					}).then(response => {
						//this.$store.state.error = response.result;
						this.docentes =  response.result;
						this.$store.commit('Loading');  
					});				
                }
            },
			VerHorario(data) {
				this.asignaturas = [];
				this.horario = [];
				this.cruce = [];
				this.$store.commit('Mobile');
				Consulta.Procesar('VerHorario',{
					periodo: 	this.periodo.code,
					data: 		data,
				}).then(response => {
					//this.$store.state.error =response;
					this.asignaturas = response.result.asignaturas;
					this.horario = response.result.horario;
					this.cruce = response.result.cruce;
					this.openHorario = true;
					this.$store.commit('Loading');
				}); 
			},
			VerPDF(data){
				this.asignaturas = [];
				this.horario = [];
				this.cruce = [];
				this.$store.commit('Mobile');
				Consulta.Procesar('VerHorario',{
					periodo: 	this.periodo.code,
					data: 		data,
				}).then(response => {
					//this.$store.state.error =response;
					this.$store.commit('Loading');
					const doc = new PDF();
					doc.VerHorario(this.nucleo,this.periodo,data,response.result.horario,response.result.asignaturas,2);
				}); 
			},
			close() {
				this.openHorario = false;
			},




        }
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
