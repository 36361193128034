<template>
	<div class="p-grid">
		<div class="p-col-2">
			<Card style="margin-bottom: 2em">
				<template #title>
					Parámetros
				</template>
				<template #content>
					<div class="p-fluid grid formgrid">
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="Guardar()" />
					</div>
				</template>
			</Card>
		</div>

		<div class="p-col-10">
			<Accordion :activeIndex="opcion">
				<AccordionTab header="Activación Preinscripción">
					<Card style="margin-bottom: 2em">
						<template #content>
							<div class="p-fluid grid formgrid">
								<div class="p-col-4">
									<h6>Período</h6>
									<Dropdown v-model="product.preins_periodo" :options="periodos" optionLabel="per" placeholder="Período ..."/>
								</div>
								<div class="p-col-4">
									<h6 style="margin-top: 0" v-if="product.preinscricpion">Externo</h6>
									<h6 style="margin-top: 0" v-else>Interno</h6>
									<InputSwitch v-model="product.preinscricpion" />
								</div>
							</div>
						</template>
					</Card>			
				</AccordionTab>
				<AccordionTab header="Preinscripción: Correo Inicial">
					<Card style="margin-bottom: 2em">
						<template #content>
							<div class="p-fluid grid formgrid">
								<div class="p-col-12">
									<label>Contenido</label>
									<Editor v-model="product.preins_menini" editorStyle="height: 320px"/>
								</div>
								<div class="p-col-12">
									<label>Correo</label>
									<InputText v-model="correo" type="text"/>
								</div>
								<div class="p-col-4">
									<Button label="Enviar" icon="pi pi-check" class="p-button-info  p-mr-2 p-mb-2" @click="Enviar(product.preins_menini)"/>
								</div>
							</div>
						</template>
					</Card>			
				</AccordionTab>
				<AccordionTab header="Preinscripción: Correo de Aceptación">
					<Card style="margin-bottom: 2em">
						<template #content>
							<div class="p-fluid grid formgrid">
								<div class="p-col-12">
									<label>Contenido</label>
									<Editor v-model="product.preins_mensaje" editorStyle="height: 320px"/>
								</div>
								<div class="p-col-12">
									<label>Correo</label>
									<InputText v-model="correo" type="text"/>
								</div>
								<div class="p-col-4">
									<Button label="Enviar" icon="pi pi-check" class="p-button-info  p-mr-2 p-mb-2" @click="Enviar(product.preins_mensaje)"/>
								</div>
							</div>
						</template>
					</Card>
				</AccordionTab>
				<AccordionTab header="Preinscripción: Condiciones">
					<Card style="margin-bottom: 2em">
						<template #content>
							<div class="p-fluid grid formgrid">
								<div class="p-col-12">
									<label>Contenido</label>
									<Editor v-model="product.preins_compromiso" editorStyle="height: 320px"/>
								</div>
							</div>
						</template>
					</Card>
				</AccordionTab>
				<AccordionTab header="Inscripción: Carta Convenio">
					<Card style="margin-bottom: 2em">
						<template #content>
							<div class="p-fluid p-grid formgrid">
								<div class="p-col-12">
									<label>Encabezado</label>
                                    <Textarea v-model="product.convenio_ini" rows="2"/>								
								</div>
								<div class="p-col-6">
									01. <Textarea v-model="product.convenio01" rows="2"/>								
								</div>
								<div class="p-col-6">
									02. <Textarea v-model="product.convenio02" rows="2"/>								
								</div>
								<div class="p-col-6">
									03. <Textarea v-model="product.convenio03" rows="2"/>								
								</div>
								<div class="p-col-6">
									04. <Textarea v-model="product.convenio04" rows="2"/>								
								</div>
								<div class="p-col-6">
									05. <Textarea v-model="product.convenio05" rows="2"/>								
								</div>
								<div class="p-col-6">
									06. <Textarea v-model="product.convenio06" rows="2"/>								
								</div>
								<div class="p-col-6">
									07. <Textarea v-model="product.convenio07" rows="2"/>								
								</div>
								<div class="p-col-6">
									08. <Textarea v-model="product.convenio08" rows="2"/>								
								</div>
								<div class="p-col-6">
									09. <Textarea v-model="product.convenio09" rows="2"/>								
								</div>
								<div class="p-col-6">
									10. <Textarea v-model="product.convenio10" rows="2"/>								
								</div>
								<div class="p-col-6">
									11. <Textarea v-model="product.convenio11" rows="2"/>								
								</div>
								<div class="p-col-6">
									12. <Textarea v-model="product.convenio12" rows="2"/>								
								</div>
								<div class="p-col-6">
									13. <Textarea v-model="product.convenio13" rows="2"/>								
								</div>
								<div class="p-col-6">
									14. <Textarea v-model="product.convenio14" rows="2"/>								
								</div>
								<div class="p-col-6">
									15. <Textarea v-model="product.convenio15" rows="2"/>								
								</div>
								<div class="p-col-6">
									16. <Textarea v-model="product.convenio16" rows="2"/>								
								</div>
								<div class="p-col-6">
									17. <Textarea v-model="product.convenio17" rows="2"/>								
								</div>
								<div class="p-col-6">
									18. <Textarea v-model="product.convenio18" rows="2"/>								
								</div>
								<div class="p-col-6">
									19. <Textarea v-model="product.convenio19" rows="2"/>								
								</div>
								<div class="p-col-6">
									20. <Textarea v-model="product.convenio20" rows="2"/>								
								</div>
                                <div class="p-col-12">
									<label>Pie de Página</label>
                                    <Textarea v-model="product.convenio_fin" rows="2"/>								
								</div>
							</div>
						</template>
					</Card>
				</AccordionTab>
				<AccordionTab header="Acta de Grado">
					<Card style="margin-bottom: 2em">
						<template #content>
							<div v-for="(stats, index) in acta" :key="index">
								<div class="p-fluid p-grid formgrid">
									<div class="p-col-2"><h5>Párrafo {{index+1}}</h5> </div>
									<div class="p-col-1">
										<label>Orden</label> 
										<InputNumber v-model="stats.orden" required="true"/>					
									</div>
									<div class="p-col-1">
										<label>Altura</label> 
										<InputNumber v-model="stats.alt" required="true"/>					
									</div>
									<div class="p-col-1">
										<label>Tamaño</label> 
										<InputNumber v-model="stats.tam" required="true"/>					
									</div>
									<div class="p-col-1">
										<label v-if="stats.normal">Negrita</label> 
										<label v-else>Normal</label> 
										<InputSwitch :id="index" v-model="stats.normal" />
									</div>
									<div class="p-col-1">
										<Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-mr-2" v-tooltip.bottom="'Eliminar'"  @click="Eliminar(1,index)"/>
									</div>
									<div class="p-col-10">
										<Textarea v-model="stats.contenido"  rows="6"/>						
									</div>
									<div class="p-col-2">
										<div class="p-fluid p-grid formgrid">
											<div class="p-col-12">
												<RadioButton name="city" value="justify" v-model="stats.alin" />
												<label for="city1"> Justificado</label>											
											</div>
											<div class="p-col-12">
												<RadioButton name="city" value="center" v-model="stats.alin" />
												<label for="city2"> Centro</label>											
											</div>
											<div class="p-col-12">
												<RadioButton name="city" value="right" v-model="stats.alin" />
												<label for="city3"> Deracha</label>											
											</div>
											<div class="p-col-12">
												<RadioButton name="city" value="left" v-model="stats.alin" />
												<label for="city4"> Izquierda</label>											
											</div>
										</div>
									</div>
								</div>
							</div>
							<Button icon="pi pi-plus" class="p-button-rounded p-button-success p-mr-2" v-tooltip.bottom="'Agregar'" @click="Agregar(1)"/>
							<h5>Codificación</h5>
							<div class="p-fluid p-grid formgrid">
								<div class="p-col-3"><strong>&CED</strong> Cédula de Identidad</div>
								<div class="p-col-3"><strong>&NAC</strong> Nacionalidad</div>
								<div class="p-col-3"><strong>&APE</strong> Apellidos</div>
								<div class="p-col-3"><strong>&NOM</strong> Nombres</div>
								<div class="p-col-3"><strong>&TIT</strong> Título</div>
								<div class="p-col-3"><strong>&LIB</strong> N° de Libro</div>
								<div class="p-col-3"><strong>&ACT</strong> N° de Acta de Grado</div>
								<div class="p-col-3"><strong>&FEC</strong> Fecha de Acta DD/MM/AAAA</div>
								<div class="p-col-3"><strong>&FEL</strong> Fecha de Acta en letras</div>
								<div class="p-col-3"><strong>&LUG</strong> Lugar de Nacimiento</div>
								<div class="p-col-3"><strong>&FAC</strong> Fecha Actual DD/MM/AAAA</div>
								<div class="p-col-3"><strong>&FAL</strong> Fecha Actual en letras</div>
								<div class="p-col-3"><strong>&LEX</strong> Lugar de la Extensión</div>
							</div>
						</template>
					</Card>
				</AccordionTab>
				<AccordionTab header="Certificación de Grado">
					<Card style="margin-bottom: 2em">
						<template #content>
							<div v-for="(stats, index) in certificacion" :key="index">
								<div class="p-fluid p-grid formgrid">
									<div class="p-col-2"><h5>Párrafo {{index+1}}</h5> </div>
									<div class="p-col-1">
										<label>Orden</label> 
										<InputNumber v-model="stats.orden" required="true"/>					
									</div>
									<div class="p-col-1">
										<label>Altura</label> 
										<InputNumber v-model="stats.alt" required="true"/>					
									</div>
									<div class="p-col-1">
										<label>Tamaño</label> 
										<InputNumber v-model="stats.tam" required="true"/>					
									</div>
									<div class="p-col-1">
										<label v-if="stats.normal">Negrita</label> 
										<label v-else>Normal</label> 
										<InputSwitch :id="index" v-model="stats.normal" />
									</div>
									<div class="p-col-1">
										<Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-mr-2" v-tooltip.bottom="'Eliminar'"  @click="Eliminar(1,index)"/>
									</div>
									<div class="p-col-10">
										<Textarea v-model="stats.contenido"  rows="6"/>						
									</div>
									<div class="p-col-2">
										<div class="p-fluid p-grid formgrid">
											<div class="p-col-12">
												<RadioButton name="city" value="justify" v-model="stats.alin" />
												<label for="city1"> Justificado</label>											
											</div>
											<div class="p-col-12">
												<RadioButton name="city" value="center" v-model="stats.alin" />
												<label for="city2"> Centro</label>											
											</div>
											<div class="p-col-12">
												<RadioButton name="city" value="right" v-model="stats.alin" />
												<label for="city3"> Deracha</label>											
											</div>
											<div class="p-col-12">
												<RadioButton name="city" value="left" v-model="stats.alin" />
												<label for="city4"> Izquierda</label>											
											</div>
										</div>
									</div>
								</div>
							</div>
							<Button icon="pi pi-plus" class="p-button-rounded p-button-success p-mr-2" v-tooltip.bottom="'Agregar'" @click="Agregar(2)"/>
							<h5>Codificación</h5>
							<div class="p-fluid p-grid formgrid">
								<div class="p-col-3"><strong>&CED</strong> Cédula de Identidad</div>
								<div class="p-col-3"><strong>&NAC</strong> Nacionalidad</div>
								<div class="p-col-3"><strong>&APE</strong> Apellidos</div>
								<div class="p-col-3"><strong>&NOM</strong> Nombres</div>
								<div class="p-col-3"><strong>&TIT</strong> Título</div>
								<div class="p-col-3"><strong>&LIB</strong> N° de Libro</div>
								<div class="p-col-3"><strong>&ACT</strong> N° de Acta de Grado</div>
								<div class="p-col-3"><strong>&FEC</strong> Fecha de Acta DD/MM/AAAA</div>
								<div class="p-col-3"><strong>&FEL</strong> Fecha de Acta en letras</div>
								<div class="p-col-3"><strong>&LUG</strong> Lugar de Nacimiento</div>
								<div class="p-col-3"><strong>&FAC</strong> Fecha Actual DD/MM/AAAA</div>
								<div class="p-col-3"><strong>&FAL</strong> Fecha Actual en letras</div>
								<div class="p-col-3"><strong>&LEX</strong> Lugar de la Extensión</div>
							</div>
						</template>
					</Card>
				</AccordionTab>
				<AccordionTab header="Notificación de Pago">
					<Card style="margin-bottom: 2em">
						<template #content>
							<div class="p-fluid grid formgrid">
								<div class="p-col-12">
									<label>Contenido</label>
									<Editor v-model="product.notificacionpago" editorStyle="height: 320px"/>
								</div>
							</div>
						</template>
					</Card>
				</AccordionTab>

			</Accordion>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Administracion','Parametros');

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				product: {},
				submitted: false,
				size: '60vw',
				periodos: [],
				correo: null,
                aceptar: false,
				acta: [],
				certificacion: [],
				opcion: null,
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}

			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.opcion = null;
				this.product = {};
				this.periodos = [];
				this.acta = [];
				this.certificacion = [];
				Consulta.Ini().then(result => {
					//this.$store.state.error = result.consult.parrafos;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.product = result.consult.configuracion;
					this.periodos = result.consult.periodos;
					this.periodos.forEach(element => {
						if(this.product.preins_periodo==element.code){
							this.product.preins_periodo = element;
						}
					});
					this.acta = result.consult.parrafos.filter(val => val.ventana == 'acta');
					this.certificacion = result.consult.parrafos.filter(val => val.ventana == 'certificado');
					this.$store.commit('Loading');
				}); 
			},
			Agregar(caso){
				if(caso == 1){
					this.acta.push({
						id: 0,
						ventana: 'acta',
						alin: 0,
						orden: 0,
						tam: 0,
						normal: false,
						alt: 0,
						contenido: ''
					});
				}
				if(caso == 2){
					this.certificacion.push({
						id: 0,
						ventana: 'certificado',
						alin: 0,
						orden: 0,
						tam: 0,
						normal: false,
						alt: 0,
						contenido: ''
					});
				}
			},
			Eliminar(caso,index){
				if(caso == 1){
					this.acta.splice(index, 1);
				}
				if(caso == 2){
					this.certificacion.splice(index, 1);
				}
			},
			Guardar() {
				Consulta.Procesar('UPD',{
					produc: 		this.product,
					acta: 			this.acta,
					certificacion: 	this.certificacion,
				}).then(response => {
					//this.$store.state.error =response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.$store.commit('Loading');
						this.Mostrar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
						this.$store.commit('Loading')
					}
				});
			},
			Enviar(mensaje) {
				if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
					test(this.correo)) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Correo', life: 10000});
				} else {
					Consulta.Procesar('SEND',{
						correo: this.correo,
						mensaje: mensaje,
					}).then(response => {
						//this.$store.state.error =response;
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Información Procesada', 
								life: 3000
							});
							this.$store.commit('Loading');
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: 'No fue procesada la solicitud', 
								life: 3000
							});
							this.$store.commit('Loading');
						}
					});
				}
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
				convfech(fecha){
					return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
				},

		}
	}
</script>

<style scoped lang="scss">

.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
