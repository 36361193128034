<template>
	<div class="p-grid panel-demo">
		<div class="p-col-12 p-lg-12 ">
			<i class="bi bi-journal-bookmark" style="color: #d46900;fontSize: 1.5rem"> Preinscripción</i>
		</div>	
		<div class="p-col-12 p-md-12">
            <div class="card">
                <Steps :model="items" :readonly="true" />
            </div>
            <router-view v-slot="{Component}" :formData="formObject" @prev-page="prevPage($event)" @next-page="nextPage($event)" @complete="complete">
                <keep-alive>
                    <component :is="Component" />
                </keep-alive>
            </router-view>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				items: [
					{
						label: 'Reportar Pago',
						to: '/preinscripcion'
					},
					{
						label: 'Seleccionar Extensión y Carrera',
						to: '/preinscripcion_paso2'
					},
					{
						label: 'Datos Personales',
						to: '/preinscripcion_paso3'
					},
					{
						label: 'Datos Académicos',
						to: '/preinscripcion_paso4'
					},
					{
						label: 'Confirmar',
						to: '/preinscripcion_paso5'
					}
				],
				formObject: {},
				cedula: null,
				correo: null,
				codigo: null,
				token: null,
				size: '30vw',
				Dialog1: false,
				Nuevo: false,
				Verificacion: false,
			}
		},
		created() {
			this.Nuevo = false;
			this.Verificacion = false;
			if(!this.$store.state.preinscripcion){
				this.$router.push({ path: '/' });
			} 
			if (this.$store.state.mobile){this.size = '90vw';}
		},
		methods: {
			nextPage(event) {
				for (let field in event.formData) {
					this.formObject[field] = event.formData[field];
				}
				this.$router.push(this.items[event.pageIndex + 1].to);
			},
			prevPage(event) {
				this.$router.push(this.items[event.pageIndex - 1].to);
			},
			complete() {
				this.$toast.add({severity:'success', summary:'Order submitted', detail: 'Dear, ' + this.formObject.firstname + ' ' + this.formObject.lastname + ' your order completed.'});
			},


		}
	}
</script>

