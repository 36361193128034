<template>
	<div class="p-grid p-fluid">
		<div class="p-col-12 p-lg-12">
			<h3 class="centerText">Reporte Administrativo</h3>
		</div>
		<div class="p-col-4 p-lg-4">
			<label>Extensión</label>
			<Dropdown v-model="nucleo" :options="nucleos" optionLabel="nombre" placeholder="Seleccione..."/>
		</div>
		<div class="p-col-2 p-lg-2">
			<label>Período</label>
			<Dropdown v-model="periodo" :options="periodos" optionLabel="per" placeholder="Seleccione..."/>
		</div>
		<div class="p-col-2 p-lg-2">
            <Button label="Buscar" icon="pi pi-search" class="p-button-success p-mr-2 p-mb-2" @click="Buscar" />
		</div>
		<div class="p-col-4 p-lg-4" v-if="total>0">
			<h4 class="centerText">Total: {{total}}</h4>
		</div>
		<div class="p-col-12 p-lg-12">
			<div class="card">
				<Chart type="horizontalBar" :data="Data1" />
			</div>
		</div>
		<div class="p-col-12 p-lg-12">
			<div class="card">
				<Chart type="horizontalBar" :data="Data2" />
			</div>
		</div>
	</div>
	
</template>

<script>
import API from "../service/API";
const Consulta = new API('Estadistica','EstadAdministrativo');

export default {
	data() {
		return {
			nucleos: [{nombre: 'Todas', code: 0}],
			nucleo: null,
			periodos: [],
			periodo: null,
			Data1: {},
			Data2: {},
			total: 0,
		}
	},
	created() {
		this.$store.commit('Validar',this.$route);
		this.Mostrar();
	},
	methods: {
		Mostrar() {
			Consulta.Ini().then(result => {
				//this.$store.state.error = result;
				this.insert = result.insert;
				this.edit = result.edit;
				this.del = result.delete;
                this.periodos = result.consult.periodos;
				if(result.consult.nucleos.length>1){
					result.consult.nucleos.forEach(element => {
						this.nucleos.push(element);
					});
					this.nucleo = this.nucleos[0];
				} else {
					this.nucleos = result.consult.nucleos;
					this.nucleo = this.nucleos[0];
				}
				this.$store.commit('Loading');
			}); 
		},
		Buscar() {
			this.Data1 = {};
			this.Data2 = {};
			this.total = 0
			Consulta.Procesar('Buscar',{
				periodo: this.periodo.code,
				nucleo: this.nucleo.code,
			}).then(response => {
				//this.$store.state.error = response;	
				this.Data1 = response.result.data1;
				this.Data2 = response.result.data2;
				this.total = response.result.total;
				this.$store.commit('Loading');  
			});
		},

	}
}
</script>

<style scoped>

</style>
