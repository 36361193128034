<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<InputText type="number" min="0" v-model="buscar" required="true" placeholder="Cédula" @keyup.enter="Buscar" v-if="opcion"/>
						<InputText type="text" v-model="buscar" style="width:250px;" required="true" placeholder="Apellido o Nombre" @keyup.enter="Buscar" v-if="!opcion"/>
						<InputSwitch v-model="opcion" v-tooltip.bottom="'Cédula <-> Nombre'"/>				
					</template>
					<template v-slot:right>
						<Button label="Buscar" icon="pi pi-search   " class="p-button-success p-mr-2" @click="Buscar"/>
					</template>			
				</Toolbar>
				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" 
					:filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Graduados</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="nucleo" header="Extensión" :sortable="true" headerStyle="width: 200px">
						<template #body="slotProps">
							{{slotProps.data.extension}}
						</template>
					</Column>
					<Column field="id" header="N° Cédula" :sortable="true" headerStyle="width: 120px">
						<template #body="slotProps">
							{{slotProps.data.nac}}-{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="nombre" header="Nombres" :sortable="true" headerStyle="width: 200px">
						<template #body="slotProps">
							{{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="apellido" header="Apellidos" :sortable="true" headerStyle="width: 200px">
						<template #body="slotProps">
							{{slotProps.data.apellido}}
						</template>
					</Column>
					<!--
					<Column field="year" header="Año" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.acta.year}}
						</template>
					</Column>
					<Column field="periodo" header="Período" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.acta.periodo}}
						</template>
					</Column>
					<Column field="tomo" header="Tomo" headerStyle="width: 100px">
						<template #body="slotProps">
							{{Romano(slotProps.data.acta.tomo)}}
						</template>
					</Column>
					<Column field="acta" header="Folio" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.acta.id.toString().padStart(5,'0')}}
						</template>
					</Column>
					<Column field="folio" header="Acta" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.libro.folio.toString().padStart(5,'0')}}
						</template>
					</Column>
					<Column field="periodo" header="Índice G." headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.libro.indice}}
						</template>
					</Column>
					<Column field="periodo" header="Posición" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.libro.posic_grado}}
						</template>
					</Column>
					-->
					<Column header="Opciones" headerStyle="width: 120px">
						<template #body="slotProps">
							<Button icon="pi pi-eye" class="p-button-rounded p-button-info p-mr-2" v-tooltip.bottom="'Ver'" @click="VerDetalle(slotProps.data)"/>
							<Button icon="bi bi-journal-bookmark" class="p-button-rounded p-button-success p-mr-2" @click="openCalificaciones(slotProps.data)" v-tooltip.bottom="'Calificaciones'"/>
							<Button icon="pi pi-file-pdf" class="p-button-rounded p-button-warning p-mr-2" v-tooltip.bottom="'Record'" @click="VerRecord(slotProps.data)"/>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
	</div>


	<Dialog v-model:visible="productDialog" :style="{width: size}" header="Detalle" :modal="true" class="p-fluid" :maximizable="true">
			<br>
            <div class="p-field">
				<div class="p-grid">
					<div class="p-col-12">
						<strong>Extensión: </strong>{{product.extension}}
					</div>
					<div class="p-col-9">
						<strong>Estudiante: </strong>{{product.apellido+', '+product.nombre}}
					</div>
					<div class="p-col-3">
						<strong>N° Cédula: </strong>{{product.nac+product.estudiante}}
					</div>
					<div class="p-col-9">
						<strong>Plan de Estudio: </strong>{{product.plan}}
					</div>
					<div class="p-col-3">
						<strong>Telf: </strong>{{product.tlf_local}}
					</div>
					<div class="p-col-9">
						<strong>Correo: </strong>{{product.correo}}
					</div>
					<div class="p-col-3">
						<strong>Celular: </strong>{{product.tlf_celular}}
					</div>
					<div class="p-col-12">
						<strong>Observación: </strong>{{product.observacion}}
					</div>

					<div class="p-col-4">
						<div class="p-grid">
							<div class="p-col-12">
								<strong>Período de Inicio: </strong>{{product.periodo}}
							</div>
							<div class="p-col-12">
								<strong>Período de Culminación: </strong>{{product.periodofin}}
							</div>
							<div class="p-col-12">
								<strong>Asignaturas Cursadas: </strong>{{product.cursado.asig}}
							</div>
							<div class="p-col-12">
								<strong>Asignaturas Aprobadas: </strong>{{product.asigs}}
							</div>
							<div class="p-col-12">
								<strong>UC Cursadas: </strong>{{product.cursado.uc}}
							</div>
							<div class="p-col-12">
								<strong>UC Aprobadas: </strong>{{product.tuc}}
							</div>
						</div>
					</div>
					<div class="p-col-4">
						<div class="p-grid">
							<div class="p-col-12">
								<strong>Período de Grado: </strong>{{product.acta.periodo}}
							</div>
							<div class="p-col-12">
								<strong>Fecha de Grado: </strong>{{product.acta.fechad}}
							</div>
							<div class="p-col-12">
								<strong>ÍRA: </strong>{{product.indicea}}
							</div>
							<div class="p-col-12">
								<strong>Índice de Grado: </strong>{{product.indiceg}}
							</div>
							<div class="p-col-12">
								<strong>Posición por Carrera: </strong>{{product.libro.posicion}}
							</div>
							<div class="p-col-12">
								<strong>Posición por Extensión: </strong>{{product.libro.posicion}}
							</div>
						</div>
					</div>
					<div class="p-col-4">
						<div class="p-grid">
							<div class="p-col-12">
								<strong>Resuelto: </strong>
							</div>
							<div class="p-col-12">
								<strong>Fecha: </strong>
							</div>
							<div class="p-col-12">
								<strong>N° Tomo: </strong>{{Romano(product.libro.tomo)}}
							</div>
							<div class="p-col-12">
								<strong>N° Folio: </strong>{{product.libro.folio}}
							</div>
							<div class="p-col-12">
								<strong>N° Asignado: </strong>{{product.libro.asignado.toString().padStart(4,'0')}}
							</div>
							<div class="p-col-12">
								<strong>N° Libro: </strong>{{Romano(product.libro.libro)}}
							</div>
							<div class="p-col-12">
								<strong>N° Acta: </strong>{{product.libro.acta.toString().padStart(4,'0')}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<template #footer>
				<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="productDialog = false"/>
			</template>
	</Dialog>



	<Calificaciones :calificaciones="calificaciones" :matricula="matricula" :display="displayCalificaciones" @aprob="onMenuToggle" @close-calificaciones="close"/>

</template>

<script>
	import API from "../service/API";
	import Calificaciones from '../components/VerCalificaciones.vue';
	import PDF from "../service/PDF";
	import Rom from "../service/Num_Romano";
	const Consulta = new API('Egreso','Graduados');

	export default {
		components: {
			'Calificaciones': Calificaciones,
		},
		data() {
			return {
				nuevo: false,
				buscar: null,
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				product: {},
				productDialog: false,
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				matriculaDialog: false,
				matricula: {},
				displayCalificaciones: false,
				calificaciones: [],
				displayInscricion: false,
				inscripcion: [],
				displayHorario: false,
				horario: [],
				cruce: [],
				convenio: []
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
		},
		mounted() {
			
		},
		methods: {
			onMenuToggle(event) {
				this.$emit('menu-toggle', event);
			},
			openCalificaciones(matricula) {
				this.$store.commit('Mobile');
				this.matricula = {...matricula};					
				Consulta.Procesar('VerCalificaciones',{
					id: 	this.matricula.id,
				}).then(response => {
					//this.$store.state.error =response.result;
					this.calificaciones = response.result;
					this.displayCalificaciones = true;
					this.$store.commit('Loading');
				}); 
			},
			VerRecord(matricula){
				this.$store.commit('Mobile');
				this.matricula = {...matricula};					
				Consulta.Procesar('VerCalificaciones',{
					id: 	this.matricula.id,
				}).then(response => {
					//this.$store.state.error =response.result;
					this.$store.commit('Loading');
					const doc = new PDF();
					doc.Record(this.matricula,response.result);
				}); 
			},
			VerDetalle(informacion) {
				this.product = {...informacion};
				this.productDialog = true;
			},
			close() {
				this.matricula = {};
				this.displayCalificaciones = false;
				this.displayInscricion = false;
				this.displayHorario = false;
			},
			Buscar() {
				if (this.buscar){
					Consulta.Procesar('Buscar',{
						id: 	this.buscar,
					}).then(response => {
						//this.$store.state.error = response.result;
						this.products = response.result;
						this.$store.commit('Loading');
					}); 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail:'Debes colocar los datos solicitados3s', 
						life: 3000
					});    
				}
			},
			Romano(num) {
				return Rom(num);
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}
				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
