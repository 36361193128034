<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<Dropdown v-model="opcion" :options="opciones" optionLabel="nombre" placeholder="Seleccione..."/>
						<Dropdown v-model="periodo" :options="periodos" optionLabel="per" placeholder="Período ..." v-if="opcion"/>
						<div v-if="periodo">
							<InputText type="number" min="0" v-model="buscar" required="true" placeholder="Cédula"/>
						</div>
					</template>				
					<template v-slot:right>
						<Button label="Buscar" icon="pi pi-search" class="p-button-success p-mr-2" @click="Buscar" v-if="opcion"/>
					</template>
				</Toolbar>
				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Casos: {{products.length}}</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="id" header="ID" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="periodo" header="Período" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.periodo}}
						</template>
					</Column>
					<Column field="nombre" header="Nombre y Apellido" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nombre}} {{slotProps.data.apellido}}
						</template>
					</Column>
					<Column field="asignatura" header="Asignatura" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.asignatura}}
						</template>
					</Column>
					<Column field="factura" header="Factura" headerStyle="width: 150px">
						<template #body="slotProps">
							<div v-if="slotProps.data.factura">
								{{slotProps.data.factura}} 
								<span class="p-tag p-tag-success" v-if="slotProps.data.fact.pagada">Verificado</span>
								<span class="p-tag p-tag-danger" v-else>Por Verificar</span>
							</div> 
							<div  v-else>
								-
							</div> 
						</template>
					</Column>
					<Column header="Nº Pagos" headerStyle="width: 150px">
						<template #body="slotProps">
							<strong v-if="slotProps.data.formapago==1">Al Contado </strong>
							<strong v-if="slotProps.data.formapago==2">A Credito </strong>
							<strong v-if="slotProps.data.formapago==3">Al Contado Una Materia </strong>
							<strong v-if="slotProps.data.formapago==0">No Definido </strong>
							({{slotProps.data.pagos.length}})
							<strong v-if="slotProps.data.exoneracion"> - Exoneración</strong>
						</template>
					</Column>
					<Column header="Nº Deudas" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.deudas.length}}
						</template>
					</Column>
					<Column header="Eliminar" headerStyle="width: 150px">
						<template #body="slotProps">
							<div v-if="slotProps.data.factura">
								<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDelete(slotProps.data)" v-if="!slotProps.data.fact.pagada"/>
							<strong v-if="slotProps.data.fact.pagada">Verifique, la factura fue pagada!!!</strong>
							</div> 
							<div  v-else>
								<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDelete(slotProps.data)"/>
							</div> 
							<div v-if="opcion.code==2">
								<div v-if="slotProps.data.calif">
									Calificaciones Cargadas
								</div> 
							</div> 
						</template>
					</Column>
				</DataTable>
			</div>
		</div>

		<Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
			<div class="confirmation-content">
				<br><br>
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span v-if="product">Confirme si desea eliminar este proceso y registro de factura</span>
			</div>
			<template #footer>
				<Button label="Sí" style="width: 110px;" icon="pi pi-save" class="p-button-secondary p-mr-2" @click="Del()"/>
				<Button label="No" style="width: 110px;" icon="pi pi-times" class="p-button-danger p-mr-2" @click="deleteDialog = false"/>
			</template>
		</Dialog>
		
	</div>
</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Administracion','Eliminar');

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				opcion: null,
				opciones: [
					{nombre: 'Preinscripción', code: 1},
					{nombre: 'Inscripción', code: 2},
				],
				buscar: null,
				products: [],
				product: {},
				estudiante: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				deleteDialog: false,
				seleccion: {},
				tipos: [],
				Dialog: false,
				periodo: null,
				periodos: [],
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.periodos = [];
				Consulta.Ini().then(result => {
					//this.$store.state.error = result.consult;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.periodos = result.consult.periodos;
					this.$store.commit('Loading');
				}); 
			},
			Buscar() {
				if (this.buscar && this.opcion && this.periodo){
					Consulta.Procesar('Buscar',{
						id: 	this.buscar,
						opcion: this.opcion.code,
						periodo: this.periodo.code,
					}).then(response => {
						//this.$store.state.error = response;
						this.products = response.result;
						this.$store.commit('Loading');
					}); 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail:'Debes colocar los datos solicitados', 
						life: 3000
					});    
				}
			},
			confirmDelete(selec) {
				this.seleccion = selec;
				this.deleteDialog = true;
			},
			Del() {
				if (this.seleccion){
					Consulta.Procesar('Procesar',{
						seleccion: 	this.seleccion,
						opcion: this.opcion.code,
					}).then(response => {
						//this.$store.state.error =response;
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Información Procesada', 
								life: 3000
							});
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: 'No fue procesada la solicitud', 
								life: 3000
							});
						}
						this.seleccion = {};
						this.Buscar();
						this.deleteDialog = false;						
						this.$store.commit('Loading');
					}); 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail:'Debes Verificar', 
						life: 3000
					});    
				}
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}
				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
			convfech(fecha){
				return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
			},
			toggle1(event) {
				this.$refs.op1.toggle(event);
			},


		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
