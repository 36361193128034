<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
                    <InputText type="number" min="0" v-model="buscar" required="true" placeholder="Cédula" @keyup.enter="Buscar" v-if="opcion"/>
                    <InputText type="text" v-model="buscar" style="width:250px;" required="true" placeholder="Apellido o Nombre" @keyup.enter="Buscar" v-if="!opcion"/>
					<br><br><br>
                    <InputSwitch v-model="opcion"/>				
					</template>
					
					<template v-slot:right>
					<Button label="Buscar" icon="pi pi-search" class="p-button-success p-mr-2 p-mb-2" @click="Buscar" />
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="openNew" v-if="insert"/>
						<!--
						<Button label="Export" icon="pi pi-upload" class="p-button-help p-mb-2" @click="exportCSV($event)"  />
						
						<FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="p-mr-2 p-mb-2 p-d-inline-block" />
							-->
					</template>
				
				</Toolbar>
				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-1">Docentes</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column header="Extensión" :sortable="true">
						<template #body="slotProps" >
							{{slotProps.data.nucleo}}
						</template>
					</Column>
					<Column field="id" header="N° Cédula" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="nombre" header="Nombre" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="apellido" header="Apellido" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.apellido}}
						</template>
					</Column>
					<Column field="correo" header="Correo" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.correo}}
						</template>
					</Column>
					<Column field="activo" header="Estatus" :sortable="true">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
						</template>
					</Column>
					<Column header="Opciones">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" v-if="edit" v-tooltip.bottom="'Editar'"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteProduct(slotProps.data)" v-if="del" v-tooltip.bottom="'Eliminar'"/>
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: size}" :header="'Docente '+titulo" :modal="true" class="p-fluid" :maximizable="true">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-2">
								<label class="p-mb-2">Nacionalidad</label>
								<Dropdown v-model="product.nac" :options="nac" optionLabel="nombre" placeholder="?..."></Dropdown>
							</div>
							<div class="p-col-2">
								<label for="name">N° Cédula</label>
								<InputNumber id="cedula" v-model="product.id" integeronly required="true" min="0" disable="true" autofocus v-if="!product.id"/>
								<InputText id="cedula" v-model="product.id" placeholder="Disabled" :disabled="true" v-else></InputText>						
							</div>
							<div class="p-col-4">
								<label for="name">Nombre</label>
								<InputText id="name" v-model.trim="product.nombre" required="true"/>
								<small class="p-invalid" v-if="submitted && !product.nombre">Name is required.</small>
							</div>
							<div class="p-col-4 ">
								<label for="name">Apellido</label>
								<InputText id="name" v-model.trim="product.apellido" required="true"/>
								<small class="p-invalid" v-if="submitted && !product.nombre">Name is required.</small>
							</div>
							<div class="p-col-2">
								<h6 style="margin-top: 0" v-if="product.sexo">Masculino</h6>
								<h6 style="margin-top: 0" v-else>Femenino</h6>
								<InputSwitch v-model="product.sexo" />
							</div>
							<div class="p-col-6 p-md-6">
								<label for="name">Correo</label>
								<InputText id="name" v-model.trim="product.correo" required="true"/>
							</div>
							<div class="p-col-4">
								<label class="p-mb-3">Extensión</label>
								<Dropdown id="state" v-model="product.nucleo" :options="nucleos" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-3">
								<label>Fec. Nacimiento</label>
								<InputText v-model="product.fecha_nac" type="date"/>
							</div>
							<div class="p-col-4">
								<label class="p-mb-2">País Nac.</label>
								<Dropdown v-model="product.pais_nac" :options="paises" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-5">
								<label class="p-mb-2">Ciudad Nac.</label>
								<Dropdown v-model="product.lugar_nac" :options="estados" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-3">
								<label class="p-mb-2">Est.Civil</label>
								<Dropdown v-model="product.est_civil" :options="estcivils" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-3">
								<label class="p-mb-2">Tipo</label>
								<Dropdown v-model="product.tipo" :options="tipos" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-3">
								<label class="p-mb-2">Categoría</label>
								<Dropdown v-model="product.categoria" :options="categorias" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-3">
								<label class="p-mb-2">Dedicación</label>
								<Dropdown v-model="product.dedicacion" :options="dedicacions" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-3 ">
								<label for="name">Rif</label>
								<InputText id="name" v-model.trim="product.rif" required="true"/>
							</div>
							<div class="p-col-9">
								<label>Observación</label>
								<Textarea v-model="product.observacion"  rows="4"/>						
							</div>
							<div class="p-col-2">
								<h6 style="margin-top: 0" v-if="product.activo">Activo</h6>
								<h6 style="margin-top: 0" v-else>No Activo</h6>
								<InputSwitch v-model="product.activo" />
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="hideDialog"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}" header="Confirmar" :modal="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button label="Sí" icon="pi pi-check" class="p-button-secondary" @click="deleteProduct" />
						<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Docente','Docentes');

	export default {
		data() {
			return {
				nuevo: false,
				buscar: null,
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				productDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				nucleos: [],
				estcivils: [],
				paises: [],
				estados: [],
				categorias: [],
				dedicacions: [],
				tipos: [],
				nac: [
					{code: 'V', nombre: 'Venezolano(a)'},
					{code: 'E', nombre: 'Extranjero(a)'}
				],
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}

			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				Consulta.Ini().then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.nucleos = result.consult.nucleos;
					this.estcivils = result.consult.estcivils;
					this.paises = result.consult.paises;
					this.estados = result.consult.estados;
					this.categorias = result.consult.categorias;
					this.dedicacions = result.consult.dedicacions;
					this.tipos = result.consult.tipos;
					this.$store.commit('Loading');
				}); 
			},
			Buscar() {
				if (this.buscar){
					Consulta.Procesar('Buscar',{
						id: 	this.buscar,
					}).then(response => {
						//this.$store.state.error =response;
						this.products = response.result;
						this.$store.commit('Loading');
					}); 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail:'Debes colocar los datos solicitados', 
						life: 3000
					});    
				}
			},
			editProduct(product) {
				this.titulo = '(Editar)';
				this.nuevo = false;
				this.product = {...product};
				this.nac.forEach(element => {
					if(this.product.nac==element.code){
						this.product.nac = element;
					}
				});  
				this.nucleos.forEach(element => {
					if(this.product.nucleo_id==element.code){
						this.product.nucleo = element;
					}
				});  
				this.nucleos.forEach(element => {
					if(this.product.nucleo_id==element.code){
						this.product.nucleo = element;
					}
				});  
				this.paises.forEach(element => {
					if(this.product.pais_nac==element.code){
						this.product.pais_nac = element;
					}
				});  
				this.estados.forEach(element => {
					if(this.product.lugar_nac==element.code){
						this.product.lugar_nac = element;
					}
				});  
				this.estcivils.forEach(element => {
					if(this.product.est_civil==element.code){
						this.product.est_civil = element;
					}
				});  
				this.tipos.forEach(element => {
					if(this.product.tipo==element.code){
						this.product.tipo = element;
					}
				});  
				this.categorias.forEach(element => {
					if(this.product.categoria==element.code){
						this.product.categoria = element;
					}
				});  
				this.dedicacions.forEach(element => {
					if(this.product.dedicacion==element.code){
						this.product.dedicacion = element;
					}
				});  
				this.productDialog = true;
			},
			openNew() {
				this.titulo = '(Nuevo)';
				this.nuevo = true;
				this.product = {};
				this.product.pais_nac = {nombre: 'Venezuela', code: 296};
				this.product.lugar_nac = {nombre: '--', code: 0};
				this.product.est_civil = {nombre: 'Soltero(a)', code: 2};
				this.product.tipo = {nombre: '--', code: 0};
				this.product.categoria = {nombre: '--', code: 0};
				this.product.dedicacion = {nombre: '--', code: 0};
				this.submitted = false;
				this.productDialog = true;
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			saveProduct() {
				if (this.product.nac == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Nacionalidad', life: 5000});
				} else if (this.product.id == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el Nº de Cédula', life: 5000});
				} else if (this.product.nombre == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Nombre', life: 5000});
				} else if (this.product.apellido == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Apellido', life: 5000});
				} else if (this.product.correo == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Correo', life: 5000});
				} else if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.
					test(this.product.correo)) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Error en Correo', life: 10000});
				} else if (this.product.nucleo == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Extensión', life: 5000});
				/*
				} else if (this.product.fecha_nac == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la Fecha de Nacimiento', life: 5000});
				} else if (this.product.pais_nac == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el País de Nacimiento', life: 5000});
				} else if (this.product.lugar_nac == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Estado de Nacimiento', life: 5000});
				} else if (this.product.est_civil == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Estado Civil', life: 5000});
				} else if (this.product.tipo == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Tipo de Docente', life: 5000});
				} else if (this.product.categoria == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Categoría', life: 5000});
				} else if (this.product.dedicacion == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Dedicación', life: 5000});
				*/
				} else {
					this.productDialog = false;
					if (this.nuevo) {
						this.buscar = this.product.id;
						Consulta.Procesar('INS',{
							id: 	this.product.id,
							nac: this.product.nac.code,
							nombre: this.product.nombre,
							apellido: this.product.apellido,
							correo: this.product.correo,
							nucleo: this.product.nucleo.code,
							sexo: this.product.sexo,

							rif: this.product.rif,
							fecha_nac: this.product.fecha_nac,
							est_civil: this.product.est_civil,
							pais_nac: this.product.pais_nac,
							lugar_nac: this.product.lugar_nac,
							categoria: this.product.categoria,
							dedicacion: this.product.dedicacion,
							tipo: this.product.tipo,
							observacion: this.product.observacion,

							activo: this.product.activo
						}).then(response => {
							//this.$store.state.error =response;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Buscar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
						});
					} else {
						Consulta.Procesar('UPD',{
							id: 	this.product.id,
							nac: this.product.nac.code,
							nombre: this.product.nombre,
							apellido: this.product.apellido,
							correo: this.product.correo,
							nucleo: this.product.nucleo.code,
							sexo: this.product.sexo,
							fecha_nac: this.product.fecha_nac,
							est_civil: this.product.est_civil,
							rif: this.product.rif,
							pais_nac: this.product.pais_nac,
							lugar_nac: this.product.lugar_nac,
							categoria: this.product.categoria,
							dedicacion: this.product.dedicacion,
							tipo: this.product.tipo,
							observacion: this.product.observacion,

							activo: this.product.activo
						}).then(response => {
							//this.$store.state.error =response;
							this.$store.commit('Loading');
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Buscar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
						});
					}
					this.productDialog = false;
					this.product = {};
				}
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			deleteProduct() {
				this.products = this.products.filter(val => val.id !== this.product.id);
				Consulta.Procesar('DEL',{
					id: 	this.product.id
				}).then(response => {
					this.$store.commit('Loading');
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
				});
				this.deleteProductDialog = false;
				this.product = {};
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
