<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<Dropdown v-model="nucleo" :options="nucleos" optionLabel="nombre" placeholder="Seleccione..." class="p-mr-2" @change="Buscar"/>
					</template>
					<template v-slot:right>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="Nuevo" v-if="insert && nucleo"/>
					</template>
				</Toolbar>
				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm" v-if="!detalles">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Lotes: {{products.length}}</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="fecha" header="Fecha">
						<template #body="slotProps">
							{{convfech(slotProps.data.fecha)}}
						</template>
					</Column>
                    <Column field="numero" header="N° Factura">
						<template #body="slotProps">
							{{('000000'+slotProps.data.ini_factura).slice(-6)}} - {{('000000'+slotProps.data.fin_factura).slice(-6)}}
						</template>
					</Column>
					<Column field="control" header="N° Control">
						<template #body="slotProps">
							{{('000000000'+slotProps.data.ini_control).slice(-9)}} - {{('000000000'+slotProps.data.fin_control).slice(-9)}}
						</template>
					</Column>                  
					<Column field="numero" header="Cantidad">
						<template #body="slotProps">
							{{slotProps.data.cantidad}}
						</template>
					</Column>                   
                    <Column field="numero" header="Utilizadas">
						<template #body="slotProps">
							{{slotProps.data.utilizadas}}
						</template>
					</Column>                   
					<Column header="Opciones">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" v-if="edit" v-tooltip.bottom="'Editar'"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteProduct(slotProps.data)" v-if="del" v-tooltip.bottom="'Eliminar'"/>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
        
                <Dialog v-model:visible="productDialog" :style="{width: size}" header="Preforma" :modal="true" class="p-fluid" :maximizable="true">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-8">
								<label for="name">Extensión</label>
								<InputText id="name" v-model="nucleo.nombre" required="true" disabled/>
							</div>
							<div class="p-col-4">
								<label for="name">Fecha</label>
								<InputText type="date" v-model="product.fecha" required="true"/>
							</div>
							<div class="p-col-2">
								<label for="name">N° Factura Inicial</label>
								<InputText type="numbre" v-model="product.ini_factura" required="true" min="1"/>
							</div>
							<div class="p-col-2">
								<label for="name">N° Control Inicial</label>
								<InputText type="numbre" v-model="product.ini_control" required="true" min="1"/>
							</div>
							<div class="p-col-2">
								<label for="name">Total de preformas</label>
								<InputText type="numbre" v-model="product.cantidad" required="true" min="1"/>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="Guardar()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="productDialog=false"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}" header="Confirmar" :modal="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button label="Sí" icon="pi pi-check" class="p-button-secondary" @click="deleteProduct" />
						<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>
	</div>
</template>

<script>
	import API from "../service/API";
	import PDF from "../service/PDF";
	const Consulta = new API('Facturacion','Preforma');

	export default {
		data() {
			return {
				opcion: true,
				desde: null,
				hasta: null,
				nucleo: null,
				nucleos: [],
				pendiente: {nombre: 'Por Verificar', code: 0},
				pendientes:  [
					{nombre: 'Por Verificar', code: 0},
					{nombre: 'Verificados', code: 1},
				],
                nuevo: false,
				buscar: null,
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				detalles: null,
				estudiante: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				pagos_pend: [],
				facturas: [],
				ajuste: [],
				imagen: null,
				deleteDialog: false,
				pago: {},
				bancos: [],
				banco_destino: {code: 3, nombre: 'Banco Mercantil'},
				tipos: [],
				Dialog: false,
				periodo: null,
				periodos: [],
                numero: 0,
                control: 0,
                recibos: [],
                products: [],
				productDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},

			}
		},
		productService: null,
		created() {
			this.url = process.env.VUE_APP_API;
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				Consulta.Ini().then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.nucleos = result.consult.nucleos;
                    if(this.nucleos.length==1){
                        this.nucleo = result.consult.nucleos[0];
                    }
					this.$store.commit('Loading');
				}); 
			},
            Buscar() {
				this.products = [];
				if (this.nucleo && (this.desde<=this.hasta)){
					Consulta.Procesar('Buscar',{
						nucleo: this.nucleo,
						desde: this.desde,
						hasta: this.hasta,
						opcion: this.opcion,
					}).then(response => {
						//this.$store.state.error = response.result[0];
						this.$store.commit('Loading');
						this.products = response.result;
					}); 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail:'Debes colocar los datos solicitados', 
						life: 3000
					});    
				}
			},
			Nuevo() {
				this.product = {};
				this.nuevo = true;
				this.productDialog = true;
			},
			Guardar() {
				if (this.product.fecha == null || this.product.ini_factura == null || this.product.ini_control == null || this.product.cantidad == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Información incompleta', life: 5000});
				} else {			
					this.productDialog = false;
                    var caso = 'UPD';
					if (this.nuevo) {caso = 'INS';}	
                    Consulta.Procesar(caso,{	
                        nucleo: this.nucleo,
						product: this.product,
					}).then(response => {
						this.$store.commit('Loading');
						//this.$store.state.error =response;
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Información Procesada', 
								life: 3000
							});
							this.Buscar();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: 'No fue procesada la solicitud', 
								life: 3000
							});
						}
					});
					this.productDialog = false;
					this.product = {};
				}
			},
			editProduct(product) {
                this.nuevo = false;
				this.product = {...product};
				this.productDialog = true;
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			deleteProduct() {
				Consulta.Procesar('DEL',{
					id: 	this.product.id
				}).then(response => {
					this.$store.commit('Loading');
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
				});
				this.deleteProductDialog = false;
				this.product = {};
			},






			Ver(data,fondo){
				const doc = new PDF();
				doc.Factura(data,fondo);
			},
			convfech(fecha){
				return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
			},
			moneda(num){
				return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num)
			}
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
