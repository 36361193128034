<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-2">
			<Card style="margin-bottom: 2em">
				<template #title>
					Aranceles
				</template>
				<template #content>
					<div class="p-fluid grid formgrid">
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="Guardar()" />
					</div>
				</template>
			</Card>
		</div>
		<div class="p-col-10">
			<Accordion>
				<AccordionTab header="Inscripción">
					<Card style="margin-bottom: 2em">
						<template #content>
							<DataTable :value="products1" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
									dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
								<template #header>
									<div class="table-header">
										<Dropdown v-model="periodo1" :options="periodos1" optionLabel="per" placeholder="Período ..." @change="Buscar(1,periodo1)"/>
										<div v-if="periodo1">
											<img :src="'images/moneda'+periodo1.moneda+'.png'" width="30" v-if="periodo1.moneda>0"/>
										</div>
									</div>
								</template>
								<Column field="nombre" header="Nombre" :sortable="true">
									<template #body="slotProps">
										{{slotProps.data.nombre}}
									</template>
								</Column>
								<Column field="monto" header="Monto" headerStyle="width: 150px">	
									<template #body="slotProps">
										<InputText v-model="slotProps.data.monto" type="number" min="0" step='0.01' style="max-width:70px;"/>
									</template>
								</Column>
								<Column field="vence" header="Vencimiento" headerStyle="width: 150px">	
									<template #body="slotProps">
										<InputText v-model="slotProps.data.vencimiento" type="date"  style="max-width:140px;" v-if="slotProps.data.arancel==2 || (slotProps.data.arancel>10 && slotProps.data.arancel<15)"/>
									</template>
								</Column>
							</DataTable>						
						</template>
					</Card>
				</AccordionTab>
				<AccordionTab header="Verano">
					<Card style="margin-bottom: 2em">
						<template #content>
							<DataTable :value="products2" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
									dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
								<template #header>
									<div class="table-header">
										<Dropdown v-model="periodo2" :options="periodos2" optionLabel="per" placeholder="Período ..." @change="Buscar(2,periodo2)"/>
										<div v-if="periodo2">
											<img :src="'images/moneda'+periodo2.moneda+'.png'" width="30" v-if="periodo2.moneda>0"/>
										</div>
									</div>
								</template>
								<Column field="nombre" header="Nombre" :sortable="true">
									<template #body="slotProps">
										{{slotProps.data.nombre}}
									</template>
								</Column>
								<Column field="monto" header="Monto" headerStyle="width: 150px">	
									<template #body="slotProps">
										<InputText v-model="slotProps.data.monto" type="number" min="0" step='0.01' style="max-width:70px;"/>
									</template>
								</Column>
							</DataTable>						
						</template>
					</Card>				
				</AccordionTab>
				<AccordionTab header="Solicitudes: Documentos">
					<Card style="margin-bottom: 2em">
						<template #content>
							<DataTable :value="products3" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
									dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
								<template #header>
									<div class="table-header">
										<Dropdown v-model="periodo3" :options="periodos3" optionLabel="per" placeholder="Período ..." @change="Buscar(3,periodo3)"/>
										<div v-if="periodo3">
											<img :src="'images/moneda'+periodo3.moneda+'.png'" width="30" v-if="periodo3.moneda>0"/>
										</div>
									</div>
								</template>
								<Column field="nombre" header="Nombre" :sortable="true">
									<template #body="slotProps">
										{{slotProps.data.nombre}}
									</template>
								</Column>
								<Column field="monto" header="Monto" headerStyle="width: 150px">	
									<template #body="slotProps">
										<InputText v-model="slotProps.data.monto" type="number" min="0" step='0.01' style="max-width:70px;"/>
									</template>
								</Column>
							</DataTable>						
						</template>
					</Card>				
				</AccordionTab>
				<AccordionTab header="Solicitudes: Trámites">
					<Card style="margin-bottom: 2em">
						<template #content>
							<DataTable :value="products4" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
									dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
								<template #header>
									<div class="table-header">
										<Dropdown v-model="periodo4" :options="periodos4" optionLabel="per" placeholder="Período ..." @change="Buscar(4,periodo4)"/>
										<div v-if="periodo3">
											<img :src="'images/moneda'+periodo3.moneda+'.png'" width="30" v-if="periodo3.moneda>0"/>
										</div>
									</div>
								</template>
								<Column field="nombre" header="Nombre" :sortable="true">
									<template #body="slotProps">
										{{slotProps.data.nombre}}
									</template>
								</Column>
								<Column field="monto" header="Monto" headerStyle="width: 150px">	
									<template #body="slotProps">
										<InputText v-model="slotProps.data.monto" type="number" min="0" step='0.01' style="max-width:70px;"/>
									</template>
								</Column>
							</DataTable>						
						</template>
					</Card>				
				</AccordionTab>
			</Accordion>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Administracion','Arancel');

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				products1: [],
				products2: [],
				products3: [],
				products4: [],
				productDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				periodo1: null,
				periodos1: [],
				periodo2: null,
				periodos2: [],
				periodo3: null,
				periodos3: [],
				periodo4: null,
				periodos4: [],
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.$store.commit('Loading');
				this.periodos1 = [];
				this.periodos2 = [];
				Consulta.Ini().then(result => {
					//this.$store.state.error = result.consult;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.periodos1 = result.consult.periodos1;
					this.periodos2 = result.consult.periodos2;
					this.periodos3 = result.consult.periodos3;
					this.periodos4 = result.consult.periodos4;
					this.$store.commit('Loading');
				}); 
			},
			Buscar(tipo,periodo) {
				Consulta.Procesar('Buscar',{
					tipo: 	tipo,
					periodo: 	periodo,
				}).then(response => {
					//this.$store.state.error =response;
					if (response.result) {
						if(tipo==1){this.products1 = response.result;}
						if(tipo==2){this.products2 = response.result;}
						if(tipo==3){this.products3 = response.result;}
						if(tipo==4){this.products4 = response.result;}
						this.$store.commit('Loading');
					}
				});
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			Guardar() {
				Consulta.Procesar('UPD',{
					caso: 	'UPD',
					periodo1: 	this.periodo1,
					periodo2: 	this.periodo2,
					products1: 	this.products1,
					products2: 	this.products2,
					products3: 	this.products3,
					products4: 	this.products4,
				}).then(response => {
					//this.$store.state.error =response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.$store.commit('Loading');
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
						this.$store.commit('Loading');
						this.Mostrar();
					}
				});
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
				convfech(fecha){
					return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
				},

		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
