<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
                    <InputText type="number" min="0" v-model="buscar" required="true" placeholder="Cédula" @keyup.enter="Buscar" v-if="opcion"/>
                    <InputText type="text" v-model="buscar" style="width:250px;" required="true" placeholder="Apellido o Nombre" @keyup.enter="Buscar" v-if="!opcion"/>
					<br><br><br>
                    <InputSwitch v-model="opcion"/>				
					</template>
					<template v-slot:right>
					<Button label="Buscar" icon="pi pi-search" class="p-button-success p-mr-2 p-mb-2" @click="Buscar" />
					</template>
				</Toolbar>

				<DataTable :value="matriculas" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm" v-if="matriculas">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-1">Estudiante: {{estudiante.id}} - {{estudiante.apellido}}, {{estudiante.nombre}}</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column header="Extensión" :sortable="true">
						<template #body="slotProps" >
							{{slotProps.data.extension}}
						</template>
					</Column>
					<Column field="plan" header="Plan de Estudio" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.plan}}
						</template>
					</Column>
					<Column field="periodo" header="Período" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.periodo}}
						</template>
					</Column>
					<Column field="turno" header="Turno" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.turno}}
						</template>
					</Column>
					<Column field="seccion" header="Sección" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.sec}}
						</template>
					</Column>
					<Column field="activo" header="Estatus" :sortable="true">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
						</template>
					</Column>
					<Column header="Iniciar">
						<template #body="slotProps">
							<Button icon="pi pi-play" class="p-button-rounded p-button-info p-mr-2" @click="Inscribir(slotProps.data)" v-if="edit && slotProps.data.activo" v-tooltip.bottom="'Iniciar Inscripción'"/>
						</template>
					</Column>
					<template #footer>
						<Dropdown v-model="inscripcion" :options="inscripciones" optionLabel="nombre" placeholder="Seleccione el Período ..." v-if="iniciar && inscripciones.length>0"/>
						<Button label="Procesar" icon="pi pi-plus-circle" class="p-button-info p-mr-2 p-mb-2" @click="Agregar" v-if="inscripcion.detalle.length==0 && iniciar"/>
					</template>
				</DataTable>

				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm" v-else>
					<template #header>
						<div class="table-header">
							<h5 class="p-m-1">Estudiantes</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column header="Extensión" :sortable="true">
						<template #body="slotProps" >
							{{slotProps.data.nucleo}}
						</template>
					</Column>
					<Column field="id" header="N° Cédula" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="nombre" header="Nombre" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="apellido" header="Apellido" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.apellido}}
						</template>
					</Column>
					<Column field="activo" header="Estatus" :sortable="true">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
						</template>
					</Column>
					<Column header="Opciones">
						<template #body="slotProps">
							<Button icon="pi pi-arrow-right" class="p-button-rounded p-button-success p-mr-2" @click="VerMatricula(slotProps.data)" v-if="edit && slotProps.data.deuda<=0" v-tooltip.bottom="'Seleccionar'"/>
							<span class="p-tag p-tag-danger" v-if="slotProps.data.deuda>0">Tiene Deuda</span>	
						</template>
					</Column>
				</DataTable>
				<br>
				<DataTable :value="inscripcion.detalle" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" class="p-datatable-sm" v-if="iniciar && inscripciones.length>0">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Inscripción: {{inscripcion.detalle.length}} asignatura(s)</h5>
                            <Button icon="pi pi-file-pdf" label="Imprimir Constancia" class="p-button-rounded p-button-primary p-mr-2" @click="Ver()" v-if="inscripcion.detalle.length>0"/>
							<Button label="Procesar" icon="pi pi-plus-circle" class="p-button-info p-mr-2 p-mb-2" @click="Modificar" v-if="inscripcion.detalle.length"/>
						</div>
					</template>
					<Column header="Período" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.periodo}}
						</template>
					</Column>
					<Column header="Código" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.cod}}
						</template>
					</Column>
					<Column header="Asignatura" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.asignatura}}
						</template>
					</Column>
					<Column header="UC" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.uc}}
						</template>
					</Column>
					<Column header="Semestre" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.sem}}
						</template>
					</Column>
					<Column header="Sección" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.secf}}
						</template>
					</Column>
				</DataTable>
				<br><br>				
				<DataTable :value="inscripcion.horario" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm" v-if="iniciar && inscripciones.length>0">
					<template #header>
                        <h5>Horario</h5>
					</template>
					<Column field="bloque" header="Hora" :sortable="true"></Column>
						<Column field="Lunes" header="Lunes">	
							<template #body="{data}">
								{{data.lun['asignatura']}}
							</template>
						</Column>
						<Column field="Martes" header="Martes">
							<template #body="{data}">
								{{data.mar['asignatura']}}
							</template>
						</Column>
						<Column field="Miercoles" header="Miércoles">
							<template #body="{data}">
								{{data.mie['asignatura']}}
							</template>
						</Column>
						<Column field="Jueves" header="Jueves">
							<template #body="{data}">
								{{data.jue['asignatura']}}
							</template>
						</Column>
						<Column field="Viernes" header="Viernes">
							<template #body="{data}">
								{{data.vie['asignatura']}}
							</template>
						</Column>
						<Column field="Sabado" header="Sábado">
							<template #body="{data}">
								{{data.sab['asignatura']}}
							</template>
						</Column>
						<Column field="Domingo" header="Domingo">
							<template #body="{data}">
								{{data.dom['asignatura']}}
							</template>
						</Column>			
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
	import API from "../service/API";
	import PDF from "../service/PDF";
	const Consulta = new API('Inscripcion','Inscripcion');

	export default {
		data() {
			return {
				iniciar: false,
				bloque: false,
				buscar: null,
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				productDialog: false,
				product: {},
                estudiante: {},
                matriculas: [],
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				nac: [
					{code: 'V', nombre: 'Venezolano(a)'},
					{code: 'E', nombre: 'Extranjero(a)'}
				],
				inscripciones: [],
				horario: [],
				inscripcion:  {
					detalle: [],
					horario: []
				},
				convenio: null,
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
		
		},
		methods: {
			Ver(){
				const doc = new PDF();
				doc.Horario(this.matriculas[0],this.inscripcion.detalle,this.inscripcion.horario,this.convenio);
			},
			Mostrar() {
                this.iniciar = false;
				Consulta.Ini().then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					if (this.$store.state.matricula>0) {
						this.Datos(this.$store.state.matricula);
					}
					this.convenio = result.consult;
					this.$store.commit('Loading');
				}); 

			},
			Datos(matricula) {
				if (matricula>0){
					Consulta.Procesar('Datos',{
						id: 	matricula,
					}).then(response => {
						//this.$store.state.error = response;
						this.buscar = response.result.estudiante.id;
						this.estudiante = response.result.estudiante;
						this.matriculas = response.result.matricula;
						if(response.result.inscripciones.length>0){
							this.inscripciones = response.result.inscripciones;
							this.inscripcion = response.result.inscripciones[0];
						}
						this.iniciar = true;
						this.$store.commit('Loading');
					}); 
				}
			},
			Buscar() {
				this.iniciar = false;
                this.matriculas = null;
				this.inscripciones = [];
				this.horario = [];
				this.$store.commit('Matricula',{matricula: 0});
				if (this.buscar){
					Consulta.Procesar('Buscar',{
						id: 	this.buscar,
					}).then(response => {
						//this.$store.state.error =response;
						this.products = response.result;
						this.$store.commit('Loading');
					}); 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail:'Debes colocar los datos solicitados', 
						life: 3000
					});    
				}
			},
			VerMatricula(estudiante) {
				this.estudiante = {};
				this.estudiante = {...estudiante};
				this.matriculas = [];
				Consulta.Procesar('Matricula',{
					id: 	this.estudiante.id,
					nucleo: 	this.estudiante.nucleo_id,
				}).then(response => {
					//this.$store.state.error = response.result;
					this.matriculas = response.result;
					this.$store.commit('Loading');
				}); 
			},
			Inscribir(matricula) {
				this.$store.commit('Loading');
                this.matriculas = [matricula];
				this.$store.commit('Matricula',{matricula: matricula.id});
				this.inscripcion =  {
					detalle: [],
					horario: [],
				};
				Consulta.Procesar('Consulta',{
					id: 	matricula.id,
				}).then(response => {
					//this.$store.state.error = response;
					if(response.result.inscripciones.length){
						this.inscripciones = response.result.inscripciones;
						this.inscripcion = response.result.inscripciones[0];
					}
					this.iniciar = true;
					//this.horario = response.result.horario;
					this.$store.commit('Loading');
				}); 
			},
			Agregar() {
				this.$store.commit('Loading');
				if(this.inscripciones.length==0){
					this.$router.push({ path: '/inscrip'});
				}
                this.$store.commit('Loading');
			},
			Modificar() {
				this.$store.commit('Loading');
				if(this.inscripciones.length>0){
					this.$router.push({ path: '/inscrip'});
				}
                this.$store.commit('Loading');
			},

			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}
				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},

		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
