<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<Dropdown v-model="nucleo" :options="nucleos" optionLabel="nombre" placeholder="Seleccione la Extensión ..." @change="Validacion1()"/>
						<Dropdown v-model="plan" :options="planes" optionLabel="nombre" placeholder="Seleccione el Plan de Estudio ..." @change="Validacion2()" v-if="nucleo"/>
						<Dropdown v-model="periodo" :options="periodos" optionLabel="per" placeholder="Seleccione el Período ..." @change="Buscar()" v-if="plan"/>
						<!--<Button label="Buscar" icon="pi pi-search" class="p-button-success" @click="Buscar()" v-if="periodo"/>-->
					</template>
					<template v-slot:right>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="openNew" v-if="insert && periodo"/>
					</template>
				</Toolbar>			
				<br>
				<div v-if="products.length>0">
					<ProgressBar :value="numdocente*100/products.length">Asignación Docente: {{(numdocente*100/products.length).toFixed(0)}}%</ProgressBar>
					<br>
					<ProgressBar :value="numhoras*100/totalhoras">Asignación de Horario: {{(numhoras*100/totalhoras).toFixed(0)}}%</ProgressBar>
				</div>
				<br>
				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Ofertas: {{products.length}}</h5>
							<Dropdown v-model="seccion" :options="secciones" optionLabel="nombre" placeholder="Seleccione la Sección ..." @change="Seccion()" v-if="periodo"/>
							<Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver()" v-if="seccion"/>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="secf" header="Sección" :sortable="true">	
						<template #body="slotProps">
							{{slotProps.data.secf}}
						</template>
					</Column>
					<Column field="cod" header="Código" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.cod}}
						</template>
					</Column>
					<Column field="asignatura" header="Asignatura" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.asignatura}}
						</template>
					</Column>
					<Column field="sem" header="Semestre" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.sem}}
						</template>
					</Column>
					<Column field="capacidad" header="Capacidad">
						<template #body="slotProps">
							{{slotProps.data.capacidad}}
						</template>
					</Column>
					<Column header="Docente">
						<template #body="slotProps">
							{{slotProps.data.ndocente}}<br>
							<Button icon="pi pi-calendar-minus" class="p-button-rounded p-button-success p-mr-1" @click="DocHorario(slotProps.data)" v-if="slotProps.data.docente" v-tooltip.bottom="'Horario'"/>
						</template>
					</Column>
					<Column header="Estatus">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
							<i class="pi pi-info-circle" style="fontSize: 2rem"  v-if="slotProps.data.error1"></i>
						</template>
					</Column>
					<Column header="">
						<template #body="slotProps">
							<span class="p-overlay-badge p-mr-4" v-if="slotProps.data.fusionsec.length==0">
								<Button icon="pi pi-calendar-minus" class="p-button-rounded p-button-info" @click="editHorario(slotProps.data)" v-if="edit" v-tooltip.bottom="'Horario'"/>
								<span class="p-badge">{{slotProps.data.horario}}</span>
							</span>
							<span class="p-overlay-badge p-mr-4" v-if="slotProps.data.fusionsec.length==0">
							<Button icon="bi bi-arrow-left-right" class="p-button-rounded p-button-warning" @click="openFusion(slotProps.data)" v-if="edit" v-tooltip.bottom="'Fusión'"/>
								<span class="p-badge">{{slotProps.data.fusion}}</span>
							</span>
							<span class="p-tag p-tag-warning" v-if="slotProps.data.fusionsec.length>0" @click="verFusion(slotProps.data.fusionsec)">Fusionada</span>
						</template>
					</Column>
					<Column header="">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" v-if="edit" v-tooltip.bottom="'Editar'"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteProduct(slotProps.data)" v-if="del" v-tooltip.bottom="'Eliminar'"/>
						</template>
					</Column>
				</DataTable>
				<Dialog v-model:visible="productDialog" :style="{width: size}" :header="'Oferta '+titulo+' ID:'+product.id" :modal="true" class="p-fluid" :maximizable="true">
					<br>
					<Message severity="info" v-if="product.error1">Existe choque de Horario del Docente, verifique.</Message>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-3">
								<label>Extensión</label>
								<InputText v-model="nucleo.nombre" :disabled="true"></InputText>						
							</div>
							<div class="p-col-5">
								<label>Plan de Estudio</label>
								<InputText v-model="plan.nombre" :disabled="true"></InputText>						
							</div>
							<div class="p-col-2">
								<label>Período</label>
								<InputText v-model="periodo.nombre" :disabled="true"></InputText>						
							</div>
							<div class="p-col-2">
								<h6 style="margin-top: 0" v-if="product.activo">Activo</h6>
								<h6 style="margin-top: 0" v-else>No Activo</h6>
								<InputSwitch v-model="product.activo" />
							</div>
							<div class="p-col-4" v-if="!product.id">
								<label class="p-mb-3">Opción</label>
								<Dropdown id="state" v-model="selectedopc" :options="opciones" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-2">
								<label class="p-mb-3">Semestre</label>
								<Dropdown id="state" v-model="product.sem" :options="semestres" optionLabel="nombre" placeholder="Selecciona..." @change="Validacion3(product.sem)"></Dropdown>
							</div>
							<div class="p-col-2">
								<label class="p-mb-3">Turno</label>
								<Dropdown id="state" v-model="product.turno" :options="turnos" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-2">
								<label class="p-mb-3">Sección</label>
								<Dropdown id="state" v-model="product.sec" :options="secs" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
                            <div class="p-col-2">
                                <label class="p-mb-3">Capacidad</label>
                                <InputNumber id="req_uc" v-model="product.capacidad" required="true"/>
                            </div>
							<div class="p-col-2">
								<label>Horas</label>
								<InputText v-model="product.horas" :disabled="true"></InputText>						
							</div>
							<div class="p-col-2" v-if="periodo.tipo_id==2">
                                <label class="p-mb-3">Hor. Adicionales</label>
                                <InputNumber id="req_uc" v-model="product.hextra" required="true"/>
							</div>
							<div class="p-col-12 p-field" v-if="selectedopc.code==0">
								<div class="p-grid">
                                    <div class="p-col-6">
                                        <label class="p-mb-3">Asignatura</label>
                                        <Dropdown id="state" v-model="product.asignatura" :options="asignaturas" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
                                    </div>
                                    <div class="p-col-6">
                                        <label class="p-mb-3">Docente</label>
										<Dropdown v-model="product.docente" :options="docentes" optionLabel="nombre" :filter="true" placeholder="Selecciona..."  :showClear="true">
											<template #value="slotProps">
												<div class="p-dropdown-car-value" v-if="slotProps.value">
													<span> {{slotProps.value.nombre}}</span>
												</div>
												<span v-else>
													{{slotProps.placeholder}}
												</span>
											</template>
											<template #option="slotProps">
												<div class="p-dropdown-car-option">
													<span> {{slotProps.option.nombre}}</span>
												</div>
											</template>
										</Dropdown>
                                    </div>
								</div>
							</div>
                            <br>
							<div class="p-field" v-if="selectedopc.code>0 && product.sem">
								<div class="p-grid">
									<div class="p-col-12"  v-for="(stats, index) in asignaturas" :key="index">
										<div class="p-grid">
											<div class="p-col-1"></div>
                                            <div class="p-col-7">
                                                <label class="p-mb-3">Asignatura {{index+1}}</label>
                                                <InputText v-model="stats.nombre" :disabled="true"></InputText>
                                            </div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="hideDialog"/>
					</template>
				</Dialog>
				<Dialog v-model:visible="productDialog2" :style="{width: size}" :header="'Horario de Oferta '+titulo" :modal="true" class="p-fluid" :maximizable="true">
					<br><Toast/>
					<Message severity="info" v-if="product.error1">Existe choque de Horario del Docente, verifique.</Message>
                    <div class="p-field">
						<div class="p-grid">
								<div class="p-col-5">
									<label>Asignatura</label>
									<InputText v-model="product.asignatura" :disabled="true"></InputText>						
								</div>
								<div class="p-col-5">
									<label>Docente</label>
								<InputText v-model="product.ndocente" :disabled="true"></InputText>						
							</div>
							<div class="p-col-2">
								<label>Período</label>
								<InputText v-model="periodo.nombre" :disabled="true"></InputText>						
							</div>
							<div class="p-col-2">
								<label>Semestre</label>
								<InputText v-model="product.sem" :disabled="true"></InputText>						
							</div>
							<div class="p-col-2">
								<label>Turno</label>
								<InputText v-model="product.turno" :disabled="true"></InputText>						
							</div>
							<div class="p-col-2">
								<label>Sección</label>
								<InputText v-model="product.sec" :disabled="true"></InputText>						
							</div>
							<div class="p-col-2">
								<label>Capacidad</label>
								<InputText v-model="product.capacidad" :disabled="true"></InputText>						
							</div>
							<div class="p-col-2">
								<label>Horas</label>
								<InputText v-model="product.horas" :disabled="true"></InputText>						
							</div>
							<div class="p-col-2" v-if="periodo.tipo_id==2">
                                <label class="p-mb-3">Hor. Adicionales</label>
								<InputText v-model="product.hextra" :disabled="true"></InputText>
							</div>
							<div class="p-col-12" v-if="(product.horas + product.hextra)>horario.length"> 
								<div class="p-grid">
									<div class="p-col-1"></div>
									<div class="p-col-3">
										<label>Día</label>
										<Dropdown v-model="dia" :options="dias" optionLabel="nombre" placeholder="Día..." @change="Validacion4()"/>
									</div>
									<div class="p-col-3">
										<label>Hora</label>
										<Dropdown v-model="hora" :options="horas" optionLabel="nombre" placeholder="Hora..." @change="Validacion4()"/>
									</div>
									<div class="p-col-3">
										<label>Aula</label>
										<Dropdown v-model="aula" :options="aulas" optionLabel="nombre" placeholder="Aula..."/>
									</div>
									<div class="p-col-2">
										<Button icon="pi pi-plus" class="p-button-rounded p-button-secondary p-mr-2" @click="agregaraula()" v-if="edit" v-tooltip.bottom="'Agregar'"/>
									</div>
								
								</div>
							</div>
							<div class="p-col-12">
								<DataTable :value="horario" :scrollable="true" scrollHeight="300px" class="p-datatable-sm">
									<Column field="dianombre" header="Día" :sortable="true"></Column>
									<Column field="bloque" header="Horario" :sortable="true"></Column>
									<Column field="nombre" header="Aula" :sortable="true"></Column>
									<Column header="">
										<template #body="{data}">
											<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="eliminaraula(data)" v-if="del" v-tooltip.bottom="'Eliminar'"/>
										</template>
									</Column>
								</DataTable>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="productDialog2 = false"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}" header="Confirmar" :modal="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button label="Sí" icon="pi pi-check" class="p-button-secondary" @click="deleteProduct" />
						<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>
				<br>
				<h5 class="p-m-0" v-if="seccion">Horario de Sección</h5>
				<DataTable :value="horariosec" :scrollable="true" scrollHeight="400px" class="p-datatable-sm" v-if="seccion">
					<Column field="bloque" header="Hora" :sortable="true"></Column>
						<Column field="Lunes" header="Lunes">	
							<template #body="{data}">
								{{data.lun['asignatura']}}
							</template>
						</Column>
						<Column field="Martes" header="Martes">
							<template #body="{data}">
								{{data.mar['asignatura']}}
							</template>
						</Column>
						<Column field="Miercoles" header="Miércoles">
							<template #body="{data}">
								{{data.mie['asignatura']}}
							</template>
						</Column>
						<Column field="Jueves" header="Jueves">
							<template #body="{data}">
								{{data.jue['asignatura']}}
							</template>
						</Column>
						<Column field="Viernes" header="Viernes">
							<template #body="{data}">
								{{data.vie['asignatura']}}
							</template>
						</Column>
						<Column field="Sabado" header="Sábado">
							<template #body="{data}">
								{{data.sab['asignatura']}}
							</template>
						</Column>
						<Column field="Domingo" header="Domingo">
							<template #body="{data}">
								{{data.dom['asignatura']}}
							</template>
						</Column>			
				</DataTable>

				<Dialog v-model:visible="HoraDocDialog" :style="{width: size}" :header="'Horario Docente: '+titulo" :modal="true" :maximizable="true">
					<br>
                    <div class="confirmation-content">
						<div class="p-field">
							<div class="p-grid">
								<div class="p-col-12">
									<h5 class="p-m-0">Asignaturas: {{asigdoc.length}}</h5>
									<DataTable :value="asigdoc" :scrollable="true" scrollHeight="300px" class="p-datatable-sm">
										<Column header="Período" :sortable="true">
											<template #body="slotProps">
												{{slotProps.data.periodo}}
											</template>
										</Column>
										<Column header="Plan de Estudio" :sortable="true">
											<template #body="slotProps">
												{{slotProps.data.plan}} {{slotProps.data.codplan}}
											</template>
										</Column>
										<Column header="Código" :sortable="true">
											<template #body="slotProps">
												{{slotProps.data.cod}}
											</template>
										</Column>
										<Column header="Asignatura" :sortable="true">
											<template #body="slotProps">
												{{slotProps.data.asignatura}}
											</template>
										</Column>
										<Column header="Sección" :sortable="true">
											<template #body="slotProps">
												{{slotProps.data.secc}}
											</template>
										</Column>
										<Column header="Semestre" :sortable="true">
											<template #body="slotProps">
												{{slotProps.data.sem}}
											</template>
										</Column>
									</DataTable>
								</div>
								<div class="p-col-12">
									<DataTable :value="horariodoc" :scrollable="true" scrollHeight="300px" class="p-datatable-sm">
										<Column field="bloque" header="Hora" :sortable="true"></Column>
											<Column field="Lunes" header="Lunes">	
												<template #body="{data}">
													{{data.lun['asignatura']}}
												</template>
											</Column>
											<Column field="Martes" header="Martes">
												<template #body="{data}">
													{{data.mar['asignatura']}}
												</template>
											</Column>
											<Column field="Miercoles" header="Miércoles">
												<template #body="{data}">
													{{data.mie['asignatura']}}
												</template>
											</Column>
											<Column field="Jueves" header="Jueves">
												<template #body="{data}">
													{{data.jue['asignatura']}}
												</template>
											</Column>
											<Column field="Viernes" header="Viernes">
												<template #body="{data}">
													{{data.vie['asignatura']}}
												</template>
											</Column>
											<Column field="Sabado" header="Sábado">
												<template #body="{data}">
													{{data.sab['asignatura']}}
												</template>
											</Column>
											<Column field="Domingo" header="Domingo">
												<template #body="{data}">
													{{data.dom['asignatura']}}
												</template>
											</Column>			
									</DataTable>
								</div>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="HoraDocDialog = false"/>
					</template>
				</Dialog>


				<Dialog v-model:visible="fusionDialog" :style="{width: size}" header="Fusión de Oferta" :modal="true" :maximizable="true">
					<br>
                    <div class="p-field">
						<h4>Oferta Principal</h4>
						<div class="p-grid">
							<div class="p-col-6">
								Carrera: <strong>{{seleccion.codplan}} {{seleccion.plan}}</strong>
							</div>
							<div class="p-col-6">
								Asignatura: <strong>{{seleccion.cod}} {{seleccion.asignatura}}</strong>
							</div>
							<div class="p-col-2">
								Turno: <strong>{{seleccion.turno}}</strong>
							</div>
							<div class="p-col-2">
								Semestre: <strong>{{seleccion.sem}}</strong>
							</div>
							<div class="p-col-2">
								Sección: <strong>{{seleccion.secc}}</strong>
							</div>
							<div class="p-col-6">
								Docente: <strong>{{seleccion.ndocente}}</strong>
							</div>
						</div>
						<div class="p-grid">
							<div class="p-col-12">
								<div class="card">
									<h5>Ofertas Secundarias Disponibles</h5>
									Sólo aplica para oferta de asignaturas equivalentes y que no tenga cargado horario.
									<Tree :value="equivalentes" selectionMode="checkbox" v-model:selectionKeys="equivalente"></Tree>
								</div>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveFusion()" />
						<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="fusionDialog = false"/>
					</template>
				</Dialog>
				<Sidebar v-model:visible="verfusionDialog" position="top">
					<h4>Principal de la Fusión en la Carrera: <strong>{{fusion.codplan}} {{fusion.plan}}</strong></h4>
					<h5>Sección: <strong>S{{fusion.sem}}{{fusion.secc}}</strong>,  Asignatura: <strong>{{fusion.asignatura}} ({{fusion.cod}})</strong></h5>
				</Sidebar>

			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	import PDF from "../service/PDF";
	const Consulta = new API('Academico','Oferta');

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				productDialog: false,
				productDialog2: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				HoraDocDialog: false,
				product: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '80vw',
				nucleo: null,
				nucleos: [],
				plan: null,
				planes: [],
				periodo: null,
				periodos: [],
				seccion: null,
				secciones: [],
				horariosec: [],
				secs: [],
				turnos: [],
				docentescomp: [],
				docentes: [],
				opciones: [
                    {nombre: 'Una Asignatura', code: 0},
                    {nombre: 'Por Bloque', code: 1},
                ],
				selectedopc: {nombre: 'Una Asignatura', code: 0},
				semestres: [],
				asignaturas: [],
				horario: [],
				horariodoc: [],
				asigdoc: [],
				horas: null,
				hora: null,
				aulas: null,
				aula: null,
				dias: null,
				dia: null,
				numdocente: 0,
				numhoras: 0,
				totalhoras: 0,
				fusionDialog: false,
				seleccion: {},
				equivalente: [],
				equivalentes: [],				
				fusion: null,				
				verfusionDialog: false,
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}

			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Ver(){
				const doc = new PDF();
				doc.HorarioSec(this.nucleo,this.plan,this.periodo,this.seccion,this.products,this.horariosec);
			},
			Mostrar() {
				Consulta.Ini().then(result => {
					//this.$store.state.error = result.consult.docentes;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.nucleos = result.consult.nucleos;				
					this.secs = result.consult.secs;
					this.turnos = result.consult.turnos;
					//this.docentes = result.consult.docentes;
					this.$store.commit('Loading');
				}); 
			},
			Validacion1() {
				this.plan = null;
				this.planes = [];
				this.periodo = null;
				this.periodos = [];
				this.products=[];
				this.secciones=[];
				this.horariosec=[];
				Consulta.Procesar('Validacion1',{
					nucleo: this.nucleo.code,
				}).then(response => {
					//this.$store.state.error = response;	
					this.planes = response.result;
					this.$store.commit('Loading');  
				});
			},
			Validacion2() {
				this.periodo = null;
				this.periodos = [];
				this.products=[];
				this.secciones=[];
				this.horariosec=[];
				Consulta.Procesar('Validacion2',{
					plan: this.plan.code,
				}).then(response => {
					//this.$store.state.error = response;	
					this.periodos = response.result.periodos;
					this.semestres = response.result.semestres;
					this.$store.commit('Loading');  
				});
			},
			Validacion3(valor) {
				this.asignaturas = [];
				Consulta.Procesar('Validacion3',{
					plan: this.plan.code,
					sem: valor.code,
				}).then(response => {
					//this.$store.state.error = response.docentes;	
					this.asignaturas = response.result.asignaturas;
					this.asignaturas.forEach(element => {
						if(this.product.asignatura_id==element.code){
							this.product.asignatura = element;
						}
					});
					this.$store.commit('Loading');  
				});
			},
			Validacion4() {
				if (this.hora && this.dia){
					this.aulas = null;
					Consulta.Procesar('Validacion4',{
						id: this.product.id,
						hora: this.hora.code,
						dia: this.dia.code,
					}).then(response => {
						//this.$store.state.error = response;	
						this.aulas = response.result;
						this.$store.commit('Loading');  
					});
				}
			},
			DocHorario(data){
				this.titulo = data.ndocente;
				this.HoraDocDialog = true;
				Consulta.Procesar('HorarioDoc',{
					docente: data.docente,
					nucleo: 	this.nucleo.code,
					periodo: 	this.periodo.code,
				}).then(response => {
					//this.$store.state.error = response.result;	
					this.horariodoc = response.result.horario;
					this.asigdoc = response.result.asignaturas;
					this.$store.commit('Loading');
				});
			},
			agregaraula() {
				if (this.product.id && this.hora && this.dia && this.aula){
					Consulta.Procesar('UPD2',{
						oferta: 	this.product.id,
						dia: 	this.dia.code,
						hora: 	this.hora.code,
						aula: 	this.aula.code,
					}).then(response => {
						//this.$store.state.error =response;
						this.$store.commit('Loading');
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Información Procesada', 
								life: 3000
							});
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: 'No fue procesada la solicitud', 
								life: 3000
							});
						}
						this.ActHorario(this.product.id);
						if (this.seccion){this.Seccion();}
						else {this.Buscar();}
						this.dia=null;
						this.hora=null;
					});
				}
			},
			eliminaraula(data) {
				Consulta.Procesar('DEL2',{
					oferta: data.oferta,
					dia: 	data.dia,
					hora: 	data.hora,
					aula: 	data.aula,
				}).then(response => {
					//this.$store.state.error =response;
					this.$store.commit('Loading');
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
					this.ActHorario(this.product.id);
					if (this.seccion){this.Seccion();}
					else {this.Buscar();}
				});
			},
			Buscar() {
				if (this.nucleo != null && this.plan != null) {
					this.numdocente = 0;
					this.numhoras = 0;
					this.totalhoras = 0;
					this.products=[];
					this.secciones=[];
					this.seccion=null;
					this.horariosec=[];
					Consulta.Procesar('Buscar',{
						nucleo: 	this.nucleo.code,
						estado: 	this.nucleo.estado,
						plan: 	this.plan.code,
						periodo: 	this.periodo.code,
					}).then(response => {
						//this.$store.state.error =response.result.docentes;
						this.docentescomp = response.result.docentes;
						this.docentes = response.result.docentes;
						if(response.result.oferta){this.products = response.result.oferta;}
						this.secciones = response.result.secciones;
						this.numdocente = response.result.numdocente;
						this.numhoras = response.result.numhoras;
						if (response.result.totalhoras){this.totalhoras = response.result.totalhoras;}
						this.$store.commit('Loading');
					}); 
				}
			},
			Seccion() {
				if (this.nucleo != null && this.plan != null) {
					this.numdocente = 0;
					this.numhoras = 0;
					this.totalhoras = 0;
					this.products=[];
					this.horariosec=[];
					Consulta.Procesar('HorarioSec',{
						nucleo: 	this.nucleo.code,
						plan: 	this.plan.code,
						periodo: 	this.periodo.code,
						turno: 	this.seccion.turno_id,
						seccion: 	this.seccion.sec_id,
						sem: 	this.seccion.sem,
					}).then(response => {
						//this.$store.state.error =response.result.numdocente;
						this.products = response.result.oferta;
						this.horariosec = response.result.horario;
						this.numdocente = response.result.numdocente;
						this.numhoras = response.result.numhoras;
						this.totalhoras = response.result.totalhoras;
						this.$store.commit('Loading');
					}); 
				}
			},
			openNew() {
				this.titulo = '(Nuevo)';
				this.selectedopc = {nombre: 'Una Asignatura', code: 0};
				this.asignaturas = [];
				this.selectedsem = null;
				this.product = {};
				this.submitted = false;
				this.productDialog = true;
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			openFusion(product) {
				this.seleccion = {...product};
				this.equivalente = [];
				this.equivalentes = [];
				Consulta.Procesar('Equivalente',{
					oferta: 	this.seleccion,
				}).then(response => {
					//this.$store.state.error = response.result;
					this.equivalentes = response.result.equivalentes;
					this.equivalente = response.result.equivalente;
					this.$store.commit('Loading');
				});
				this.fusionDialog = true;
			},
			verFusion(data) {
				this.fusion = data[0];
				this.verfusionDialog = true;
			},
			saveFusion() {
				if (this.seleccion) {
					Consulta.Procesar('UPD3',{
						oferta: this.seleccion,
						fusion: this.equivalente
					}).then(response => {
						//this.$store.state.error = response;
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Información Procesada', 
								life: 3000
							});
							this.fusionDialog = false;
							this.Buscar();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: 'No fue procesada la solicitud', 
								life: 3000
							});
						}
						this.$store.commit('Loading');
					});
				}
			},
			editProduct(product) {
				this.titulo = '(Editar)';
				this.selectedopc = {nombre: 'Una Asignatura', code: 0};
				this.asignaturas = [];
				this.selectedsem = null;
				this.product = null;
				this.product = {...product};
				this.product.plan = this.product.plan+' '+this.product.codigo;
				this.semestres.forEach(element => {
					if(this.product.sem==element.code){
						this.product.sem = element;
						this.Validacion3(element);
					}
				});
				this.secs.forEach(element => {
					if(this.product.sec_id==element.code){
						this.product.sec = element;
					}
				});  
				this.turnos.forEach(element => {
					if(this.product.turno_id==element.code){
						this.product.turno = element;
					}
				});  
				this.docentes = this.docentescomp.filter(doc => doc.id == this.product.docente || doc.horas<36);
				this.docentescomp.forEach(element => {
					if(this.product.docente==element.code){
						this.product.docente = element;
					}
				});  
				this.productDialog = true;
			},
			editHorario(product) {
				this.dia = null;
				this.hora = null;
				this.aula = null;
				this.titulo = '(Editar)';
				this.product = null;
				this.product = {...product};
				this.ActHorario(this.product.id);
				this.productDialog2 = true;
			},
			ActHorario(oferta) {
				Consulta.Procesar('Horario',{
					id: 	oferta,
				}).then(response => {
					//this.$store.state.error =response;
					this.horas = response.result.horas;
					this.dias = response.result.dias;
					this.horario = response.result.horario;
					this.Validacion4();
					this.$store.commit('Loading');
				}); 
			},
			saveProduct() {
				if (this.product.turno == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Turno', life: 5000});
				} else {
					if(!this.product.docente) {
						this.product.docente = {code: false};
					}
					this.productDialog = false;
					if (this.product.id) {
						Consulta.Procesar('UPD',{
							id: 	this.product.id,
							nucleo: this.nucleo.code,
							plan: this.plan.code,
							periodo: this.periodo.code,
							turno: this.product.turno.code,
							sec: this.product.sec.code,
							capacidad: this.product.capacidad,
							hextra: this.product.hextra,
							horas: this.product.horas,
							asignatura: this.product.asignatura,
							docente: this.product.docente.code,
							activo: this.product.activo,
						}).then(response => {
							//this.$store.state.error =response;
							this.$store.commit('Loading');
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Buscar();
								if (this.seccion){
									this.Seccion();
								}
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
						});
					} else {
                        if (this.selectedopc.code>0){
                            Consulta.Procesar('INS',{
                                opcion: this.selectedopc.code,
                                nucleo: this.nucleo.code,
                                plan: this.plan.code,
                                periodo: this.periodo.code,
                                turno: this.product.turno.code,
								sec: this.product.sec.code,
								capacidad: this.product.capacidad,
                                asignaturas: this.asignaturas,
								activo: this.product.activo,
                            }).then(response => {
                                //this.$store.state.error =response;
								this.$store.commit('Loading');
                                if (response.result) {
                                    this.$toast.add({
                                        severity:'success', 
                                        summary: 'Éxito', 
                                        detail: 'Información Procesada', 
                                        life: 3000
                                    });
                                    this.Buscar();
                                } else {
                                    this.$toast.add({
                                        severity:'warn', 
                                        summary: 'Advertencia', 
                                        detail: 'No fue procesada la solicitud', 
                                        life: 3000
                                    });
                                }
                            });
                        } else {
                            Consulta.Procesar('INS',{
                                opcion: this.selectedopc.code,
                                nucleo: this.nucleo.code,
                                plan: this.plan.code,
                                periodo: this.periodo.code,
                                turno: this.product.turno.code,
                                sec: this.product.sec.code,
                                capacidad: this.product.capacidad,
								docente: this.product.docente.code,
                                asignatura: this.product.asignatura,
                            }).then(response => {
                                //this.$store.state.error =response;
								this.$store.commit('Loading');
                                if (response.result) {
                                    this.$toast.add({
                                        severity:'success', 
                                        summary: 'Éxito', 
                                        detail: 'Información Procesada', 
                                        life: 3000
                                    });
                                    this.Buscar();
                                } else {
                                    this.$toast.add({
                                        severity:'warn', 
                                        summary: 'Advertencia', 
                                        detail: 'No fue procesada la solicitud', 
                                        life: 3000
                                    });
                                }
                            });
                        }
					}
					this.productDialog = false;
					this.product = {};
				}
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			deleteProduct() {
				this.products = this.products.filter(val => val.id !== this.product.id);
				Consulta.Procesar('DEL',{
					id: 	this.product.id
				}).then(response => {
					//this.$store.state.error =response;
					this.$store.commit('Loading');
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
				});
				this.deleteProductDialog = false;
				this.product = {};
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
				convfech(fecha){
					return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
				},

		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
