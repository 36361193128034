<template>
	<div class="p-grid">
		<div class="p-col-12 p-md-12">
            <div class="card">
				<h5>Estudiante: {{estudiante.id}} - {{estudiante.apellido}}, {{estudiante.nombre}} ({{matricula.extension}}) <strong>{{condicion}}</strong></h5> 
				<h5>{{matricula.plan}} - Turno: {{matricula.turno}} - Sección: {{matricula.sec}}</h5>
				<h4 style="width: 800px;">Proceso de Inscripción</h4>
                <Steps :model="items" :readonly="true" />
            </div>
            <router-view v-slot="{Component}" :formData="formObject" @prev-page="prevPage($event)" @next-page="nextPage($event)" @complete="complete">
                <keep-alive>
                    <component :is="Component" />
                </keep-alive>
            </router-view>
		</div>
	</div>
</template>
<script>
	import API from "../service/API";
	const Consulta = new API('Inscripcion','Inscripcion');

	export default {
		data() {
			return {
				items: [
					{
						label: 'Forma de Pago',
						to: '/inscrip'
					},
					{
						label: 'Seleccionar Asignatura(s)',
						to: '/inscripcion_paso2'
					},
					{
						label: 'Verificar Horario',
						to: '/inscripcion_paso3'
					},
					{
						label: 'Confirmar',
						to: '/inscripcion_paso4'
					}
				],
				formObject: {},
				size: '60vw',
                estudiante: {},
                matricula: {},
                condicion: null,
			}
		},
		created() {
			if (this.$store.state.matricula>0) {
				this.Datos(this.$store.state.matricula);
			}
		},
		methods: {
			Datos(matricula) {
				if (matricula>0){
					Consulta.Procesar('Datos',{
						id: 	matricula,
					}).then(response => {
						//this.$store.state.error =response;
						this.estudiante = response.result.estudiante;
						this.matricula = response.result.matricula[0];
						if (response.result.nuevo){this.condicion = 'Nuevo Ingreso';}
						this.$store.commit('Loading');
					}); 
				}
			},

			nextPage(event) {
				for (let field in event.formData) {
					this.formObject[field] = event.formData[field];
				}
				this.$router.push(this.items[event.pageIndex + 1].to);
			},
			prevPage(event) {
				this.$router.push(this.items[event.pageIndex - 1].to);
			},
			complete() {
				this.$toast.add({severity:'success', summary:'Order submitted', detail: 'Dear, ' + this.formObject.firstname + ' ' + this.formObject.lastname + ' your order completed.'});
			},
		}
	}
</script>

