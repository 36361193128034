function Acta_Grado(pdf,data,contenido) {
    pdf.addImage("logo.jpg", 'JPEG', 15, 15, 20, 20);   
    pdf.setFontSize(12);
    pdf.setFont("times", "normal");
    pdf.text(100, 20, "INSTITUTO UNIVERSITARIO DE TECNOLOGÍA INDUSTRIAL" , 'center');
    pdf.text(100, 25, "RODOLFO LOERO ARISMENDI (IUTIRLA)", 'center');
    pdf.text(100, 30, "RIF.: J-00130751-6", 'center');
    pdf.text(100, 35,'EXTENSIÓN: '+data.extension.toUpperCase(), 'center');
    
    //Firma
    pdf.setFontSize(14);
    pdf.text(150, 210, contenido.firma.nombre, 'center');
    pdf.text(150, 215,contenido.firma.cargo, 'center');
    
    var parraf = contenido.parrafos;
    for (var j = 0; j < parraf.length; j++) {
        pdf.setFontSize(parraf[j].tam);
        if(parraf[j].normal){pdf.setFont("arial", "bold");} else {pdf.setFont("arial", "");}
        if(parraf[j].alin=='center'){
            pdf.text((100+parraf[j].izq), parraf[j].alt+40, parraf[j].contenido, 'center',{lineHeightFactor: parseFloat(parraf[j].interlineado)});
        } else {
            pdf.text(parraf[j].contenido, parraf[j].izq, parraf[j].alt+40, {align: parraf[j].alin,lineHeightFactor: parseFloat(parraf[j].interlineado), maxWidth: (215-2*parraf[j].izq)});
        }
    }

}

export default Acta_Grado;