<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
                    <InputText type="number" min="0" v-model="buscar" required="true" placeholder="Cédula" @keyup.enter="Buscar" v-if="opcion"/>
                    <InputText type="text" v-model="buscar" style="width:250px;" required="true" placeholder="Apellido o Nombre" @keyup.enter="Buscar" v-if="!opcion"/>
					<Button label="Buscar" icon="pi pi-search   " class="p-button-success p-mr-2" @click="Buscar" />
                    <InputSwitch v-model="opcion" v-tooltip.bottom="'Cédula <-> Nombre'"/>				
					</template>			
					<template v-slot:right>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="openNew" v-if="insert"/>
					</template>
				</Toolbar>
				<DataTable :value="matriculas" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters" v-if="matriculas.length>0">
					<template #header>
						<h4>
							{{estudiante.nac}}{{estudiante.id}} {{estudiante.apellido}}, {{estudiante.nombre}} ({{estudiante.nucleo}})		
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(estudiante)" v-if="edit"/>
							<Button icon="pi pi-plus" class="p-button-rounded p-button-secondary p-mr-2" @click="newMat()" v-if="edit"/>
							<div v-if="estudiante.nac=='P'">
								{{estudiante.pasaporte}}
							</div>
						</h4>
					</template>
					<Column field="plan" header="Plan de Estudio" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.plan}}<br>
							({{slotProps.data.extension}})
						</template>
					</Column>
					<Column field="periodo" header="Período" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.periodo}}
						</template>
					</Column>
					<Column field="turno" header="Turno" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.turno}}
						</template>
					</Column>
					<Column field="sec" header="Sección" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.sec}}
						</template>
					</Column>
					<Column field="planes" header="Calificaciones">
						<template #body="slotProps">
							<span class="p-overlay-badge p-mr-5" @click="openCalificaciones(slotProps.data)">
								<i class="bi bi-journal-bookmark" style="font-size: 2em"></i>
								<span class="p-badge">{{slotProps.data.calificaciones}}</span>
							</span>
							{{slotProps.data.tasig}}/{{slotProps.data.asigs}}
						</template>
					</Column>
					<Column field="activo" header="Estatus">
						<template #body="slotProps">
								<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
						</template>
					</Column>
					<Column header="Opciones">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editMat(slotProps.data)" v-if="edit" v-tooltip.bottom="'Editar'"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmMatriculaProduct(slotProps.data)" v-if="del" v-tooltip.bottom="'Eliminar'"/>
						</template>
					</Column>
				</DataTable>
				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm" v-else>
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Estudiantes</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="nucleo" header="Extensión" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nucleo}}
						</template>
					</Column>
					<Column field="id" header="N° Cédula" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nac}}{{slotProps.data.id}}
							<div v-if="slotProps.data.nac!='V' && slotProps.data.pasaporte!=null && slotProps.data.pasaporte!=''">
								{{slotProps.data.pasaporte}}
							</div>
						</template>
					</Column>
					<Column field="nombre" header="Nombre" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="apellido" header="Apellido" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.apellido}}
						</template>
					</Column>
					<Column field="planes" header="Planes" >
						<template #body="slotProps">
							<span class="p-overlay-badge p-mr-5" @click="openMatricula(slotProps.data)">
								<i class="pi pi-eye" style="font-size: 2em"></i>
								<span class="p-badge">{{slotProps.data.planes}}</span>
							</span>
						</template>
					</Column>
					<Column field="activo" header="Estatus">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
						</template>
					</Column>
					<Column header="Opciones">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" v-if="edit" v-tooltip.bottom="'Editar'"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteProduct(slotProps.data)" v-if="del" v-tooltip.bottom="'Eliminar'"/>
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: size}" :header="'Estudiante '+titulo" :modal="true" class="p-fluid" :maximizable="true">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-2">
								<label class="p-mb-2">Nacionalidad</label>
								<Dropdown v-model="product.nac" :options="nac" optionLabel="nombre" placeholder="?..."></Dropdown>
							</div>
							<div class="p-col-2">
								<label for="name">Cédula</label>
								<InputNumber id="cedula" v-model="product.id" integeronly required="true" min="0" disable="true" autofocus v-if="!product.id"/>
								<InputText id="cedula" v-model="product.id" placeholder="Disabled" :disabled="true" v-else></InputText>						
							</div>
							<div class="p-col-4">
								<label for="name">Nombre</label>
								<InputText id="name" v-model.trim="product.nombre" required="true"/>
								<small class="p-invalid" v-if="submitted && !product.nombre">Name is required.</small>
							</div>
							<div class="p-col-4">
								<label for="name">Apellido</label>
								<InputText id="name" v-model.trim="product.apellido" required="true"/>
								<small class="p-invalid" v-if="submitted && !product.nombre">Name is required.</small>
							</div>
							<div class="p-col-2">
								<h6 style="margin-top: 0" v-if="product.sexo_id">Masculino</h6>
								<h6 style="margin-top: 0" v-else>Femenino</h6>
								<InputSwitch v-model="product.sexo_id" />
							</div>
							<div class="p-col-6">
								<label for="name">Correo</label>
								<InputText id="name" v-model.trim="product.correo" required="true"/>
								<small class="p-invalid" v-if="submitted && !product.nombre">Name is required.</small>
							</div>
							<div class="p-col-4">
								<label>Fec. Nacimiento</label>
								<InputText v-model="product.fecha_nac" type="date"/>
							</div>
							<div class="p-col-4">
								<label class="p-mb-2">País Nac.</label>
								<Dropdown v-model="product.paisnac" :options="paisnac" optionLabel="nombre" placeholder="Selecciona..." @change="Validacion1()"></Dropdown>
							</div>
							<div class="p-col-4">
								<label class="p-mb-2">Estado Nac.</label>
								<Dropdown v-model="product.estadonac" :options="estadonac" optionLabel="nombre" placeholder="Selecciona..." v-if="product.paisnac" @change="Validacion2()"></Dropdown>
							</div>
							<div class="p-col-4">
								<label class="p-mb-2">Ciudad Nac.</label>
								<Dropdown v-model="product.lugarnac" :options="lugarnac" optionLabel="nombre" placeholder="Selecciona..." v-if="product.estadonac"></Dropdown>
							</div>
							<div class="p-col-4">
								<label class="p-mb-3">Extensión</label>
								<Dropdown id="state" v-model="product.nucleo" :options="nucleos" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-4">
								<label class="p-mb-2">Est.Civil</label>
								<Dropdown v-model="product.estcivil" :options="estcivil" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-4">
								<label class="p-mb-2">Etnia</label>
								<Dropdown v-model="product.etnia" :options="etnias" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-4">
								<label class="p-mb-2">Discapacidad</label>
								<Dropdown v-model="product.discapacidad" :options="discapacidades" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-2">
								<h6 style="margin-top: 0">Residente</h6>
								<InputSwitch v-model="product.residente" />
							</div>							
							<div class="p-col-2">
								<h6 style="margin-top: 0" v-if="product.activo">Activo</h6>
								<h6 style="margin-top: 0" v-else>No Activo</h6>
								<InputSwitch v-model="product.activo" />
							</div>
							<div class="p-col-4" v-if="product.nac">
								<label for="name">Pasaporte</label>
								<InputText id="name" v-model="product.pasaporte" :disabled="product.nac.code=='V'"/>
							</div>
						</div>
						<div class="p-fluid p-formgrid p-grid">                   
							<div class="p-field p-col-12 p-md-6">
								<FileUpload chooseLabel="Subir Copia de Cédula JPG" uploadLabel="Cargar" mode="basic" :name="token1" :url="url+'doc.php'"  accept="image/jpeg" :maxFileSize="1000000" @upload="myUploader(token1,1)" :auto="true"/>
								<img style="width: 200px;" :src="url+'doc/'+product.img_cedula+'.jpg'" @click="VIMG(product.img_cedula+'.jpg')" v-if="product.img_cedula"/>
							</div>
							<div class="p-field p-col-12 p-md-6">
								<FileUpload chooseLabel="Subir Copia de Título JPG" uploadLabel="Cargar" mode="basic" :name="token2" :url="url+'doc.php'"  accept="image/jpeg" :maxFileSize="1000000" @upload="myUploader(token2,2)" :auto="true"/>
								<img style="width: 200px;" :src="url+'doc/'+product.img_titulo+'.jpg'" @click="VIMG(product.img_titulo+'.jpg')" v-if="product.img_titulo"/>
							</div>
							<div class="p-field p-col-12 p-md-4">
								<FileUpload chooseLabel="Subir Copia de Notas 1 JPG" uploadLabel="Cargar" mode="basic" :name="token3" :url="url+'doc.php'"  accept="image/jpeg" :maxFileSize="1000000" @upload="myUploader(token3,3)" :auto="true"/>
								<img style="width: 200px;" :src="url+'doc/'+product.img_notas1+'.jpg'" @click="VIMG(product.img_notas1+'.jpg')" v-if="product.img_notas1"/>
							</div>
							<div class="p-field p-col-12 p-md-4">
								<FileUpload chooseLabel="Subir Copia de Notas 2 JPG" uploadLabel="Cargar" mode="basic" :name="token4" :url="url+'doc.php'"  accept="image/jpeg" :maxFileSize="1000000" @upload="myUploader(token4,4)" :auto="true"/>
								<img style="width: 200px;" :src="url+'doc/'+product.img_notas2+'.jpg'" @click="VIMG(product.img_notas2+'.jpg')" v-if="product.img_notas2"/>
							</div>
							<div class="p-field p-col-12 p-md-4">
								<FileUpload chooseLabel="Subir Copia de Notas 3 JPG" uploadLabel="Cargar" mode="basic" :name="token5" :url="url+'doc.php'"  accept="image/jpeg" :maxFileSize="1000000" @upload="myUploader(token5,5)" :auto="true"/>
								<img style="width: 200px;" :src="url+'doc/'+product.img_notas3+'.jpg'" @click="VIMG(product.img_notas3+'.jpg')" v-if="product.img_notas3"/>
							</div>
						</div>          
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="hideDialog"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}" header="Confirmar" :modal="true" :maximizable="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button label="Sí" icon="pi pi-check" class="p-button-secondary" @click="deleteProduct" />
						<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteMatriculaDialog" :style="{width: size}" header="Confirmar Eliminar Matricula" :modal="true" :maximizable="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="matricula">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button label="Sí" icon="pi pi-check" class="p-button-secondary" @click="deleteMatricula" />
						<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteMatriculaDialog = false"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="matriculaDialog" :style="{width: size}" :header="'Plan de Estudio por Estudiante '+titulo" :modal="true" class="p-fluid" :maximizable="true">
					<br>
					<h4>{{estudiante.nac}}{{estudiante.id}} {{estudiante.apellido}}, {{estudiante.nombre}} ({{estudiante.nucleo}})</h4>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-6">
								<label class="p-mb-2">Plan de Estudio</label>
								<Dropdown v-model="matricula.plan" :options="planes" optionLabel="nombre" placeholder="Selecciona..." @change="ValidaSeccion()"></Dropdown>
							</div>
							<div class="p-col-2">
								<label class="p-mb-2">Período</label>
								<Dropdown v-model="matricula.periodo" :options="periodos[matricula.plan['modalidad']]" optionLabel="nombre" placeholder="Selecciona..." v-if="matricula.plan" @change="ValidaSeccion()"></Dropdown>
							</div>
							<div class="p-col-4">
								<label class="p-mb-2">Turno</label>
								<Dropdown v-model="matricula.turno" :options="turnos" optionLabel="nombre" placeholder="Selecciona..." v-if="matricula.plan" @change="ValidaSeccion()"></Dropdown>
							</div>
							<div class="p-col-3">
								<label class="p-mb-2">Sección</label>
								<Dropdown v-model="matricula.seccion" :options="secciones" optionLabel="nombre" placeholder="Selecciona..." v-if="matricula.plan && matricula.periodo"></Dropdown>
							</div>
							<div class="p-col-3">
								<label class="p-mb-2">Condición</label>
								<Dropdown v-model="matricula.condicion" :options="condiciones" optionLabel="nombre" placeholder="Selecciona..." v-if="matricula.plan"></Dropdown>
							</div>
							<div class="p-col-3">
								<label class="p-mb-2">Ingreso</label>
								<Dropdown v-model="matricula.tipoing" :options="tiposing" optionLabel="nombre" placeholder="Selecciona..." v-if="matricula.plan"></Dropdown>
							</div>
							<div class="p-col-3">
								<label class="p-mb-2">Tipo</label>
								<Dropdown v-model="matricula.tipomatricula" :options="tiposmat" optionLabel="nombre" placeholder="Selecciona..." v-if="matricula.plan"></Dropdown>
							</div>
							<div class="p-col-12">
								<label>Observación</label>
								<Textarea v-model="matricula.observacion"  rows="4"/>						
							</div>
							<div class="p-field p-col-12 p-md-3">
							<label>Fecha Ingreso</label>
						<InputText v-model="matricula.fingreso" type="date"/>
							</div>						
							<div class="p-col-2">
								<h6 style="margin-top: 0" v-if="matricula.activo">Activo</h6>
								<h6 style="margin-top: 0" v-else>No Activo</h6>
								<InputSwitch v-model="matricula.activo" />
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveMat()" v-if="matricula.seccion"/>
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger"  @click="matriculaDialog = false"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>
	<Calificaciones :calificaciones="calificaciones" :matricula="matricula" :display="displayCalificaciones" @aprob="onMenuToggle" @close-calificaciones="close"/>

</template>

<script>
	import API from "../service/API";
	import Calificaciones from '../components/VerCalificaciones.vue';
	import Token from "uuid-token-generator";
	const Consulta = new API('Prosecusion','Estudiantes');

	export default {
		components: {
		'Calificaciones': Calificaciones
		},
		data() {
			return {
				nuevo: false,
				buscar: null,
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				matriculas: [],
				periodos: [],
				planes: [],
				turnos: [],
				condiciones: [],
				tiposing: [],
				tiposmat: [],
				secciones: [],
				nac: [
					{code: 'V', nombre: 'Venezolano(a)'},
					{code: 'E', nombre: 'Extranjero(a)'},
					{code: 'P', nombre: 'Pasaporte'}
				],
				productDialog: false,
				deleteProductDialog: false,
				deleteMatriculaDialog: false,
				deleteProductsDialog: false,
				product: {},
				estudiante: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				nucleos: [],
				paisnac: [],
				estadonac: [],
				lugarnac0: [],
				lugarnac: [],
				estcivil: [],
				etnias: [],
				discapacidades: [],
				matriculaDialog: false,
				matricula: {},
				displayCalificaciones: false,
				calificaciones: [],
				token1: null,
				token2: null,
				token3: null,
				token4: null,
				token5: null,
			}
		},
		productService: null,
		created() {
			this.url = process.env.VUE_APP_API;
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			const tokgen = new Token();
			this.token1 = tokgen.generate();
			this.token2 = tokgen.generate();
			this.token3 = tokgen.generate();
			this.token4 = tokgen.generate();
			this.token5 = tokgen.generate();
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			onMenuToggle(event) {
				this.$emit('menu-toggle', event);
			},
			openCalificaciones(matricula) {
				this.$store.commit('Mobile');
				this.matricula = {...matricula};					
				Consulta.Procesar('Calificaciones',{
					id: 	this.matricula.id,
				}).then(response => {
					//this.$store.state.error =response;
					this.calificaciones = response.result;
					this.displayCalificaciones = true;
					this.$store.commit('Loading');
				}); 
			},
			close() {
				this.matricula = {};
				this.displayCalificaciones = false;
			},
			VIMG(data){
				window.open(this.url+'doc/'+data, '_blank');
			},
			myUploader(token,caso) {
				Consulta.Procesar('ImgDoc',{
					img: token,
				}).then(response => {
					if(response.result && caso==1){this.product.img_cedula = token;}
					if(response.result && caso==2){this.product.img_titulo = token;}
					if(response.result && caso==3){this.product.img_notas1 = token;}
					if(response.result && caso==4){this.product.img_notas2 = token;}
					if(response.result && caso==5){this.product.img_notas3 = token;}
					const tokgen = new Token();
					this.token = tokgen.generate();
					this.$store.commit('Loading');
				});
			},
			Mostrar() {
				this.matriculas = [];
				Consulta.Ini().then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.nucleos = result.consult.nucleos;
					this.paisnac = result.consult.paisnac;
					this.estadonac = result.consult.estadonac;
					this.lugarnac0 = result.consult.lugarnac;
					this.estcivil = result.consult.estcivil;
					this.discapacidades = result.consult.discapacidades;
					this.etnias = result.consult.etnias;
					this.$store.commit('Loading');
				}); 
			},
			Buscar() {
				this.matriculas = [];
				if (this.buscar){
					Consulta.Procesar('Buscar',{
						id: 	this.buscar,
					}).then(response => {
						//this.$store.state.error =response;
						this.products = response.result;
						this.$store.commit('Loading');
					}); 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail:'Debes colocar los datos solicitados3s', 
						life: 3000
					});    
				}
			},
			Validacion1() {
				Consulta.Procesar('Parametros',{
				}).then(response => {
					//this.$store.state.error =response;
					this.estadonac = response.result.estadonac;
					if (this.product.paisnac.code!=296){
						this.estadonac = this.estadonac.filter(val => val.code == 25);
					} else {
						this.estadonac = this.estadonac.filter(val => val.code != 25);                    
					}
					this.$store.commit('Loading');
				});
			},
			Validacion2() {
				Consulta.Procesar('Parametros',{
				}).then(response => {
					//this.$store.state.error = response;
					this.lugarnac = response.result.lugarnac;
					this.lugarnac = this.lugarnac.filter(val => val.estado == this.product.estadonac.code);
					this.$store.commit('Loading');
				});
			},
			ValidaSeccion() {
				if (this.matricula.plan && this.matricula.periodo && this.matricula.turno){
					this.secciones = [];
					Consulta.Procesar('Secciones',{
						plan: this.matricula.plan.code,
						nucleo: this.estudiante.nucleo_id,
						periodo: this.matricula.periodo.code,
						turno: this.matricula.turno.code,
					}).then(response => {
						//this.$store.state.error = response;
						this.$store.commit('Loading');	
						this.secciones = response.result;
						this.secciones.forEach(element => {
							if(this.matricula.seccion==element.code){
								this.matricula.seccion = element;
							}
						});  
					});
				}
			},
			openMatricula(estudiante) {
				this.estudiante = {};
				this.estudiante = {...estudiante};
				if (estudiante.planes>0){
					Consulta.Procesar('Matricula',{
						id: 	this.estudiante.id,
						nucleo: 	this.estudiante.nucleo_id,
					}).then(response => {
						//this.$store.state.error = response.result.matriculas;
						this.matriculas = response.result.matriculas;
						this.planes = response.result.planes;
						this.periodos = response.result.periodos;
						this.turnos = response.result.turnos;
						this.condiciones = response.result.condiciones;
						this.tiposing = response.result.tiposing;
						this.tiposmat = response.result.tiposmat;
						this.$store.commit('Loading');
					}); 
				} else {
					this.newMat(estudiante);
				}
			},
			openNew() {
				this.titulo = '(Nuevo)';
				this.nuevo = true;
				this.product = {};

				this.product.paisnac = {code: 296, nombre: 'Venezuela'};
				this.submitted = false;
				this.productDialog = true;
			},
			newMat() {
				this.titulo = '(Nuevo)';
				this.hideDialog();
				this.matricula = {};
				Consulta.Procesar('Matricula',{
					id: 	this.estudiante.id,
					nucleo: 	this.estudiante.nucleo_id,
				}).then(response => {
					//this.$store.state.error = response;
					this.planes = response.result.planes;
					this.periodos = response.result.periodos;
					this.turnos = response.result.turnos;
					this.condiciones = response.result.condiciones;
					this.tiposing = response.result.tiposing;
					this.tiposmat = response.result.tiposmat;
					this.$store.commit('Loading');
					this.matriculaDialog = true;
					this.ValidaSeccion();
				}); 
			},
			editMat(matricula) {
				this.titulo = '(Editar)';
				this.matricula = {};
				this.matricula = {...matricula};
				this.planes.forEach(element => {
					if(this.matricula.plan_id==element.code){
						this.matricula.plan = element;
					}
				});  
				this.periodos[this.matricula.modalidad].forEach(element => {
					if(this.matricula.periodo_id==element.code){
						this.matricula.periodo = element;
					}
				});
				this.turnos.forEach(element => {
					if(this.matricula.turno_id==element.code){
						this.matricula.turno = element;
					}
				});  
				this.condiciones.forEach(element => {
					if(this.matricula.condicion_id==element.code){
						this.matricula.condicion = element;
					}
				});  
				this.tiposing.forEach(element => {
					if(this.matricula.tipoing_id==element.code){
						this.matricula.tipoing = element;
					}
				});  
				this.tiposmat.forEach(element => {
					if(this.matricula.tipomatricula_id==element.code){
						this.matricula.tipomatricula = element;
					}
				});  
				this.ValidaSeccion();
				this.matriculaDialog = true;
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			cerrar() {
				this.editmatriculaDialog = false;
			},
			saveMat() {
				if (this.matricula.plan == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Plan de Estudio', life: 5000});
				} else if (this.matricula.periodo == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Periodo', life: 5000});
				} else if (this.matricula.turno == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Turno', life: 5000});
				} else if (this.matricula.condicion == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Condición de Ingreso', life: 5000});
				} else if (this.matricula.tipoing == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Tipo de Ingreso', life: 5000});
				} else if (this.matricula.tipomatricula == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Tipo de Estudio', life: 5000});
				} else if (this.matricula.fingreso == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Fecha de Ingreso', life: 5000});
				} else {
					this.buscar = this.estudiante.id;
					this.matriculaDialog = false;
					if (!this.matricula.id) {
						Consulta.Procesar('INS2',{
							estudiante: this.estudiante.id,
							nucleo: this.estudiante.nucleo_id,
							plan: this.matricula.plan.code,
							periodo: this.matricula.periodo.code,
							turno: this.matricula.turno.code,
							seccion: this.matricula.seccion,
							condicion: this.matricula.condicion.code,
							tipo_ing: this.matricula.tipoing.code,
							tipomatricula: this.matricula.tipomatricula.code,
							fingreso: this.matricula.fingreso,
							observacion: this.matricula.observacion,
							activo: this.matricula.activo
						}).then(response => {
							//this.$store.state.error =response;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.$store.commit('Loading');
								this.Buscar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
								this.$store.commit('Loading');
							}
						});
					} else {
						Consulta.Procesar('UPD2',{
							id: 	this.matricula.id,
							estudiante: this.estudiante.id,
							nucleo: this.estudiante.nucleo_id,
							plan: this.matricula.plan.code,
							periodo: this.matricula.periodo.code,
							turno: this.matricula.turno.code,
							seccion: this.matricula.seccion,
							condicion: this.matricula.condicion.code,
							tipo_ing: this.matricula.tipoing.code,
							tipomatricula: this.matricula.tipomatricula.code,
							fingreso: this.matricula.fingreso,
							observacion: this.matricula.observacion,
							activo: this.matricula.activo
						}).then(response => {
							//this.$store.state.error =response;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.$store.commit('Loading');
								this.openMatricula(this.estudiante);
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
								this.$store.commit('Loading');
							}
						});
					}
					this.productDialog = false;
					this.product = {};
				}
			},
			saveProduct() {
				if (this.product.nac == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione Nacionalidad', life: 5000});
				} else if (this.product.id == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el Nº Cédula', life: 5000});
				} else if (this.product.nombre == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el Nombre', life: 5000});
				} else if (this.product.apellido == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el Apellido', life: 5000});
				} else if (this.product.correo == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el Correo', life: 5000});
				} else if (this.product.nucleo == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Extensión', life: 5000});
				} else if (this.product.fecha_nac == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la Fecha de Nacimiento', life: 5000});
				} else if (this.product.paisnac == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el País de Nacimiento', life: 5000});
				} else if (this.product.lugarnac == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Ciudad de Nacimiento', life: 5000});
				} else if (this.product.estcivil == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Estado Civil', life: 5000});
				} else if (this.product.etnia == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Etnia', life: 5000});
				} else if (this.product.discapacidad == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Discapacidad', life: 5000});
				} else {
					this.productDialog = false;
					if (this.nuevo) {
						this.buscar = this.product.id;
						this.opcion = true;
						Consulta.Procesar('INS',{
							id: 	this.product.id,
							nac: this.product.nac.code,
							apellido: this.product.apellido,
							nombre: this.product.nombre,
							correo: this.product.correo,
							nucleo: this.product.nucleo.code,
							fecha_nac: this.product.fecha_nac,
							est_civil: this.product.estcivil.code,
							sexo: this.product.sexo_id,
							pais_nac: this.product.paisnac.code,
							estado_nac: this.product.estadonac.code,
							lugar_nac: this.product.lugarnac.code,
							discapacidad: this.product.discapacidad.code,
							etnia: this.product.etnia.code,
							residente: this.product.residente,
							activo: this.product.activo,
							pasaporte: this.product.pasaporte,
							img_cedula: this.product.img_cedula,
							img_titulo: this.product.img_titulo,
							img_notas1: this.product.img_notas1,
							img_notas2: this.product.img_notas2,
							img_notas3: this.product.img_notas3,
						}).then(response => {
							//this.$store.state.error =response;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.$store.commit('Loading');
								this.Buscar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
								this.$store.commit('Loading');
							}						
						});
					} else {
						this.buscar = this.product.id;
						Consulta.Procesar('UPD',{
							id: 	this.product.id,
							nac: this.product.nac.code,
							apellido: this.product.apellido,
							nombre: this.product.nombre,
							correo: this.product.correo,
							nucleo: this.product.nucleo.code,
							fecha_nac: this.product.fecha_nac,
							est_civil: this.product.estcivil.code,
							sexo: this.product.sexo_id,
							pais_nac: this.product.paisnac.code,
							lugar_nac: this.product.lugarnac.code,
							estado_nac: this.product.estadonac.code,
							pasaporte: this.product.pasaporte,
							discapacidad: this.product.discapacidad.code,
							etnia: this.product.etnia.code,
							residente: this.product.residente,
							activo: this.product.activo,
							img_cedula: this.product.img_cedula,
							img_titulo: this.product.img_titulo,
							img_notas1: this.product.img_notas1,
							img_notas2: this.product.img_notas2,
							img_notas3: this.product.img_notas3,
						}).then(response => {
							//this.$store.state.error =response;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.$store.commit('Loading');
								this.Buscar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
								this.$store.commit('Loading');
							}
							
						});
					}
					this.productDialog = false;
					this.product = {};
				}
			},
			editProduct(product) {
				this.titulo = '(Editar)';
					this.nuevo = false;
				this.product = {...product};
				this.nac.forEach(element => {
					if(this.product.nac==element.code){
						this.product.nac = element;
					}
				});  
				this.nucleos.forEach(element => {
					if(this.product.nucleo_id==element.code){
						this.product.nucleo = element;
					}
				});  
				this.estcivil.forEach(element => {
					if(this.product.estcivil_id==element.code){
						this.product.estcivil = element;
					}
				});  
				this.paisnac.forEach(element => {
					if(this.product.paisnac_id==element.code){
						this.product.paisnac = element;
					}
				});
				this.estadonac.forEach(element => {
					if(this.product.estadonac_id==element.code){
						this.product.estadonac = element;
						}
				});
				this.lugarnac0.forEach(element => {
					if(this.product.lugarnac_id==element.code){
						this.product.lugarnac = element;
					}
				});  
				this.Validacion1();
				this.Validacion2();
				this.discapacidades.forEach(element => {
					if(this.product.discapacidad_id==element.code){
						this.product.discapacidad = element;
					}
				});  
				this.etnias.forEach(element => {
					if(this.product.etnia_id==element.code){
						this.product.etnia = element;
					}
				});  
				this.productDialog = true;
			},
			confirmMatriculaProduct(matricula) {
				this.matricula = matricula;
				this.deleteMatriculaDialog = true;
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			deleteMatricula() {
				this.matriculas = this.matriculas.filter(val => val.id !== this.matricula.id);
				Consulta.Procesar('DEL2',{
					id: 	this.matricula.id
				}).then(response => {
					this.$store.commit('Loading');
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
				});
				this.deleteMatriculaDialog = false;
				this.matricula = {};
			},
			deleteProduct() {
				this.products = this.products.filter(val => val.id !== this.product.id);
				Consulta.Procesar('DEL',{
					id: 	this.product.id
				}).then(response => {
					this.$store.commit('Loading');
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
				});
				this.deleteProductDialog = false;
				this.product = {};
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
