<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
				<DataTable :value="matriculas" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters" v-if="matriculas.length>0">
					<template #header>
						<h4>
							{{estudiante.nac}}{{estudiante.id}} {{estudiante.apellido}}, {{estudiante.nombre}} ({{estudiante.nucleo}})		
						</h4>
					</template>
					<Column field="plan" header="Plan de Estudio" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.plan}}
						</template>
					</Column>
					<Column field="periodo" header="Período" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.periodo}}
						</template>
					</Column>
					<Column field="turno" header="Turno" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.turno}}
						</template>
					</Column>
					<Column field="seccion" header="Sección" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.sec}}
						</template>
					</Column>
					<Column field="activo" header="Estatus">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
						</template>
					</Column>
					<Column field="planes" header="Consultar">
						<template #body="slotProps">
							<span class="p-overlay-badge p-mr-5" @click="openCalificaciones(slotProps.data)">
								<i class="bi bi-journal-bookmark" style="font-size: 2em"></i>
								<span class="p-badge">{{slotProps.data.calificaciones}}</span>
							</span>
							<span class="p-overlay-badge p-mr-5" @click="openInscripcion(slotProps.data)">
								<i class="bi bi-layers-half" style="font-size: 2em"></i>
								<span class="p-badge">{{slotProps.data.inscrip}}</span>
							</span>
							{{slotProps.data.tasig+'/'+slotProps.data.materias}}
						</template>
					</Column>
				</DataTable>
		</div>
	</div>
	<Calificaciones :calificaciones="calificaciones" :matricula="matricula" :display="displayCalificaciones" @aprob="onMenuToggle" @close-calificaciones="close"/>
	<Inscripcion :inscripcion="inscripcion" :matricula="matricula" :horario="horario" :display="displayInscricion" @aprob="onMenuToggle" @close-inscripcion="close"/>

</template>

<script>
	import API from "../service/API";
	import Calificaciones from '../components/VerCalificaciones.vue';
	import Inscripcion from '../components/VerInscripcion.vue';
	const Consulta = new API('AreaEstudiante','MisCalificaciones');

	export default {
		components: {
			'Calificaciones': Calificaciones,
			'Inscripcion': Inscripcion,
		},
		data() {
			return {
				nuevo: false,
				buscar: null,
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				matriculas: [],
				product: {},
				estudiante: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				matriculaDialog: false,
				matricula: {},
				displayCalificaciones: false,
				calificaciones: [],
				displayInscricion: false,
				inscripcion: [],
				displayHorario: false,
				horario: [],
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
            this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				Consulta.Ini().then(result => {
					//this.$store.state.error = result;
                    this.estudiante = result.consult.estudiante;
					this.matriculas = result.consult.matriculas;
					this.$store.commit('Loading');
				}); 
			},

            onMenuToggle(event) {
				this.$emit('menu-toggle', event);
			},
			openCalificaciones(matricula) {
				this.$store.commit('Mobile');
				this.matricula = {...matricula};					
				Consulta.Procesar('VerCalificaciones',{
					id: 	this.matricula.id,
				}).then(response => {
					//this.$store.state.error =response.result;
					this.calificaciones = response.result;
					this.displayCalificaciones = true;
					this.$store.commit('Loading');
				}); 
			},
			openInscripcion(matricula) {
				this.$store.commit('Mobile');
				this.matricula = {...matricula};					
				Consulta.Procesar('VerInscripcion',{
					id: 	this.matricula.id,
				}).then(response => {
					//this.$store.state.error =response.result;
					this.inscripcion = response.result.inscripcion;
					this.horario = response.result.horario;
					this.displayInscricion = true;
					this.$store.commit('Loading');
				}); 
			},
			close() {
				this.matricula = {};
				this.displayCalificaciones = false;
				this.displayInscricion = false;
				this.displayHorario = false;
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}
				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
