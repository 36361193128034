<template>
	<div class="p-grid">
		<div class="p-col-12" v-if="!product">
			<div class="card">
                <Toolbar class="p-col-12 p-md-12">
                    <template v-slot:left>
                        <InputText type="number" min="0" v-model="buscar" required="true" placeholder="Cédula" @keyup.enter="Buscar" v-if="opcion"/>
                        <InputText type="text" v-model="buscar" style="width:250px;" required="true" placeholder="Apellido o Nombre" @keyup.enter="Buscar" v-if="!opcion"/>
                        <br><br><br>
                        <InputSwitch v-model="opcion"/>				
                    </template>
                    <template v-slot:right>
                        <Button label="Buscar" icon="pi pi-search" class="p-button-success p-mr-2 p-mb-2" @click="Buscar" />
                    </template>
                </Toolbar>                   
                <DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
                    <template #header>
                        <div class="table-header">
                            <h5 class="p-m-1">Estudiantes</h5>
                        </div>
                    </template>
                    <Column header="Extensión" :sortable="true">
                        <template #body="slotProps" >
                            {{slotProps.data.nucleo}}
                        </template>
                    </Column>
                    <Column field="id" header="N° Cédula" :sortable="true">
                        <template #body="slotProps">
                            {{slotProps.data.id}}
                        </template>
                    </Column>
                    <Column field="nombre" header="Nombre" :sortable="true">
                        <template #body="slotProps">
                            {{slotProps.data.nombre}}
                        </template>
                    </Column>
                    <Column field="apellido" header="Apellido" :sortable="true">
                        <template #body="slotProps">
                            {{slotProps.data.apellido}}
                        </template>
                    </Column>
                    <Column field="activo" header="Estatus" :sortable="true">
                        <template #body="slotProps">
                            <span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
                            <span class="p-tag p-tag-danger" v-else>No Activo</span>
                        </template>
                    </Column>
                    <Column header="Seleccionar">
                        <template #body="slotProps">
                            <Button icon="pi pi-arrow-right" class="p-button-rounded p-button-success p-mr-2" @click="Seleccion(slotProps.data)" v-if="edit && slotProps.data.deuda<=0" v-tooltip.bottom="'Seleccionar'"/>
							<span class="p-tag p-tag-danger" v-if="slotProps.data.deuda>0">Tiene Deuda</span>	
                        </template>
                    </Column>                
                </DataTable>
            </div>
        </div>

		<div class="p-col-12" v-if="product">
			<div class="card">
				<Card>
					<template #title>
						<h5>Estudiante</h5>
					</template>
					<template #content>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12 p-md-2">
								Nº Cedula: <strong>{{product.nac}}{{product.id}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-8">
								Nombres y Apellidos: <strong>{{product.nombre}} {{product.apellido}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-8">
								Correo: <strong>{{product.correo}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-4">
								Teléfono: <strong>{{product.tlf_celular}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-8">
								Extensión: <strong>{{product.nucleo}}</strong>
							</div>		
                            <div class="p-field p-col-12 p-md-2" v-if="periodo">
								Período: <strong>{{periodo.nombre}}</strong>
                            </div>
							<div class="p-field p-col-12 p-md-6">
								Plan de Estudio: <Dropdown v-model="matricula" :options="matriculas" optionLabel="nombre" placeholder="?..." @change="Aranceles"></Dropdown>
							</div>		
						</div>
					</template>
				</Card>
			</div>
        </div>
		<div class="p-col-12" v-if="periodo">
			<div class="card">
				<Card>
					<template #title>
						<h5>Solicitudes</h5>
					</template>
					<template #content>
                        <Button label="Agregar" icon="pi pi-file" class="p-button-warning p-mr-2 p-mb-2" @click="DialogSolic=true" style="width: 200px;"/>
                        <DataTable :value="solicitudes" :scrollable="true" scrollHeight="200px" class="p-datatable-sm">
                            <Column header="N°"  headerStyle="width: 100px">
                                <template #body="slotProps">
                                    {{(slotProps.index+1)}}
                                </template>	
                            </Column>                            
                            <Column header="Tipo" headerStyle="width: 100px">	
                                <template #body="{data}">
                                    <strong v-if="data.tipo==3">Documento</strong>
                                    <strong v-else>Trámite</strong>
                                </template>
                            </Column>
                            <Column header="Nombre">	
                                <template #body="{data}">
                                    {{data.nombre}}
                                </template>
                            </Column>
                            <Column header="Monto"  headerStyle="width: 150px">	
                                <template #body="{data}">
                                    <strong v-if="periodo.moneda==1">Ref.</strong>
                                    <img :src="'images/moneda'+periodo.moneda+'.png'" width="15"/>
                                    {{data.monto}}
                                </template>
                            </Column>
                            <Column header="Eliminar"  headerStyle="width: 150px">	
                                <template #body="slotProps">
                                    <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="EliminarSolic(slotProps.data)"/>
                                </template>
                            </Column>
                        </DataTable>
					</template>
				</Card>
			</div>
        </div>

		<div class="p-col-12" v-if="factura_monto">
			<div class="card">
				<Card>
					<template #title>
						<h5>Total de Factura: 
                            <strong v-if="periodo.moneda==1">Ref.</strong>
                            <strong>
                                <img :src="'images/moneda'+periodo.moneda+'.png'" width="15" v-if="periodo.moneda"/>
                                {{factura_monto.toFixed(2)}} (Bs. {{new Intl.NumberFormat("de-DE").format((factura_monto*$store.state.dolar).toFixed(2))}})
                            </strong>
                        </h5>
					</template>
					<template #content>
                        <br><br>
                        <Button label="Agregar Pago" icon="pi pi-money-bill" class="p-button-info p-mr-2 p-mb-2" @click="CargarPago()" style="width: 200px;"/>
                        <small v-show="validationErrors.pagos && submitted" style="color: red;">Debe reportar el pago</small>
                        <DataTable :value="pagos" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
                            <Column header="Fecha">	
                                <template #body="{data}">
                                    {{data.fecha}}
                                </template>
                            </Column>
                            <Column header="Tipo">	
                                <template #body="{data}">
                                    {{data.tipo.nombre}}
                                </template>
                            </Column>
                            <Column header="Banco">	
                                <template #body="{data}">
                                    {{data.banco_orig.nombre}}
                                    <div v-if="data.banco">
                                        <br>{{data.banco.nombre}}
                                    </div>    
                                    <div v-if="data.movil">
                                        <br>{{data.banco.nombre}}
                                    </div> 
                                </template>
                            </Column>
                            <Column header="N° de referencia bancaria">	
                                <template #body="{data}">
                                    {{data.referencia}}
                                    <img style="width: 100px;" :src="url+'pagos/'+data.img+'.jpg'" v-if="data.img"/>
                                </template>
                            </Column>
                            <Column header="Monto Bs">	
                                <template #body="{data}">
                                    Bs. {{new Intl.NumberFormat("de-DE").format((data.montobs).toFixed(2))}}
                                </template>
                            </Column>
                            <Column header="Monto">	
                                <template #body="{data}">
                                    <strong v-if="periodo.moneda==1">Ref.</strong>
                                    <img :src="'images/moneda'+periodo.moneda+'.png'" width="15"/>
                                    {{(data.monto).toFixed(2)}}
                                </template>
                            </Column>
                            <Column header="Eliminar">
                                <template #body="{data}">
                                    <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="EliminarPago(data)"/>
                                </template>
                            </Column>
                        </DataTable>                   
                        <h5>Total de Pagos: 
                            <strong v-if="periodo.moneda==1">Ref.</strong>
                            <strong>
                                <img :src="'images/moneda'+periodo.moneda+'.png'" width="15" v-if="periodo.moneda"/>
                                {{pago_monto.toFixed(2)}}
                            </strong>
                        </h5>
                        <h5>
                            <strong v-if="pago_monto<factura_monto">
                                Diferencia: 
                                <strong v-if="+periodo.moneda==1">Ref.</strong>
                                <img :src="'images/moneda'+periodo.moneda+'.png'" width="15"/>
                                {{(factura_monto-pago_monto).toFixed(2)}}
                                 (Bs. {{new Intl.NumberFormat("de-DE").format(((factura_monto-pago_monto)*$store.state.dolar).toFixed(2))}})
                            </strong>
                            <strong v-if="pago_monto>factura_monto">
                                A favor: 
                                <strong v-if="+periodo.moneda==1">Ref.</strong>
                                <img :src="'images/moneda'+periodo.moneda+'.png'" width="15"/>
                                {{(pago_monto-factura_monto).toFixed(2)}}
                                 (Bs. {{new Intl.NumberFormat("de-DE").format(((pago_monto-factura_monto)*$store.state.dolar).toFixed(2))}})
                            </strong>
                        </h5>
					</template>
					<template #footer>
                        <Button label="Cerrar" style="width: 110px;" icon="pi pi-times" class="p-button-danger p-mr-2" @click="product=null"/>
                        <Button label="Procesar" style="width: 110px;" icon="pi pi-check-circle" class="p-button-secondary p-mr-2" @click="Procesar()"/>
					</template>
				</Card>
			</div>
        </div>

		<Dialog v-model:visible="Dialog" :style="{width: size}" header="Agregar Pago" :modal="true" class="p-fluid" :maximizable="true">
            <br>
            <div class="p-field">
				<div class="p-grid">
					<div class="p-col-8">
                        <label>Tipo</label>
                        <div v-for="(stats, index) in tipos" :key="index">
                            <RadioButton id="tipo" name="tipo" :value="stats.code" v-model="pago.tipo"/>
                            <strong> {{stats.nombre}}<br><br></strong>
                        </div>
					</div>
					<div class="p-col-4" v-if="pago.tipo">
                        <label>Fecha</label>
                        <InputText v-model="pago.fecha" type="date" :max="$store.state.fechaini"/>
					</div>
					<div class="p-col-7" v-if="pago.tipo">
                        <label v-if="pago.tipo==1">Banco de Tarjeta</label>
                        <strong v-if="pago.tipo==2">{{banco_destino.nombre}}</strong>
						<label v-if="pago.tipo==3 || pago.tipo==4">Banco de Origen</label>
						<Dropdown v-model="pago.banco_orig" :options="bancos" optionLabel="nombre" placeholder="Seleccione..." v-if="pago.tipo==1 || pago.tipo==3 || pago.tipo==4"/>
					</div>
                    <div class="p-col-5" v-if="pago.tipo==4">
                        <label>N° de celular de origen</label>
                        <InputText v-model="pago.movil" type="number" placeholder="Número" min="0"/>
					</div>
					<div class="p-col-7" v-if="pago.tipo">
                        <label>N° de referencia bancaria</label>
                        <InputText v-model="pago.referencia" type="number" placeholder="Número" min="0"/>
					</div>
					<div class="p-col-5" v-if="pago.tipo">
                        <label>Monto Bs</label>
                        <InputNumber v-model="pago.montobs" required="true" min="1" :max="$store.state.tope" :minFractionDigits="2"/>
					</div>
                    <div class="p-field p-col-12 p-md-4" v-if="pago.tipo">
                        <FileUpload chooseLabel="Subir Foto JPG" uploadLabel="Cargar" mode="basic" :name="token" :url="url+'pagos.php'"  accept="image/jpeg" :maxFileSize="1000000" @upload="myUploader(token)" :auto="true" v-if="pago.tipo>1"/>
                    </div>
					<div class="p-field p-col-4 p-md-4" v-if="pago.tipo">
						<img style="width: 100px;" :src="url+'pagos/'+pago.img+'.jpg'" v-if="pago.img && pago.tipo>1"/>
					</div>
				</div>
			</div>
			<template #footer>
				<Button label="Agregar" icon="pi pi-check" class="p-button-secondary" @click="Agregar()" />
				<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="Dialog = false"/>
			</template>
		</Dialog>

        <Dialog v-model:visible="DialogSolic" :style="{width: size}" header="Agregar Solicitud" :modal="true" class="p-fluid" :maximizable="true">
            <br>
			<div>
                <DataTable :value="aranceles" :scrollable="true" scrollHeight="200px" class="p-datatable-sm">
                    <Column header="Tipo" headerStyle="width: 100px">	
                        <template #body="{data}">
                            <strong v-if="data.tipo==3">Documento</strong>
                            <strong v-else>Trámite</strong>
                        </template>
                    </Column>
                    <Column header="Nombre">	
                        <template #body="{data}">
                            {{data.nombre}}
                        </template>
                    </Column>
                    <Column header="Monto" headerStyle="width: 100px">	
                        <template #body="{data}">
                            <strong v-if="periodo.moneda==1">Ref.</strong>
                            <img :src="'images/moneda'+periodo.moneda+'.png'" width="15"/>
                            {{data.monto}}
                        </template>
                    </Column>
                    <Column header="Agregar" headerStyle="width: 100px">
                        <template #body="{data}">
                            <Button icon="pi pi pi-check" class="p-button-rounded p-button-success p-mr-2" @click="AgregarSolic(data)" v-if="edit" v-tooltip.bottom="'Agregar'"/>
                        </template>   
                    </Column>
                </DataTable>
			</div>
		</Dialog>

    </div>
</template>

<script>
import API from "@/service/API";
import Token from "uuid-token-generator";
const Consulta = new API('Solicitudes','CrearSolicitud');

export default {
    data () {
        return {
			iniciar: false,
			buscar: null,
            bancos: [],
            banco_destino: {code: 3, nombre: 'Banco Mercantil'},
            tipos: [],
			opcion: true,
			productDialog: false,
            DialogSolic: false,
			product: null,
			filters: {},
            products: [],
            deudas: [],
            aranceles: [],
            submitted: false,
            validationErrors: {},
            pagos: [],
            deuda: [],
            factura: [],
            previo: [],
            matricula: null,
            matriculas: [],
            solicitudes: [],
            periodo: null,
            factura_monto: 0,
            factura_montobs: 0,
            pago_montobs: 0,
            pago_monto: 0,
            Dialog: false,
            pago: {
                fecha: null,
                tipo: null,
                banco_orig: null,
                banco: 3,
                referencia: null,
                tasa: null,
                moneda: null,
                monto: null,
                montobs: null,
                img: null,
                movil: 0,
            },
			size: '50vw',
			token: null,
            ultimafecha: null,
            moneda: null,
            tasa: 1,
            ind: 0,
        }
    },
	created() {
        this.url = process.env.VUE_APP_API;
        if (this.$store.state.mobile){this.size = '90vw';}
		this.Mostrar();
	},
    methods: {
        Mostrar() {
            this.iniciar = false;
            this.bancos = [];
            this.tipos = [];
            this.factura = [];
            this.pagos = [];
			Consulta.Ini().then(result => {
				//this.$store.state.error = result;
				this.insert = result.insert;
				this.edit = result.edit;
				this.del = result.delete;
                this.bancos = result.consult.bancos;
                this.tipos = result.consult.tipos;
				this.$store.commit('Loading');
			}); 
        },
		Buscar() {
			this.products = [];
            this.product = null;
            this.pagos = [];
            this.periodo = null;
            this.factura = [];
            this.factura_monto = 0;
			if (this.buscar){
				Consulta.Procesar('Buscar',{
					id: 	this.buscar,
				}).then(response => {
					//this.$store.state.error =response;
					this.products = response.result;
					this.$store.commit('Loading');
				}); 
			} else {
				this.$toast.add({
					severity:'warn', 
					summary: 'Advertencia', 
					detail:'Debes colocar los datos solicitados', 
					life: 3000
				});  
			}
		},       
        Seleccion(data){
            this.product = data;
            Consulta.Procesar('Seleccion',{
                id: data.id,
            }).then(response => {
                //this.$store.state.error =response;
				this.matriculas = response.result;
                if(response.result.length==1){
                    this.matricula = response.result[0];
                    this.Aranceles();
                }
                this.$store.commit('Loading');
            });
        },
        Aranceles(){
            this.factura_monto = 0;
            this.periodo = null;
            this.aranceles = [];
            this.solicitudes = []
            Consulta.Procesar('Aranceles',{
                matricula: this.matricula,
            }).then(response => {
                //this.$store.state.error =response;
                this.periodo = response.result.periodo;
				this.aranceles = response.result.aranceles;
                this.$store.commit('Loading');
            });
        },
        AgregarSolic(data){
            this.solicitudes.push({
                id: data.id,
                arancel: data.arancel,
                monto: data.monto,
                nombre: data.nombre,
                periodo: data.periodo,
                ind: this.ind,
                tipo: data.tipo,
            });
            this.ind++;
            this.DialogSolic = false;
            this.Calcular();
        },
        EliminarSolic(selec){
			this.solicitudes = this.solicitudes.filter(val => val !== selec);
            this.Calcular();
        },
		Calcular() {
            if(this.solicitudes.length>0){
                var monto = 0;
                this.solicitudes.forEach(element => {
                    monto = parseFloat(monto) + parseFloat(element.monto);
                }); 
                this.factura_monto = monto;
            } else {
                this.factura_monto = 0;
            }
		},       
        CargarPago() {
            if(this.factura_monto>0){
                const tokgen = new Token();
                this.token = tokgen.generate();
                this.pago = {
                    fecha: this.$store.state.fechaini,
                    tipo: null,
                    banco_orig: null,
                    banco: null,
                    referencia: null,
                    montobs: null,
                    tasa: null,
                    monto: null,
                    moneda: this.moneda,
                    img: null,
                    movil: 0,
                };
                this.Dialog = true;
            }
        },
		myUploader(img) {
			Consulta.Procesar('ImgPagos',{
				img: img,
			}).then(response => {
                if(response.result){
                    this.pago.img = this.token;
                }
                const tokgen = new Token();
                this.token = tokgen.generate();

				this.$store.commit('Loading');
			});
        },
		EliminarPago(selec) {
            this.pago_montobs = 0;
            this.pago_monto = 0;
			this.pagos = this.pagos.filter(val => val !== selec);
            this.UltimaFech();
            this.Calcular();
		},
        Procesar(){
            if (parseFloat(this.pago_monto+0.000001) < parseFloat(this.factura_monto)) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Debe reportar el Pago Completo', life: 5000});
            } else {
                Consulta.Procesar('INS',{
                    product: this.product,
                    matricula: this.matricula,
                    solicitudes: this.solicitudes,
                    pagos: this.pagos,
                    factura_monto: this.factura_monto,
                    pago_montobs: this.pago_montobs,
                    periodo: this.periodo,
                }).then(response => {
                    //this.$store.state.error =response;
                    this.$store.commit('Loading');
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
                });
            }
        },
        Agregar(){
            if (this.pago.tipo == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el tipo de pago', life: 5000});
			} else if (this.pago.fecha == null) {
                this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Indique la fecha del pago', life: 10000});
            } else if (this.pago.banco_orig == null && (this.pago.tipo == 1 || this.pago.tipo == 3 || this.pago.tipo == 4)) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el banco', life: 5000});
			} else if (this.pago.movil == 0 && this.pago.tipo == 4) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduz el número de celular de origen', life: 5000});
			} else if (this.pago.referencia == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el número de referencia bancaria', life: 5000});
			} else if (this.pago.montobs == null) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el monto del pago', life: 5000});
			} else if (this.pago.img == null && this.pago.tipo>1) {
				this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Debe cargar la imagen de la transferencia', life: 5000});
            } else {
                this.$store.commit('Loading');
                this.pago.banco = this.banco_destino;
                if (this.pago.tipo==2){
                    this.pago.banco_orig = this.banco_destino;
                }
				this.tipos.forEach(element => {
					if(element.code == this.pago.tipo){
						this.pago.tipo = element;
					}
				}); 
                Consulta.Procesar('VerifPago',{
                    id: this.matricula.id,
                    pago: this.pago,
                    moneda: this.periodo.moneda,
                }).then(response => {
                    //this.$store.state.error =response;
                    if (response.result.verificacion && response.result.tasa>0) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
                        this.pago.tasa = response.result.tasa;
                        this.pago.monto = Math.round((parseFloat(this.pago.montobs) / parseFloat(this.pago.tasa)) * 100) / 100;
                        this.pagos.push(this.pago);
                        this.pago_montobs = parseFloat(this.pago_montobs) + parseFloat(this.pago.montobs);
                        this.pago_monto = parseFloat(this.pago_monto) + parseFloat(this.pago.monto);
                        this.Dialog = false;
                    } else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: response.result.mens, 
							life: 3000
						});
                        this.Dialog = false;
                    }
                    this.$store.commit('Loading');
                });
            }
        },
        validateForm() {
            if (!this.formapago)
                this.validationErrors['formapago'] = true;
            else
                delete this.validationErrors['formapago'];
            if (this.pagos.length==0)
                this.validationErrors['pagos'] = true;
            else
                delete this.validationErrors['pagos'];

            return !Object.keys(this.validationErrors).length;
        },
    }
}
</script>