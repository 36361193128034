<template>
	<div class="p-grid">
		<div class="p-col-10">
			<Card style="margin-bottom: 2em">
				<template #title>
					Codificación
				</template>
				<template #content>
                    <div class="p-fluid p-grid formgrid" style="font-size: 12px;">
                        <div class="p-col-3"><strong>&CED</strong> Cédula de Identidad</div>
                        <div class="p-col-3"><strong>&NAC</strong> Nacionalidad</div>
                        <div class="p-col-3"><strong>&APE</strong> Apellidos</div>
                        <div class="p-col-3"><strong>&NOM</strong> Nombres</div>
                        <div class="p-col-3"><strong>&TIT</strong> Título</div>
                        <div class="p-col-3"><strong>&FEC</strong> Fecha de Acta DD/MM/AAAA</div>
						<div class="p-col-3"><strong>&FEL</strong> Fecha de Acta en letras</div>
						<div class="p-col-3"><strong>&FEN</strong> Fecha de Acta en num. letras</div>
						<div class="p-col-3"><strong>&LUG</strong> Lugar de Nacimiento</div>
						<div class="p-col-3"><strong>&FAC</strong> Fecha Actual DD/MM/AAAA</div>
						<div class="p-col-3"><strong>&FAL</strong> Fecha Actual en letras</div>
						<div class="p-col-3"><strong>&EXT</strong> Nombre de Extensión</div>
						<div class="p-col-3"><strong>&LEX</strong> Lugar de la Extensión</div>
						<div class="p-col-3"><strong>&JEX</strong> Jefe de Extensión</div>
						<div class="p-col-3"><strong>&SEM</strong> Último semestre letras</div>
						<div class="p-col-3"><strong>&PER</strong> Último período inscrito</div>
						<div class="p-col-3"><strong>&CAR</strong> Carrera</div>
                        <div class="p-col-3"><strong>&ACT</strong> N° Acta de Grado</div>
                        <div class="p-col-3"><strong>&LIB</strong> N° Libro de Grado</div>
                        <div class="p-col-3"><strong>&TOM</strong> N° Tomo de Grado</div>
                        <div class="p-col-3"><strong>&FOL</strong> N° Folio de Grado</div>
                        <div class="p-col-3"><strong>&ASG</strong> N° Asignado de Grado</div>
                        <div class="p-col-3"><strong>&RES</strong> N° Resuelto de Grado</div>
                        <div class="p-col-3"><strong>&RFE</strong> Fecha Resuelto de Grado</div>
                        <div class="p-col-3"><strong>&DIR</strong> Directo</div>
                        <div class="p-col-3"><strong>&AUT</strong> Autenticación</div>
                        <div class="p-col-3"><strong>&DNO</strong> Dirgida a: Nombre</div>
                        <div class="p-col-3"><strong>&DIN</strong> Dirgida a: Institución</div>
                        <div class="p-col-3"><strong>&DCA</strong> Dirgida a: Cargo</div>
						<div class="p-col-3"><strong>&DCF</strong> Dirgida a: Fecha</div>
						<div class="p-col-3"><strong>&TSU</strong> TSU Graduado</div>
						<div class="p-col-3"><strong>&CAH</strong> Horas Carga Académica</div>
						<div class="p-col-3"><strong>&RGE</strong> Régimen de Estudio</div>
						<div class="p-col-3"><strong>&MOD</strong> Modalidad de Estudio</div>
						<div class="p-col-3"><strong>&PEN</strong> Pensum</div>

					</div>
				</template>
			</Card>
		</div>
		<div class="p-col-2">
			<Card style="margin-bottom: 2em">
				<template #title>
					Formatos
				</template>
				<template #content>
					<div class="p-fluid grid formgrid">
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary p-mb-2" @click="Guardar()" />
                        <Button label="Cancelar" icon="pi pi-times" class="p-button-danger p-mb-2" @click="Mostrar()" />
					</div>
				</template>
			</Card>
		</div>
		<div class="p-col-12">
            <Accordion >
                <AccordionTab :header="formato.nombre" v-for="(formato, code) in documentos" :key="code">
                    <Card style="margin-bottom: 2em">
						<template #content>
							<div v-for="(stats, index) in formato.cont" :key="index">
								<div class="p-fluid p-grid formgrid" v-if="stats.activo">
									<div class="p-col-2"><h5><strong>Párrafo {{index+1}}</strong></h5></div>
									<div class="p-col-1">
										<label>Orden</label> 
										<InputNumber v-model="stats.orden" required="true"/>					
									</div>
									<div class="p-col-1">
										<label>Altura</label> 
										<InputNumber v-model="stats.alt" required="true"/>					
									</div>
									<div class="p-col-1">
										<label>Margen</label> 
										<InputNumber v-model="stats.izq" required="true"/>					
									</div>
									<div class="p-col-1">
										<label>Tamaño</label> 
										<InputNumber v-model="stats.tam" required="true"/>					
									</div>
									<div class="p-col-1">
										<label v-if="stats.normal">Negrita</label> 
										<label v-else>Normal</label> 
										<InputSwitch :id="index" v-model="stats.normal" />
									</div>
									<div class="p-col-2">
										<label>Interlineado</label> 
										<InputNumber v-model="stats.interlineado" inputId="stacked-buttons" showButtons :min="1" :minFractionDigits="2"/>				
									</div>
									<div class="p-col-2">
										<label>Alineación</label> 
										<Dropdown v-model="stats.alin" :options="alineacion"/>
									</div>
									<div class="p-col-1" v-if="formato.opcs.length>0">
										<label v-if="stats.internacional">Internacional</label> 
										<label v-else>Nacional</label> 
										<InputSwitch :id="index" v-model="stats.internacional" />
									</div>
									<div class="p-col-1">
										<Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-mr-2" v-tooltip.bottom="'Eliminar'"  @click="stats.activo=false"/>
									</div>
									<div class="p-col-12">
										<Textarea v-model="stats.contenido" autoResize rows="2" cols="30"/>						
									</div>
								</div>
							</div>
							<Button icon="pi pi-plus" class="p-button-rounded p-button-success p-mr-2" v-tooltip.bottom="'Agregar'" @click="Agregar(code)"/>
						</template>
					</Card>
                </AccordionTab>
            </Accordion>
		</div>
	</div>
</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Administracion','Formatos');

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				product: {},
				submitted: false,
				size: '60vw',
				periodos: [],
				correo: null,
                aceptar: false,
				acta: [],
				certificacion: [],
				opcion: null,
				alineacion: ['justify','center','right','left'],
                documentos: [
                    {id: 1,  ventana: 'CAR', nombre: '01. Carnet', opc: false, opcs: [], cont: []},
                    {id: 2,  ventana: 'POT', nombre: '02. Carta de Postulación', opc: false, opcs: [], cont: []},
                    {id: 3,  ventana: 'PRE', nombre: '03. Carta de Presentación', opc: false, opcs: [], cont: []},
                    {id: 4,  ventana: 'ACT', nombre: '04. Certificación de Acta de Grado (Papel Sellado)', opc: false, opcs: [], cont: []},
                    {id: 5,  ventana: 'CAL', nombre: '05. Certificación de Calificaciones', opc: false, opcs: [], cont: []},
                    {id: 6,  ventana: 'NFN', nombre: '06. Certificación de Notas Fondo Negro', opc: true, opcs: ['Nacional','Internacional'], cont: []},
                    {id: 21, ventana: 'PAN', nombre: '07. Certificación de Programa', opc: true, opcs: ['Carrera','Semestre'], cont: []},
                    {id: 7,  ventana: 'RPA', nombre: '08. Certificación de Recibo de Pago', opc: false, opcs: [], cont: []},
                    {id: 8,  ventana: 'TIT', nombre: '09. Certificación de Título', opc: true, opcs: ['Nacional','Internacional'], cont: []},
                    {id: 9,  ventana: 'BCO', nombre: '10. Constancia de Buena Conducta', opc: false, opcs: [], cont: []},
                    {id: 10, ventana: 'CAC', nombre: '11. Constancia de Carga Académica', opc: false, opcs: [], cont: []},
                    {id: 11, ventana: 'CDE', nombre: '12. Constancia de Culminación de Estudios', opc: false, opcs: [], cont: []},
                    {id: 12, ventana: 'EST', nombre: '13. Constancia de Estudios', opc: false, opcs: [], cont: []},
                    {id: 18, ventana: 'TTE', nombre: '14. Constancia de Egreso', opc: false, opcs: [], cont: []},
                    {id: 13, ventana: 'CHE', nombre: '15. Constancia de Haber Estudiado', opc: false, opcs: [], cont: []},
                    {id: 14, ventana: 'INS', nombre: '16. Constancia de Inscripción', opc: false, opcs: [], cont: []},
                    {id: 15, ventana: 'MES', nombre: '17. Constancia de Modalidad de Estudio', opc: false, opcs: [], cont: []},
                    {id: 16, ventana: 'NOT', nombre: '18. Constancia de Notas', opc: false, opcs: [], cont: []},
                    {id: 17, ventana: 'ESP', nombre: '19. Constancia Especial', opc: false, opcs: [], cont: []},
                    {id: 19, ventana: 'PEN', nombre: '20. Pensum', opc: false, opcs: [], cont: []},
                    {id: 20, ventana: 'PGR', nombre: '21. Posición de Grado',   opc: false, opcs: [], cont: []},
                ],

			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}

			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.opcion = null;
				this.product = {};
				this.periodos = [];
				this.acta = [];
				this.certificacion = [];
				Consulta.Ini().then(result => {
                    //this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
                    var contenido = result.consult.parrafos;
                    for(var k = 0; k < this.documentos.length; k++) {
                        this.documentos[k].cont = contenido.filter(val => val.ventana ==  this.documentos[k].ventana);
                    }
					this.$store.commit('Loading');
				}); 
			},
			Agregar(index){
					this.documentos[index].cont.push({
						id: 0,
						ventana: this.documentos[index].ventana,
						alin: 'justify',
						orden: 0,
						tam: 12,
						normal: false,
						alt: 0,
						izq: 0,
						contenido: '',
						internacional: false,
						interlineado: 1,
						activo: true
					});
			},
			Guardar() {
				Consulta.Procesar('UPD',{
					produc: this.documentos,
				}).then(response => {
					//this.$store.state.error =response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.$store.commit('Loading');
						this.Mostrar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
						this.$store.commit('Loading')
					}
				});
			},

		}
	}
</script>

<style scoped lang="scss">

.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
