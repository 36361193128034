<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<InputText type="number" min="0" v-model="buscar" required="true" placeholder="Cédula" @keyup.enter="Buscar" v-if="opcion"/>
						<InputText type="text" v-model="buscar" style="width:250px;" required="true" placeholder="Apellido o Nombre" @keyup.enter="Buscar" v-if="!opcion"/>
						<InputSwitch v-model="opcion" v-tooltip.bottom="'Cédula <-> Nombre'"/>				
					</template>
					<template v-slot:right>
						<Button label="Buscar" icon="pi pi-search   " class="p-button-success p-mr-2" @click="Buscar"/>
					</template>			
				</Toolbar>
				<DataTable :value="matriculas" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters" v-if="matriculas.length>0">
					<template #header>
						<h4>
							{{estudiante.nac}}{{estudiante.id}} {{estudiante.apellido}}, {{estudiante.nombre}} ({{estudiante.nucleo}})		
						</h4>
					</template>
					<Column field="plan" header="Plan de Estudio">
						<template #body="slotProps">
							{{slotProps.data.plan}}
						</template>
					</Column>
					<Column field="periodo" header="Período" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.periodo}}
						</template>
					</Column>
					<Column field="turno" header="Turno"  headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.turno}}
						</template>
					</Column>
					<Column field="seccion" header="Sección"  headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.sec}}
						</template>
					</Column>
					<Column field="activo" header="Estatus"  headerStyle="width: 100px">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
						</template>
					</Column>
					<Column field="activo" header="TUC e Índices" headerStyle="width: 150px">
						<template #body="slotProps">
							{{slotProps.data.tuc}}uc
							({{parseFloat(slotProps.data.tuc*100/slotProps.data.uc).toFixed(1)}}%)<br>
							<strong>ÍRA: </strong>{{CalcularIA(slotProps.data.calif_finales)}}<br>
							<strong>ÍG: </strong>{{CalcularIAG(slotProps.data.calif_finales)}}
						</template>
					</Column>
					<Column field="planes" header="Consultar" headerStyle="width: 220px">
						<template #body="slotProps">
							<span class="p-overlay-badge p-mr-5" @click="openCalificaciones(slotProps.data)">
								<i class="bi bi-journal-bookmark" style="font-size: 2em"></i>
								<span class="p-badge">{{slotProps.data.calificaciones}}</span>
							</span>
							<span class="p-overlay-badge p-mr-5" @click="openInscripcion(slotProps.data)">
								<i class="bi bi-layers-half" style="font-size: 2em"></i>
								<span class="p-badge">{{slotProps.data.inscrip}}</span>
							</span>
							<Button icon="pi pi-file-pdf" class="p-button-rounded p-button-warning" v-tooltip.bottom="'Record'" @click="VerRecord(slotProps.data)"/>
							{{slotProps.data.tasig+'/'+slotProps.data.materias}}
						</template>
					</Column>
				</DataTable>
				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm" v-else>
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Estudiantes</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="nucleo" header="Extensión" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nucleo}}
						</template>
					</Column>
					<Column field="id" header="N° Cédula" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nac}}{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="nombre" header="Nombre" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="apellido" header="Apellido" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.apellido}}
						</template>
					</Column>
					<Column field="activo" header="Estatus">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
						</template>
					</Column>
					<Column header="Opciones">
						<template #body="slotProps">
							<Button icon="pi pi-arrow-right" class="p-button-rounded p-button-success p-mr-2" @click="VerMatricula(slotProps.data)" v-tooltip.bottom="'Seleccionar'"/>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
	</div>
	<Calificaciones :calificaciones="calificaciones" :matricula="matricula" :display="displayCalificaciones" @aprob="onMenuToggle" @close-calificaciones="close"/>
	<Inscripcion :inscripcion="inscripcion" :matricula="matricula" :horario="horario" :convenio="convenio" :cruce="cruce" :display="displayInscricion" @aprob="onMenuToggle" @close-inscripcion="close"/>
</template>

<script>
	import API from "../service/API";
	import Calificaciones from '../components/VerCalificaciones.vue';
	import Inscripcion from '../components/VerInscripcion.vue';
	import PDF from "../service/PDF";
	const Consulta = new API('Consulta','Estudiante');

	export default {
		components: {
			'Calificaciones': Calificaciones,
			'Inscripcion': Inscripcion
		},
		data() {
			return {
				nuevo: false,
				buscar: null,
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				matriculas: [],
				product: {},
				estudiante: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				matriculaDialog: false,
				matricula: {},
				displayCalificaciones: false,
				calificaciones: [],
				displayInscricion: false,
				inscripcion: [],
				displayHorario: false,
				horario: [],
				cruce: [],
				convenio: []
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
		},
		mounted() {
			
		},
		methods: {
			onMenuToggle(event) {
				this.$emit('menu-toggle', event);
			},
			openCalificaciones(matricula) {
				this.$store.commit('Mobile');
				this.matricula = {...matricula};					
				Consulta.Procesar('VerCalificaciones',{
					id: 	this.matricula.id,
				}).then(response => {
					//this.$store.state.error =response.result;
					this.calificaciones = response.result;
					this.displayCalificaciones = true;
					this.$store.commit('Loading');
				}); 
			},
			VerRecord(matricula){
				this.$store.commit('Mobile');
				this.matricula = {...matricula};					
				Consulta.Procesar('VerCalificaciones',{
					id: 	this.matricula.id,
				}).then(response => {
					//this.$store.state.error =response.result;
					this.$store.commit('Loading');
					const doc = new PDF();
					doc.Record(this.matricula,response.result);
				}); 
			},
			openInscripcion(matricula) {
				this.$store.commit('Mobile');
				this.matricula = {...matricula};					
				Consulta.Procesar('VerInscripcion',{
					id: 	this.matricula.id,
				}).then(response => {
					//this.$store.state.error =response.result;
					this.inscripcion = response.result.inscripcion;
					this.horario = response.result.horario;
					this.cruce = response.result.cruce;
					this.convenio = response.result.convenio;
					this.displayInscricion = true;
					this.$store.commit('Loading');
				}); 
			},
			close() {
				this.matricula = {};
				this.displayCalificaciones = false;
				this.displayInscricion = false;
				this.displayHorario = false;
			},
			Buscar() {
				this.matriculas = [];
				if (this.buscar){
					Consulta.Procesar('Buscar',{
						id: 	this.buscar,
					}).then(response => {
						//this.$store.state.error =response;
						this.products = response.result;
						this.$store.commit('Loading');
					}); 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail:'Debes colocar los datos solicitados3s', 
						life: 3000
					});    
				}
			},
			VerMatricula(estudiante) {
				this.estudiante = {};
				this.estudiante = {...estudiante};
				if (estudiante.planes>0){
					Consulta.Procesar('Matricula',{
						caso: 	'Matricula',
						id: 	this.estudiante.id,
						nucleo: 	this.estudiante.nucleo_id,
					}).then(response => {
						//this.$store.state.error = response;
						this.matriculas = response.result;
						this.$store.commit('Loading');
					}); 
				} else {
					this.newMat(estudiante);
				}
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}
				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
			CalcularIAG(data){
				var asigporuc = 0
				var cursadasuc = 0
				for (var i = 0; i < data.length; i++) {
					if(data[i].aprobada && data[i].tipo_id<5){
						cursadasuc = parseFloat(cursadasuc) + parseFloat(data[i].uc);						
						asigporuc = parseFloat(asigporuc) + parseFloat(data[i].uc*data[i].definitiva);
					} 
				}
				return parseFloat(asigporuc/cursadasuc).toFixed(2);
			},
			CalcularIA(data){
				var asigporuc = 0
				var cursadasuc = 0
				for (var i = 0; i < data.length; i++) {
					if(data[i].tipo_id<5){
						cursadasuc = parseFloat(cursadasuc) + parseFloat(data[i].uc);
						asigporuc = parseFloat(asigporuc) + parseFloat(data[i].uc*data[i].definitiva);
					}
				}
				return parseFloat(asigporuc/cursadasuc).toFixed(2);
			}


		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
