<template>
	<div class="p-grid">
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<Dropdown v-model="opcion" :options="opciones" optionLabel="nombre" placeholder="Seleccione..."/>
						<div v-if="opcion">
                            <InputText type="number" min="0" v-model="buscar" required="true" placeholder="Cédula" @keyup.enter="Buscar" v-if="opcion.code==1"/>
                            <InputText type="number" min="0" v-model="buscar" required="true" placeholder="ID" @keyup.enter="Buscar" v-if="opcion.code==3"/>
                            <InputText type="text" v-model="buscar" style="width:250px;" required="true" placeholder="Apellido o Nombre" v-if="opcion.code==2"/>
                            <InputText type="text" v-model="buscar" style="width:250px;" required="true" placeholder="Tabla" v-if="opcion.code==4"/>
                        </div>
					</template>
					
					<template v-slot:right>
                        <Button label="Buscar" icon="pi pi-search" class="p-button-success p-mr-2 p-mb-2" @click="Buscar" v-if="opcion"/>
					</template>
				
				</Toolbar>
				<DataTable :value="products" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-1">Usuarios: {{products.length}}</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search"/>
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column header="Extensión" :sortable="true">
						<template #body="slotProps" >
							{{slotProps.data.nucleo}}
						</template>
					</Column>
					<Column field="id" header="N° Cédula" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="nombre" header="Nombre" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="apellido" header="Apellido" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.apellido}}
						</template>
					</Column>
					<Column field="ingreso" header="Último Ingreso" :sortable="true"  headerStyle="width: 250px">
						<template #body="slotProps">
							{{slotProps.data.ingreso}}
						</template>
					</Column>
					<Column header="Ver" headerStyle="width: 100px">
						<template #body="slotProps">
							<Button icon="pi pi-eye" class="p-button-rounded p-button-success p-mr-2" @click="Ver(slotProps.data)" v-if="edit" v-tooltip.bottom="'Ver'"/>
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: size}" :header="'Registros: '+detalles.length" :modal="true" class="p-fluid" :maximizable="true">
					<br>
                    <div class="p-field">
						<DataTable :value="detalles" scrollable class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
							scrollHeight="300px" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters2">
							<template #header>
								<div class="table-header">
									<span class="p-input-icon-left">
										<i class="pi pi-search"/>
										<InputText v-model="filters2['global']" placeholder="Buscar..." />
									</span>
								</div>
							</template>
							<Column field="id" header="ID" :sortable="true" headerStyle="width: 70px">
								<template #body="slotProps">
									{{slotProps.data.id}}
								</template>
							</Column>
							<Column field="fecha" header="Fecha" :sortable="true" headerStyle="width: 150px">
								<template #body="slotProps">
									{{slotProps.data.fecha}}
								</template>
							</Column>
							<Column field="usuario" header="Usuario" :sortable="true" headerStyle="width: 100px">
								<template #body="slotProps">
									{{slotProps.data.usuario}}
								</template>
							</Column>
							<Column field="tabla" header="Tabla" :sortable="true" headerStyle="width: 150px">
								<template #body="slotProps">
									{{slotProps.data.tabla}}
								</template>
							</Column>
							<Column field="code" header="ID de Registro" :sortable="true" headerStyle="width: 150px">
								<template #body="slotProps">
									{{slotProps.data.code}}
								</template>
							</Column>
							<Column field="tipo" header="Descripción" :sortable="true">
								<template #body="slotProps">
									{{slotProps.data.tipo}}
								</template>
							</Column>
						</DataTable>			
					</div>
				</Dialog>

			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Configuracion','Auditar');

	export default {
		data() {
			return {
				nuevo: false,
				buscar: null,
				insert: false,
				edit: false,
				del: false,
				products: [],
				productDialog: false,
				product: {},
				selectedProducts: null,
				filters: {},
				filters2: {},
				submitted: false,
				size: '60vw',
				opcion: {nombre: 'Usuario en Línea', code: 0},
				opciones: [
					{nombre: 'Usuario en Línea', code: 0},
					{nombre: 'Cédula de Usuario', code: 1},
					{nombre: 'Nombre o Apellido de Usiario', code: 2},
					{nombre: 'ID de Registro', code: 3},
					{nombre: 'Nombre de Tabla', code: 4},
				],
				detalles: [],

			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}

			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				Consulta.Ini().then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.products = result.consult;
					this.$store.commit('Loading');
				}); 
			},
			Buscar() {
				if (this.buscar || this.opcion.code==0){
					this.products = [];
					this.detalles = [];
					Consulta.Procesar('Buscar',{
						id: 	this.buscar,
                        opcion: 	this.opcion.code,
					}).then(response => {
						//this.$store.state.error =response;
						this.$store.commit('Loading');
						if(this.opcion.code<3){
							this.products = response.result;
						} else {
							this.detalles = response.result;
							this.productDialog = true;
						}
						if(this.opcion.code==0){this.$store.state.conectados = response.result.length;}
					}); 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail:'Debes colocar los datos solicitados', 
						life: 3000
					});    
				}
			},
			Ver(data) {
				if (this.opcion){
					this.detalles = [];
					Consulta.Procesar('Ver',{
						data: 	data,
					}).then(response => {
						//this.$store.state.error =response;
						this.detalles = response.result;
						this.$store.commit('Loading');
						this.productDialog = true;
					}); 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail:'Debes colocar los datos solicitados', 
						life: 3000
					});    
				}
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
