import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Rom from "../service/Num_Romano";
import Record_Academico from '../imprimir/document/Record_Academico.js';
import store from "../store";
import Comic from "./Comic-Book-Normal.js";
import Draft from "./Draft.js";

let imp = store.state.impresora; 

var pdf = new jsPDF('p', 'mm', [297, 210]);

class PDFs {

    Plantilla(EXTENSION){
        pdf.addImage("logo.jpg", 'JPEG', 5, 5, 15, 15);
        pdf.setFontSize(12);
        pdf.setFont("italic", "bold");
        pdf.text(100, 10, "INSTITUTO UNIVERSITARIO DE TECNOLOGÍA INDUSTRIAL" , 'center');
        pdf.text(100, 15, "RODOLFO LOERO ARISMENDI", 'center');
        pdf.text(100, 20,'EXTENSIÓN: '+EXTENSION, 'center');
        /*
        pdf.setFont("italic","normal");
        pdf.text(100, 284, 'Las Acacias - Caracas', 'center');
        pdf.text(100, 287, 'Tlf '+empresa.tlflocal, 'center');
        pdf.text(100, 290, 'Correo: '+empresa.correo, 'center');
        */
    }
    

    Tabla(margen,altura,titulos,filas){
        pdf.autoTable({
            head: titulos,
            body: filas,
            startY: altura,
            margin: margen,
            headerStyles: {fillColor: [0, 0, 0]},                     
            styles: {
                //font: 'courier',
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                columnWidth: 'wrap',
                halign: 'center', // left, center, right
            },
        });
    }

    Out(){
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(pdf.output("blob"),"consulta.pdf");
        } else {
            //pdf.autoPrint();
            window.open(
                URL.createObjectURL(pdf.output("blob")),
                "_blank",
                "height=650,width=500,scrollbars=yes,location=yes"
            );
            setTimeout(() => {    
                window.URL.revokeObjectURL(pdf.output("bloburl"));
            }, 100);
        }
        pdf = new jsPDF();      
    }

    moneda(number){
        return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(number);

    }

    convfech(fecha){
        return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
    }
   
    Horario(matricula,inscripcion,horario,convenio) {
        pdf = new jsPDF('p', 'mm', 'letter');
        this.Plantilla(matricula.extension);     
        pdf.setFontSize(14);
        pdf.setFont("italic", "bold");
        pdf.text(100, 30, "CONSTANCIA DE INSCRIPCIÓN "+inscripcion[0].periodo, 'center');

        pdf.setFontSize(10);

        pdf.setFont("italic", "normal");
        pdf.text('Cédula de Identidad:', 10, 40);
        pdf.text('Nombre y Apellido:', 10, 45);
        pdf.text('Plan de Estudio:', 10, 50);


        pdf.setFont("italic", "bold");
        pdf.text(matricula.nac+matricula.estudiante, 50, 40);
        pdf.text(matricula.nombre+' '+matricula.apellido, 50, 45);
        pdf.text(matricula.plan, 50, 50);

        const head1 = [['Nº','Sección', 'Código', 'Asignatura','UC','Docente']];
        var body1 = [];
  
        for (var i = 0; i < inscripcion.length; i++) {
			body1.push([
                i+1,
                'S'+inscripcion[i].sem+inscripcion[i].secc,
                inscripcion[i].cod,
                inscripcion[i].asignatura,
                inscripcion[i].uc,
                inscripcion[i].doc + ' (' + inscripcion[i].doc_correo+')',
            ]);
        }
        pdf.autoTable({
            head: head1,
            body: body1,
            startY: 55,
            margin: 10,
            headerStyles: {fillColor: [0, 0, 0]},                     
            styles: {
                fontSize: 7,
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                columnWidth: [5,10,15,70,5,80],
                halign: 'center', // left, center, right
            },
        });

        const head2 = [['Hora','Lunes', 'Martes', 'Miércoles','Jueves','Viernes','Sábado']];
        var body2 = [];
  
        for (var j = 0; j < horario.length; j++) {
			body2.push([
                horario[j].bloque,
                horario[j].lun['cod'],
                horario[j].mar['cod'],
                horario[j].mie['cod'],
                horario[j].jue['cod'],
                horario[j].vie['cod'],
                horario[j].sab['cod'],
            ]);
        }
        pdf.autoTable({
            head: head2,
            body: body2,
            startY: 140,
            margin: 10,
            headerStyles: {fillColor: [0, 0, 0]},                     
            styles: {
                fontSize: 7,
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                columnWidth: 28,
                halign: 'center', // left, center, right
            },
        });
        pdf.addPage();

        this.Plantilla(matricula.extension);     
        pdf.setFontSize(14);
        pdf.setFont("italic", "bold");
        pdf.text(100, 30, "CARTA CONVENIO", 'center');

        
        pdf.setFont("italic", "");
        pdf.setFontSize(10);
        pdf.text(convenio.ini, 15, 50, {maxWidth: 180, align: "justify"});

        const head3 = [['Hora']];
        var body3 = [];
  
        for (var k = 0; k < convenio.cont.length; k++) {
			body3.push([
                (k+1)+') '+convenio.cont[k].text,              
            ]);
        }
        pdf.autoTable({
            head: head3,
            body: body3,
            startY: 55,
            margin: 20,
            headerStyles: {fillColor: [255, 255, 255]},                     
            styles: {
                fontSize: 7,
                lineColor: [0, 0, 0],
                lineWidth: 0,
                columnWidth: 'wrap',
                halign: 'justify', // left, center, right
            },
        });

        pdf.text(convenio.fin, 15, 240, {maxWidth: 180, align: "justify"});
        pdf.setFont("italic", "bold");
        pdf.text(100, 250, matricula.nombre+' '+matricula.apellido, 'center');
        pdf.text(100, 255, matricula.nac+matricula.estudiante, 'center');
        pdf.text(100, 260, "Período "+inscripcion[0].periodo, 'center');

        this.Out();
    }

    HorarioSec(nucleo,plan,periodo,seccion,oferta,horario) {
        this.Plantilla(nucleo.nombre);     
        pdf.setFontSize(12);
        pdf.setFont("italic", "bold");

        pdf.setFontSize(14);
        pdf.setFont("italic", "bold");
        pdf.text(100, 30, "Horario de Sección "+seccion.nombre, 'center');
        pdf.text(100, 35, periodo.nombre, 'center');
        pdf.setFontSize(12);

        pdf.setFont("italic", "normal");
        pdf.text('Plan de Estudio:', 10, 50);
        pdf.text('Semestre:', 10, 55);
        pdf.text('Turno:', 10, 60);
        pdf.text('Sección:', 10, 65);

        pdf.setFont("italic", "bold");
        pdf.text(plan.nombre, 50, 50);
        pdf.text(''+seccion.sem, 50, 55);
        pdf.text(seccion.turno, 50, 60);
        pdf.text(seccion.sec, 50, 65);

        const head1 = [['Nº','Código','Asignatura', 'Horas', 'Docente']];
        var body1 = [];

        for (var i = 0; i < oferta.length; i++) {
			body1.push([
                i+1,
                oferta[i].cod,
                oferta[i].asignatura,
                oferta[i].horat,
                oferta[i].doc
            ]);
        }
        this.Tabla(10,70,head1,body1);     

        const head2 = [['Hora','Lunes', 'Martes', 'Miércoles','Jueves','Viernes','Sábado']];
        var body2 = [];
  
        for (var j = 0; j < horario.length; j++) {
			body2.push([
                horario[j].bloque,
                horario[j].lun['cod'],
                horario[j].mar['cod'],
                horario[j].mie['cod'],
                horario[j].jue['cod'],
                horario[j].vie['cod'],
                horario[j].sab['cod'],
            ]);
        }
        pdf.autoTable({
            head: head2,
            body: body2,
            startY: 150,
            margin: 10,
            headerStyles: {fillColor: [0, 0, 0]},                     
            styles: {
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                columnWidth: 27,
                halign: 'center', // left, center, right
            },
        });
            
        this.Out();
    }

    VerHorario(extension,periodo,data,horario,asignaturas,opcion) {
        this.Plantilla(extension.nombre);
        
        pdf.setFontSize(14);
        pdf.setFont("italic", "bold");
        if(opcion==1){
            pdf.text(100, 30, "HORARIO DEL AULA "+data.nombre, 'center');
        }
        if(opcion==2){
            pdf.text(100, 30, "HORARIO DE DOCENTE", 'center');
            pdf.text(100, 35, data.nombre+' '+data.apellido+' - '+data.id, 'center');
        }
        if(opcion==3){
            pdf.text(100, 30, "HORARIO DE LA SECCIÓN "+data.secf, 'center');
            pdf.text(100, 35, data.plan, 'center');
        }
        pdf.text(100, 40, "Período "+periodo.nombre, 'center');

        const head2 = [['Hora','Lunes', 'Martes', 'Miércoles','Jueves','Viernes','Sábado']];
        var body2 = [];

        for (var j = 0; j < horario.length; j++) {
			body2.push([
                horario[j].bloque,
                horario[j].lun['cod'],
                horario[j].mar['cod'],
                horario[j].mie['cod'],
                horario[j].jue['cod'],
                horario[j].vie['cod'],
                horario[j].sab['cod'],
            ]);
        }
        pdf.autoTable({
            head: head2,
            body: body2,
            startY: 50,
            margin: 10,
            headerStyles: {fillColor: [0, 0, 0]},                     
            styles: {
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                columnWidth: 27,
                halign: 'center', // left, center, right
            },
        });
        pdf.addPage();
        const head1 = [['Nº','Código','Asignatura', 'Horas', 'Docente']];
        var body1 = [];
        let thoras = 0;
        for (var i = 0; i < asignaturas.length; i++) {
			body1.push([
                i+1,
                asignaturas[i].cod,
                asignaturas[i].asignatura,
                asignaturas[i].horas,
                asignaturas[i].doc
            ]);
            thoras = parseFloat(thoras)+parseFloat(asignaturas[i].horas);
        }
        body1.push([
            null,
            null,
            'Total Horas Semanales',
            thoras,
            null
        ]);



        this.Tabla(10,20,head1,body1);     


        this.Out();
    }

    VerHorarioD(docente,asignaturas,horario) {
        this.Plantilla(docente.nucleo);
        
        pdf.setFontSize(14);
        pdf.setFont("italic", "bold");
        pdf.text(100, 30, "HORARIO DEL DOCENTE: "+docente.nombre+" "+docente.apellido, 'center');

        pdf.setFontSize(12);

        const head1 = [['Nº','Extensión','Plan de Estudio','Código','Asignatura', 'Horas']];
        var body1 = [];
        for (var i = 0; i < asignaturas.length; i++) {
			body1.push([
                i+1,
                asignaturas[i].nucleo,
                asignaturas[i].plan,
                asignaturas[i].cod,
                asignaturas[i].asignatura,
                asignaturas[i].horat,
            ]);
        }
        this.Tabla(10,35,head1,body1);     

        const head2 = [['Hora','Lunes', 'Martes', 'Miércoles','Jueves','Viernes','Sábado']];
        var body2 = [];
        for (var j = 0; j < horario.length; j++) {
            if(horario[j].lun['asignatura'] || horario[j].mar['asignatura'] || horario[j].mie['asignatura']
             || horario[j].jue['asignatura'] || horario[j].vie['asignatura'] || horario[j].sab['asignatura']){
                body2.push([
                    horario[j].bloque,
                    horario[j].lun['asignatura'],
                    horario[j].mar['asignatura'],
                    horario[j].mie['asignatura'],
                    horario[j].jue['asignatura'],
                    horario[j].vie['asignatura'],
                    horario[j].sab['asignatura'],
                ]);    
            }
        }
        pdf.autoTable({
            head: head2,
            body: body2,
            startY: asignaturas.length*10+35,
            margin: 10,
            headerStyles: {fillColor: [0, 0, 0]},                     
            styles: {
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                columnWidth: 27,
                halign: 'center', // left, center, right
            },
        });

        this.Out();
    }

    Preinscritos(extension,periodo,data) {
        pdf = new jsPDF('l', 'mm', 'a3');
        this.Plantilla(extension.nombre);
           
        pdf.setFontSize(12);
        pdf.setFont("italic", "bold");

        pdf.setFontSize(14);
        pdf.setFont("italic", "bold");
        pdf.text(100, 30, "Lista de Preinscritos", 'center');
        pdf.text(100, 35, periodo.nombre, 'center');

        const head1 = [['Nº','Fecha y Hora','Nº Cédula', 'Apellidos y Nombres', 'Plan de Estudio', 'Turno', 'Correo', 'Celular']];
        var body1 = [];

        for (var i = 0; i < data.length; i++) {
			body1.push([
                i+1,
                data[i].fecha_reg,
                data[i].cedula,
                data[i].apellido+' '+data[i].nombre,
                data[i].plan,
                data[i].turno,
                data[i].correo,
                data[i].tlf_celular
            ]);
        }
        this.Tabla(10,50,head1,body1);     

        this.Out();
    }

    Seccion(seccion,data) {
        pdf = new jsPDF('l', 'mm', 'a3');
        this.Plantilla(seccion.nucleo);
           
        pdf.setFontSize(12);
        pdf.setFont("italic", "bold");

        pdf.setFontSize(14);
        pdf.setFont("italic", "bold");
        pdf.text(100, 30, seccion.codigo+' '+seccion.plan, 'center');
        pdf.text(100, 35, "Lista de Sección "+seccion.abrev+seccion.sec, 'center');
        pdf.text(100, 40, seccion.periodo, 'center');

        const head1 = [['Nº','Nº Cédula', 'Apellidos y Nombres', 'Plan de Estudio', 'Turno', 'Correo', 'Celular']];
        var body1 = [];

        for (var i = 0; i < data.length; i++) {
			body1.push([
                i+1,
                data[i].estudiante,
                data[i].apellido+' '+data[i].nombre,
                data[i].plan,
                data[i].turno,
                data[i].correo,
                data[i].tlf_celular
            ]);
        }
        this.Tabla(10,50,head1,body1);     

        this.Out();
    }

    Inscritos(data) {
        this.Plantilla(data.nucleo);
           
        pdf.setFontSize(12);
        pdf.setFont("italic", "bold");

        pdf.setFontSize(14);
        pdf.setFont("italic", "bold");
        //pdf.text(100, 30, data.codplan+' '+data.plan, 'center');
        pdf.text(100, 30, "Lista de Estudiantes por Asignatura de la Sección "+data.secf, 'center');
        pdf.text(10, 40, "Asignatura: "+data.asignatura+" "+data.cod);
        pdf.text(10, 50, "Docente: "+data.doc);
        pdf.text(150, 50, "Período: "+data.periodo);

        const head1 = [['Nº', 'Plan de Estudio','Nº Cédula', 'Apellidos y Nombres', 'Correo', 'Celular']];
        var body1 = [];
        for (var i = 0; i < data.inscripcion.length; i++) {
			body1.push([
                i+1,
                data.inscripcion[i].plan_estudio+' '+data.inscripcion[i].codplan,
                data.inscripcion[i].estudiante,
                data.inscripcion[i].apellido+' '+data.inscripcion[i].nombre,
                data.inscripcion[i].correo,
                data.inscripcion[i].tlf_celular
            ]);
        }

        pdf.autoTable({
            head: head1,
            body: body1,
            startY: 60,
            margin: 10,
            headerStyles: {fillColor: [0, 0, 0]},                     
            styles: {
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                //columnWidth: 57,
                halign: 'center', // left, center, right
            },
        });


        //this.Tabla(10,70,head1,body1);     
        this.Out();
    }

    Factura(data,fondo) {
        pdf = new jsPDF('l', 'mm', [148.5, 210]);

        pdf.addFileToVFS('Comic Book Normal-normal.ttf', Comic);
        pdf.addFont('Comic Book Normal-normal.ttf', 'comic', 'normal');
        pdf.addFileToVFS('Draft-B-Regular-normal.ttf', Draft);
        pdf.addFont('Draft-B-Regular-normal.ttf', 'draft', 'normal');

        pdf.setFont(imp.font, 'normal');

        if(fondo){
            pdf.addImage("modelo.jpg", 'JPEG', -7, 0 , 206, 140);
        }
        var sup = parseInt(imp.sup);
        var izq = parseInt(imp.izq);
        pdf.setFontSize(imp.size);
        pdf.text(168+izq, 27+sup, this.convfech(data.fecha));
        
        pdf.text(38+izq, 32+sup, data.nombre.toUpperCase()+' '+data.apellido.toUpperCase());
        pdf.text(155+izq, 32+sup, data.nac+data.estudiante);
        
        if(data.ciudad_id){
            pdf.text(18+izq, 37+sup, data.direccion.toUpperCase()+'. '+data.ciudad.toUpperCase());
        } else {pdf.text(18+izq, 37+sup, data.ciudad2.toUpperCase());}

        if(data.tlf_celular){
            pdf.text(18+izq, 40+sup, data.tlf_celular);
        }
        if(data.tlf_local){
            pdf.text(48+izq, 40+sup, data.tlf_local);
        }
        pdf.text(160+izq, 40+sup, ('0000000000'+data.recibo).slice(-10)+' - '+data.fech_recibo);
        
        for (var i = 0; i < data.detalle.length; i++) {
            pdf.text(6+izq, sup+52+5*i, '1');
            if(data.detalle[i].arancel!=null){
                pdf.text(17+izq, sup+52+5*i, ('00000'+data.detalle[i].arancel).slice(-5)+'');
                pdf.text(40+izq, sup+52+5*i, data.detalle[i].descripcion.toUpperCase());
                pdf.text(170+izq, sup+52+5*i, this.moneda(data.detalle[i].monto*data.tasa), {align:'right'});
                pdf.text(197+izq, sup+52+5*i, this.moneda(data.detalle[i].monto*data.tasa), {align:'right'});
            } else {
                pdf.text(20+izq, sup+52+5*i, ' - ');
                if(data.detalle[i].factura!=data.recibo){
                    pdf.text(40+izq, sup+52+5*i, 'Excedente de pago para uso a posterior.'.toUpperCase());
                    pdf.text(170+izq, sup+52+5*i, this.moneda((-1)*data.detalle[i].montobs), {align:'right'});    
                    pdf.text(197+izq, sup+52+5*i, this.moneda((-1)*data.detalle[i].montobs), {align:'right'});    
                } else {
                    pdf.text(40+izq, sup+52+5*i, 'Descuento por excedente de Recibo N° '.toUpperCase()+data.detalle[i].ajuste);
                    pdf.text(170+izq, sup+52+5*i, this.moneda(data.detalle[i].montobs), {align:'right'});    
                    pdf.text(197+izq, sup+52+5*i, this.moneda(data.detalle[i].montobs), {align:'right'});    
                }
            }
        }
        pdf.text(48+izq, sup+115, 'X');
        pdf.text(120+izq, sup+100, 'IGTF');
        pdf.text(197+izq, sup+100, this.moneda(0), {align:'right'});
        pdf.text(197+izq, sup+115, this.moneda(data.montobs), {align:'right'});
        pdf.text(197+izq, sup+122, this.moneda(data.montobs), {align:'right'});
        pdf.text(25+izq, sup+128, data.pagos);
        var validado = data.validado.split(' ');
        if(validado.length>0){
            pdf.text(175+izq, sup+128, validado[0].toUpperCase());
        }
        this.Out();
    }

    Recibo(data) {
        pdf = new jsPDF('l', 'mm', [148.5, 210]);
        pdf.addImage("logo.jpg", 'JPEG', 5, 5, 15, 15);
        pdf.setFontSize(12);
        pdf.setFont("italic", "bold");
        pdf.text(20, 10, "INSTITUTO UNIVERSITARIO DE TECNOLOGÍA INDUSTRIAL");
        pdf.text(20, 15, "RODOLFO LOERO ARISMENDI");
        pdf.text(20, 20,'RIF: J-00130751-6');
                                                                                    
        pdf.setFontSize(10);

        pdf.text(145, 15, 'Nº de Control de Pago: '+data.est.nucleo_id+'-'+('0000000000'+data.id).slice(-10));
        pdf.text(145, 20, 'Fecha: '+this.convfech(data.fecha));

        pdf.text(10, 30, 'Sr (a). '+data.apellido+', '+data.nombre);
        pdf.text(155, 30, 'Cédula: '+data.nac+data.estudiante);
        pdf.text(10, 35,'Extensión: '+data.est.nucleo);
        pdf.text(155, 35, 'Celular: '+data.tlf_celular);

        pdf.setFontSize(8);

        var afavor = 0;
        var pendiente = 0;
        if (data.monto<data.petros){afavor = parseFloat(data.petros-data.monto).toFixed(4);}
        if (data.monto>data.petros){afavor = parseFloat(data.monto-data.petros).toFixed(4);}

        pdf.text(10, 45, 'Conceptos a pagar');
        pdf.rect(10, 46, 74, 5*data.deudas.length+5); 
        pdf.text(12, 50, 'Nº');
        pdf.text(17, 50, 'Descripción');
        pdf.text(75, 50, 'Petros');
        for (var i = 0; i < data.deudas.length; i++) {
            pdf.text(12, 55+5*i, ''+(i+1));
            pdf.text(17, 55+5*i, data.deudas[i].descripcion);
            pdf.text(75, 55+5*i, data.deudas[i].monto);
        }
        pdf.text(45, 55+5*data.deudas.length, 'Total a cancelar:');
        pdf.text(75, 55+5*data.deudas.length, data.monto);


        pdf.text(85, 45, 'Descripción de pagos');
        pdf.setFontSize(7);
        pdf.rect(85, 46, 115, 30); 
        pdf.text(87, 50, 'Nº');
        pdf.text(92, 50, 'Fecha');
        pdf.text(105, 50, 'Banco');
        pdf.text(140, 50, 'Referencia');
        pdf.text(160, 50, 'Monto Bs');
        pdf.text(175, 50, 'Petro');
        pdf.text(185, 50, 'Tasa');
        for (var j = 0; j < data.pagos.length; j++) {
            pdf.text(87, 55+5*j, ''+(j+1));
            pdf.text(92, 55+5*j, this.convfech(data.pagos[j].fecha));
            pdf.text(105, 55+5*j, data.pagos[j].nbanco);
            pdf.text(140, 55+5*j, data.pagos[j].referencia);
            pdf.text(160, 55+5*j, 'Bs. ' + this.moneda(data.pagos[j].montobs));
            pdf.text(175, 55+5*j, data.pagos[j].monto);
            pdf.text(185, 55+5*j, 'Bs. ' + this.moneda(data.pagos[j].valor_petro));
        }
        pdf.text(175, 55+5*data.pagos.length, 'Total:');
        pdf.text(185, 55+5*data.pagos.length, 'Bs. ' + new Intl.NumberFormat("de-DE").format(data.recibidobs));

        pdf.setFontSize(8);
        pdf.text(12, 75+5*data.deudas.length, 'Petros a Favor:');
        pdf.text(45, 75+5*data.deudas.length, ''+afavor);
        pdf.text(12, 80+5*data.deudas.length, 'Petros Pendientes:');
        pdf.text(45, 80+5*data.deudas.length, ''+pendiente);

        pdf.text(100, 120, "Este documento solo tiene valor informativo", 'center');

        this.Out();
    }

    PlanEvaluacion(data) {
        this.Plantilla(data.nucleo);
           
        pdf.setFontSize(12);
        pdf.setFont("italic", "bold");

        pdf.setFontSize(14);
        pdf.setFont("italic", "bold");
        //pdf.text(100, 30, data.codplan+' '+data.plan, 'center');
        pdf.text(100, 30, "Plan de Evaluación", 'center');
        pdf.text(10, 40, "Asignatura: "+data.asignatura+" "+data.cod);  
        pdf.text(150, 40, "Seccón: "+data.secf);
        pdf.text(10, 50, "Docente: "+data.doc);
        pdf.text(150, 50, "Período: "+data.periodo);

        const head1 = [['Nº', 'Actividad','Corte', '%', 'Fecha']];
        var body1 = [];
        for (var i = 0; i < data.evaluac.length; i++) {
			body1.push([
                i+1,
                data.evaluac[i].actividad,
                data.evaluac[i].corte,
                data.evaluac[i].porcentaje+'%',
                this.convfech(data.evaluac[i].fecha),
            ]);
        }

        pdf.autoTable({
            head: head1,
            body: body1,
            startY: 60,
            margin: 10,
            headerStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
            },                     
            styles: {
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                //columnWidth: 57,
                halign: 'center', // left, center, right
            },
        });

        //this.Tabla(10,70,head1,body1);     
        this.Out();
    }

    ActaCalificaciones(data) {
        pdf = new jsPDF('l', 'mm', 'a3');

        this.Plantilla(data.nucleo);

        pdf.setFontSize(12);
        pdf.setFont("italic", "bold");

        pdf.setFontSize(14);
        pdf.setFont("italic", "bold");

        pdf.text(300, 15, "REGISTRO DE CALIFICACIONES ACUMULATIVAS", 'center');
        pdf.text(10, 30, "Asignatura: "+data.asignatura+" "+data.cod);
        pdf.text(370, 30, "Seccón: "+data.secf);
        pdf.text(10, 40, "Docente: "+data.doc);
        pdf.text(370, 40, "Período: "+data.periodo);

        const head1 = [['Nº', 'Plan de Estudio', 'Apellidos y Nombres','Nº Cédula', 
                        'Corte 01 25%', 'Corte 02 25%','Corte 03 25%','Corte 04 25%',
                        'Acumulativa','Evaluación Extra','Definitiva']];
        var body1 = [];
        var definitva =  null;

        for (var i = 0; i < data.inscripcion.length; i++) {
			if (data.inscripcion[i].escala != 3){
                body1.push([
                    i+1,
                    data.inscripcion[i].plan_estudio+' '+data.inscripcion[i].codplan,
                    data.inscripcion[i].apellido+', '+data.inscripcion[i].nombre,
                    data.inscripcion[i].estudiante,
                    data.inscripcion[i].calif1,
                    data.inscripcion[i].calif2,
                    data.inscripcion[i].calif3,
                    data.inscripcion[i].calif4,
                    (parseFloat(data.inscripcion[i].calif1)+parseFloat(data.inscripcion[i].calif2)+parseFloat(data.inscripcion[i].calif3)+parseFloat(data.inscripcion[i].calif4)).toFixed(0),
                    data.inscripcion[i].extra,
                    this.Final(data.esc,parseFloat(data.inscripcion[i].calif1)+parseFloat(data.inscripcion[i].calif2)+parseFloat(data.inscripcion[i].calif3)+parseFloat(data.inscripcion[i].calif4),data.inscripcion[i].extra),
                ]);    
            } else {
                if(data.inscripcion[i].inas1){
                    definitva = 'Inasistente';  
                } else if (data.inscripcion[i].calif1>0){
                    definitva = 'Aprobado'; 
                } else {
                    definitva = 'Reprobado'; 
                } 
                body1.push([
                    i+1,
                    data.inscripcion[i].plan_estudio+' '+data.inscripcion[i].codplan,
                    data.inscripcion[i].apellido+', '+data.inscripcion[i].nombre,
                    data.inscripcion[i].estudiante,
                    null,
                    null,
                    null,
                    null,
                    null,
                    null,
                    definitva,
                ]);           
            }
        }

        pdf.autoTable({
            head: head1,
            body: body1,
            startY: 50,
            margin: 10,
            headerStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
            },                     
            styles: {
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                textColor: [0, 0, 0],
                //columnWidth: 57,
                halign: 'center', // left, center, right
            },
            columnStyles: {
                0: {cellWidth: 10},
                1: {cellWidth: 100},
                2: {cellWidth: 100},
                3: {cellWidth: 30},
                4: {cellWidth: 20},
                5: {cellWidth: 20},
                6: {cellWidth: 20},
                7: {cellWidth: 20},
                8: {cellWidth: 25},
                9: {cellWidth: 25},
                10: {cellWidth: 25},
              }
        });

        // PAGE NUMBERING
        // Add Page number at bottom-right
        // Get the number of pages
        const pageCount = pdf.internal.getNumberOfPages();

        // For each page, print the page number and the total pages
        for(var j = 1; j <= pageCount; j++) {
            // Go to page i
            pdf.setPage(j);
            //Print Page 1 of 4 for example
            pdf.text('Página ' + String(j) + ' de ' + String(pageCount),210-20,297-10,null,null,"right");

        }
        this.Out();
    }

    ActaCalificacionesFINAL(data) {
        pdf = new jsPDF('l', 'mm', 'a3');

        for(var i = 0; i < data.length; i++) {
            if(i>0) {pdf.addPage();}
            this.Plantilla(data[i].nucleo);
            pdf.setFontSize(12);
            pdf.setFont("italic", "bold");
    
            pdf.setFontSize(14);
            pdf.setFont("italic", "bold");
    
            pdf.text(300, 15, "REGISTRO DE CALIFICACIONES", 'center');
            pdf.text(10, 30, "Plan de Estudio: "+data[i].codplan+" "+data[i].plan);
            pdf.text(200, 30, "Asignatura: "+data[i].asignatura+" "+data[i].cod);
            pdf.text(370, 30, "Seccón: "+data[i].secf);
            pdf.text(10, 40, "Docente: "+data[i].doc);
            pdf.text(370, 40, "Período: "+data[i].periodo);
  
            var notas =  data[i].calific;

            const head1 = [['Nº', 'Apellidos y Nombres','Nº Cédula',
            'Corte 01 25%', 'Corte 02 25%','Corte 03 25%','Corte 04 25%',
            'Acumulativa','Evaluación Extra','Definitiva','Condición']];
            var condicion = false;
            var body1 = [];
            for (var j = 0; j < notas.length; j++) {
                condicion = 'Reprobado';  
                if(notas[j].aprobada)  {condicion = 'Aprobado';}
                if (notas[j].escala != 3){
                    body1.push([
                        j+1,
                        notas[j].apellido+', '+notas[j].nombre,
                        notas[j].estudiante,
                        notas[j].calif1,
                        notas[j].calif2,
                        notas[j].calif3,
                        notas[j].calif4,
                        notas[j].acumulativa,
                        notas[j].extra,
                        notas[j].definitiva,
                        condicion                        
                    ]);    
                } else {
                    body1.push([
                        j+1,
                        notas[j].apellido+', '+notas[j].nombre,
                        notas[j].estudiante,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        null,
                        condicion                        
                    ]);    
                }

            }  
  
            pdf.autoTable({
                head: head1,
                body: body1,
                startY: 50,
                margin: 10,
                headerStyles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                },                     
                styles: {
                    lineColor: [0, 0, 0],
                    lineWidth: 0.1,
                    textColor: [0, 0, 0],
                    //columnWidth: 57,
                    halign: 'center', // left, center, right
                },
                columnStyles: {
                    0: {cellWidth: 10},
                    1: {cellWidth: 100},
                    2: {cellWidth: 30},
                    3: {cellWidth: 30},
                    4: {cellWidth: 30},
                    5: {cellWidth: 30},
                    6: {cellWidth: 30},
                    7: {cellWidth: 30},
                    8: {cellWidth: 30},
                    9: {cellWidth: 30},
                  }
            });
      

    }

    
        // PAGE NUMBERING
        // Add Page number at bottom-right
        // Get the number of pages
        const pageCount = pdf.internal.getNumberOfPages();

        // For each page, print the page number and the total pages
        for(var k = 1; k <= pageCount; k++) {
            // Go to page i
            pdf.setPage(k);
            //Print Page 1 of 4 for example
            pdf.text('Página ' + String(k) + ' de ' + String(pageCount),210-20,297-10,null,null,"right");

        }
        this.Out();
    }

    AsistenciaDOC(nucleo,periodo,turno,dia,data) {
        pdf = new jsPDF('l', 'mm', 'a3');

        this.Plantilla(nucleo.nombre);
        pdf.setFontSize(12);
        pdf.setFont("italic", "bold");
    
        pdf.setFontSize(14);
        pdf.setFont("italic", "bold");

        pdf.text(300, 15, "CONTROL DE ASISTENCIA "+periodo.nombre, 'center');
        pdf.text(300, 20, "TURNO: "+turno.nombre, 'center');
        pdf.text(300, 25, "DIA: "+dia.nombre, 'center');
            
        const head1 = [['Nº', 'Nº Cédula', 'Docente', 'Asignatura', 'Seccion','Aula','Horario', 'Contenido','Firma']];
        var body1 = [];
        var horas = null;

        for (var j = 0; j < data.length; j++) {
            horas = '';
            for (var i = 0; i < data[j].horas.length; i++) {
                if(i>0){horas = horas +', ';}
                horas = horas +' '+ data[j].horas[i].nhora;
            }
            body1.push([
                j+1,
                data[j].docente,
                data[j].apellido+', '+data[j].nombre,
                data[j].asignatura,
                'S'+data[j].sem+data[j].secc,
                data[j].aula,
                horas,
                null,
                null,
            ]);    
                        
        }
            
        pdf.autoTable({
            head: head1,
            body: body1,
            startY: 30,
            margin: 10,
            headerStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
            },                     
            styles: {
                minCellHeight: 20,
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                textColor: [0, 0, 0],
                //columnWidth: 57,
                halign: 'center', // left, center, right
            },
            columnStyles: {
                0: {cellWidth: 10},
                1: {cellWidth: 20},
                2: {cellWidth: 70},
                3: {cellWidth: 70},
                4: {cellWidth: 20},
                5: {cellWidth: 20},
                6: {cellWidth: 30},
                7: {cellWidth: 110},
                8: {cellWidth: 50},
              }
        });
    
        // PAGE NUMBERING
        // Add Page number at bottom-right
        // Get the number of pages
        const pageCount = pdf.internal.getNumberOfPages();

        // For each page, print the page number and the total pages
        for(var k = 1; k <= pageCount; k++) {
            // Go to page i
            pdf.setPage(k);
            //Print Page 1 of 4 for example
            pdf.text('Página ' + String(k) + ' de ' + String(pageCount),210-20,297-10,null,null,"right");

        }
        this.Out();
    }



    Definitiva(escala,acum){
        if(escala.length>0){
            for (let i = 0; i < escala.length; i++) {
                if (escala[i].inferior <= acum && acum <= escala[i].superior) {
                    //return escala[i].calif;
                    return ('00'+escala[i].calif).slice(-2);
                }
            }
        } else {
            return 0;
        }
    }	

    Final(escala,acum,extra){
        if(extra>0){
            if(this.Definitiva(escala,acum)==7 && extra>=13){return 10;}
            else if(this.Definitiva(escala,acum)==8 && extra>=12){return 10;}
            else if(this.Definitiva(escala,acum)==9 && extra>=11){return 10;}
            else {return this.Definitiva(escala,acum);}
        } else {
            return this.Definitiva(escala,acum);
        }
    }

    Record(matricula,data) {
        pdf = new jsPDF('p', 'mm', 'letter');
        var contenido = {};
        contenido.calificaciones = data;
        Record_Academico(pdf,matricula,contenido);
        this.Out();
    }

    ActaExt(acta,data) {
        pdf = new jsPDF('p', 'mm', 'letter');
        this.Plantilla(acta.nucleo);
        
        pdf.setFontSize(14);
        pdf.setFont("italic", "bold");
        pdf.text(100, 30, "LISTA DE GRADUANDO" , 'center');
        pdf.text(100, 35, acta.programa, 'center');
        pdf.text(100, 40, "Fecha: "+acta.fechad, 'center');

        const head1 = [['Nº', 'Libro', 'Acta','Posición','N° Cédula','Apellidos y Nombres','Índice G.','Tomo','Folio','N° Asignado']];
        var body1 = [];
        var romano = null;
        for (var i = 0; i < data.length; i++) {
            if(data[i].detalle.tomo) {
                romano = Rom(data[i].detalle.tomo);
            } else {
                romano = null;
            }

			body1.push([
                i+1,
                Rom(data[i].detalle.libro),
                data[i].detalle.acta,
                data[i].detalle.posicion,
                data[i].estudiante,
                data[i].nombre+', '+data[i].apellido,
                data[i].indiceg,
                romano,
                data[i].detalle.folio,
                data[i].detalle.asignado,
            ]);
        }
        //this.Tabla(10,50,head1,body1);     

        pdf.autoTable({
            head: head1,
            body: body1,
            startY: 50,
            margin: {
                top: 10,
                right: 10,
                left: 10,
                bottom: 20
            },
            headerStyles: {
                fillColor: [255, 255, 255],
                textColor: [0, 0, 0],
                halign: 'center'
            },                     
            styles: {
                fontSize: 7,
                lineColor: [0, 0, 0],
                lineWidth: 0.1,
                halign: 'center', // left, center, right
            },
            columnStyles: {
                0: {cellWidth: 10, halign: 'center'},
                1: {cellWidth: 10, halign: 'center'},
                2: {cellWidth: 10, halign: 'center'},
                3: {cellWidth: 17, halign: 'center'},
                4: {cellWidth: 17, halign: 'center'},
                5: {cellWidth: 70},
                7: {cellWidth: 15, halign: 'center'},
                8: {cellWidth: 15, halign: 'center'},
                9: {cellWidth: 15, halign: 'center'},
                10: {cellWidth: 15, halign: 'center'},
            }
        });
       
        this.Out();
    }

    Certificacion(data) {
        pdf = new jsPDF('p', 'mm', 'letter');
        for (var i = 0; i < data.length; i++) {
            if(i>0){pdf.addPage();}
            pdf.addImage("logo.jpg", 'JPEG', 25, 15, 20, 20);
            pdf.setFont("italic", "bold");
            pdf.setFontSize(12);
            pdf.text(110, 20, "INSTITUTO UNIVERSITARIO DE TECNOLOGÍA INDUSTRIAL" , 'center');
            pdf.text(110, 25, "RODOLFO LOERO ARISMENDI", 'center');
            pdf.text(110, 30, "(IUTIRLA)", 'center');
            pdf.text(110, 35, "RIF: J-00130751-6", 'center');               
            pdf.text(110, 65, "CERTIFICA", 'center'); 
            pdf.setFont("italic", "");
            for (var j = 0; j < data[i].parrafos.length; j++) {
                pdf.setFontSize(data[i].parrafos[j].tam);
                if(data[i].parrafos[j].normal){pdf.setFont("italic", "bold");} else {pdf.setFont("italic", "");}
                pdf.text(data[i].parrafos[j].contenido, 25, data[i].parrafos[j].alt, {maxWidth: 170, align: data[i].parrafos[j].alin});
            }
            pdf.text('Constancia que se expide en '+data[i].ciudad_nucleo+', a los '+data[i].fecha_letras+'.', 25, 185, {maxWidth: 160, align: "justify"});
            pdf.setFontSize(14);
            pdf.setFont("italic", "bold");
            pdf.text(110, 230, "DR. RICHARD TUCKER LOERO", 'center'); 
            pdf.setFont("italic", "");           
            pdf.text(110, 235, "Director ", 'center');            
        }
        this.Out();
    }

    Actas(data) {
        pdf = new jsPDF('p', 'mm', 'letter');
        for (var i = 0; i < data.length; i++) {
            if(i>0){pdf.addPage();}
            pdf.addImage("logo.jpg", 'JPEG', 25, 15, 20, 20);
            pdf.setFont("italic", "bold");
            pdf.setFontSize(12);
            pdf.text(110, 20, "INSTITUTO UNIVERSITARIO DE TECNOLOGÍA INDUSTRIAL" , 'center');
            pdf.text(110, 25, "RODOLFO LOERO ARISMENDI", 'center');
            pdf.text(110, 30, "(IUTIRLA)", 'center');
            pdf.text(110, 35, "RIF: J-00130751-6", 'center');               
            pdf.text(110, 50, "Acta N° "+data[i].detalle.acta.toString().padStart(4,'0'), 'center');            
            pdf.setFont("italic", "");
            for (var j = 0; j < data[i].parrafos.length; j++) {
                pdf.setFontSize(data[i].parrafos[j].tam);
                if(data[i].parrafos[j].normal){pdf.setFont("italic", "bold");} else {pdf.setFont("italic", "");}
                if(data[i].parrafos[j].alin=='center'){
                    pdf.text(110, data[i].parrafos[j].alt, data[i].parrafos[j].contenido, 'center');
                } else {
                    pdf.text(data[i].parrafos[j].contenido, 25, data[i].parrafos[j].alt, {maxWidth: 170, align: data[i].parrafos[j].alin});
                }
            }
            pdf.text(50, 205, "Director");
            pdf.text(100, 205, "Jefe de Control de Estudios y Evaluación");
            pdf.text(110, 250, "El Graduado", 'center');            
        }
        this.Out();
    }
    
    Expediente(acta,data) {
        pdf = new jsPDF('p', 'mm', 'letter');
        for (var i = 0; i < data.length; i++) {
            if(i>0){pdf.addPage();}
            //PAG1
            pdf.addImage("logo.jpg", 'JPEG', 25, 15, 20, 20);
            pdf.setFont("italic", "bold");
            pdf.setFontSize(12);
            pdf.text(110, 20, "INSTITUTO UNIVERSITARIO DE TECNOLOGÍA INDUSTRIAL" , 'center');
            pdf.text(110, 25, "RODOLFO LOERO ARISMENDI", 'center');
            pdf.text(110, 30, "(IUTIRLA)", 'center');
            pdf.text(110, 35, "RIF: J-00130751-6", 'center');               
            pdf.setFontSize(18);
            pdf.text(110, 90, "EXPEDIENTE DE OPCIÓN AL TÍTULO" , 'center');
            pdf.text(110, 110, "DE" , 'center');
            pdf.text(data[i].titulo.toUpperCase(), 110, 130, {maxWidth: 150, align: "center"});
            pdf.text(110, 160, "BR. "+data[i].nombre.toUpperCase()+" "+data[i].apellido.toUpperCase(), 'center');
            pdf.text(110, 170, "C.I. "+data[i].nac+"-"+data[i].estudiante, 'center');
            pdf.setFontSize(14);
            pdf.text(110, 260, data[i].ciudad_nucleo+", "+data[i].fecha_actual, 'center');
            //PAG2
            pdf.addPage();
            pdf.addImage("logo.jpg", 'JPEG', 25, 15, 20, 20);
            pdf.setFontSize(12);
            pdf.setFont("italic", "bold");
            pdf.text(110, 20, "INSTITUTO UNIVERSITARIO DE TECNOLOGÍA INDUSTRIAL" , 'center');
            pdf.text(110, 25, "RODOLFO LOERO ARISMENDI", 'center');
            pdf.text(110, 30, "(IUTIRLA)", 'center');
            pdf.text(110, 35, "RIF: J-00130751-6", 'center');               
            pdf.setFontSize(12);          
            pdf.text(data[i].parrafos[0].contenido, 25, data[i].parrafos[0].alt, {maxWidth: 170, align: data[i].parrafos[0].alin});
            pdf.text(110, 65, "CERTIFICA", 'center'); 
            let intro = "Que en el expediente de opción al título de "+data[i].titulo.toUpperCase()+", correspondiente a (a la) ciudadano (a) "
            +data[i].nombre.toUpperCase()+" "+data[i].apellido.toUpperCase()+", titular de la cédula de identidad N° "
            +data[i].nac+"-"+data[i].estudiante+", se encuentran los siguientes documentos:";
            pdf.text(intro, 25, 80, {maxWidth: 170, align: "justify"});
            pdf.text(25, 110, "PRIMERO:");
            pdf.text("Fotocopia de la Cédula de Identidad confrontada con el original.", 50, 110, {maxWidth: 140, align: "justify"});
            pdf.text(25, 120, "SEGUNDO:");
            pdf.text("Acta de grado.", 50, 120, {maxWidth: 140, align: "justify"});
            pdf.text(25, 130, "TERCERO:");
            let tercero = 'Un Registro Académico de Calificaciones en la cual consta que cursó y aprobó en el Instituto Universitario de Tecnología Rodolfo Loero Arismendi las asignaturas previstas en el plan de estudios de la carrera.';
            pdf.text(tercero, 50, 130, {maxWidth: 140, align: "justify"});
            if( data[i].calificaciones.filter(val => val.tipoasig == 6 || val.tipoasig == 8).length>0){
                pdf.text(25, 150, "CUARTO:");
                pdf.text("Acta de Evaluación Definitiva de Pasantía Ocupacional.", 50, 150, {maxWidth: 140, align: "justify"});
                pdf.text(25, 160, "QUINTO:");
                pdf.text("Acta de evaluación del Trabajo Especial de Grado.", 50, 160, {maxWidth: 140, align: "justify"});
                pdf.text(25, 170, "SEXTO:");
                pdf.text("Lugar de Promoción.", 50, 170, {maxWidth: 140, align: "justify"});
                pdf.text(25, 180, "SEPTIMO:");
                pdf.text("Acta de Aprobación de Servicio Comunitario.", 50, 180, {maxWidth: 140, align: "justify"});    
            } else {
                pdf.text(25, 150, "CUARTO:");
                pdf.text("Lugar de Promoción.", 50, 150, {maxWidth: 140, align: "justify"});
                pdf.text(25, 160, "QUINTO:");
                pdf.text("Acta de Aprobación de Servicio Comunitario.", 50, 160, {maxWidth: 140, align: "justify"});    
            }
            pdf.text("Doy fe de lo anterior en "+data[i].ciudad_nucleo+", a los "+data[i].fecha_letras+".", 25, 200, {maxWidth: 170, align: "justify"});
            pdf.text(125, 250, "Dr. RICHARD TUCKER LOERO");
            pdf.text(150, 255, "Director");
            //PAG3
            pdf.addPage();
            pdf.addImage("logo.jpg", 'JPEG', 25, 15, 20, 20);
            pdf.setFontSize(12);
            pdf.setFont("italic", "bold");
            pdf.text(110, 20, "INSTITUTO UNIVERSITARIO DE TECNOLOGÍA INDUSTRIAL" , 'center');
            pdf.text(110, 25, "RODOLFO LOERO ARISMENDI", 'center');
            pdf.text(110, 30, "(IUTIRLA)", 'center');
            pdf.text(110, 35, "RIF: J-00130751-6", 'center');               
            let parraf1 = 'Quien suscribe, Director del Instituto Universitario de Tecnología Rodolfo Loero Arismendi, hace constar que la fotocopia de la Cédula de Identidad que antecede, corresponde fielmente al original que ha sido presentado por el titular de dicho documento.';
            let parraf2 = 'Constancia que se expide en '+data[i].ciudad_nucleo+', a los '+data[i].fecha_letras+'.';
            pdf.text(parraf1, 25, 130, {maxWidth: 160, align: "justify"});
            pdf.text(parraf2, 25, 160, {maxWidth: 160, align: "justify"});
            pdf.text(125, 220, "Dr. RICHARD TUCKER LOERO");
            pdf.text(150, 225, "Director");
            //PAG4
            pdf.addPage();
            pdf.addImage("logo.jpg", 'JPEG', 25, 15, 20, 20);
            pdf.setFontSize(12);
            pdf.setFont("italic", "bold");
            pdf.text(110, 20, "INSTITUTO UNIVERSITARIO DE TECNOLOGÍA INDUSTRIAL" , 'center');
            pdf.text(110, 25, "RODOLFO LOERO ARISMENDI", 'center');
            pdf.text(110, 30, "(IUTIRLA)", 'center');
            pdf.text(110, 35, "RIF: J-00130751-6", 'center');               
            pdf.text(110, 65, "CERTIFICA", 'center'); 
            pdf.setFont("italic", "");
            for (var k = 0; k < data[i].parrafos.length; k++) {
                pdf.setFontSize(data[i].parrafos[k].tam);
                if(data[i].parrafos[k].normal){pdf.setFont("italic", "bold");} else {pdf.setFont("italic", "");}
                pdf.text(data[i].parrafos[k].contenido, 25, data[i].parrafos[k].alt, {maxWidth: 170, align: data[i].parrafos[k].alin});
            }
            pdf.text('Constancia que se expide en '+data[i].ciudad_nucleo+', a los '+data[i].fecha_letras_acta+'.', 25, 185, {maxWidth: 160, align: "justify"});
            pdf.setFontSize(14);
            pdf.setFont("italic", "bold");
            pdf.text(110, 230, "DR. RICHARD TUCKER LOERO", 'center'); 
            pdf.setFont("italic", "");           
            pdf.text(110, 235, "Director ", 'center');            
            //PAG5
            pdf.addPage();
            pdf.addImage("logo.jpg", 'JPEG', 25, 15, 20, 20);
            pdf.setFontSize(12);
            pdf.setFont("italic", "bold");
            pdf.text(110, 20, "INSTITUTO UNIVERSITARIO DE TECNOLOGÍA INDUSTRIAL" , 'center');
            pdf.text(110, 25, "RODOLFO LOERO ARISMENDI", 'center');
            pdf.text(110, 30, "(IUTIRLA)", 'center');
            pdf.text(110, 35, "RIF: J-00130751-6", 'center');               
            pdf.setFontSize(10);
            pdf.setFont("italic", "bold");
            pdf.text(100, 40, "REGISTRO ACADEMICO DE CALIFICACIONES", 'center');   
            pdf.text(10, 45, "Estudiante: "+data[i].apellido+", "+data[i].nombre);
            pdf.text(155, 45, "Nº Cédula: "+data[i].nac+data[i].estudiante);
            pdf.text(10, 50, "Plan de Estudio: "+data[i].plan);
            pdf.text(155, 50, "Período: "+data[i].periodo);
            const head1 = [['Nº', 'Código','Asignatura', 'Creditos', 'Calificación', 'Período', 'Rég.']];
            var asigporuc = 0
            var cursadasuc = 0
            var body1 = [];
            var definitiva = 0;
            for (var j = 0; j < data[i].calificaciones.length; j++) {
                if(data[i].calificaciones[j].aprobada && data[i].calificaciones[j].tipoasig==7){
                    definitiva = 'Aprobado';
                } else if(data[i].calificaciones[j].aprobada && data[i].calificaciones[j].tipo_id==5){
                    definitiva = 'Equivalencia';
                } else {
                    definitiva = data[i].calificaciones[j].definitiva;
                }
                cursadasuc = parseFloat(cursadasuc) + parseFloat(data[i].calificaciones[j].uc);
                asigporuc = parseFloat(asigporuc) + parseFloat(data[i].calificaciones[j].uc*data[i].calificaciones[j].definitiva);
                body1.push([
                    j+1,
                    data[i].calificaciones[j].cod,
                    data[i].calificaciones[j].asignatura,
                    data[i].calificaciones[j].uc,
                    definitiva,
                    data[i].calificaciones[j].periodo,
                    data[i].calificaciones[j].esc,
                ]);
            }
            pdf.autoTable({
                head: head1,
                body: body1,
                startY: 50,
                margin: {
                    top: 10,
                    right: 10,
                    left: 10,
                    bottom: 10
                },
                headerStyles: {
                    fillColor: [255, 255, 255],
                    textColor: [0, 0, 0],
                    halign: 'center'
                },                     
                styles: {
                    lineColor: [255, 255, 255],
                    lineWidth: 0.1,
                    textColor: [0, 0, 0],
                    fontSize: 8,
                    cellPadding: 1,
                },
                columnStyles: {
                    0: {cellWidth: 10, halign: 'center'},
                    1: {cellWidth: 20, halign: 'center'},
                    2: {cellWidth: 90},
                    3: {cellWidth: 17, halign: 'center'},
                    4: {cellWidth: 25, halign: 'center'},
                    5: {cellWidth: 18, halign: 'center'},
                    6: {cellWidth: 10, halign: 'center'},
                  }
            });
            pdf.text(15, 270, "Índice de Grado: "+ parseFloat(data[i].detalle.indice).toFixed(2));
            pdf.text(140, 270, "Unidades de Créditos Aprobadas: "+data[i].detalle.creditos);  
            //PAG6
            pdf.addPage();
            pdf.addImage("logo.jpg", 'JPEG', 25, 15, 20, 20);
            pdf.setFontSize(12);
            pdf.setFont("italic", "bold");
            pdf.text(110, 20, "INSTITUTO UNIVERSITARIO DE TECNOLOGÍA INDUSTRIAL" , 'center');
            pdf.text(110, 25, "RODOLFO LOERO ARISMENDI", 'center');
            pdf.text(110, 30, "(IUTIRLA)", 'center');
            pdf.text(110, 35, "RIF: J-00130751-6", 'center');               
            pdf.text(110, 50, "LUGAR DE PROMOCIÓN", 'center');            
            let parraf3 = 'Quien suscribe, Director del Instituto Universitario de Tecnología Rodolfo Loero Arismendi, por medio de la presente hace constar que el (la) Técnico Superior Universitario:';
            pdf.text(parraf3, 25, 80, {maxWidth: 170, align: "justify"});
            pdf.text(110, 110, data[i].nombre.toUpperCase()+" "+data[i].apellido.toUpperCase(), 'center');            
            let parraf4 = 'titular de la Cédula de Identidad N° '+data[i].nac+"-"+data[i].estudiante+' ocupa la posición N° '+data[i].detalle.posicion+' de un total de '+data.length+' egresados en la carrera '+acta.programa.toUpperCase()+' en el período '+acta.periodo;
            pdf.text(parraf4, 25, 130, {maxWidth: 170, align: "justify"});
            pdf.text('Constancia que se expide en '+data[i].ciudad_nucleo+', a los '+data[i].fecha_letras+'.', 25, 150, {maxWidth: 170, align: "justify"});
            pdf.text(125, 210, "Dr. RICHARD TUCKER LOERO");
            pdf.text(150, 215, "Director");
            //PAG7
            pdf.addPage();
            pdf.addImage("logo.jpg", 'JPEG', 25, 15, 20, 20);
            pdf.setFont("italic", "bold");
            pdf.setFontSize(12);
            pdf.text(110, 20, "INSTITUTO UNIVERSITARIO DE TECNOLOGÍA INDUSTRIAL" , 'center');
            pdf.text(110, 25, "RODOLFO LOERO ARISMENDI", 'center');
            pdf.text(110, 30, "(IUTIRLA)", 'center');
            pdf.text(110, 35, "RIF: J-00130751-6", 'center');               
            pdf.setFontSize(18);
            pdf.text(110, 90, "ANEXO DEL EXPEDIENTE DE OPCIÓN AL TÍTULO" , 'center');
            pdf.text(110, 110, "DE" , 'center');
            pdf.text(data[i].titulo.toUpperCase(), 110, 130, {maxWidth: 150, align: "center"});
            pdf.text(110, 160, "BR. "+data[i].nombre.toUpperCase()+" "+data[i].apellido.toUpperCase(), 'center');
            pdf.text(110, 170, "C.I. "+data[i].nac+"-"+data[i].estudiante, 'center');
            pdf.setFontSize(14);
            pdf.text(110, 260, data[i].ciudad_nucleo+", "+data[i].fecha_actual, 'center');

        }
        this.Out();
    }


}

export default PDFs;