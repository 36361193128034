<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
                        <Dropdown v-model="selec" :options="years" placeholder="Seleccione..." class="p-mr-2 p-mb-2"/>
						<Button label="Buscar" icon="pi pi-search" class="p-button-secondary p-mr-2 p-mb-2" @click="Buscar"/>
					</template>
					<!--
					<template v-slot:right>
						<FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="p-mr-2 p-mb-2 p-d-inline-block" />
						<Button label="Export" icon="pi pi-upload" class="p-button-help p-mb-2" @click="exportCSV($event)"  />
					</template>
					-->
				</Toolbar>

				<DataTable :value="products" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Estadística OPSU</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="id" header="Extensión">
						<template #body="slotProps">
							{{slotProps.data.nucleo}}
						</template>
					</Column>
					<Column field="estud" header="N° Estudiantes" headerStyle="width: 10%">
						<template #body="slotProps">
							{{slotProps.data.estud}}
						</template>
					</Column>
					<Column headerStyle="width: 5%">
						<template #body="slotProps">
                            <Button icon="pi pi-cloud-download" class="p-button-rounded p-button-success p-mr-2" @click="Descargar(1,slotProps.data)" v-tooltip.bottom="'Descargar'"/>
						</template>
					</Column>
					<Column field="egreso" header="N° Egresados" headerStyle="width: 10%">
						<template #body="slotProps">
							{{slotProps.data.egreso}}
						</template>
					</Column>
                    <Column headerStyle="width: 5%">
						<template #body="slotProps">
                            <Button icon="pi pi-cloud-download" class="p-button-rounded p-button-success p-mr-2" @click="Descargar(2,slotProps.data)" v-tooltip.bottom="'Descargar'"/>
						</template>
					</Column>
					<Column field="doc" header="N° Docentes" headerStyle="width: 10%">
						<template #body="slotProps">
							{{slotProps.data.doc}}
						</template>
					</Column>
					<Column headerStyle="width: 5%">
						<template #body="slotProps">
                            <Button icon="pi pi-cloud-download" class="p-button-rounded p-button-success p-mr-2" @click="Descargar(3,slotProps.data)" v-tooltip.bottom="'Descargar'"/>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Estadistica','EstadOPSU');
	import XLS from "../service/XLS";

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				products: [],
				productDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
                years: [],
                selec: null,


				niveles: null,
				estados: [],
				ciudades: [],
				ciudades_data: [],
				modalidad: [],

			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}

			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
                this.years = [];
				Consulta.Ini().then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
                    for (let i = parseInt(result.consult.year.max); i >= parseInt(result.consult.year.min); i--) {
                        this.years.push(i);
                    }
					this.$store.commit('Loading');
				}); 
			},
            Buscar() {
                this.products = [];
                Consulta.Procesar('Buscar',{
                    opcion: this.selec,
                }).then(response => {
                    //this.$store.state.error = response;
                    this.products = response.result;
                    this.$store.commit('Loading');  
                });
            },
            Descargar(tipo,nucleo) {
                Consulta.Procesar('Descargar',{
                    opcion: this.selec,
                    tipo: tipo,
                    nucleo: nucleo.id,
                }).then(response => {
                    this.$store.commit('Loading');  
                    //this.$store.state.error = response.result[0];
                    const xls = new XLS();
                    if(tipo==1){ xls.OPSU1(this.selec,nucleo,response.result); }
                    if(tipo==2){ xls.OPSU2(this.selec,nucleo,response.result); }
                    if(tipo==3){ xls.OPSU3(this.selec,nucleo,response.result); }                   
                });
            },
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
