<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<Dropdown v-model="periodo" :options="periodos" optionLabel="per" placeholder="Seleccione el Período ..."/>
						<Dropdown v-model="nucleo" :options="nucleos" optionLabel="nombre" placeholder="Seleccione la Extensión ..." @change="Validacion1()" v-if="periodo"/>
						<Dropdown v-model="plan" :options="planes" optionLabel="nombre" placeholder="Seleccione el Plan de Estudio ..." @change="Validacion2()" v-if="nucleo"/>
						<Dropdown v-model="sem" :options="sems" optionLabel="nombre" placeholder="Semestr ..." @change="Buscar()" v-if="plan"/>
					</template>
					<template v-slot:right>
					</template>
				</Toolbar>			
				<br>
				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Actas: {{products.length}}</h5>
							<Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver()" v-if="seccion"/>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="id" header="ID" :sortable="true">	
						<template #body="slotProps">
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="secf" header="Sección" :sortable="true" headerStyle="width: 100px">	
						<template #body="slotProps">
							{{slotProps.data.secf}}
							<span class="p-tag p-tag-warning" v-if="slotProps.data.fusionsec.length>0" @click="verFusion(slotProps.data.fusionsec)">Fusionada</span>
						</template>
					</Column>
					<Column field="cod" header="Código" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.cod}}
						</template>
					</Column>
					<Column field="asignatura" header="Asignatura" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.asignatura}}
						</template>
					</Column>
					<Column field="doc" header="Docente">
						<template #body="slotProps">
							{{slotProps.data.doc}}
						</template>
					</Column>
					<Column header="Plan Evaluación" headerStyle="width: 100px">
						<template #body="slotProps">
							<span class="p-overlay-badge p-mr-4">
								<Button icon="pi pi-file-pdf" class="p-button-rounded p-button-secondary" v-tooltip.bottom="'Plan de Evaluación'" @click="VerPDF1(slotProps.data)"/>
								<span class="p-badge">{{slotProps.data.porcent}}%</span>
							</span>
						</template>
					</Column>
					<Column header="Calificaciones" headerStyle="width: 200px">
						<template #body="slotProps">
							<span class="p-overlay-badge p-mr-4" v-if="slotProps.data.inscritos!=slotProps.data.cerradas">
								<Button icon="pi pi-file-pdf" class="p-button-rounded p-button-info" v-tooltip.bottom="'Registro de Calificaciones'" @click="VerPDF2(slotProps.data)"/>
								<span class="p-badge">{{slotProps.data.inscritos}}</span>
							</span>
							<span class="p-overlay-badge p-mr-4" v-if="slotProps.data.cerradas>0">
								<Button icon="pi pi-lock" class="p-button-rounded p-button-danger" v-tooltip.bottom="'Registro de Calificaciones'" @click="VerPDF2(slotProps.data)"/>
								<span class="p-badge">{{slotProps.data.cerradas}}</span>
							</span>
						</template>
					</Column>
					<Column header="Opciones" headerStyle="width: 100px">
						<template #body="slotProps">
							<Button icon="pi pi-refresh" class="p-button-rounded p-button-help p-mr-2" v-if="edit && insert && del && slotProps.data.cerradas>0" @click="Abrir(slotProps.data)"/>
							<Button icon="pi pi-lock" class="p-button-rounded p-button-danger p-mr-2" v-if="edit && insert && del && slotProps.data.inscritos>slotProps.data.cerradas" @click="Cerrar(slotProps.data)"/>
						</template>
					</Column>
				</DataTable>

				<Sidebar v-model:visible="verfusionDialog" position="top">
					<h4>Principal de la Fusión en la Carrera: <strong>{{fusion.codplan}} {{fusion.plan}}</strong></h4>
					<h5>Sección: <strong>S{{fusion.sem}}{{fusion.secc}}</strong>,  Asignatura: <strong>{{fusion.asignatura}} ({{fusion.cod}})</strong></h5>
				</Sidebar>

				<Dialog v-model:visible="Dialog" :style="{width: size}" header="Calificaciones Cerradas" :modal="true">
						<br><br>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12 p-md-2">
								Período: <strong>{{seleccion.periodo}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-8">
								Plan de Estudio: <strong>{{seleccion.plan}} {{seleccion.codplan}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-2">
								Código: <strong>{{seleccion.cod}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-6">
								Asignatura: <strong>{{seleccion.asignatura}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-4">
								Docente: <strong>{{seleccion.doc}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-2">
								Sección: <strong>{{seleccion.secf}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-12">
								<DataTable :value="cerradas" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
									<template #header>
										<div class="table-header">
											<div class="p-input-icon-right">
												<h5 class="p-m-0">Estudiantes: {{cerradas.length}}</h5>
											</div>	
											<div>	
												<Checkbox v-model="all" :binary="true" @change="Todo(1)" />
											</div>	
										</div>
									</template>
									<Column field="estudiante" header="Nº Cédula" :sortable="true" headerStyle="width: 8rem;">	
										<template #body="slotProps">
											{{slotProps.data.estudiante}}				
										</template>
									</Column>
									<Column field="estudiante" header="Apellidos y Nombres" :sortable="true">	
										<template #body="slotProps">
											{{slotProps.data.apellido}}, {{slotProps.data.nombre}}			
										</template>
									</Column>
									<Column header="C01" headerStyle="width: 4rem;">
										<template #body="slotProps">
											{{slotProps.data.calif1}}
										</template>
									</Column>
									<Column header="C02" headerStyle="width: 4rem;">	
										<template #body="slotProps">
											{{slotProps.data.calif2}}
										</template>
									</Column>
									<Column header="C03" headerStyle="width: 4rem;">	
										<template #body="slotProps">
											{{slotProps.data.calif3}}
										</template>
									</Column>
									<Column header="C04" headerStyle="width: 4rem;">	
										<template #body="slotProps">
											{{slotProps.data.calif4}}
										</template>
									</Column>
									<Column header="Acumulativa" headerStyle="width: 8rem;">	
										<template #body="slotProps">
											{{slotProps.data.acumulativa}}
										</template>
									</Column>
									<Column header="Extra" headerStyle="width: 4rem;">	
										<template #body="slotProps">
											{{slotProps.data.extra}}
										</template>
									</Column>
									<Column header="Definitiva" headerStyle="width: 8rem;">	
										<template #body="slotProps">
											{{slotProps.data.definitiva}}
											<i class="pi pi-check" v-if="slotProps.data.aprobada"></i>
											<i class="pi pi-times" v-else></i>
										</template>
									</Column>
									<Column header="Selección" headerStyle="width: 8rem;">	
										<template #body="slotProps">
											<Checkbox v-model="slotProps.data.opcion" :binary="true"/>
										</template>
									</Column>
								</DataTable>
							</div>
						</div>
					<template #footer>
						<Button label="Abrir Acta" icon="pi pi-check" class="p-button-secondary" @click="ProcesarAbrir"  v-if="edit"/>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="Dialog = false"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="Dialog2" :style="{width: size}" header="Calificaciones Abiertas" :modal="true">
						<br><br>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12 p-md-2">
								Período: <strong>{{seleccion.periodo}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-8">
								Plan de Estudio: <strong>{{seleccion.plan}} {{seleccion.codplan}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-2">
								Código: <strong>{{seleccion.cod}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-6">
								Asignatura: <strong>{{seleccion.asignatura}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-4">
								Docente: <strong>{{seleccion.doc}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-2">
								Sección: <strong>{{seleccion.secf}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-12">
								<DataTable :value="abiertas" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
									<template #header>
										<div class="table-header">
											<div class="p-input-icon-right">
												<h5 class="p-m-0">Estudiantes: {{abiertas.length}}</h5>
											</div>		
											<div>	
												<Checkbox v-model="all" :binary="true" @change="Todo(2)" />
											</div>										
										</div>
									</template>
									<Column field="estudiante" header="Nº Cédula" :sortable="true" headerStyle="width: 8rem;">	
										<template #body="slotProps">
											{{slotProps.data.estudiante}}				
										</template>
									</Column>
									<Column field="estudiante" header="Apellidos y Nombres" :sortable="true">	
										<template #body="slotProps">
											{{slotProps.data.apellido}}, {{slotProps.data.nombre}}			
										</template>
									</Column>
									<Column header="C01" headerStyle="width: 4rem;">
										<template #body="slotProps">
											{{slotProps.data.calif1}}
										</template>
									</Column>
									<Column header="C02" headerStyle="width: 4rem;">	
										<template #body="slotProps">
											{{slotProps.data.calif2}}
										</template>
									</Column>
									<Column header="C03" headerStyle="width: 4rem;">	
										<template #body="slotProps">
											{{slotProps.data.calif3}}
										</template>
									</Column>
									<Column header="C04" headerStyle="width: 4rem;">	
										<template #body="slotProps">
											{{slotProps.data.calif4}}
										</template>
									</Column>
									<Column header="Acumulativa" headerStyle="width: 8rem;">	
										<template #body="slotProps">
											{{(parseFloat(slotProps.data.calif1)+parseFloat(slotProps.data.calif2)+parseFloat(slotProps.data.calif3)+parseFloat(slotProps.data.calif4)).toFixed(0)}}
										</template>
									</Column>
									<Column header="Extra" headerStyle="width: 4rem;">	
										<template #body="slotProps">
											{{slotProps.data.extra}}
										</template>
									</Column>
									<Column header="Definitiva" headerStyle="width: 8rem;">	
										<template #body="slotProps">
											{{Final(parseFloat(slotProps.data.calif1)+parseFloat(slotProps.data.calif2)+parseFloat(slotProps.data.calif3)+parseFloat(slotProps.data.calif4),slotProps.data.extra)}}
											<i class="pi pi-check" v-if="Final(parseFloat(slotProps.data.calif1)+parseFloat(slotProps.data.calif2)+parseFloat(slotProps.data.calif3)+parseFloat(slotProps.data.calif4),slotProps.data.extra)>=aprob"></i>
											<i class="pi pi-times" v-else></i>
										</template>
									</Column>
									<Column header="Selección" headerStyle="width: 8rem;">	
										<template #body="slotProps">
											<Checkbox v-model="slotProps.data.opcion" :binary="true"/>
										</template>
									</Column>
								</DataTable>
							</div>
						</div>
					<template #footer>
						<Button label="Cerrar Acta" icon="pi pi-check" class="p-button-secondary" @click="ProcesarCerrar"  v-if="edit"/>
						<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="Dialog2 = false"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	import PDF from "../service/PDF";
	const Consulta = new API('Evaluacion','ActasCalif');

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				productDialog: false,
				productDialog2: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '80vw',
				nucleo: null,
				nucleos: [],
				plan: null,
				planes: [],
				sem: null,
				sems: [],
				periodo: null,
				periodos: [],
				opciones: [
                    {nombre: 'Una Asignatura', code: 0},
                    {nombre: 'Por Bloque', code: 1},
                ],
				selectedopc: {nombre: 'Una Asignatura', code: 0},
				semestres: [],
				asignaturas: [],
				horario: [],
				horariodoc: [],
				asigdoc: [],
				horas: null,
				hora: null,
				aulas: null,
				aula: null,
				dias: null,
				dia: null,
				numdocente: 0,
				numhoras: 0,
				totalhoras: 0,
				fusionDialog: false,
				seleccion: {},
				fusion: null,				
				verfusionDialog: false,
				cerradas: [],
				abiertas: [],
				Dialog: false,
				Dialog2: false,
				escala: [],
				aprob: 0,
				all: false,
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}

			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.periodo = null;
				this.periodos = [];
				this.all = false;
				Consulta.Ini().then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.nucleos = result.consult.nucleos;				
					this.periodos = result.consult.periodos;
					this.sems = result.consult.sems;
					this.$store.commit('Loading');
				}); 
			},
			VerINS(data){
				var dat = data;
				Consulta.Procesar('inscripcion',{
					oferta: 	data.id,
				}).then(response => {
					//this.$store.state.error =response
					dat.inscripcion = response.result.inscripcion;
					const doc = new PDF();
					doc.Inscritos(dat);
					this.$store.commit('Loading');
				}); 				
			},
			Abrir(data){
				this.all = false;
				this.seleccion = data;
				Consulta.Procesar('VerCerradas',{
					oferta: 	data.id,
				}).then(response => {
					//this.$store.state.error =response
					this.cerradas = response.result;
					this.Dialog = true;
					this.$store.commit('Loading');
				}); 				
			},
			ProcesarAbrir(){
				Consulta.Procesar('Procesar',{
					notas: 	this.cerradas,
				}).then(response => {
					//this.$store.state.error =response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Dialog = false;
						this.$store.commit('Loading');
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
						this.$store.commit('Loading');
					}
				}); 				
			},
			Cerrar(data){
				this.all = false;
				this.seleccion = data;
				Consulta.Procesar('VerAbiertas',{
					oferta: 	data.id,
					escala: 	data.escala,
				}).then(response => {
					//this.$store.state.error = response;
					this.abiertas = response.result.notas;
					this.escala = response.result.escala;
					this.aprob = response.result.aprob;
					this.Dialog2 = true;
					this.$store.commit('Loading');
				}); 				
			},
			ProcesarCerrar(){
				Consulta.Procesar('Procesar2',{
					notas: 	this.abiertas,
					escala: 	this.escala,
					aprob: this.aprob

				}).then(response => {
					//this.$store.state.error = response;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Dialog2 = false;
						this.$store.commit('Loading');
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
						this.$store.commit('Loading');
					}
				}); 				
			},
			Todo(caso){
				if(caso==1){
					this.cerradas.forEach(element => {
						element.opcion = this.all;
					}); 
				}
				if(caso==2){
					this.abiertas.forEach(element => {
						element.opcion = this.all;
					}); 
				}
			},
			VerPDF1(data){
				Consulta.Procesar('VerPlanEval',{
					oferta: data.id
					}).then(response => {
						//this.$store.state.error =response;
						this.$store.commit('Loading');
						if (response.result) {
							var dat = data;
							dat['evaluac'] = response.result;
							const doc = new PDF();
							doc.PlanEvaluacion(dat);
						} 
					});
			},
			VerPDF2(data){
				if(data.inscritos!=data.cerradas){
					Consulta.Procesar('VerCalif',{
						oferta: data.id
						}).then(response => {
							//this.$store.state.error =response;
							this.$store.commit('Loading');
							if (response.result) {
								var dat = data;
								dat['inscripcion'] = response.result;
								const doc = new PDF();
								doc.ActaCalificaciones(dat);
							} 
						});
				} else {
					Consulta.Procesar('VerCalifFinal',{
						oferta: data.id
						}).then(response => {
							this.$store.commit('Loading');
							//this.$store.state.error =response;
							if (response.result) {
								const doc = new PDF();
								doc.ActaCalificacionesFINAL(response.result);
							} 
						});
				}
			},
			Validacion1() {
				this.plan = null;
				this.planes = [];
				this.products=[];
				Consulta.Procesar('Validacion1',{
					nucleo: this.nucleo.code,
					periodo: 	this.periodo.code,
				}).then(response => {
					//this.$store.state.error = response;	
					this.planes = response.result;
					this.$store.commit('Loading');  
				});
			},
			Validacion2() {
				this.sem = null;
				this.sems = [];
				Consulta.Procesar('Validacion2',{
					nucleo: this.nucleo.code,
					periodo: 	this.periodo.code,
					plan: 	this.plan.code,
				}).then(response => {
					//this.$store.state.error = response;	
					this.sems = response.result;
					this.$store.commit('Loading');  
				});
			},
			Buscar() {
				if (this.periodo != null && this.nucleo != null && this.plan != null && this.sem != null) {
					this.numdocente = 0;
					this.numhoras = 0;
					this.totalhoras = 0;
					this.products=[];
					Consulta.Procesar('Buscar',{
						nucleo: 	this.nucleo.code,
						plan: 		this.plan.code,
						periodo: 	this.periodo.code,
						sem: 	this.sem.code,
					}).then(response => {
						//this.$store.state.error = response;
						if(response.result.oferta){this.products = response.result.oferta;}
						this.$store.commit('Loading');
					}); 
				}
			},
			Seccion() {
				if (this.nucleo != null && this.plan != null) {
					this.numdocente = 0;
					this.numhoras = 0;
					this.totalhoras = 0;
					this.products=[];
					Consulta.Procesar('Seccion',{
						nucleo: 	this.nucleo.code,
						plan: 	this.plan.code,
						periodo: 	this.periodo.code,
						turno: 	this.seccion.turno_id,
						seccion: 	this.seccion.sec_id,
						sem: 	this.seccion.sem,
					}).then(response => {
						//this.$store.state.error =response;
						this.products = response.result.oferta;
						//this.numdocente = response.result.numdocente;
						//this.numhoras = response.result.numhoras;
						//this.totalhoras = response.result.totalhoras;
						this.$store.commit('Loading');
					}); 
				}
			},
			Definitiva(acum){
				if(this.escala.length>0){
					for (let i = 0; i < this.escala.length; i++) {
						if (this.escala[i].inferior <= acum && acum <= this.escala[i].superior) {
							return this.escala[i].calif;
						}
					}
				} else {
					return 0;
				}
			},
			Final(acum,extra){
				if(extra>0){
					if(this.Definitiva(acum)==7 && extra>=13){return 10;}
					else if(this.Definitiva(acum)==8 && extra>=12){return 10;}
					else if(this.Definitiva(acum)==9 && extra>=11){return 10;}
					else {return this.Definitiva(acum);}
				} else {
					return this.Definitiva(acum);
				}
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			verFusion(data) {
				this.fusion = data[0];
				this.verfusionDialog = true;
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
				convfech(fecha){
					return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
				},

		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
