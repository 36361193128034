<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="openNew" v-if="insert"/>
						<!--<Button :label="Borrar" icon="pi pi-trash" class="p-button-danger p-mb-2" @click="confirmDeleteSelected" :disabled="!selectedProducts || !selectedProducts.length" />-->
					</template>
					<!--
					<template v-slot:right>
						<FileUpload mode="basic" accept="image/*" :maxFileSize="1000000" label="Import" chooseLabel="Import" class="p-mr-2 p-mb-2 p-d-inline-block" />
						<Button label="Export" icon="pi pi-upload" class="p-button-help p-mb-2" @click="exportCSV($event)"  />
					</template>
					-->
				</Toolbar>

				<DataTable :value="products" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Extensiones</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="id" header="Extensión" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nucleo}}
						</template>
					</Column>
					<Column field="nombre" header="Sede" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="telefono" header="Teléfono" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.telefono}}
						</template>
					</Column>
					<Column field="activo" header="Estatus" :sortable="true">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
						</template>
					</Column>
					<Column header="Opciones">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" v-if="edit" v-tooltip.bottom="'Editar'"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteProduct(slotProps.data)" v-if="del" v-tooltip.bottom="'Eliminar'"/>
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: size}" header="Extensión" :modal="true" class="p-fluid" :maximizable="true">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-5">
								<label for="name">Extensión</label>
								<InputText id="name" v-model.trim="product.nucleo" required="true" autofocus/>
							</div>
							<div class="p-col-5">
								<label for="name">Sede</label>
								<InputText id="name" v-model="product.nombre" required="true"/>
							</div>
							<div class="p-col-2">
								<label for="name">Teléfono</label>
								<InputMask v-model="product.telefono" mask="(9999) 999-9999" />
							</div>
							<div class="p-col-6">
								<label for="name">Correo</label>
								<InputText v-model="product.correo"></InputText>	
							</div>
							<div class="p-col-6">
								<label class="p-mb-2">Estado</label>
								<Dropdown v-model="product.estado" :options="estados" optionLabel="nombre" placeholder="Selecciona..." @change="actualizar"></Dropdown>
							</div>
							<div class="p-col-6">
								<label class="p-mb-2">Ciudad</label>
								<Dropdown v-model="product.ciudad" :options="ciudades" optionLabel="nombre" placeholder="Selecciona..." v-if="product.estado"></Dropdown>
							</div>
							<div class="p-col-6">
								<label for="name">Jefe de Extensión (Firma de Documentos)</label>
								<InputText v-model="product.firma"></InputText>	
							</div>
							<div class="p-col-6">
								<label class="p-mb-2">Modalidad</label>
								<Dropdown v-model="product.modalidad" :options="modalidad" optionLabel="nombre" placeholder="Selecciona..." v-if="product.estado"></Dropdown>
							</div>
							<div class="p-col-5">
								<label for="name">Código OPSU</label>
								<InputText id="name" v-model="product.opsu" required="true"/>
							</div>
							<div class="p-col-2">
								<h6 style="margin-top: 0" v-if="product.activo">Activo</h6>
								<h6 style="margin-top: 0" v-else>No Activo</h6>
								<InputSwitch v-model="product.activo" />
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="hideDialog"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}" header="Confirmar" :modal="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button label="Sí" icon="pi pi-check" class="p-button-secondary" @click="deleteProduct" />
						<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Administracion','Nucleos');

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				products: [],
				productDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				niveles: null,
				estados: [],
				ciudades: [],
				ciudades_data: [],
				modalidad: [],

			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}

			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				Consulta.Ini().then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.products = result.consult.nucleo;
					this.estados = result.consult.estados;
					this.ciudades_data = result.consult.ciudades;
					this.modalidad = result.consult.modalidad;
					this.$store.commit('Loading');
				}); 
			},
			openNew() {
				this.product = {};
				this.submitted = false;
				this.productDialog = true;
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			actualizar() {
				this.ciudades = this.ciudades_data.filter(val => val.estado == this.product.estado.code);
			},
			saveProduct() {
				if (this.product.nucleo == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Extensión', life: 5000});
				} else if (this.product.nombre == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Sede', life: 5000});
				} else if (this.product.telefono == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Teléfono', life: 5000});
				} else if (this.product.correo == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Correo', life: 5000});
				} else if (this.product.estado == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el Estado', life: 5000});
				} else if (this.product.modalidad == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la Modalidad', life: 5000});
				} else {			
					this.productDialog = false;
					if (this.product.id) {
						Consulta.Procesar('UPD',{
							id: 	this.product.id,
							nucleo: this.product.nucleo,
							nombre: this.product.nombre,
							telefono: this.product.telefono,
							correo: this.product.correo,
							firma: this.product.firma,
							activo: this.product.activo,
							estado: this.product.estado.code,
							ciudad: this.product.ciudad.code,
							modalidad: this.product.modalidad.code,
							opsu: this.product.opsu,
						}).then(response => {
							this.$store.commit('Loading');
							//this.$store.state.error =response;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Mostrar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
						});
					} else {
						Consulta.Procesar('INS',{
							id: 	this.product.id,
							nucleo: this.product.nucleo,
							nombre: this.product.nombre,
							telefono: this.product.telefono,
							correo: this.product.correo,
							firma: this.product.firma,
							activo: this.product.activo,
							estado: this.product.estado.code,
							ciudad: this.product.ciudad.code,
							modalidad: this.product.modalidad.code,
							opsu: this.product.opsu,
						}).then(response => {
							//this.$store.state.error =response;
							this.$store.commit('Loading');
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Mostrar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
						});
					}

					this.productDialog = false;
					this.product = {};
				}
			},
			editProduct(product) {
				this.product = {...product};
				this.estados.forEach(element => {
					if(this.product.estado==element.code){
						this.product.estado = element;
					}
				});
				this.actualizar();
				this.ciudades.forEach(element => {
					if(this.product.ciudad==element.code){
						this.product.ciudad = element;
					}
				});
				this.modalidad.forEach(element => {
					if(this.product.modalidad==element.code){
						this.product.modalidad = element;
					}
				});
				this.productDialog = true;
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			deleteProduct() {
				this.products = this.products.filter(val => val.id !== this.product.id);
				Consulta.Procesar('DEL',{
					id: 	this.product.id
				}).then(response => {
					this.$store.commit('Loading');
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Mostrar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
				});
				this.deleteProductDialog = false;
				this.product = {};
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
