import jsPDF from 'jspdf';
import Carnet from './document/Carnet.js';
import Carta_Postulacion from './document/Carta_Postulacion.js';
import Carta_Presentacion from './document/Carta_Presentacion.js';
import Acta_Grado from './document/Acta_Grado.js';
import Calificaciones from './document/Calificaciones.js';
import Fondo_Negro from './document/Fondo_Negro.js';
import Recibo_Pago from './document/Recibo_Pago.js';
import Titulo from './document/Titulo.js';
import Buena_Conducta from './document/Buena_Conducta.js';
import Carga_Academica from './document/Carga_Academica.js';
import Culminacion_Estudios from './document/Culminacion_Estudios.js';
import Constancia_Estudios from './document/Constancia_Estudios.js';
import Haber_Estudiado from './document/Haber_Estudiado.js';
import Inscripcion from './document/Inscripcion.js';
import Modalidad_Estudio from './document/Modalidad_Estudio.js';
import Notas from './document/Notas.js';
import Constancia_Especial from './document/Constancia_Especial.js';
import Titulo_Extraviado from './document/Titulo_Extraviado.js';
import Pensum from './document/Pensum.js';
import Posicion_Grado from './document/Posicion_Grado.js';
import Programa_Analitico from './document/Programa_Analitico.js';

import Comic from "./font/ComicBookNormal.js";


var pdf = new jsPDF('p', 'mm', [297, 210]);


class PDFs {

    constructor(tipo,data,contenido) {
        this.Documento(tipo,data,contenido);
    }

    Out(){
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(pdf.output("blob"),"consulta.pdf");
        } else {
            //pdf.autoPrint();
            window.open(
                URL.createObjectURL(pdf.output("blob")),
                "_blank",
                "height=650,width=500,scrollbars=yes,location=yes"
            );
            setTimeout(() => {    
                window.URL.revokeObjectURL(pdf.output("bloburl"));
            }, 100);
        }
        pdf = new jsPDF();      
    }
   
    Documento(tipo,data,contenido) {
        pdf = new jsPDF('p', 'mm', 'letter');
        pdf.addFileToVFS('Comic Book Normal.ttf', Comic);
        pdf.addFont('Comic Book Normal.ttf', 'Comic', 'normal');


        switch (tipo.id) {
            case  1: Carnet(pdf,data,contenido); break;
            case  2: Carta_Postulacion(pdf,data,contenido); break;
            case  3: Carta_Presentacion(pdf,data,contenido); break;
            case  4: Acta_Grado(pdf,data,contenido); break;
            case  5: Calificaciones(pdf,data,contenido); break;
            case  6: Fondo_Negro(pdf,data,contenido); break;
            case  7: Recibo_Pago(pdf,data,contenido); break;
            case  8: Titulo(pdf,data,contenido); break;
            case  9: Buena_Conducta(pdf,data,contenido); break;
            case 10: Carga_Academica(pdf,data,contenido); break;
            case 11: Culminacion_Estudios(pdf,data,contenido); break;
            case 12: Constancia_Estudios(pdf,data,contenido); break;
            case 13: Haber_Estudiado(pdf,data,contenido); break;
            case 14: Inscripcion(pdf,data,contenido); break;
            case 15: Modalidad_Estudio(pdf,data,contenido); break;
            case 16: Notas(pdf,data,contenido); break;
            case 17: Constancia_Especial(pdf,data,contenido); break;
            case 18: Titulo_Extraviado(pdf,data,contenido); break;
            case 19: Pensum(pdf,data,contenido); break;
            case 20: Posicion_Grado(pdf,data,contenido); break;
            case 21: Programa_Analitico(pdf,data,contenido); break;
        }
        this.Out();
    }
}

export default PDFs;