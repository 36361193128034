<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<Dropdown v-model="nucleo" :options="nucleos" optionLabel="nombre" placeholder="Seleccione..." class="p-mr-2"/>
						<div v-if="nucleo">
							<InputText v-model="fecha1" type="date" class="p-mr-2"/>
							<InputText v-model="fecha2" type="date" class="p-mr-2"/>
                            <Button label="Buscar" icon="pi pi-search" class="p-button-success p-mr-2" @click="Buscar" v-if="nucleo"/>
						</div>
						<Button label="Recibos Pendientes" icon="pi pi-cog" class="p-button-info p-mr-2" @click="Pendientes" v-if="nucleo"/>
					</template>
					<template v-slot:right>
						<Button  icon="pi pi-print" class="p-button-primary p-mr-2" @click="$store.state.imp = true" v-tooltip.bottom="'Impresión'"/>
					</template>
				</Toolbar>
				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm" v-if="!detalles">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Facturas: {{products.length}}</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="fecha" header="Fecha">
						<template #body="slotProps">
							{{convfech(slotProps.data.fecha)}}
						</template>
					</Column>
					<Column field="numero" header="N° Factura">
						<template #body="slotProps">
							{{('000000'+slotProps.data.numero).slice(-6)}}
						</template>
					</Column>
					<Column field="control" header="N° Control">
						<template #body="slotProps">
							{{('000000000'+slotProps.data.control).slice(-9)}}
						</template>
					</Column>
					<Column field="estudiante" header="Estudiante">
						<template #body="slotProps">
							{{slotProps.data.estudiante}}
						</template>
					</Column>
					<Column field="monto" header="Monto Bs">
						<template #body="slotProps">
							Bs. {{moneda(slotProps.data.montobs)}}
						</template>
					</Column>
					<Column header="Selec" headerStyle="width: 140px">
						<template #body="{data}">
							<span class="p-tag p-tag-danger" v-if="!data.activo">Anulado</span>
							<Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver(data,false)" v-tooltip.bottom="'Ver'" v-if="data.activo"/>
							<Button icon="pi pi-file-pdf" class="p-button-rounded p-button-success p-mr-2" @click="Ver(data,true)" v-tooltip.bottom="'Ver'" v-if="data.activo"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-mr-2" @click="confirmAnular(data)" v-tooltip.bottom="'Anular'" v-if="data.activo"/>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
        
        <Dialog v-model:visible="Dialog" :style="{width: size}" header="Recibos Pendientes" :modal="true" class="p-fluid" :maximizable="true">
			<div class="confirmation-content">
                <DataTable :value="recibos" :scrollable="true" scrollHeight="400px"  class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Recibos: {{recibos.length}}</h5>
                            <div>
                                N° Factura: <InputText type="number" v-model="numero" :min="numero" required="true" placeholder="Factura"/>
                            </div>
                            <div>
                                N° Control: <InputText type="number" v-model="control" :min="control" required="true" placeholder="Cooontrol"/>
                            </div>
						</div>
					</template>
					<Column field="recibo" header="Recibo">
						<template #body="slotProps">
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="fecha" header="Fecha" :sortable="true">
						<template #body="slotProps">
							{{convfech(slotProps.data.fecha)}}
						</template>
					</Column>
					<Column field="estudiante" header="Estudiante">
						<template #body="slotProps">
							{{slotProps.data.estudiante}}
						</template>
					</Column>
					<Column field="monto" header="Monto Bs">
						<template #body="slotProps">
							Bs. {{new Intl.NumberFormat("de-DE").format((slotProps.data.montobs))}}
						</template>
					</Column>
                    <Column header="Selección" headerStyle="width: 8rem;">	
                        <template #body="slotProps">
                            <Checkbox v-model="slotProps.data.seleccion" :binary="true"/>
                        </template>
					</Column>
				</DataTable>
			</div>
			<template #footer>
				<Button label="Procesar" style="width: 110px;" icon="pi pi-save" class="p-button-secondary p-mr-2" @click="Procesar()"/>
				<Button label="Cerrar" style="width: 110px;" icon="pi pi-times" class="p-button-danger p-mr-2" @click="Dialog = false"/>
			</template>
		</Dialog>

		<Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
			<div class="confirmation-content">
				<br><br>
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span v-if="product">Confirme si desea Anular la Factura Nº {{product.numero}}</span>
			</div>
			<template #footer>
				<Button label="Sí" style="width: 110px;" icon="pi pi-save" class="p-button-secondary p-mr-2" @click="Anular()"/>
				<Button label="No" style="width: 110px;" icon="pi pi-times" class="p-button-danger p-mr-2" @click="deleteDialog = false"/>
			</template>
		</Dialog>


	</div>
</template>

<script>
	import API from "../service/API";
	import PDF from "../service/PDF";
	const Consulta = new API('Facturacion','Facturas');

	export default {
		data() {
			return {
				opcion: null,
				opciones: [
					{nombre: 'Buscar por Cédula', code: 1},
					{nombre: 'Buscar por Fechas', code: 2},
				],
				fecha1: null,
				fecha2: null,
				nucleo: null,
				nucleos: [],
				pendiente: {nombre: 'Por Verificar', code: 0},
				pendientes:  [
					{nombre: 'Por Verificar', code: 0},
					{nombre: 'Verificados', code: 1},
				],
				buscar: null,
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				detalles: null,
				product: {},
				estudiante: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				pagos_pend: [],
				facturas: [],
				ajuste: [],
				imagen: null,
				deleteDialog: false,
				pago: {},
				bancos: [],
				banco_destino: {code: 3, nombre: 'Banco Mercantil'},
				tipos: [],
				Dialog: false,
				periodo: null,
				periodos: [],
                numero: 0,
                control: 0,
                recibos: [],
			}
		},
		productService: null,
		created() {
			this.url = process.env.VUE_APP_API;
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.fecha1 = this.$store.state.fechaini;
			this.fecha2 = this.$store.state.fechaini;
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				Consulta.Ini().then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.nucleos = result.consult.nucleos;
                    if(this.nucleos.length==1){
                        this.nucleo = result.consult.nucleos[0];
                    }
					this.$store.commit('Loading');
				}); 
			},
            Buscar() {
				this.products = [];
				if (this.nucleo && (this.fecha1<=this.fecha2)){
					Consulta.Procesar('Buscar',{
						nucleo: this.nucleo,
						fecha1: this.fecha1,
						fecha2: this.fecha2,
					}).then(response => {
						//this.$store.state.error = response.result[0];
						this.$store.commit('Loading');
						this.products = response.result;
					}); 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail:'Debes colocar los datos solicitados', 
						life: 3000
					});    
				}
			},
			Pendientes() {
				if (this.nucleo){
					Consulta.Procesar('Pendientes',{
						nucleo: this.nucleo,
					}).then(response => {
						//this.$store.state.error = response;
						this.numero = response.result.numero;						
						this.control = response.result.control;
						this.recibos = response.result.recibos;
						this.$store.commit('Loading');
                        this.Dialog = true;
					}); 
				} 
			},
			Procesar() {
				if (this.nucleo){
					Consulta.Procesar('Procesar',{
						numero: this.numero,
						control: this.control,
						recibos: this.recibos.filter(val => val.seleccion == true),
					}).then(response => {
						//this.$store.state.error = response;
                        if (response.result) {
                            this.$toast.add({
                                severity:'success', 
                                summary: 'Éxito', 
                                detail: 'Información Procesada', 
                                life: 3000
                            });
                            this.Dialog = false;
                            this.$store.commit('Loading');
                            this.Buscar();
                        } else {
                            this.$toast.add({
                                severity:'warn', 
                                summary: 'Advertencia', 
                                detail: 'Información No Procesada', 
                                life: 3000
                            });
                            this.$store.commit('Loading');
                        }
					}); 
				} 
			},
			confirmAnular(product) {
				this.product = product;
				this.deleteDialog = true;
			},
			Anular() {
				if (this.nucleo){
					Consulta.Procesar('Anular',{
						id: this.product.id,
					}).then(response => {
						//this.$store.state.error = response;
                        if (response.result) {
                            this.$toast.add({
                                severity:'success', 
                                summary: 'Éxito', 
                                detail: 'Información Procesada', 
                                life: 3000
                            });
                            this.deleteDialog = false;
                            this.$store.commit('Loading');
                            this.Buscar();
                        } else {
                            this.$toast.add({
                                severity:'warn', 
                                summary: 'Advertencia', 
                                detail: 'Información No Procesada', 
                                life: 3000
                            });
                            this.$store.commit('Loading');
                        }
					}); 
				} 
			},
			Ver(data,fondo){
				const doc = new PDF();
				doc.Factura(data,fondo);
			},
			convfech(fecha){
				return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
			},
			moneda(num){
				return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num)
			}
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
