<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<Dropdown v-model="nucleo" :options="nucleos" optionLabel="nombre" placeholder="Seleccione la Extensión ..." @change="Validacion1()"/>
						<Dropdown v-model="plan" :options="planes" optionLabel="nombre" placeholder="Seleccione el Plan de Estudio ..." @change="Validacion2()" v-if="nucleo"/>
						<Dropdown v-model="periodo" :options="periodos" optionLabel="per" placeholder="Seleccione el Período ..." v-if="plan"  @change="Buscar()"/>
						<Button label="Buscar" icon="pi pi-search" class="p-button-success" @click="Buscar()" v-if="periodo"/>
					</template>
					<template v-slot:right>
						<div v-if="periodo">
							<Button label="Nuevo" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="openNew" v-if="insert && periodo" :disabled="!plan.activo"/>
						</div>
					</template>
				</Toolbar>
				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Secciones: {{products.length}}</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="sec" header="Sección" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.sec}}
						</template>
					</Column>
					<Column field="turno" header="Turno" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.turno}}
						</template>
					</Column>
					<Column field="maximo" header="Capacidad" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.maximo}}
						</template>
					</Column>
					<Column field="maximo" header="Estudiantes" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.maximo-slotProps.data.disp}}
						</template>
					</Column>
					<Column field="activo" header="Estatus">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
						</template>
					</Column>
					<Column header="Opciones">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" v-if="edit" v-tooltip.bottom="'Editar'"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-mr-2" @click="confirmDeleteProduct(slotProps.data)" v-if="del" v-tooltip.bottom="'Eliminar'"/>
						</template>
					</Column>
					<Column header="Ver">
						<template #body="slotProps">
							<Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver(slotProps.data)"/>
							<Button icon="pi pi-upload" class="p-button-rounded p-button-help p-mr-2" @click="VerXLS(slotProps.data)"/>
						</template>
					</Column>
				</DataTable>
				<Dialog v-model:visible="productDialog" :style="{width: size}" :header="'ID'+product.id+' Sección '+titulo" :modal="true" class="p-fluid" :maximizable="true">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-4">
								<label>Extensión</label>
								<InputText v-model="nucleo.nombre" :disabled="true"></InputText>						
							</div>
							<div class="p-col-6">
								<label>Plan de Estudio</label>
								<InputText v-model="plan.nombre" :disabled="true"></InputText>						
							</div>
							<div class="p-col-2">
								<label>Período</label>
								<InputText v-model="periodo.nombre" :disabled="true"></InputText>						
							</div>
							<div class="p-col-4">
								<label class="p-mb-3">Sección</label>
								<Dropdown id="state" v-model="product.sec" :options="secs" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-4">
								<label class="p-mb-3">Turno</label>
								<Dropdown id="state" v-model="product.turno" :options="turnos" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-2">
								<label class="p-mb-3">Capacidad</label>
								<InputNumber id="req_uc" v-model="product.maximo" required="true"/>
							</div>
							<div class="p-col-2">
								<h6 style="margin-top: 0" v-if="product.activo">Activo</h6>
								<h6 style="margin-top: 0" v-else>No Activo</h6>
								<InputSwitch v-model="product.activo" />
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="hideDialog"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}" header="Confirmar" :modal="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button label="Sí" icon="pi pi-check" class="p-button-secondary" @click="deleteProduct" />
						<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>

</template>

<script>
	import API from "../service/API";
	import PDF from "../service/PDF";
	import XLS from "../service/XLS";
	const Consulta = new API('Ingreso','Seccion');

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				productDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				nucleo: null,
				nucleos: [],
				plan: null,
				planes: [],
				periodo: null,
				periodos: [],
				secs: [],
				turnos: [],
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Ver(data){
				Consulta.Procesar('Lista',{
					seccion: data.id
				}).then(response => {
					//this.$store.state.error = response;	
					if(response.result){
						const doc = new PDF();
						doc.Seccion(data,response.result);
					}
					this.$store.commit('Loading');
				});		
			},
			VerXLS(data){
				Consulta.Procesar('Lista',{
					seccion: data.id
				}).then(response => {
					//this.$store.state.error = response;	
					if(response.result){
						const xls = new XLS();
						xls.Seccion(data,response.result);
					}
					this.$store.commit('Loading');
				});		
			},
			Mostrar() {
				Consulta.Ini().then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.nucleos = result.consult.nucleos;				
					this.secs = result.consult.secs;
					this.turnos = result.consult.turnos;
					this.$store.commit('Loading');
				}); 
			},
			Validacion1() {
				this.plan = null;
				this.planes = [];
				this.periodo = null;
				this.periodos = [];
				Consulta.Procesar('Validacion1',{
					nucleo: this.nucleo.code,
				}).then(response => {
					//this.$store.state.error = response;	
					this.planes = response.result;
					this.$store.commit('Loading');
				});
			},
			Validacion2() {
				this.periodo = null;
				this.periodos = [];
				Consulta.Procesar('Validacion2',{
					plan: this.plan.code,
				}).then(response => {
					//this.$store.state.error = response;	
					this.periodos = response.result;
					this.$store.commit('Loading');
				});
			},
			Buscar() {
				if (this.nucleo != null && this.plan != null) {
					Consulta.Procesar('Buscar',{
						nucleo: 	this.nucleo.code,
						plan: 	this.plan.code,
						periodo: 	this.periodo.code,
					}).then(response => {
						//this.$store.state.error =response.result;
						this.products = response.result;
						this.$store.commit('Loading');
					}); 
				}
			},
			openNew() {
				this.titulo = '(Nuevo)';
				this.product = {};
				this.submitted = false;
				this.productDialog = true;
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			saveProduct() {
				if (this.product.sec == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Sección', life: 5000});
				} else if (this.product.turno == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el Turno', life: 5000});
				} else if (this.product.maximo == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la Capacidad', life: 5000});
				} else {			
					this.productDialog = false;
					if (this.product.id) {
						Consulta.Procesar('UPD',{
							id: 	this.product.id,
							nucleo: this.nucleo.code,
							plan: this.plan.code,
							periodo: this.periodo.code,
							maximo: this.product.maximo,
							turno: this.product.turno.code,
							sec: this.product.sec.code,
							activo: this.product.activo
						}).then(response => {
							//this.$store.state.error =response;
							this.$store.commit('Loading');
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Buscar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
						});
					} else {
						Consulta.Procesar('INS',{
							id: 	this.product.id,
							nucleo: this.nucleo.code,
							plan: this.plan.code,
							periodo: this.periodo.code,
							maximo: this.product.maximo,
							turno: this.product.turno.code,
							sec: this.product.sec.code,
							activo: this.product.activo
						}).then(response => {
							//this.$store.state.error =response;
							this.$store.commit('Loading');
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Buscar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
						});
					}

					this.productDialog = false;
					this.product = {};
				}
			},
			editProduct(product) {
				this.titulo = '(Editar)';
				this.product = {...product};
				this.product.plan = this.product.plan+' '+this.product.codigo;
				this.secs.forEach(element => {
					if(this.product.sec_id==element.code){
						this.product.sec = element;
					}
				});  
				this.turnos.forEach(element => {
					if(this.product.turno_id==element.code){
						this.product.turno = element;
					}
				});  
				this.productDialog = true;
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			deleteProduct() {
				this.products = this.products.filter(val => val.id !== this.product.id);
				Consulta.Procesar('DEL',{
					id: 	this.product.id
				}).then(response => {
					this.$store.commit('Loading');
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
				});
				this.deleteProductDialog = false;
				this.product = {};
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
				convfech(fecha){
					return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
				},

		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
