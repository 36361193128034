<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<Dropdown v-model="opcion" :options="opciones" optionLabel="nombre" placeholder="Seleccione..."/>
						<div v-if="opcion">
							<InputText type="number" min="0" v-model="buscar" required="true" placeholder="Cédula" v-if="opcion.code==1"/>
							<InputText type="text" v-model="buscar" style="width:250px;" required="true" placeholder="Apellido o Nombre" v-if="opcion.code==2"/>
							<Dropdown v-model="periodo" :options="periodos" optionLabel="per" placeholder="Seleccione..." v-if="opcion.code==3"/>
							<Dropdown v-model="nucleo" :options="nucleos" optionLabel="nombre" placeholder="Seleccione la Extensión ..." v-if="opcion.code==3"/>
							<Dropdown v-model="pendiente" :options="pendientes" optionLabel="nombre" placeholder="Seleccione..." v-if="opcion.code==3"/>
						</div>
					</template>				
					<template v-slot:right>
						<Button label="Buscar" icon="pi pi-search" class="p-button-success p-mr-2" @click="Buscar" v-if="opcion"/>
					</template>
				</Toolbar>
				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm" v-if="!detalles">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Recibos: {{products.length}}</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="fecha" header="Fecha" :sortable="true" headerStyle="width: 120px">
						<template #body="slotProps">
							{{slotProps.data.fecha}}
						</template>
					</Column>
					<Column field="id" header="Número" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="estudiante" header="N° Cédula" :sortable="true" headerStyle="width: 120px">
						<template #body="slotProps">
							{{slotProps.data.nac}}{{slotProps.data.estudiante}}
						</template>
					</Column>
					<Column field="nombre" header="Nombre y Apellido" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nombre}} {{slotProps.data.apellido}}
						</template>
					</Column>
						<Column header="Monto" headerStyle="width: 120px">	
						<template #body="{data}">
							<strong v-if="data.moneda==1">Ref.</strong>
							<img :src="'images/moneda'+data.moneda+'.png'" width="15" v-if="data.moneda"/>
							{{data.monto}}
						</template>
					</Column>
					<Column field="plan" header="Monto Bs." :sortable="true" headerStyle="width: 120px">
						<template #body="slotProps">
							Bs. {{new Intl.NumberFormat("de-DE").format((slotProps.data.recibidobs))}}
						</template>
					</Column>
					<Column header="Estatus" headerStyle="width: 120px">	
						<template #body="{data}">
							<span class="p-tag p-tag-success" v-if="data.pagada">Verificado</span>
							<span class="p-tag p-tag-danger" v-else>Por Verificar</span>
						</template>
					</Column>
					<Column header="Seleccionar" headerStyle="width: 120px">
						<template #body="{data}">
							<Button icon="pi pi-arrow-right" class="p-button-rounded p-button-success p-mr-2" @click="VerDetalles(data)" v-tooltip.bottom="'Seleccionar'"/>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>

		<div class="p-col-12 p-md-12" v-if="detalles">
			<div class="card">
				<Card>
					<template #title>
						<h5>Datos Personales</h5>
					</template>
					<template #content>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12 p-md-2">
								Nº Cedula: <strong>{{detalles.nac}}{{detalles.estudiante}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-8">
								Nombres y Apellidos: <strong>{{detalles.datos.nombre}} {{detalles.datos.apellido}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-2">
								Teléfono: <strong>{{detalles.datos.tlf_celular}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-6">
								Extensión: <strong>{{detalles.extension}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-6">
								Correo: <strong>{{detalles.datos.correo}}</strong>
							</div>
						</div>
					</template>
				</Card>
			</div>
		</div>
		<div class="p-col-12 p-md-12" v-if="detalles">
			<div class="card">
				<Card>
					<template #title>
						<h5>Recibo</h5>
					</template>
					<template #content>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12 p-md-3">
								<h5>Nº: <strong>{{detalles.id}}</strong></h5>
							</div>
							<div class="p-field p-col-12 p-md-3">
								<h5>Fecha: <strong>{{detalles.fecha}}</strong></h5>
							</div>
							<div class="p-field p-col-12 p-md-4">
								<h5>Usuario: <strong>{{detalles.edited}}</strong></h5>
							</div>
							<div class="p-field p-col-12 p-md-2">
								<span class="p-tag p-tag-success" v-if="detalles.pagada">Verificado</span>
								<span class="p-tag p-tag-danger" v-else>Por Verificar</span>
							</div>
							<div class="p-field p-col-12 p-md-6">
								<h5>Nº de Control: <strong>{{detalles.control}}</strong></h5>
							</div>
							<div class="p-field p-col-12 p-md-4">
								<h5>Nº de Factura: <strong>{{detalles.numero}}</strong></h5>
							</div>
							<div class="p-field p-col-12 p-md-2" v-if="detalles.pagada">
								<Button icon="pi pi-file-pdf" label="Imprimir Recibo" class="p-button-rounded p-button-secondary p-mr-2" @click="Ver2()"/>
							</div>
							<div class="p-field p-col-12 p-md-12">
								<h5>Conceptos de Pagos</h5>							
								<DataTable :value="detalles.deudas" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
									<Column header="ID">	
										<template #body="{data}">
											{{data.id}}<br>
										</template>
									</Column>
									<Column header="Fecha">	
										<template #body="{data}">
											{{convfech(data.fecha)}}<br>
										</template>
									</Column>
									<Column header="Descripción">	
										<template #body="{data}">
											{{data.descripcion}}<br>
										</template>
									</Column>
									<Column header="Monto">	
										<template #body="{data}">
											<strong v-if="data.moneda==1">Ref.</strong>
											<img :src="'images/moneda'+data.moneda+'.png'" width="15"/>
											{{data.monto}}
										</template>
									</Column>
								</DataTable>								
							</div>
							<div class="p-field p-col-12 p-md-12">
								<Button label="Agregar Pago" icon="pi pi-money-bill" class="p-button-info p-mr-2 p-mb-2" @click="CargarPago()" style="width: 200px;"/>
								<DataTable :value="detalles.pagos" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
									<Column header="Fecha">	
										<template #body="{data}">
											ID: {{data.id}}<br>
											<InputText v-model="data.fecha" type="date"/>
											<!--{{convfech(data.fecha)}}-->
										</template>
									</Column>
									<Column header="N° de referencia bancaria">	
										<template #body="{data}">
											{{data.nbanco}} <i class="pi pi-search" @click="IMG(url+'pagos/'+data.img)"></i> 
											<InputText v-model="data.referencia" type="number" placeholder="Número" min="0"/>
											<div v-if="data.movil>0">
												Pago Móvil, origen: {{data.movil}}
											</div> 
										</template>
									</Column>
									<Column header="Monto Bs">	
										<template #body="{data}">
											Bs. <InputNumber v-model="data.montobs" required="true" min="0.00001" :minFractionDigits="2"/>
											<div v-if="data.montobs2">
												<br>({{new Intl.NumberFormat("de-DE").format((data.montobs2))}})
											</div>
										</template>
									</Column>
									<Column header="Monto">	
										<template #body="{data}">
											<strong v-if="data.moneda==1">Ref.</strong>
											<img :src="'images/moneda'+data.moneda+'.png'" width="15"/>
											{{parseFloat(data.monto).toFixed(2)}}
										</template>
									</Column>
									<Column header="Tasa Bs">	
										<template #body="{data}">
											<strong>
												Bs. {{new Intl.NumberFormat("de-DE").format((data.tasa))}}
											</strong>
										</template>
									</Column>
									<Column header="Estatus">	
										<template #body="{data}">
											<InputSwitch v-model="data.verificado" v-if="data.tasa" @change="Calcular()" class="p-mr-2"/>
											<span class="p-tag p-tag-success p-mr-2" v-if="data.verificado">Verificado</span>
											<span class="p-tag p-tag-danger p-mr-2" v-else>Por Verificar</span>
											<Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-mr-2" @click="confirmDelete(data)"  v-if="!data.verificado"/>			
										</template>
									</Column>
								</DataTable>
							</div>
							<div class="p-field p-col-6 p-md-2">
								<h5>Monto:</h5>
							</div>
							<div class="p-field p-col-6 p-md-2">
								<h5>
									<strong v-if="moneda==1">Ref.</strong>
									<img :src="'images/moneda'+moneda+'.png'" width="15"/>
									<strong>{{parseFloat(detalles.montofinal).toFixed(2)}}</strong>
								</h5>
							</div>
							<div class="p-field p-col-6 p-md-2">
								<h5>Recibido: </h5>
							</div>
							<div class="p-field p-col-6 p-md-2">
								<h5>
									<strong>Bs. {{new Intl.NumberFormat("de-DE").format((detalles.recibidobs))}}</strong>
								</h5>
								<h5>
									<strong v-if="moneda==1">Ref.</strong>
									<img :src="'images/moneda'+moneda+'.png'" width="15"/>
									<strong>{{parseFloat(detalles.montob).toFixed(2)}}</strong>
								</h5>
							</div>
							<div class="p-field p-col-6 p-md-2">
								<h5 v-if="detalles.montofinal-detalles.montob>0.00001">Diferencia Pendiente:</h5>
								<h5 v-if="detalles.montofinal-detalles.montob<-0.00001">Diferencia a Favor:</h5>
							</div>
							<div class="p-field p-col-6 p-md-2">
								<h5 v-if="detalles.montofinal-detalles.montob>0.00001">
									<strong v-if="moneda==1">Ref.</strong>
									<img :src="'images/moneda'+moneda+'.png'" width="15"/>
									<strong>{{parseFloat(detalles.montofinal-detalles.montob).toFixed(2)}}</strong>
								</h5>
								<h5 v-if="detalles.montofinal-detalles.montob<-0.00001">
									<strong v-if="moneda==1">Ref.</strong>
									<img :src="'images/moneda'+moneda+'.png'" width="15"/>
									<strong>{{parseFloat(detalles.montob-detalles.montofinal).toFixed(2)}}</strong>
								</h5>
								<h5 v-if="moneda==1">Tasa: Bs. {{parseFloat($store.state.dolar).toFixed(2)}}</h5>
								<h5 v-if="moneda==2">Tasa: Bs. {{parseFloat($store.state.petro).toFixed(2)}}</h5>
							</div>

							<div class="p-field p-col-12 p-md-12" v-if="detalles.ajuste.length>0">
								<h5>Ajuste por Diferencia</h5>
								<DataTable :value="detalles.ajuste" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
									<Column header="Fecha">	
										<template #body="{data}">
											{{convfech(data.fecha_reg)}}<br>
										</template>
									</Column>
									<Column header="Descripción">	
										<template #body="{data}">
											{{data.descripcion}}<br>
										</template>
									</Column>
									<Column header="Monto Bs">	
										<template #body="{data}">
											Bs. {{new Intl.NumberFormat("de-DE").format(((-1)*data.montobs))}}
										</template>
									</Column>
								</DataTable>
							</div>
						</div>
						<OverlayPanel ref="op1" appendTo="body" :showCloseIcon="true">
							<img style="width: 350px;" :src="imagen+'.jpg'" @click="VIMG(imagen+'.jpg')"/>
						</OverlayPanel>
					</template>
					<template #footer>
						<Button label="Guardar" style="width: 110px;" icon="pi pi-save" class="p-button-secondary p-mr-2" @click="Guardar(detalles)"/>
						<Button label="Cancelar" style="width: 110px;" icon="pi pi-times" class="p-button-danger p-mr-2" @click="detalles=null"/>
					</template>
				</Card>
			</div>
		</div>

		<Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
			<div class="confirmation-content">
				<br><br>
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span v-if="product">Confirme si desea eliminar el pago Nº {{pago.id}}</span>
			</div>
			<template #footer>
				<Button label="Sí" style="width: 110px;" icon="pi pi-save" class="p-button-secondary p-mr-2" @click="Del()"/>
				<Button label="No" style="width: 110px;" icon="pi pi-times" class="p-button-danger p-mr-2" @click="deleteDialog = false"/>
			</template>
		</Dialog>

		<Dialog v-model:visible="Dialog" :style="{width: size}" header="Agregar Pago" :modal="true" class="p-fluid" :maximizable="true">
            <br>
            <div class="p-field">
				<div class="p-grid">
					<div class="p-col-8">
                        <label>Tipo</label>
                            <div v-for="(stats, index) in tipos" :key="index">
                                <RadioButton id="tipo" name="tipo" :value="stats.code" v-model="pago.tipo"/>
                                <strong> {{stats.nombre}}<br><br></strong>
                            </div>
					</div>
					<div class="p-col-4" v-if="pago.tipo">
                        <label>Fecha</label>
                        <InputText v-model="pago.fecha" type="date" :max="$store.state.fechaini"/>
					</div>
					<div class="p-col-7" v-if="pago.tipo">
                        <label v-if="pago.tipo==1">Banco de Tarjeta</label>
                        <strong v-if="pago.tipo==2">{{banco_destino.nombre}}</strong>
						<label v-if="pago.tipo==3 || pago.tipo==4">Banco de Origen</label>
						<Dropdown v-model="pago.banco_orig" :options="bancos" optionLabel="nombre" placeholder="Seleccione..." v-if="pago.tipo==1 || pago.tipo==3 || pago.tipo==4"/>
					</div>
					<div class="p-col-5" v-if="pago.tipo==4">
                        <label>N° de celular de origen</label>
                        <InputText v-model="pago.movil" type="number" placeholder="Número" min="0"/>
					</div>
					<div class="p-col-7" v-if="pago.tipo">
                        <label>N° de referencia bancaria</label>
                        <InputText v-model="pago.referencia" type="number" placeholder="Número" min="0"/>
					</div>
					<div class="p-col-5" v-if="pago.tipo">
                        <label>Monto Bs</label>
                        <InputNumber v-model="pago.montobs" required="true" min="0.00001" :minFractionDigits="2"/>
					</div>
                    <div class="p-field p-col-12 p-md-4" v-if="pago.tipo">
                        <FileUpload chooseLabel="Subir Foto JPG" uploadLabel="Cargar" mode="basic" :name="token" :url="url+'pagos.php'"  accept="image/jpeg" :maxFileSize="1000000" @upload="myUploader(token)" :auto="true" v-if="pago.tipo>1"/>
                    </div>
					<div class="p-field p-col-4 p-md-4" v-if="pago.tipo">
						<img style="width: 100px;" :src="url+'pagos/'+pago.img+'.jpg'" v-if="pago.img && pago.tipo>1"/>
					</div>
				</div>
			</div>
			<template #footer>
				<Button label="Agregar" icon="pi pi-check" class="p-button-secondary" @click="Agregar()" />
				<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="Dialog = false"/>
			</template>
		</Dialog>


		
	</div>
</template>

<script>
	import API from "../service/API";
	import Token from "uuid-token-generator";
	import PDF from "../service/PDF";
	const Consulta = new API('Pagos','PagosPreinscripcion');

	export default {
		data() {
			return {
				opcion: null,
				opciones: [
					{nombre: 'Buscar por Cédula', code: 1},
					{nombre: 'Buscar por Nombre o Apellido', code: 2},
					{nombre: 'Buscar por Extensión', code: 3},
				],
				nucleo: null,
				nucleos: [],
				pendiente: {nombre: 'Por Verificar', code: 0},
				pendientes:  [
					{nombre: 'Por Verificar', code: 0},
					{nombre: 'Verificados', code: 1},
				],
				buscar: null,
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				detalles: null,
				product: {},
				estudiante: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				pagos_pend: [],
				facturas: [],
				ajuste: [],
				imagen: null,
				deleteDialog: false,
				pago: {},
				token: null,
				bancos: [],
				banco_destino: {code: 3, nombre: 'Banco Mercantil'},
				tipos: [],
				Dialog: false,
				periodo: null,
				periodos: [],
				moneda: null,
			}
		},
		productService: null,
		created() {
			this.url = process.env.VUE_APP_API;
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				Consulta.Ini().then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.nucleos = result.consult.nucleos;
					this.bancos = result.consult.bancos;
					this.tipos = result.consult.tipos;		
					this.periodos = result.consult.periodos;		
					this.$store.commit('Loading');
				}); 
			},
			Calcular() {
				let recibidobs = 0;
				let monto = 0;
				this.detalles.pagos.forEach(element => {
					if(element.verificado){
						recibidobs = parseFloat(element.montobs) + parseFloat(recibidobs);
						monto = parseFloat(element.monto) + parseFloat(monto);
                    } 
				}); 
				this.detalles.recibidobs = recibidobs;
				this.detalles.montob = monto;
			},
			Ver(){
				const doc = new PDF();
				doc.Factura(this.detalles);
			},
			Ver2(){
				const doc = new PDF();
				doc.Recibo(this.detalles);
			},
			VIMG(data){
				window.open(data, '_blank');
			},
			onMenuToggle(event) {
				this.$emit('menu-toggle', event);
			},
			Buscar() {
				this.detalles = null;
                this.pagos_pend = [];
				this.facturas = [];
				this.ver = false;
				if ((this.buscar && this.opcion.code<=2) || (this.nucleo.code>0 && this.opcion.code==3)){
					Consulta.Procesar('Buscar',{
						id: 	this.buscar,
						opcion: this.opcion,
						nucleo: this.nucleo,
						periodo: this.periodo,
						pendiente: this.pendiente,
					}).then(response => {
						//this.$store.state.error =response;
						this.products = response.result;
						this.$store.commit('Loading');
					}); 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail:'Debes colocar los datos solicitados', 
						life: 3000
					});    
				}
			},
			VerDetalles(data) {
				this.detalles = null;
				this.moneda = data.moneda;
				this.ver = false;
				if ((this.buscar && this.opcion.code<=2) || (this.nucleo.code>0 && this.opcion.code==3)){
					Consulta.Procesar('Seleccion',{
						id: 		data.id,
						estudiante: data.estudiante,
					}).then(response => {
						//this.$store.state.error =response.result;
						this.detalles = response.result;
						this.detalles.montob = 0;
						this.detalles.recibidobs = 0;
						this.Calcular();
						this.$store.commit('Loading');
					}); 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail:'Debes colocar los datos solicitados', 
						life: 3000
					});    
				}
			},
			CargarPago() {
				if(this.detalles){
					const tokgen = new Token();
					this.token = tokgen.generate();
					this.pago = {
						fecha: this.$store.state.fechaini,
						tipo: null,
						banco_orig: null,
						banco: null,
						referencia: null,
						montobs: null,
						tasa: null,
						monto: null,
						moneda: this.moneda,
						img: null,
						movil: 0,
					};
					this.Dialog = true;
				}
			},
			myUploader(img) {
				Consulta.Procesar('ImgPagos',{
					img: img,
				}).then(response => {
					if(response.result){
						this.pago.img = this.token;
					}
					const tokgen = new Token();
					this.token = tokgen.generate();
					this.$store.commit('Loading');
				});
			},
			Agregar(){
				if (this.pago.tipo == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el tipo de pago', life: 5000});
				} else if (this.pago.fecha == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Indique la fecha del pago', life: 10000});
				} else if (this.pago.banco_orig == null && (this.pago.tipo == 1 || this.pago.tipo == 3)) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione el banco', life: 5000});
				} else if (this.pago.movil == 0 && this.pago.tipo == 4) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduz el número de celular de origen', life: 5000});
				} else if (this.pago.referencia == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el número de referencia bancaria', life: 5000});
				} else if (this.pago.montobs == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el monto del pago', life: 5000});
				} else if (this.pago.img == null && this.pago.tipo>1) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Debe cargar la imagen de la transferencia', life: 5000});
				} else {
					this.$store.commit('Loading');
					this.pago.banco = this.banco_destino;
					if (this.pago.tipo==2){
						this.pago.banco_orig = this.banco_destino;
					}
					this.tipos.forEach(element => {
						if(element.code == this.pago.tipo){
							this.pago.tipo = element;
						}
					}); 
					Consulta.Procesar('VerifPago',{
						factura: this.detalles.id,
						estudiante: this.detalles.estudiante,
						pago: this.pago,
						moneda: this.moneda,
					}).then(response => {
						//this.$store.state.error =response;
						this.$store.commit('Loading');
						if (response.result.verificacion && response.result.tasa>0) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Información Procesada', 
								life: 3000
							});
							this.pago.id = 0;
							this.pago.verificado = false;
							this.pago.tasa = response.result.tasa;
							this.pago.monto = parseFloat(this.pago.montobs) / parseFloat(this.pago.tasa);
							this.detalles.pagos.push(this.pago);
							this.Dialog = false;
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: response.result.mens, 
								life: 3000
							});
							this.Dialog = false;
						}
					});
				}
			},
			Guardar(data){
				Consulta.Procesar('UPD',{
					id: data.id,
					factura: data,
					moneda: this.moneda,
					recibmonto: this.detalles.montob,
					monto: this.detalles.montofinal
					}).then(response => {
						//this.$store.state.error =response;
						this.$store.commit('Loading');
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Información Procesada', 
								life: 3000
							});
							this.Buscar();
							this.VerDetalles(data);
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: 'No fue procesada la solicitud', 
								life: 3000
							});
						}
					});
			},
			confirmDelete(pago) {
				this.pago = pago;
				this.deleteDialog = true;
			},
			Del() {
				if (this.pago.id){
					Consulta.Procesar('EliminarPago',{
						id: 	this.pago.id,
					}).then(response => {
						//this.$store.state.error =response;
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Información Procesada', 
								life: 3000
							});
							this.detalles.pagos = this.detalles.pagos.filter(val => val.id !== this.pago.id);
							this.pago = {};
							this.deleteDialog = false;
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: 'No fue procesada la solicitud', 
								life: 3000
							});
						}
						this.$store.commit('Loading');
					}); 
				} else {
					this.detalles.pagos = this.detalles.pagos.filter(val => val.id !== this.pago.id); 
					this.deleteDialog = false;
				}
			},


			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}
				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
			convfech(fecha){
				return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
			},
			IMG(imegan){
				this.imagen = imegan;
				this.toggle1(event);
			},
			toggle1(event) {
				this.$refs.op1.toggle(event);
			},


		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
