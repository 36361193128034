import 'jspdf-autotable';

function Calificaciones(pdf,data,contenido) {
    pdf.setFontSize(14);
    pdf.setFont("italic", "bold");
    pdf.text(110, 50, "INSTITUTO UNIVERSITARIO DE TECNOLOGÍA INDUSTRIAL" , 'center');
    pdf.text(110, 57, '"RODOLFO LOERO ARISMENDI"', 'center');
    pdf.setFontSize(16);
    pdf.text(110, 70, "CERTIFICACION DE NOTAS", 'center');
    pdf.setFontSize(14);
    pdf.setFont("italic", "normal");
    let parraro1 = 'Quien suscribe, Dr. Richard Tucker Loero Director del INSTITUTO UNIVERSITARIO DE TECNOLOGÍA INDUSTRIAL "RODOLFO LOERO ARISMENDI" Certifica: que en el expediente de estudio No. '+data.nac+'-'+data.estudiante+' correspondiente al ciudadano';
    pdf.text(parraro1, 30, 90, {maxWidth: 160, lineHeightFactor: 1.5, align: "justify"}); 
    pdf.setFont("italic", "bold");   
    pdf.text(110, 125, data.nombre.toUpperCase()+' '+data.apellido.toUpperCase(), 'center');
    pdf.setFont("italic", "normal");
    let parraro2 = 'Titular de la Cédula de Identidad número '+data.nac+'-'+data.estudiante+' inscrito(a) en este INSTITUTO en la carrera';
    if(data.acta>0){
        parraro2 = 'Titular de la Cédula de Identidad número '+data.nac+'-'+data.estudiante+' egresado(a) de esta Instituciónen la carrera';
    }
    pdf.text(parraro2, 30, 135, {maxWidth: 160, lineHeightFactor: 1.5, align: "justify"})
    pdf.setFont("italic", "bold");   
    pdf.text(110, 155, contenido.programa.toUpperCase(), 'center');
    pdf.setFont("italic", "normal");
    let parraro3 = 'se encuentra un REGISTRO ACADEMICO donde consta que obtuvo las Calificaciones contenidas en el presente Documento.';
    pdf.text(parraro3, 30, 170, {maxWidth: 160, lineHeightFactor: 1.5, align: "justify"}); 
    let parraro4 = 'Se expide la presente Certificacion a solicitud de la parte interesada, en '+data.ciudad_nucleo+' '+contenido.fecha;
    pdf.text(parraro4, 30, 190, {maxWidth: 160, lineHeightFactor: 1.5, align: "justify"}); 
    pdf.text(150, 220, contenido.firma.nombre, 'center');
    pdf.text(150, 225,contenido.firma.cargo, 'center'); 
    pdf.setFont("italic", "bold");   
    var validado = contenido.usuario.nombre.split(' ');
    pdf.text(30, 250, 'Preparado por: '+validado[0].toUpperCase());
    pdf.setFont("italic", "normal");
    pdf.addPage();

    var calif = contenido.calificaciones.filter(val => val.aprobada ==  true);
    const head1 = [['Nº', 'Código','Asignatura', 'Creditos', 'Calificación', 'Período', 'Rég.']];
    var asigporuc = 0;
    var cursadasuc = 0;
    var definitiva = 0;
    var body1 = [];
    for (var i = 0; i < calif.length; i++) {
        if(calif[i].aprobada && calif[i].tipoasig==7){
            definitiva = 'Aprobado';
        } else if(calif[i].aprobada && calif[i].tipo_id==5){
            definitiva = 'Equivalencia';
        } else {
            definitiva = calif[i].definitiva;
        }
        cursadasuc = parseFloat(cursadasuc) + parseFloat(calif[i].uc);
        asigporuc = parseFloat(asigporuc) + parseFloat(calif[i].uc*calif[i].definitiva);
        body1.push([
            i+1,
            calif[i].cod,
            calif[i].asignatura,
            calif[i].uc,
            definitiva,
            calif[i].periodo,
            calif[i].esc,
        ]);
        if((i+1) < calif.length){
            if(calif[i].sem != calif[i+1].sem ){
                body1.push([null, null,null,null,null,null,null,null]);
            }
        }
    }

    pdf.autoTable({
        head: head1,
        body: body1,
        startY: 60,
        margin: {
            top: 60,
            right: 15,
            left: 20,
            bottom: 45
        },
        headerStyles: {
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            halign: 'center'
        },                     

        styles: {
            lineColor: [255, 255, 255],
            lineWidth: 0.1,
            textColor: [0, 0, 0],
            fontSize: 10,
            cellPadding: 1,
        },

        columnStyles: {
            0: {cellWidth: 10, halign: 'center'},
            1: {cellWidth: 20, halign: 'center'},
            2: {cellWidth: 70},
            3: {cellWidth: 17, halign: 'center'},
            4: {cellWidth: 25, halign: 'center'},
            5: {cellWidth: 18, halign: 'center'},
            6: {cellWidth: 10, halign: 'center'},
            7: {cellWidth: 18, halign: 'center'},

          }
    });

    pdf.setFontSize(8);

    // PAGE NUMBERING
    // Add Page number at bottom-right
    // Get the number of pages
    const pageCount = pdf.internal.getNumberOfPages();

    // For each page, print the page number and the total pages
    for(var k = 1; k <= pageCount; k++) {

        // Go to page i
        pdf.setPage(k);

        if (k >=2){
            pdf.addImage("logo.jpg", 'JPEG', 5, 5, 15, 15);
            pdf.setFontSize(12);
            pdf.setFont("italic", "bold");
            pdf.text(100, 10, "INSTITUTO UNIVERSITARIO DE TECNOLOGÍA INDUSTRIAL" , 'center');
            pdf.text(100, 15, "RODOLFO LOERO ARISMENDI (IUTIRLA)", 'center');
            pdf.text(100, 20, "RIF.: J-00130751-6", 'center');
            var parraf = contenido.parrafos;  
            for (var j = 0; j < parraf.length; j++) {
                pdf.setFontSize(parraf[j].tam);
                if(parraf[j].normal){pdf.setFont("arial", "bold");} else {pdf.setFont("arial", "");}
                if(parraf[j].alin=='center'){
                    pdf.text((100+parraf[j].izq), parraf[j].alt, parraf[j].contenido, 'center',{lineHeightFactor: parseFloat(parraf[j].interlineado)});
                } else {
                    pdf.text(parraf[j].contenido, parraf[j].izq, parraf[j].alt, {align: parraf[j].alin,lineHeightFactor: parseFloat(parraf[j].interlineado), maxWidth: (215-2*parraf[j].izq)});
                }
            }
            pdf.setDrawColor(0, 0, 0);
            pdf.setLineWidth(0.5); 
            pdf.line(15, 57, 200, 57);    
            pdf.line(15, 265, 200, 265);  
            
            //Firma
            pdf.setFontSize(12);
            pdf.text(150, 250, contenido.firma.nombre, 'center');
            pdf.text(150, 255,contenido.firma.cargo, 'center');      
        }




        if (k == pageCount){

            pdf.setFontSize(9);

            pdf.text(25, 200, "Materias Cursadas: "+data.asigs);
            pdf.text(90, 200, "Materias Aprobadas: "+data.tasig);
            pdf.text(155, 200, "U.C. Aprobadas: "+data.tuc);

            pdf.text(25, 205, "Índice de Rendimiento Académico: "+parseFloat(asigporuc/cursadasuc).toFixed(2));
            pdf.text(155, 205, "Índice de Grado Académico: "+parseFloat(asigporuc/cursadasuc).toFixed(2));
            
            pdf.setFont("italic", "bold");
            pdf.text(25, 210, "RÉGIMEN");
            pdf.text(50, 210, "ESCALA DE CALIFICACIÓN");
            pdf.text(110, 210, "MÍNIMA APROBATORIA");
            pdf.text(160, 210, "GACETA OFICIAL");

            pdf.setFont("italic", "normal");
            pdf.text(32, 215, "1");
            pdf.text(67, 215, "1 al 9");
            pdf.text(117, 215, "Cinco (5) Puntos");
            pdf.text(161, 215, "Nº 30348 09/03/1974");

            pdf.text(32, 220, "2");
            pdf.text(67, 220, "1 al 20");
            pdf.text(117, 220, "Doce (12) Puntos");
            pdf.text(161, 220, "Nº 36568 27/10/1998");

            pdf.text(32, 225, "3");
            pdf.text(67, 225, "1 al 20");
            pdf.text(117, 225, "Diez (10) Puntos");
            pdf.text(161, 225, "Nº 5342 06/05/1999");
            
            pdf.setFontSize(10);
            pdf.text('Constancia que se expide en '+data.ciudad_nucleo+', a los '+contenido.fecha+'.', 25, 230, {maxWidth: 170, align: "justify"});

        }
        pdf.setFontSize(9);
        //Print Page 1 of 4 for example
        pdf.text('Página ' + String(k) + ' de ' + String(pageCount),35,270,null,null,"right");
        pdf.text(100, 270,'www.iutirla.com', 'center');
        pdf.text('Fecha de Impresión: '+localStorage.getItem('Fecha'),200,270,null,null,"right");

    }
    
}

export default Calificaciones;