<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12" v-if="!detalles">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<Dropdown v-model="nucleo" :options="nucleos" optionLabel="nombre" placeholder="Seleccione la Extensión ..." @change="Validacion()"/>
						<Dropdown v-model="plan" :options="planes" optionLabel="nombre" placeholder="Seleccione..." v-if="nucleo"  @change="Buscar()"/>
						<Dropdown id="state" v-model="opcion" :options="opciones" optionLabel="nombre" placeholder="Selecciona..." v-if="datos.length>0" @change="Opcion()" ></Dropdown>
					</template>
					<template v-slot:right>
                        <Button label="Export" icon="pi pi-upload" class="p-button-help p-mr-2" @click="VerXLS()" v-if="products.length>0"/>					
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="Guardar()" v-if="products.length>0"/>
                    </template>
				</Toolbar>

				<DataTable :value="products" ref="dt" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Estudiantes: {{products.length}}</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="estudiante" header="Nº Cédula" :sortable="true" headerStyle="width: 120px">
						<template #body="slotProps">
							{{slotProps.data.estudiante}}
						</template>
					</Column>
					<Column field="nombre_apellido" header="Nombres y Apellidos" :sortable="true" headerStyle="width: 350px">
						<template #body="slotProps">
							{{slotProps.data.nombre_apellido}}
						</template>
					</Column>
					<Column field="cod" header="Plan" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.cod}}
						</template>
					</Column>
					<Column field="periodo" header="P. Inicio" headerStyle="width: 120px">
						<template #body="slotProps">
							{{slotProps.data.periodo}}
						</template>
					</Column>
					<Column field="periodofin" header="P. Culminación" headerStyle="width: 120px">
						<template #body="slotProps">
							{{slotProps.data.periodofin}}
						</template>
					</Column>
					<Column field="activo" header="TUC" headerStyle="width: 120px">
						<template #body="slotProps">
							{{slotProps.data.tuc}}uc
						</template>
					</Column>
					<Column header="Opciones" headerStyle="width: 150px">
						<template #body="slotProps">
							<Button icon="bi bi-journal-bookmark" class="p-button-rounded p-button-success p-mr-2" @click="openCalificaciones(slotProps.data)" v-tooltip.bottom="'Calificaciones'"/>
							<Button icon="pi pi-file-pdf" class="p-button-rounded p-button-warning p-mr-2" v-tooltip.bottom="'Record'" @click="VerRecord(slotProps.data)"/>
							<span class="p-overlay-badge p-mr-5" @click="EditRecaudos(slotProps.data)">
								<i class="pi pi-pencil" style="font-size: 2em"></i>
								<span class="p-badge">{{slotProps.data.recaudo}}%</span>
							</span>
						</template>
					</Column>
					<Column header="V°B°" headerStyle="width: 50px">
						<template #body="slotProps">
							<Checkbox id="binary" v-model="slotProps.data.posg" :binary="true" />
						</template>
					</Column>
				</DataTable>
			</div>
		</div>

		<Dialog v-model:visible="productDialog" :style="{width: size}" header="Recaudos" :modal="true" class="p-fluid" :maximizable="true">
			<br>
            <div class="p-field">
				<div class="p-grid">
					<div class="p-col-9">
						<strong>Estudiante: </strong>{{estudiante.apellido+', '+estudiante.nombre}}
					</div>
					<div class="p-col-3">
						<strong>N° Cédula: </strong>{{estudiante.nac+estudiante.estudiante}}
					</div>
					<div class="p-col-9">
						<strong>Plan de Estudio: </strong>{{estudiante.plan}}
					</div>
					<div class="p-col-3">
						<strong>Telf: </strong>{{estudiante.tlf_local}}
					</div>
					<div class="p-col-9">
						<strong>Correo: </strong>{{estudiante.correo}}
					</div>
					<div class="p-col-3">
						<strong>Celular: </strong>{{estudiante.tlf_celular}}
					</div>
					<div class="p-col-12">
						<strong>Observación: </strong>{{estudiante.observacion}}
					</div>
					<div class="p-col-3">
						<strong>P. Inicio: </strong>{{estudiante.periodo}}
					</div>
					<div class="p-col-3">
						<strong>UC Cursadas: </strong>{{estudiante.cursado.uc}}
					</div>
					<div class="p-col-3">
						<strong>Asignaturas Cursadas: </strong>{{estudiante.cursado.asig}}
					</div>
					<div class="p-col-3" v-if="estudiante.calificaciones.length>0">
						<strong>ÍRA: </strong>{{CalcularIA(estudiante.calificaciones)}}
					</div>
					<div class="p-col-3">
						<strong>P. Culminación: </strong>{{estudiante.periodofin}}
					</div>
					<div class="p-col-3">
						<strong>UC Aprobadas: </strong>{{estudiante.tuc}}
					</div>
					<div class="p-col-3">
						<strong>Asignaturas Aprobadas: </strong>{{estudiante.asigs}}
					</div>
					<div class="p-col-3" v-if="estudiante.calificaciones.length>0">
						<strong>Índice de Grado: </strong>{{CalcularIAG(estudiante.calificaciones)}}
					</div>
				</div>
				<br>
				<div class="p-grid">
					<h5><strong>Recaudos</strong></h5>
					<div class="p-col-12">
						<div class="p-grid">
							<div class="p-col-12"  v-for="(stats, index) in estudiante.recaudos" :key="index">
								<div class="p-grid">
									<div class="p-col-9">
										<strong>{{index+1}}. </strong> {{stats.nombre}}
									</div>
									<div class="p-col-3">
										<InputSwitch :id="index" v-model="estudiante.recaudos[index].estatus" />
									</div>
								</div>
							</div>
						</div>	
					</div>
				</div>
			</div>
			<template #footer>
				<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="SaveRecaudos()" />
				<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="productDialog = false"/>
			</template>
		</Dialog>
		<Calificaciones :calificaciones="calificaciones" :matricula="matricula" :display="displayCalificaciones" @aprob="onMenuToggle" @close-calificaciones="close"/>
	</div>

</template>

<script>
	import API from "../service/API";
	import PDF from "../service/PDF";
	import XLS from "../service/XLS";
	import Calificaciones from '../components/VerCalificaciones.vue';
	const Consulta = new API('Egreso','PosiGrad');

	export default {
		components: {
			'Calificaciones': Calificaciones,
		},
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				productDialog: false,
				product: {},
				datos: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				nucleo: null,
				nucleos: [],
				plan: null,
				planes: [],
				periodo: null,
				periodos: [],
				detalles: null,
				visibletitulo: false,
				opcion: {nombre: 'Todas', code: 0},
				opciones: [
					{nombre: 'Todas', code: 0},
					{nombre: 'Selección', code: 1},
					{nombre: 'No Selección', code: 2},
				],
				matricula: {},
				displayCalificaciones: false,
				calificaciones: [],
				estudiante: {},
			}
		},
		productService: null,
		created() {
			this.url = process.env.VUE_APP_API;
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			onMenuToggle(event) {
				this.$emit('menu-toggle', event);
			},
			close() {
				this.matricula = {};
				this.displayCalificaciones = false;
			},
			Ver(){
				const doc = new PDF();
				doc.Preinscritos(this.nucleo,this.periodo,this.products);
			},
			VerXLS(){
				var datos = [];
				if(this.opcion.code==1){
					datos = this.datos.filter(val => val.posg == true);
				} else if(this.opcion.code==2){
					datos = this.datos.filter(val => val.posg != true);
				} else {
					datos = this.datos;
				}
				const xls = new XLS();
				xls.PosiGrad(this.nucleo.nombre,this.plan.nombre,datos);
			},
			VIMG(data){
				window.open(data, '_blank');
			},
			Mostrar() {
				Consulta.Ini().then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.nucleos = result.consult.nucleos;	
					this.$store.commit('Loading');
				}); 
			},
			Validacion() {
				this.plan = null;
				this.planes = [];
                this.products = [];
				Consulta.Procesar('Validacion',{
					nucleo: this.nucleo.code,
				}).then(response => {
					//this.$store.state.error = response.result;	
					this.planes = response.result.planes;
                    this.$store.commit('Loading');
				});
			},
			Buscar() {
				if (this.nucleo != null && this.plan != null) {
					this.detalles = null;
                    this.products = [];
					Consulta.Procesar('Buscar',{
						nucleo: 	this.nucleo.code,
						plan: 	    this.plan.code,
					}).then(response => {
						//this.$store.state.error = response.result[0];
						this.products = response.result;
						this.datos = response.result;
						this.$store.commit('Loading');
					}); 
				}
			},
			Opcion() {
				if(this.opcion.code==1){
					this.products = this.datos.filter(val => val.posg == true);
				} else if(this.opcion.code==2){
					this.products = this.datos.filter(val => val.posg != true);
				} else {
					this.products = this.datos;
				}
			},
			openCalificaciones(matricula) {
				this.$store.commit('Mobile');
				this.matricula = {...matricula};					
				Consulta.Procesar('VerCalificaciones',{
					id: 	this.matricula.id,
				}).then(response => {
					//this.$store.state.error =response.result;
					this.calificaciones = response.result;
					this.displayCalificaciones = true;
					this.$store.commit('Loading');
				}); 
			},
			VerRecord(matricula){
				this.$store.commit('Mobile');
				this.matricula = {...matricula};					
				Consulta.Procesar('VerCalificaciones',{
					id: 	this.matricula.id,
				}).then(response => {
					//this.$store.state.error = response.result;
					this.$store.commit('Loading');
					const doc = new PDF();
					doc.Record(this.matricula,response.result);
				}); 
			},
			EditRecaudos(estudiante) {
				this.estudiante = {};
				this.estudiante = {...estudiante};
				this.estudiante.recaudos = [];
				this.estudiante.calificaciones = [];
				this.estudiante.ia = null;
				this.productDialog = true;
				Consulta.Procesar('Recaudos',{
					id: 	this.estudiante.id,
					tipo: 	this.estudiante.tipo,
				}).then(response => {
					//this.$store.state.error = response.result;
					this.estudiante.recaudos = response.result.recaudos;
					this.estudiante.calificaciones = response.result.calificaciones;
					this.$store.commit('Loading');
				}); 
			},
			SaveRecaudos() {
				Consulta.Procesar('SaveRecaudos',{
					estudiante: 	this.estudiante,
				}).then(response => {
					//this.$store.state.error = response.result;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.productDialog = false;
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
					this.$store.commit('Loading');
				}); 
			},
			Guardar() {
				Consulta.Procesar('UPD',{
					estudiantes: 	this.products,
				}).then(response => {
					//this.$store.state.error = response.result;
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Buscar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
					this.$store.commit('Loading');
				}); 
			},
			CalcularIAG(data){
				var asigporuc = 0
				var cursadasuc = 0
				for (var i = 0; i < data.length; i++) {
					if(data[i].aprobada && data[i].tipo_id<5){
						cursadasuc = parseFloat(cursadasuc) + parseFloat(data[i].uc);						
						asigporuc = parseFloat(asigporuc) + parseFloat(data[i].uc*data[i].definitiva);
					} 
				}
				return parseFloat(asigporuc/cursadasuc).toFixed(2);
			},
			CalcularIA(data){
				var asigporuc = 0
				var cursadasuc = 0
				for (var i = 0; i < data.length; i++) {
					if(data[i].tipo_id<5){
						cursadasuc = parseFloat(cursadasuc) + parseFloat(data[i].uc);
						asigporuc = parseFloat(asigporuc) + parseFloat(data[i].uc*data[i].definitiva);
					}
				}
				return parseFloat(asigporuc/cursadasuc).toFixed(2);
			}































		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
