<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
                    <InputText type="number" min="0" v-model="buscar" required="true" placeholder="Cédula" @keyup.enter="Buscar" v-if="opcion"/>
                    <InputText type="text" v-model="buscar" style="width:250px;" required="true" placeholder="Apellido o Nombre" @keyup.enter="Buscar" v-if="!opcion"/>
                    <InputSwitch v-model="opcion" v-tooltip.bottom="'Cédula <-> Nombre'"/>				
					</template>				
					<template v-slot:right>
						<Button label="Buscar" icon="pi pi-search" class="p-button-success p-mr-2" @click="Buscar"/>
					</template>
				</Toolbar>
				<DataTable :value="matriculas" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                    dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters" v-if="matriculas.length>0">
					<template #header>
						<h4>{{estudiante.nac}}{{estudiante.id}} {{estudiante.apellido}}, {{estudiante.nombre}} ({{estudiante.nucleo}})</h4>
					</template>
					<Column field="plan" header="Plan de Estudio" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.plan}}
						</template>
					</Column>
					<Column field="periodo" header="Período" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.periodo}}
						</template>
					</Column>
					<Column field="turno" header="Turno" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.turno}}
						</template>
					</Column>
					<Column field="seccion" header="Sección" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.sec}}
						</template>
					</Column>
					<Column field="activo" header="Estatus">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
						</template>
					</Column>
				</DataTable>              
				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm" v-else>
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Estudiantes</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<!--<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>-->
					<Column field="nucleo" header="Extensión" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nucleo}}
						</template>
					</Column>
					<Column field="id" header="N° Cédula" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nac}}{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="nombre" header="Nombre" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="apellido" header="Apellido" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.apellido}}
						</template>
					</Column>
					<Column field="activo" header="Estatus">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
						</template>
					</Column>
					<Column header="Seleccionar">
						<template #body="slotProps">
							<Button icon="pi pi-arrow-right" class="p-button-rounded p-button-success p-mr-2" @click="VerMatricula(slotProps.data)" v-tooltip.bottom="'Seleccionar'"/>
						</template>
					</Column>
				</DataTable>
                <br>
		</div>
		<div class="p-col-12" v-if="ver">
				<DataTable :value="saldos" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm" v-if="!detalles">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Saldos a Favor: {{saldos.length}}</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="id" header="ID" :sortable="true"  headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.id}}
						</template>
					</Column>
                    <Column field="fecha" header="Fecha" headerStyle="width: 150px">
						<template #body="slotProps">
                            {{slotProps.data.fecha}}
						</template>
					</Column>
                    <Column field="factura" header="Recibo N°" headerStyle="width: 150px">
						<template #body="slotProps">
							{{slotProps.data.ajuste}}
							<div><br>Asigna a: {{slotProps.data.factura}}</div>
						</template>
					</Column>
                    <Column field="numero" header="Factura N°" :sortable="true"  headerStyle="width: 150px">
						<template #body="slotProps">
                            {{slotProps.data.numero}}
						</template>
					</Column>
					<Column field="recibidobs" header="Monto Bs." headerStyle="width: 120px">
						<template #body="slotProps">
							Bs. {{new Intl.NumberFormat("de-DE").format(((-1)*slotProps.data.montobs))}}
						</template>
					</Column>
					<Column header="Estatus" headerStyle="width: 120px">	
						<template #body="{data}">
							<span class="p-tag p-tag-success" v-if="data.pagada">Pagada</span>
							<span class="p-tag p-tag-danger" v-else>Por Verificar</span>
						</template>
					</Column>
					<Column header="Opciones" headerStyle="width: 100px">
						<template #body="slotProps">
							<Button label="Liberar" icon="pi pi-arrow-right" class="p-button-warning p-mr-2" @click="Liberar(slotProps.data)" v-if="slotProps.data.factura" :disabled="slotProps.data.numero>0"/>
							<Button label="Asignar" icon="pi pi-arrow-right" class="p-button-info p-mr-2" @click="Asignar(slotProps.data)" v-else/>
						</template>                 
					</Column>
				</DataTable>
		</div>

		<Dialog v-model:visible="LiberarDialog" :style="{width: size}" header="Liberar" :modal="true">
			<br>
            <div class="confirmation-content">
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span v-if="product">¿Estás seguro que quieres liberar?</span>
			</div>
			<template #footer>
				<Button label="Sí" icon="pi pi-check" class="p-button-secondary" @click="LiberarDef" />
				<Button label="No" icon="pi pi-times" class="p-button-danger" @click="LiberarDialog = false"/>
			</template>
		</Dialog>

		<Dialog v-model:visible="AsignarDialog" :style="{width: size}" header="Liberar" :modal="true">
			<br>
			<h5 class="p-m-0">Recibos Pendientes por Validar</h5>
            <div class="confirmation-content">
				<DataTable :value="facturas2" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<Column field="id" header="Recibo N°">
						<template #body="slotProps">
							{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="fecha" header="Fecha">
						<template #body="slotProps">
							{{slotProps.data.fecha}}
						</template>
					</Column>
					<Column header="Estatus">	
						<template #body="{data}">
							<span class="p-tag p-tag-success" v-if="data.pagada">Pagada</span>
							<span class="p-tag p-tag-danger" v-else>Por Verificar</span>
						</template>
					</Column>
					<Column header="Seleccionar">
						<template #body="slotProps">
							<Button icon="pi pi-arrow-right" class="p-button-rounded p-button-success p-mr-2" @click="AsignarDef(slotProps.data)" v-tooltip.bottom="'Seleccionar'"/>
						</template>
					</Column>
				</DataTable>
			</div>
			<template #footer>
				<Button label="No" icon="pi pi-times" class="p-button-danger" @click="AsignarDialog = false"/>
			</template>
		</Dialog>

        
	</div>

</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Pagos','SaldoFavor');

	export default {
		data() {
			return {
				ver: false,
				LiberarDialog: false,
				AsignarDialog: false,
				buscar: null,
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				matriculas: [],
				product: {},
				estudiante: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				saldos: [],
				facturas: [],
				facturas2: [],
				monto: 0
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
		},
		mounted() {
			
		},
		methods: {
			onMenuToggle(event) {
				this.$emit('menu-toggle', event);
			},
			Buscar() {
				this.matriculas = [];
                this.saldos = [];
				this.ver = false;
				if (this.buscar){
					Consulta.Procesar('Buscar',{
						id: 	this.buscar,
					}).then(response => {
						//this.$store.state.error =response;
						this.products = response.result;
						this.$store.commit('Loading');
					}); 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail:'Debes colocar los datos solicitados3s', 
						life: 3000
					});    
				}
			},
			VerMatricula(estudiante) {
				this.$store.commit('ValorPetro');
				this.saldos = [];
                this.facturas = [];
				this.estudiante = {};
				this.estudiante = {...estudiante};
				Consulta.Procesar('Matricula',{
					id: 	this.estudiante.id,
					nucleo: 	this.estudiante.nucleo_id,
				}).then(response => {
					//this.$store.state.error = response.result.saldos;
					this.matriculas = response.result.matriculas;
                    this.saldos = response.result.saldos;
                    this.facturas = response.result.facturas;
					this.ver = true;
					this.$store.commit('Loading');
				}); 
			},
			Liberar(product) {
				this.product = {...product};
				this.LiberarDialog = true;
			},
			Asignar(product) {
				this.product = {...product};
				this.facturas2 = this.facturas.filter(val => val.id !== product.ajuste);
				this.AsignarDialog = true;
			},
			LiberarDef(){
				Consulta.Procesar('Liberar',{
					product: 	this.product,
				}).then(response => {
					//this.$store.state.error = response;
					this.$store.commit('Loading');
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.LiberarDialog = false;
						this.VerMatricula(this.estudiante);
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
				}); 
			},
			AsignarDef(selec){
				Consulta.Procesar('Asignar',{
					product: this.product.id,
					selec: 	selec.id,
				}).then(response => {
					//this.$store.state.error = response;
					this.$store.commit('Loading');
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.AsignarDialog = false;
						this.VerMatricula(this.estudiante)
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
				}); 

			},

			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}
				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
