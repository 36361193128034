<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<InputText type="number" min="0" v-model="buscar" required="true" placeholder="Cédula" @keyup.enter="Buscar" v-if="opcion"/>
						<InputText type="text" v-model="buscar" style="width:250px;" required="true" placeholder="Apellido o Nombre" @keyup.enter="Buscar" v-if="!opcion"/>
						<InputSwitch v-model="opcion" v-tooltip.bottom="'Cédula <-> Nombre'"/>				
					</template>
					<template v-slot:right>
						<Button label="Buscar" icon="pi pi-search   " class="p-button-success p-mr-2" @click="Buscar"/>
					</template>			
				</Toolbar>
				<DataTable :value="matriculas" :paginator="true" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                        :rows="25" dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters" v-if="matriculas.length>0">
					<template #header>
						<h4>
							{{estudiante.nac}}{{estudiante.id}} {{estudiante.apellido}}, {{estudiante.nombre}} ({{estudiante.nucleo}})		
						</h4>
					</template>
					<Column field="plan" header="Plan de Estudio">
						<template #body="slotProps">
							{{slotProps.data.plan}}
						</template>
					</Column>
					<Column field="periodo" header="Período" headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.periodo}}
						</template>
					</Column>
					<Column field="turno" header="Turno"  headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.turno}}
						</template>
					</Column>
					<Column field="seccion" header="Sección"  headerStyle="width: 100px">
						<template #body="slotProps">
							{{slotProps.data.sec}}
						</template>
					</Column>
					<Column field="activo" header="Estatus"  headerStyle="width: 100px">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
						</template>
					</Column>
					<Column field="planes" header="Consultar" headerStyle="width: 100px">
						<template #body="slotProps">
							<Button icon="pi pi-eye" class="p-button-rounded p-button-warning" v-tooltip.bottom="'Ver'" @click="Consultar(slotProps.data)"/>
						</template>
					</Column>
				</DataTable>
				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm" v-else>
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Estudiantes</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="nucleo" header="Extensión" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nucleo}}
						</template>
					</Column>
					<Column field="id" header="N° Cédula" :sortable="true" headerStyle="width: 110px">
						<template #body="slotProps">
							{{slotProps.data.nac}}{{slotProps.data.id}}
						</template>
					</Column>
					<Column field="nombre" header="Nombre" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="apellido" header="Apellido" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.apellido}}
						</template>
					</Column>
					<Column field="activo" header="Estatus" headerStyle="width: 100px">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
						</template>
					</Column>
					<Column header="Seleccionar" headerStyle="width: 100px">
						<template #body="slotProps">
							<Button icon="pi pi-arrow-right" class="p-button-rounded p-button-success p-mr-2" @click="VerMatricula(slotProps.data)" v-tooltip.bottom="'Seleccionar'"/>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>

		<Dialog v-model:visible="display" :style="{width: size}" header="Documentos a Imprimir" :modal="true" class="p-fluid" :maximizable="true">
            <br>
            <div v-for="category in documentos" :key="category.key" style="height: 6vh;align-content: top;">
				<RadioButton v-model="selec" :inputId="category.key" name="dynamic" :value="category" />
                <label :for="category.key" class="p-ml-2">{{ category.nombre }}</label>
                <strong v-if="category.opcs.length>0">
                    <InputSwitch v-model="category.opc" style="height: 1rem;" class="p-ml-2"/>
                    <strong v-if="category.opc"  class="p-ml-2">( {{ category.opcs[0] }} )</strong>
                    <strong v-else  class="p-ml-2">( {{ category.opcs[1] }} )</strong>
                </strong>
                <br>
            </div>
			<Divider/>
			<div v-if="selec" class="p-grid">
				<div class="p-col-12 p-md-3">
					<Checkbox v-model="auxiliar.activo" :binary="true" /> Firma Auxiliar
				</div>
				<div v-if="selec && auxiliar.activo" class="p-col-12 p-md-9">
					<InputText v-model="auxiliar.nombre" type="text" placeholder="Nombre" />
					<InputText v-model="auxiliar.cargo" type="text" placeholder="Cargo" />
				</div>
            </div>
			<Divider/>
			<div v-if="selec" class="p-grid">
				<div class="p-col-12 p-md-3" v-if="selec.id==2 || selec.id==3">
					Dirigida a:
				</div>
				<div v-if="selec.id==2 || selec.id==3" class="p-col-12 p-md-9">
					<InputText v-model="empresa.nombre" type="text" placeholder="Nombres y Apellidos"/>
					<InputText v-model="empresa.institucion" type="text" placeholder="Nombre de la Institución " />
					<InputText v-model="empresa.cargo" type="text" placeholder="Cargo " />
					<InputText v-model="empresa.fecha" type="text" placeholder="Fecha incio de pasantía" />
				</div>
            </div>
			<template #footer>        
				<Button label="Mostrar" icon="pi pi-file-pdf" class="p-button-success" @click="Imprimir(selec)" :disabled="selec==null"/>
				<Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="display = false"/>
			</template>
		</Dialog>

	</div>

</template>

<script>
	import API from "../service/API";
	import IMP from "../imprimir";
	import Divider from 'primevue/divider';
	const Consulta = new API('Solicitudes','Impresion');

	export default {
		components: {
			Divider: Divider,
		},
		data() {
			return {
				nuevo: false,
				buscar: null,
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				matriculas: [],
				product: {},
				estudiante: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				matricula: {},
				display: false,
                documentos: [
                    {id: 1,  ventana: 'CAR', nombre: '01. Carnet', opc: null, opc2: null, opcs: []},
                    {id: 2,  ventana: 'POT', nombre: '02. Carta de Postulación', opc: null, opc2: null, opcs: []},
                    {id: 3,  ventana: 'PRE', nombre: '03. Carta de Presentación', opc: null, opc2: null, opcs: []},
                    {id: 4,  ventana: 'ACT', nombre: '04. Certificación de Acta de Grado (Papel Sellado)', opc: null, opc2: null, opcs: ['Nacional','Internacional']},
                    {id: 5,  ventana: 'CAL', nombre: '05. Certificación de Calificaciones', opc: null, opc2: null, opcs: ['Nacional','Internacional']},
                    {id: 6,  ventana: 'NFN', nombre: '06. Certificación de Notas Fondo Negro', opc: null, opc2: null, opcs: ['Nacional','Internacional']},
                    {id: 21, ventana: 'PAN', nombre: '07. Certificación de Programa', opc: null, opc2: null, opcs: ['Nacional','Internacional'], opcs2: ['Carrera','Semestre']},
                    {id: 7,  ventana: 'RPA', nombre: '08. Certificación de Recibo de Pago', opc: null, opc2: null, opcs: []},
                    {id: 8,  ventana: 'TIT', nombre: '09. Certificación de Título', opc: null, opc2: null, opcs: ['Nacional','Internacional']},
                    {id: 9,  ventana: 'BCO', nombre: '10. Constancia de Buena Conducta', opc: null, opc2: null, opcs: ['Nacional','Internacional']},
                    {id: 10, ventana: 'CAC', nombre: '11. Constancia de Carga Académica', opc: null, opc2: null, opcs: ['Nacional','Internacional']},
                    {id: 11, ventana: 'CDE', nombre: '12. Constancia de Culminación de Estudios', opc: null, opc2: null, opcs: ['Nacional','Internacional']},
                    {id: 12, ventana: 'EST', nombre: '13. Constancia de Estudios', opc: null, opc2: null, opcs: []},
                    {id: 18, ventana: 'TTE', nombre: '14. Constancia de Egreso', opc: null, opc2: null, opcs: ['Nacional','Internacional']},
                    {id: 13, ventana: 'CHE', nombre: '15. Constancia de Haber Estudiado', opc: null, opc2: null, opcs: ['Nacional','Internacional']},
                    {id: 14, ventana: 'INS', nombre: '16. Constancia de Inscripción', opc: null, opc2: null, opcs: []},
                    {id: 15, ventana: 'MES', nombre: '17. Constancia de Modalidad de Estudio', opc: null, opc2: null, opcs: ['Nacional','Internacional']},
                    {id: 16, ventana: 'NOT', nombre: '18. Constancia de Notas', opc: null, opc2: null, opcs: []},
                    {id: 17, ventana: 'ESP', nombre: '19. Constancia Especial', opc: null, opc2: null, opcs: ['Nacional','Internacional']},
                    {id: 19, ventana: 'PEN', nombre: '20. Pensum', opc: null, opc2: null, opcs: ['Nacional','Internacional']},
                    {id: 20, ventana: 'PGR', nombre: '21. Posición de Grado',   opc: null, opcs: ['Nacional','Internacional']},
                ],
                selec: null,
				auxiliar: {
					activo: false,
					nombre: null,
					cargo: null
				},
				empresa: {
					institucion: null,
					nombre: null,
					cargo: null,
					fecha: null
				}

			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
		},
		mounted() {
			
		},
		methods: {
			Buscar() {
				this.matriculas = [];
				if (this.buscar){
					Consulta.Procesar('Buscar',{
						id: 	this.buscar,
					}).then(response => {
						//this.$store.state.error =response;
						this.products = response.result;
						this.$store.commit('Loading');
					}); 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail:'Debes colocar los datos solicitados3s', 
						life: 3000
					});    
				}
			},
			VerMatricula(estudiante) {
				this.estudiante = {};
				this.estudiante = {...estudiante};
				if (estudiante.planes>0){
					Consulta.Procesar('Matricula',{
						id: 	this.estudiante.id,
						nucleo: 	this.estudiante.nucleo_id,
					}).then(response => {
						//this.$store.state.error = response;
						this.matriculas = response.result;
						this.$store.commit('Loading');
					}); 
				} 
			},
            Consultar(data){
                this.selec = null;
                this.product = {...data};
                this.display = true;
            },
			Imprimir(tipo){
				var data = this.product;
				Consulta.Procesar('PDF',{
					tipo: 	tipo,
					matricula: data,
					turno_id: data.turno_id,
					empresa: this.empresa,
				}).then(response => {
					this.$store.commit('Loading');
					//this.$store.state.error = response;
					if(response.result){
						var result = response.result;
						if(this.auxiliar.activo){
							result.firma = this.auxiliar;
						}
						new IMP(tipo,data,result);
					}
				}); 
			},
			CalcularIAG(data){
				var asigporuc = 0
				var cursadasuc = 0
				for (var i = 0; i < data.length; i++) {
					if(data[i].aprobada && data[i].tipo_id<5){
						cursadasuc = parseFloat(cursadasuc) + parseFloat(data[i].uc);						
						asigporuc = parseFloat(asigporuc) + parseFloat(data[i].uc*data[i].definitiva);
					} 
				}
				return parseFloat(asigporuc/cursadasuc).toFixed(2);
			},
			CalcularIA(data){
				var asigporuc = 0
				var cursadasuc = 0
				for (var i = 0; i < data.length; i++) {
					if(data[i].tipo_id<5){
						cursadasuc = parseFloat(cursadasuc) + parseFloat(data[i].uc);
						asigporuc = parseFloat(asigporuc) + parseFloat(data[i].uc*data[i].definitiva);
					}
				}
				return parseFloat(asigporuc/cursadasuc).toFixed(2);
			}


		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
