<template>
	<div>
        <Dialog header="Inscripción" v-model:visible="openInscripcion" @update:visible="close" :style="{width: size}" :modal="true" :maximizable="true" style="background: #727376;position: absolute;">
			<br><Button icon="pi pi-file-pdf" label="Imprimir Constancia" class="p-button-rounded p-button-primary p-mr-2" @click="Ver()" v-if="inscripcion.length>0"/>
            <div class="p-fluid">
				<br>				
				<DataTable :value="inscripcion" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
                        <h4>{{matricula.plan}}</h4>
					</template>
					<Column header="Periodo" :sortable="true">
						<template #body="slotProps">{{slotProps.data.periodo}}</template>
					</Column>
					<Column header="Código" :sortable="true">
						<template #body="slotProps">{{slotProps.data.cod}}</template>
					</Column>
					<Column header="Asignatura" :sortable="true">
						<template #body="slotProps">{{slotProps.data.asignatura}}</template>
					</Column>
					<Column header="UC" :sortable="true">
						<template #body="slotProps">{{slotProps.data.uc}}</template>
					</Column>
					<Column header="Semestre" :sortable="true">
						<template #body="slotProps">{{slotProps.data.sem}}</template>
					</Column>
					<Column header="Sección" :sortable="true">
						<template #body="slotProps">{{slotProps.data.secc}}</template>
					</Column>
					<Column header="Docente" :sortable="true">
						<template #body="slotProps">{{slotProps.data.doc}}</template>
					</Column>
				</DataTable>
				<br>
				<div class="p-field p-col-12 p-md-2" style="color:red;"  v-if="cruce.length>0">
					<h5><strong>Cruce de Horario:</strong></h5>
					<div v-for="(stats, index) in cruce" :key="index">
						<div>
							{{stats.ndia}} {{stats.nhora}}	<br>
						</div>
					</div>
				</div>
				<br>				
				<DataTable :value="horario" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
                        <h5>Horario</h5>
					</template>
					<Column field="bloque" header="Hora" :sortable="true"></Column>
						<Column field="Lunes" header="Lunes">	
							<template #body="{data}">
								{{data.lun['asignatura']}}
							</template>
						</Column>
						<Column field="Martes" header="Martes">
							<template #body="{data}">
								{{data.mar['asignatura']}}
							</template>
						</Column>
						<Column field="Miercoles" header="Miércoles">
							<template #body="{data}">
								{{data.mie['asignatura']}}
							</template>
						</Column>
						<Column field="Jueves" header="Jueves">
							<template #body="{data}">
								{{data.jue['asignatura']}}
							</template>
						</Column>
						<Column field="Viernes" header="Viernes">
							<template #body="{data}">
								{{data.vie['asignatura']}}
							</template>
						</Column>
						<Column field="Sabado" header="Sábado">
							<template #body="{data}">
								{{data.sab['asignatura']}}
							</template>
						</Column>
						<Column field="Domingo" header="Domingo">
							<template #body="{data}">
								{{data.dom['asignatura']}}
							</template>
						</Column>			
				</DataTable>
			</div>	
            <template #footer>
                <Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="close"/>
            </template>
        </Dialog>

	</div>
</template>

<script>
import PDF from "../service/PDF";

export default {
    props: {
		display: {
			type: Boolean,
			default: false,
		},
        matricula: {
            type: Object,
            default: () => {}
        },
        inscripcion: {
            type: Array,
            default: () => []
        },
        horario: {
            type: Array,
            default: () => []
        },
        cruce: {
            type: Array,
            default: () => []
        },
        convenio: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
			openInscripcion: false,
            size: '90vw',
            visibleFull: false
		}
	},
    created() {      
        if (this.$store.state.mobile){this.size = '90vw';}
    },
    methods: {
		Ver(){
			const doc = new PDF();
			doc.Horario(this.matricula,this.inscripcion,this.horario,this.convenio);
		},
        close() {
            this.openInscripcion = false;
            this.$emit('close-inscripcion', event);
        },
        onMenuToggle(event) {
            this.$emit('close-inscripcion', event);
        },	
	},
    watch: {
		display(newValue) {
			this.openInscripcion = newValue;
		}
	},


}
</script>