<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<DataTable :value="matriculas" class="p-datatable-gridlines p-datatable-striped p-datatable-sm p-datatable-responsive p-datatable-customers"
                    dataKey="id" :rowHover="true" v-model:selection="selectedCustomers" :filters="filters" v-if="matriculas.length>0">
					<template #header>
						<h4>{{estudiante.nac}}{{estudiante.id}} {{estudiante.apellido}}, {{estudiante.nombre}} ({{estudiante.nucleo}})</h4>
					</template>
					<Column field="plan" header="Plan de Estudio" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.plan}}
						</template>
					</Column>
					<Column field="periodo" header="Período" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.periodo}}
						</template>
					</Column>
					<Column field="turno" header="Turno" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.turno}}
						</template>
					</Column>
					<Column field="seccion" header="Sección" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.sec}}
						</template>
					</Column>
					<Column field="activo" header="Estatus">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>
						</template>
					</Column>
				</DataTable> 
			</div>             
		</div>
		<div class="p-col-12">
			<div class="card">
                <h5 class="p-m-0"><strong>Pagos Pendientes</strong></h5>
				<DataTable :value="pagos_pend" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<Column field="fecha_reg" header="Fecha" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.fecha_reg}}
						</template>
					</Column>
					<Column field="descripcion" header="Descripción" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.descripcion}}
						</template>
					</Column>
					<Column field="monto" header="Monto" :sortable="true">
						<template #body="slotProps">
							<div v-if="slotProps.data.moneda==0 && slotProps.data.montobs<0">Bs. {{new Intl.NumberFormat("de-DE").format(parseFloat((-1)*slotProps.data.montobs).toFixed(2))}}</div>
							<div v-if="slotProps.data.moneda>0">
								<strong v-if="slotProps.data.moneda==1">Ref.</strong>
								<img :src="'images/moneda'+slotProps.data.moneda+'.png'" width="15" v-if="slotProps.data.moneda>0"/>{{slotProps.data.monto}}							
							</div>
						</template>
					</Column>
                    <Column header="Recargo">	
						<template #body="slotProps">
							<strong v-if="slotProps.data.moneda==1">Ref.</strong>
                            <img :src="'images/moneda'+slotProps.data.moneda+'.png'" width="15" v-if="slotProps.data.moneda>0"/>{{slotProps.data.recargo}}
                        </template>
                    </Column>
					<Column field="vence" header="Vencimiento" :sortable="true">
						<template #body="slotProps">
                            <strong v-if="slotProps.data.recargo>0" style="color: red;">{{slotProps.data.vence}}</strong>
                            <strong v-else>{{slotProps.data.vence}}</strong>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
		<div class="p-col-12">
			<div class="card">
                <h5 class="p-m-0"><strong>Facturas</strong></h5>
				<DataTable :value="facturas" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<Column field="fecha_reg" header="Fecha" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.fecha}}
						</template>
					</Column>
					<Column field="numero" header="Número" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.numero}}
						</template>
					</Column>
					<Column field="montobs" header="Monto Reportado" :sortable="true">
						<template #body="slotProps">
                            Bs. {{new Intl.NumberFormat("de-DE").format(parseFloat(slotProps.data.montobs).toFixed(2))}}
						</template>
					</Column>
					<Column field="recibidobs" header="Pago Recibido" :sortable="true">
						<template #body="slotProps">
                            Bs. {{new Intl.NumberFormat("de-DE").format(parseFloat(slotProps.data.recibidobs).toFixed(2))}}
						</template>
					</Column>
					<Column field="recibidobs" header="Diferencia de Pago" :sortable="true">
						<template #body="slotProps">
							<div v-if="slotProps.data.montobs-slotProps.data.recibidobs>=0">
								Bs. {{new Intl.NumberFormat("de-DE").format(parseFloat(slotProps.data.montobs-slotProps.data.recibidobs).toFixed(2))}}
							</div>
						</template>
					</Column>
					<Column field="activo" header="Estatus">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.pagada">Pagada</span>
							<span class="p-tag p-tag-danger" v-else>Por Validación</span>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
	import API from "../service/API";
	const Consulta = new API('AreaEstudiante','MisPagos');

	export default {
		data() {
			return {
				buscar: null,
				opcion: true,
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				matriculas: [],
				product: {},
				estudiante: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				pagos_pend: [],
				facturas: [],
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
            this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
                this.$store.commit('ValorPetro');
				Consulta.Ini().then(result => {
					//this.$store.state.error = result.consult;
                    this.estudiante = result.consult.estudiante;
					this.matriculas = result.consult.matriculas;
                    this.pagos_pend = result.consult.pagos_pend;
                    this.facturas = result.consult.facturas;
					this.$store.commit('Loading');
				}); 
			},
            onMenuToggle(event) {
				this.$emit('menu-toggle', event);
			},
				openFactura(matricula) {
					this.$store.commit('Mobile');
					this.matricula = {...matricula};					
					Consulta.Procesar('VerCalificaciones',{
						id: 	this.matricula.id,
					}).then(response => {
						//this.$store.state.error =response.result;
						this.calificaciones = response.result;
						this.displayCalificaciones = true;
						this.$store.commit('Loading');
					}); 
				},
				close() {
					this.matricula = {};
					this.displayCalificaciones = false;
					this.displayInscricion = false;
					this.displayHorario = false;
				},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}
				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
