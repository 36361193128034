import 'jspdf-autotable';

function Notas(pdf,data,contenido) {

    var calif = contenido.calificaciones;
    const head1 = [['Nº', 'Código','Asignatura', 'Creditos', 'Nota', 'Período', 'Rég.', 'Aprobada']];
    var aprobada = '';
    var asigporuc = 0;
    var cursadasuc = 0;
    var definitiva = 0;
    var body1 = [];
    for (var i = 0; i < calif.length; i++) {
        if(calif[i].tipo_id<5){
            cursadasuc = parseFloat(cursadasuc) + parseFloat(calif[i].uc);
            asigporuc = parseFloat(asigporuc) + parseFloat(calif[i].uc*calif[i].definitiva);    
        }
        if(calif[i].aprobada){
            aprobada = 'S';
        } else {aprobada = 'N';}
        
        if(calif[i].aprobada && calif[i].tipoasig==7){
            definitiva = 'Aprobado';
        } else if(calif[i].aprobada && calif[i].tipo_id==5){
                definitiva = 'Equivalencia';
        } else {
            definitiva = calif[i].definitiva;
        }
        body1.push([
            i+1,
            calif[i].cod,
            calif[i].asignatura,
            calif[i].uc,
            definitiva,
            calif[i].periodo,
            calif[i].esc,
            aprobada
        ]);
        if((i+1) < calif.length){
            if(calif[i].sem != calif[i+1].sem ){
                body1.push([null, null,null,null,null,null,null,null]);
            }
        }
    }

    pdf.autoTable({
        head: head1,
        body: body1,
        startY: 45,
        margin: {
            top: 45,
            right: 10,
            left: 10,
            bottom: 20
        },
        headerStyles: {
            fillColor: [255, 255, 255],
            textColor: [0, 0, 0],
            halign: 'center'
        },                     
        styles: {
            lineColor: [255, 255, 255],
            lineWidth: 0.1,
            textColor: [0, 0, 0],
            fontSize: 8,
            cellPadding: 1,
            //columnWidth: 57,
            //halign: 'center', // left, center, right
        },
        columnStyles: {
            0: {cellWidth: 10, halign: 'center'},
            1: {cellWidth: 20, halign: 'center'},
            2: {cellWidth: 70},
            3: {cellWidth: 17, halign: 'center'},
            4: {cellWidth: 25, halign: 'center'},
            5: {cellWidth: 18, halign: 'center'},
            6: {cellWidth: 10, halign: 'center'},
            7: {cellWidth: 18, halign: 'center'},

          }
    });

    pdf.setFontSize(8);

    // PAGE NUMBERING
    // Add Page number at bottom-right
    // Get the number of pages
    const pageCount = pdf.internal.getNumberOfPages();

    // For each page, print the page number and the total pages
    for(var k = 1; k <= pageCount; k++) {
        pdf.addImage("logo.jpg", 'JPEG', 5, 5, 15, 15);
        pdf.setFontSize(12);
        pdf.setFont("italic", "bold");
        pdf.text(100, 10, "INSTITUTO UNIVERSITARIO DE TECNOLOGÍA INDUSTRIAL" , 'center');
        pdf.text(100, 15, "RODOLFO LOERO ARISMENDI (IUTIRLA)", 'center');
        pdf.text(100, 20, "RIF.: J-00130751-6", 'center');
        pdf.text(100, 25,'EXTENSIÓN: '+data.extension.toUpperCase(), 'center');
    
        var parraf = contenido.parrafos;  
        for (var j = 0; j < parraf.length; j++) {
            pdf.setFontSize(parraf[j].tam);
            if(parraf[j].normal){pdf.setFont("arial", "bold");} else {pdf.setFont("arial", "");}
            if(parraf[j].alin=='center'){
                pdf.text((100+parraf[j].izq), parraf[j].alt, parraf[j].contenido, 'center',{lineHeightFactor: parseFloat(parraf[j].interlineado)});
            } else {
                pdf.text(parraf[j].contenido, parraf[j].izq, parraf[j].alt, {align: parraf[j].alin,lineHeightFactor: parseFloat(parraf[j].interlineado), maxWidth: (215-2*parraf[j].izq)});
            }
        }
    



        // Go to page i
        pdf.setPage(k);
        if (k == pageCount){
            pdf.text(80, 260, "Índice de Rendimiento Académico: "+ parseFloat(asigporuc/cursadasuc).toFixed(2));

            pdf.text(10, 260, "UC Cursadas:");
            pdf.text(30, 260, " "+cursadasuc);
            pdf.text(45, 260, "Asig. Cursadas:");
            pdf.text(70, 260, " "+calif.length);        

            pdf.text(10, 265, "UC Aprobadas:");
            pdf.text(30, 265, " "+data.tuc);
            pdf.text(45, 265, "Asig. Aprobadas:");
            pdf.text(70, 265, " "+data.tasig);
    
            pdf.text(10, 270, "UC del Plan:");
            pdf.text(30, 270, " "+data.uc10);
            pdf.text(45, 270, "Asig. del Plan:");
            pdf.text(70, 270, " "+data.asigs);
        }
        //Print Page 1 of 4 for example
        pdf.text('Fecha de Impresión: '+localStorage.getItem('Fecha'),200,265,null,null,"right");
        pdf.text('Página ' + String(k) + ' de ' + String(pageCount),200,270,null,null,"right");

    }

    //Firma
    pdf.setFontSize(14);
    pdf.text(150, 230, contenido.firma.nombre, 'center');
    pdf.text(150, 235,contenido.firma.cargo, 'center');
    

}

export default Notas;