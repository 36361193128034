<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<Dropdown v-model="seleccion" :options="nucleos" optionLabel="nombre" placeholder="Seleccione la Extensión ..." @change="Buscar()"/>
						<!--<Button label="Buscar" icon="pi pi-search" class="p-button-success" @click="Buscar()" />-->
					</template>
					<template v-slot:right>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="openNew" v-if="seleccion && insert"/>
					</template>
				</Toolbar>
				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Aulas por Extensión: {{products.length}}</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="nombre" header="Nombre de Aula" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.nombre}}
						</template>
					</Column>
					<Column field="minimo" header="Capacidad">
						<template #body="slotProps">
							{{slotProps.data.minimo}}-{{slotProps.data.maximo}}
						</template>
					</Column>
					<Column field="ubicacion" header="Ubicación" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.ubicacion}}
						</template>
					</Column>
					<Column field="activo" header="Estatus">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>							
						</template>
					</Column>
					<Column header="Opciones">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2" @click="editProduct(slotProps.data)" v-if="edit" v-tooltip.bottom="'Editar'"/>
							<!--<Button icon="pi pi-calendar-minus" class="p-button-rounded p-button-info p-mr-2" @click="editHorario(slotProps.data)" v-if="edit" v-tooltip.bottom="'Horario'"/>-->
							<span class="p-overlay-badge p-mr-5" @click="editHorario(slotProps.data)" v-if="edit" v-tooltip.bottom="'Horario'">
								<i class="pi pi-calendar-minus" style="font-size: 2em"></i>
								<span class="p-badge">{{slotProps.data.disp}}</span>
							</span>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteProduct(slotProps.data)" v-if="del" v-tooltip.bottom="'Eliminar'"/>
						</template>
					</Column>
				</DataTable>
				<Dialog v-model:visible="productDialog" :style="{width: size}" :header="'Aula '+titulo" :modal="true" class="p-fluid" :maximizable="true">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-4">
								<label class="p-mb-3">Extensión</label>
								<Dropdown id="state" v-model="product.nucleo" :options="nucleos" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-4">
								<label for="name">Nombre</label>
								<InputText id="name" v-model.trim="product.nombre" required="true" autofocus/>
							</div>
							<div class="p-col-2">
								<label class="p-mb-3">Cap. Mínima</label>
								<InputNumber id="uc" v-model="product.minimo" required="true"/>
							</div>
							<div class="p-col-2">
								<label class="p-mb-3">Cap. Máxima</label>
								<InputNumber id="uc" v-model="product.maximo" required="true"/>
							</div>
							<div class="p-col-10">
								<label for="name">Ubicación</label>
								<InputText id="name" v-model="product.ubicacion"/>
							</div>
							<div class="p-col-2">
								<h6 style="margin-top: 0" v-if="product.activo">Activo</h6>
								<h6 style="margin-top: 0" v-else>No Activo</h6>
								<InputSwitch v-model="product.activo" />
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="hideDialog"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="productDialog2" :style="{width: size}" :header="'Disponibilidad de Aula '+titulo" :modal="true" class="p-fluid" :maximizable="true">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-4">
								<label class="p-mb-3">Extensión</label>
								<InputText id="name" v-model.trim="product.nucleo.nombre" :disabled="true"/>
							</div>
							<div class="p-col-4">
								<label for="name">Nombre</label>
								<InputText id="name" v-model.trim="product.nombre" :disabled="true"/>
							</div>
							<div class="p-col-2">
								<label class="p-mb-3">Cap. Mínima</label>
								<InputNumber id="uc" v-model="product.minimo" :disabled="true"/>
							</div>
							<div class="p-col-2">
								<label class="p-mb-3">Cap. Máxima</label>
								<InputNumber id="uc" v-model="product.maximo" :disabled="true"/>
							</div>
							<div class="p-col-12">
								<DataTable :value="disponibiliad" :scrollable="true" scrollHeight="300px" class="p-datatable-sm">
									<Column field="bloque" header="Hora" :sortable="true"></Column>
									<Column field="Lunes" header="Lunes">	
										<template #body="{data}">
											<Checkbox v-model="data.lun" :binary="true" />
										</template>
									</Column>
									<Column field="Martes" header="Martes">
										<template #body="{data}">
											<Checkbox v-model="data.mar" :binary="true" />
										</template>
									</Column>
									<Column field="Miercoles" header="Miércoles">
										<template #body="{data}">
											<Checkbox v-model="data.mie" :binary="true" />
										</template>
									</Column>
									<Column field="Jueves" header="Jueves">
										<template #body="{data}">
											<Checkbox v-model="data.jue" :binary="true" />
										</template>
									</Column>
									<Column field="Viernes" header="Viernes">
										<template #body="{data}">
											<Checkbox v-model="data.vie" :binary="true" />
										</template>
									</Column>
									<Column field="Sabado" header="Sábado">
										<template #body="{data}">
											<Checkbox v-model="data.sab" :binary="true" />
										</template>
									</Column>
									<Column field="Domingo" header="Domingo">
										<template #body="{data}">
											<Checkbox v-model="data.dom" :binary="true" />
										</template>
									</Column>
								</DataTable>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveDisponibilidad()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="productDialog2 = false"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}" header="Confirmar" :modal="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button label="Sí" icon="pi pi-check" class="p-button-secondary" @click="deleteProduct" />
						<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>
</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Academico','Aulas');

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				productDialog: false,
				productDialog2: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				seleccion: null,
				nucleos: null,
				disponibiliad: null,
				checked: false
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				Consulta.Ini().then(result => {
					//this.$store.state.error = result.consult.planestudio;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.nucleos=result.consult;
					this.$store.commit('Loading');
				}); 
			},
			Buscar() {
				if (this.seleccion != null) {
					Consulta.Procesar('Buscar',{
						id: 	this.seleccion.code,
					}).then(response => {
						//this.$store.state.error =response;
						this.products = response.result;
						this.$store.commit('Loading');
					}); 
				}
			},
			openNew() {
				this.titulo = '(Nuevo)';
				this.product = {};
				this.submitted = false;
				this.productDialog = true;
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			editProduct(product) {
				this.titulo = '(Editar)';
				this.product = {...product};
				this.nucleos.forEach(element => {
					if(this.product.nucleo==element.code){
						this.product.nucleo = element;
					}
				});  
				this.productDialog = true;
			},
			editHorario(product) {
				this.titulo = '(Editar)';
				this.product = {...product};
				Consulta.Procesar('Disponibilidad',{
					id: 	this.product.id,
				}).then(response => {
					//this.$store.state.error =response;
					this.disponibiliad = response.result;
					this.$store.commit('Loading');
				}); 
				this.nucleos.forEach(element => {
					if(this.product.nucleo==element.code){
						this.product.nucleo = element;
					}
				});  
				this.productDialog2 = true;
			},
			saveProduct() {
				if (this.product.nombre == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Nombre', life: 5000});
				} else if (this.product.nucleo == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione la Extensión', life: 5000});
				} else if (this.product.ubicacion < 0) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca la Ubicación', life: 5000});
				} else {			
					this.seleccion = this.product.nucleo;
                    this.productDialog = false;
					if (this.product.id) {
						Consulta.Procesar('UPD',{
							id: 	this.product.id,
							nombre: this.product.nombre,
							nucleo: this.product.nucleo.code,
							ubicacion: this.product.ubicacion,
							minimo: this.product.minimo,
							maximo: this.product.maximo,
							activo: this.product.activo,
						}).then(response => {
							//this.$store.state.error =response;
							this.$store.commit('Loading');
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Buscar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
						});
					} else {
						Consulta.Procesar('INS',{
							nombre: this.product.nombre,
							nucleo: this.product.nucleo.code,
							ubicacion: this.product.ubicacion,
							minimo: this.product.minimo,
							maximo: this.product.maximo,
							activo: this.product.activo,
						}).then(response => {
							//this.$store.state.error =response;
							this.$store.commit('Loading');
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Buscar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
						});
					}
					this.productDialog = false;
					this.product = {};
				}
			},
			saveDisponibilidad() {
				if (this.product.id) {
					Consulta.Procesar('UPD2',{
						id: 	this.product.id,
						disponibiliad: this.disponibiliad
					}).then(response => {
						//this.$store.state.error =response;
						this.$store.commit('Loading');
						if (response.result) {
							this.$toast.add({
								severity:'success', 
								summary: 'Éxito', 
								detail: 'Información Procesada', 
								life: 3000
							});
							this.Buscar();
						} else {
							this.$toast.add({
								severity:'warn', 
								summary: 'Advertencia', 
								detail: 'No fue procesada la solicitud', 
								life: 3000
							});
						}
					});
				}
				this.productDialog2 = false;
				this.product = {};
				this.disponibiliad = null;
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			deleteProduct() {
				this.products = this.products.filter(val => val.id !== this.product.id);
				Consulta.Procesar('DEL',{
					caso: 	'DEL',
					id: 	this.product.id
				}).then(response => {
					this.$store.commit('Loading');
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Mostrar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
				});
				this.deleteProductDialog = false;
				this.product = {};
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
