<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<InputSwitch v-model="opcion" class="p-mr-2"/>				
						<Dropdown v-model="nucleo" :options="nucleos" optionLabel="nombre" placeholder="Seleccione..." class="p-mr-2"/>
						<strong class="p-mr-2" v-if="opcion">N° Factura: </strong>
						<strong class="p-mr-2" v-else>N° Control: </strong>
						<div v-if="nucleo">
							Desde: <InputText v-model="desde" type="number" class="p-mr-2"/>
							Hasta: <InputText v-model="hasta" type="number" class="p-mr-2"/>
						</div>
					</template>
					<template v-slot:right>
						<Button label="Buscar" icon="pi pi-search" class="p-button-success p-mr-2" @click="Buscar" v-if="nucleo"/>
					</template>
				</Toolbar>
				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm" v-if="!detalles">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Facturas: {{products.length}}</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="fecha" header="Fecha">
						<template #body="slotProps">
							{{convfech(slotProps.data.fecha)}}
						</template>
					</Column>
					<Column field="numero" header="N° Factura">
						<template #body="slotProps">
							{{('000000'+slotProps.data.numero).slice(-6)}}
						</template>
					</Column>
					<Column field="control" header="N° Control">
						<template #body="slotProps">
							{{('000000000'+slotProps.data.control).slice(-9)}}
						</template>
					</Column>
					<Column field="estudiante" header="Estudiante">
						<template #body="slotProps">
							{{slotProps.data.estudiante}}
						</template>
					</Column>
					<Column field="monto" header="Monto Bs">
						<template #body="slotProps">
							Bs. {{moneda(slotProps.data.montobs)}}
						</template>
					</Column>
					<Column header="Ver" headerStyle="width: 100px">
						<template #body="{data}">
							<span class="p-tag p-tag-danger" v-if="!data.activo">Anulado</span>
							<Button icon="pi pi-file-pdf" class="p-button-rounded p-button-primary p-mr-2" @click="Ver(data,false)" v-tooltip.bottom="'Ver'" v-if="data.activo"/>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
        

		<Dialog v-model:visible="deleteDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
			<div class="confirmation-content">
				<br><br>
				<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
				<span v-if="product">Confirme si desea Anular la Factura Nº {{product.numero}}</span>
			</div>
			<template #footer>
				<Button label="Sí" style="width: 110px;" icon="pi pi-save" class="p-button-secondary p-mr-2" @click="Anular()"/>
				<Button label="No" style="width: 110px;" icon="pi pi-times" class="p-button-danger p-mr-2" @click="deleteDialog = false"/>
			</template>
		</Dialog>


	</div>
</template>

<script>
	import API from "../service/API";
	import PDF from "../service/PDF";
	const Consulta = new API('Facturacion','ReporteFac');

	export default {
		data() {
			return {
				opcion: true,
				desde: null,
				hasta: null,
				nucleo: null,
				nucleos: [],
				pendiente: {nombre: 'Por Verificar', code: 0},
				pendientes:  [
					{nombre: 'Por Verificar', code: 0},
					{nombre: 'Verificados', code: 1},
				],
				buscar: null,
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				detalles: null,
				product: {},
				estudiante: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				pagos_pend: [],
				facturas: [],
				ajuste: [],
				imagen: null,
				deleteDialog: false,
				pago: {},
				bancos: [],
				banco_destino: {code: 3, nombre: 'Banco Mercantil'},
				tipos: [],
				Dialog: false,
				periodo: null,
				periodos: [],
                numero: 0,
                control: 0,
                recibos: [],
			}
		},
		productService: null,
		created() {
			this.url = process.env.VUE_APP_API;
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				Consulta.Ini().then(result => {
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.nucleos = result.consult.nucleos;
                    if(this.nucleos.length==1){
                        this.nucleo = result.consult.nucleos[0];
                    }
					this.$store.commit('Loading');
				}); 
			},
            Buscar() {
				this.products = [];
				if (this.nucleo && (this.desde<=this.hasta)){
					Consulta.Procesar('Buscar',{
						nucleo: this.nucleo,
						desde: this.desde,
						hasta: this.hasta,
						opcion: this.opcion,
					}).then(response => {
						//this.$store.state.error = response.result[0];
						this.$store.commit('Loading');
						this.products = response.result;
					}); 
				} else {
					this.$toast.add({
						severity:'warn', 
						summary: 'Advertencia', 
						detail:'Debes colocar los datos solicitados', 
						life: 3000
					});    
				}
			},
			Ver(data,fondo){
				const doc = new PDF();
				doc.Factura(data,fondo);
			},
			convfech(fecha){
				return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
			},
			moneda(num){
				return new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num)
			}
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
