function Carnet(pdf,data,contenido) {
    pdf.addImage("images/carnet_1.jpg", 'JPEG', 10, 10);   
    pdf.addImage("images/carnet_2.jpg", 'JPEG', 65, 10);   
    pdf.addImage("logo.jpg", 'JPEG', 33, 15, 10, 10);   
    
    pdf.setFontSize(4.5);
    pdf.setFont("times", "normal");
    pdf.text(37, 30, "INSTITUTO UNIVERSITARIO DE TECNOLOGÍA INDUSTRIAL" , 'center');
    pdf.text(37, 32, "RODOLFO LOERO ARISMENDI (IUTIRLA)", 'center');
    pdf.text(37, 34, "RIF.: J-00130751-6", 'center');
    pdf.text(37, 36,'EXTENSIÓN: '+data.extension.toUpperCase(), 'center');
    //Firma
    pdf.setFontSize(5);
    pdf.text(83, 87, contenido.firma.nombre, 'center');
    pdf.text(83, 90,contenido.firma.cargo, 'center');
    //Foto
    pdf.rect(27, 37, 20, 20); 
    
    var parraf = contenido.parrafos;
    for (var j = 0; j < parraf.length; j++) {
        pdf.setFontSize(parraf[j].tam);
        if(parraf[j].normal){pdf.setFont("arial", "bold");} else {pdf.setFont("arial", "");}
        if(parraf[j].alin=='center'){
            pdf.text((100+parraf[j].izq), parraf[j].alt, parraf[j].contenido, 'center',{lineHeightFactor: parseFloat(parraf[j].interlineado)});
        } else {
            pdf.text(parraf[j].contenido, parraf[j].izq, parraf[j].alt, {align: parraf[j].alin,lineHeightFactor: parseFloat(parraf[j].interlineado), maxWidth: (215-2*parraf[j].izq)});
        }
    }


}

export default Carnet;