<template>
	<div class="p-grid p-fluid">
		<div class="p-col-12 p-lg-12">
			<h3 class="centerText">Estudiantes</h3>
		</div>
		<div class="p-col-2 p-lg-2">
			<label>Condición</label>
			<Dropdown v-model="condicion" :options="condiciones" optionLabel="nombre" placeholder="Seleccione ..."/>
		</div>
		<div class="p-col-4 p-lg-4">
			<label>Extensión</label>
			<Dropdown v-model="nucleo" :options="nucleos" optionLabel="nombre" placeholder="Seleccione la Extensión ..."/>
		</div>
		<div class="p-col-2 p-lg-2">
            <Button label="Buscar" icon="pi pi-search" class="p-button-success p-mr-2 p-mb-2" @click="Buscar" />
		</div>
		<div class="p-col-12 p-lg-12">
			<div class="card">
				<Chart type="horizontalBar" :data="Data1" />
			</div>
		</div>
		<div class="p-col-12 p-lg-12">
			<div class="card">
				<Chart type="horizontalBar" :data="Data2" />
			</div>
		</div>
	</div>
	
</template>

<script>
import API from "../service/API";
const Consulta = new API('Estadistica','EstadEstudiantes');

export default {
	data() {
		return {
			condiciones: [
				{nombre: 'Activos', code: true},
				{nombre: 'No Activos', code: false}
			],
			condicion: {nombre: 'Activos', code: true},
			nucleos: [{nombre: 'Todas', code: 0}],
			nucleo: null,
			Data1: {},
			Data2: {},
		}
	},
	created() {
		this.$store.commit('Validar',this.$route);
		this.Mostrar();
	},
	methods: {
		Mostrar() {
			this.Data1 = {};
			this.Data2 = {};
			Consulta.Ini().then(result => {
				//this.$store.state.error = result.consult;
				this.insert = result.insert;
				this.edit = result.edit;
				this.del = result.delete;
				if(result.consult.nucleos.length>1){
					result.consult.nucleos.forEach(element => {
						this.nucleos.push(element);
					});
					this.nucleo = this.nucleos[0];
				} else {
					this.nucleos = result.consult.nucleos;
					this.nucleo = this.nucleos[0];
				}
				this.$store.commit('Loading');
			}); 
		},
		Buscar() {
			this.Data1 = {};
			this.Data2 = {};
			Consulta.Procesar('Buscar',{
				nucleo: this.nucleo.code,
				condicion: this.condicion.code,
			}).then(response => {
				//this.$store.state.error = response;	
				this.Data1 = response.result.data1;
				this.Data2 = response.result.data2;
				this.$store.commit('Loading');  
			});
		},

	}
}
</script>

<style scoped>

</style>
