<template>
	<div>
        <Dialog header="Consulta de Horario" v-model:visible="openHorario" @update:visible="close" :style="{width: size}" :modal="true" :maximizable="true" style="background: #727376;position: absolute;">
            <div class="p-fluid">
				<div class="p-field p-col-12 p-md-2" style="color:red;"  v-if="cruce.length>0">
					<h5><strong>Cruce de Horario:</strong></h5>
					<div v-for="(stats, index) in cruce" :key="index">
						<div>
							{{stats.ndia}} {{stats.nhora}}	<br>
						</div>
					</div>
				</div>
				<br>				
				<DataTable :value="horario" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
                        <h4>Horario</h4>
					</template>
					<Column field="bloque" header="Hora" :sortable="true"></Column>
						<Column field="Lunes" header="Lunes">	
							<template #body="{data}">
								{{data.lun['asignatura']}}
							</template>
						</Column>
						<Column field="Martes" header="Martes">
							<template #body="{data}">
								{{data.mar['asignatura']}}
							</template>
						</Column>
						<Column field="Miercoles" header="Miércoles">
							<template #body="{data}">
								{{data.mie['asignatura']}}
							</template>
						</Column>
						<Column field="Jueves" header="Jueves">
							<template #body="{data}">
								{{data.jue['asignatura']}}
							</template>
						</Column>
						<Column field="Viernes" header="Viernes">
							<template #body="{data}">
								{{data.vie['asignatura']}}
							</template>
						</Column>
						<Column field="Sabado" header="Sábado">
							<template #body="{data}">
								{{data.sab['asignatura']}}
							</template>
						</Column>
						<Column field="Domingo" header="Domingo">
							<template #body="{data}">
								{{data.dom['asignatura']}}
							</template>
						</Column>			
				</DataTable>
				<br>		
				<DataTable :value="asignaturas" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm"  v-if="asignaturas.length>0">
					<template #header>
                        <h4>Asignaturas</h4>
					</template>
					<Column header="Plan" :sortable="true">
						<template #body="slotProps">{{slotProps.data.codplan}} {{slotProps.data.plan}}</template>
					</Column>
					<Column header="Código" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">{{slotProps.data.cod}}</template>
					</Column>
					<Column header="Asignatura" :sortable="true">
						<template #body="slotProps">{{slotProps.data.asignatura}}</template>
					</Column>
					<Column header="Horas" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">{{slotProps.data.horas}}</template>
					</Column>
					<Column header="Sección" :sortable="true" headerStyle="width: 100px">
						<template #body="slotProps">S{{slotProps.data.sem+slotProps.data.secc}}</template>
					</Column>
					<Column header="Docente" :sortable="true">
						<template #body="slotProps">{{slotProps.data.doc}}</template>
					</Column>
				</DataTable>
			</div>	
            <template #footer>
                <Button label="Cerrar" icon="pi pi-times" class="p-button-danger" @click="close"/>
            </template>
        </Dialog>
	</div>
</template>

<script>

export default {
    props: {
		display: {
			type: Boolean,
			default: false,
		},
        matricula: {
            type: Object,
            default: () => {}
        },
        horario: {
            type: Array,
            default: () => []
        },
        asignaturas: {
            type: Array,
            default: () => []
        },
        cruce: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
			openHorario: false,
            size: '90vw',
            visibleFull: false
		}
	},
    created() {      
        if (this.$store.state.mobile){this.size = '90vw';}
    },
    methods: {
        close() {
            this.openHorario = false;
            this.$emit('close-horario', event);
        },
        onMenuToggle(event) {
            this.$emit('close-horario', event);
        },	
	},
    watch: {
		display(newValue) {
			this.openHorario = newValue;
		}
	},


}
</script>