<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12">
			<div class="card">
				<Toolbar class="p-col-12 p-md-12">
					<template v-slot:left>
						<Dropdown v-model="carrera" :options="carreras" optionLabel="nombre" placeholder="Seleccione la Carrera ..." @change="Buscar()"/>
						<!--<Button label="Buscar" icon="pi pi-search" class="p-button-success" @click="Buscar()" />-->
					</template>
					<template v-slot:right>
						<Button label="Nuevo" icon="pi pi-plus" class="p-button-secondary p-mr-2 p-mb-2" @click="openNew" v-if="insert"/>
					</template>
				</Toolbar>

				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Plan de Estudio: {{products.length}}</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="cod" header="Código" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.cod}}
						</template>
					</Column>
					<Column field="programa" header="Carrera" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.programa}}
						</template>
					</Column>
					<Column field="vigencia" header="Vigencia" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.vigencia}}
						</template>
					</Column>
					<Column field="regimen" header="Turno">
						<template #body="slotProps">
							{{slotProps.data.regimen}}
						</template>
					</Column>
					<Column field="modalidad" header="Modalidad">
						<template #body="slotProps">
							{{slotProps.data.modalidad}}
						</template>
					</Column>
					<Column field="asignaturas" header="Asignaturas">
						<template #body="slotProps">
							<span class="p-overlay-badge p-mr-5" @click="IrAsignaturas(slotProps.data.id)">
								<i class="pi pi-eye" style="font-size: 2em"></i>
								<span class="p-badge">{{slotProps.data.asignaturas}}</span>
								
							</span> 
							<i class="pi pi-image" @click="VerJPG(slotProps.data)" style="fontSize: 2rem" v-if="slotProps.data.imagen"/> 
							{{slotProps.data.tuc}}uc
						</template>
					</Column>
					<Column field="activo" header="Estatus">
						<template #body="slotProps">
							<span class="p-tag p-tag-success" v-if="slotProps.data.activo">Activo</span>
							<span class="p-tag p-tag-danger" v-else>No Activo</span>							
						</template>
					</Column>
					<Column header="Opciones">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success p-mr-2 p-mb-2" @click="editProduct(slotProps.data)" v-if="edit" v-tooltip.bottom="'Editar'"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-mr-2 p-mb-2" @click="confirmDeleteProduct(slotProps.data)" v-if="del" v-tooltip.bottom="'Eliminar'"/>
							<!--
							<FileUpload uploadLabel="Cargar" mode="basic" :name="slotProps.data.id" :url="url+'foto.php'"  accept="image/jpeg,image/jpg" v-if="edit" :maxFileSize="1000000" @upload="myUploader(slotProps.data)" :auto="true"/>
						-->	
							<FileUpload :name="slotProps.data.id" :url="url+'foto.php'" mode="basic" accept="image/jpeg" :maxFileSize="1000000" label="JPG/JPEG" chooseLabel="JPG" @upload="myUploader(slotProps.data)" :auto="true"/>
						</template>
					</Column>
				</DataTable>

				<Dialog v-model:visible="productDialog" :style="{width: size}" :header="'Plan de Estudio '+titulo" :modal="true" class="p-fluid" :maximizable="true">
					<br>
                    <div class="p-field">
						<div class="p-grid">
							<div class="p-col-2">
								<label for="name">Código Pensum</label>
								<InputText id="name" v-model.trim="product.codigo" required="true" autofocus/>
							</div>
							<div class="p-col-6">
								<label class="p-mb-3">Carrera</label>
								<Dropdown id="state" v-model="product.programa" :options="carreras" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-2">
								<label class="p-mb-3">Vigencia</label>
								<InputText id="vigencia" v-model.trim="product.vigencia"/>
							</div>
							<div class="p-col-2">
								<label class="p-mb-3">UC</label>
								<InputNumber id="uc" v-model="product.uc" required="true"/>
							</div>
							<div class="p-col-4">
								<label class="p-mb-3">Turno</label>
								<Dropdown id="state" v-model="product.regimen" :options="regimens" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-4">
								<label class="p-mb-3">Modalidad</label>
								<Dropdown id="state" v-model="product.modalidad" :options="modalidads" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>							
							<div class="p-col-4">
								<label class="p-mb-3">Escala</label>
								<Dropdown id="state" v-model="product.escala" :options="escalas" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-8">
								<label class="p-mb-3">Tipo</label>
								<Dropdown id="state" v-model="product.tipo" :options="tipos" optionLabel="nombre" placeholder="Selecciona..."></Dropdown>
							</div>
							<div class="p-col-2">
								<label class="p-mb-3">N° Electivas</label>
								<InputNumber id="uc" v-model="product.electivas" required="true"/>
							</div>
							<div class="p-col-2">
								<label class="p-mb-3">N° ExtraCátedra</label>
								<InputNumber id="uc" v-model="product.act_extracat" required="true"/>
							</div>
							<div class="p-col-8">
								<label for="name">Título</label>
								<InputText id="name" v-model="product.titulo"/>
							</div>
							<div class="p-col-2">
								<h6 style="margin-top: 0" v-if="product.grado">Grado Activo</h6>
								<h6 style="margin-top: 0" v-else>Grado No Activo</h6>
								<InputSwitch v-model="product.grado" />
							</div>
							<div class="p-col-2">
								<h6 style="margin-top: 0">Preinscripción</h6>
								<InputSwitch v-model="product.preinscripcion" />
							</div>
							<div class="p-col-10">
								<label for="name">Observación</label>
								<InputText id="name" v-model="product.observ"/>
							</div>
							<div class="p-col-2">
								<h6 style="margin-top: 0" v-if="product.activo">Activo</h6>
								<h6 style="margin-top: 0" v-else>No Activo</h6>
								<InputSwitch v-model="product.activo" />
							</div>
							<div class="p-col-12" v-if="product.id">
									<h5>Extensiones Asociadas al Plan de Estudio</h5>
									<PickList v-model="product.nucleos" dataKey="code">
										<template #sourceHeader>
											Disponibles
										</template>
										<template #targetHeader>
											Asociadas al Plan
										</template>
										<template #item="slotProps">
											<div>{{slotProps.item.nombre}}</div>
										</template>
									</PickList>
							</div>
						</div>
					</div>
					<template #footer>
						<Button label="Guardar" icon="pi pi-check" class="p-button-secondary" @click="saveProduct()" />
						<Button label="Cancelar" icon="pi pi-times" class="p-button-danger" @click="hideDialog"/>
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteProductDialog" :style="{width: size}" header="Confirmar" :modal="true">
					<br>
                    <div class="confirmation-content">
						<i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
						<span v-if="product">¿Estás seguro que quieres eliminar?</span>
					</div>
					<template #footer>
						<Button label="Sí" icon="pi pi-check" class="p-button-secondary" @click="deleteProduct" />
						<Button label="No" icon="pi pi-times" class="p-button-danger" @click="deleteProductDialog = false"/>
					</template>
				</Dialog>

			</div>
		</div>
	</div>
</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Academico','PlandeEstudio');

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				productDialog: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				product: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '60vw',
				regimens: null,
				modalidads: null,
				carrera: null,
				carreras: null,
				tipos: null,
				escalas: null,
				url: null,

			}
		},
		productService: null,
		created() {
			this.url = process.env.VUE_APP_API;
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				Consulta.Ini().then(result => {
					//this.$store.state.error = result.consult.planestudio;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.regimens=result.consult.regimen;
					this.modalidads=result.consult.modalidad;
					this.carreras=result.consult.carrera;
					this.tipos=result.consult.tipo;
					this.escalas=result.consult.escala;
					if (this.$store.state.seleccion['caso'] == 'plan') {
						this.carreras.forEach(element => {
							if(this.$store.state.seleccion['id']==element.code){
								this.carrera = element;
							}
						});  
						this.Buscar();
					} else {
						if (result.consult.planestudio){
							this.products = result.consult.planestudio;
						}
					}
					this.$store.commit('Loading');
				}); 
			},
			myUploader(data) {
				Consulta.Procesar('Foto',{
					foto: data.id,
				}).then(response => {
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						if (this.carrera != null) {
							this.Buscar();
						} else {
							this.Mostrar();
						}
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
					this.$store.commit('Loading');
				});
			},
			VerJPG(data){
				window.open(this.url+'foto/'+data.id+'.'+data.imagen, '_blank');
			},
			Buscar() {
				if (this.carrera != null) {
					Consulta.Procesar('Buscar',{
						id: 	this.carrera.code,
					}).then(response => {
						//this.$store.state.error =response;
						this.products = response.result;
						this.$store.commit('Loading');
					}); 
				}
			},
			IrAsignaturas(plan) {
				this.product.nivel_id
				this.$store.commit('Seleccion',{caso: 'asignaturas',id: plan});
				this.$router.push({ path: '/asignaturas'});
			},
			openNew() {
				this.titulo = '(Nuevo)';
				this.product = {};
				this.submitted = false;
				this.productDialog = true;
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			saveProduct() {
				if (this.product.codigo == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Código', life: 5000});
				} else if (this.product.vigencia == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Vigencia', life: 5000});
				} else if (this.product.uc < 0) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca UC', life: 5000});
				} else if (this.product.regimen == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione Turno', life: 5000});
				} else if (this.product.modalidad == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Seleccione Modalidad', life: 5000});
				} else if (this.product.electivas < 0) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca N° Electivas', life: 5000});
				} else if (this.product.act_extracat < 0) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca N° Extracatedra', life: 5000});
				} else if (this.product.titulo == null) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca Título que se Otorga', life: 5000});
				} else {	
					this.carrera = this.product.programa;
					this.productDialog = false;
					if (this.product.id) {
						Consulta.Procesar('UPD',{
							id: 	this.product.id,
							codigo: this.product.codigo,
							regimen: this.product.regimen.code,
							uc: this.product.uc,
							modalidad: this.product.modalidad.code,
							programa: this.product.programa.code,
							tipo: this.product.tipo.code,
							observ: this.product.observ,
							vigencia: this.product.vigencia,
							act_extracat: this.product.act_extracat,
							titulo: this.product.titulo,
							grado: this.product.grado,
							preinscripcion: this.product.preinscripcion,
							escala: this.product.escala.code,
							electivas: this.product.electivas,
							activo: this.product.activo,
							nucleos: this.product.nucleos
						}).then(response => {
							//this.$store.state.error =response;
							this.$store.commit('Loading');
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Buscar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
						});
					} else {
						Consulta.Procesar('INS',{
							id: 	this.product.id,
							codigo: this.product.codigo,
							regimen: this.product.regimen.code,
							uc: this.product.uc,
							modalidad: this.product.modalidad.code,
							programa: this.product.programa.code,
							tipo: this.product.tipo.code,
							observ: this.product.observ,
							vigencia: this.product.vigencia,
							act_extracat: this.product.act_extracat,
							titulo: this.product.titulo,
							grado: this.product.grado,
							escala: this.product.escala.code,
							electivas: this.product.electivas,
							activo: this.product.activo
						}).then(response => {
							//this.$store.state.error =response;
							this.$store.commit('Loading');
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.Buscar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
							}
						});
					}

					this.productDialog = false;
					this.product = {};
				}
			},
			editProduct(product) {
				this.titulo = '(Editar)';
				this.product = {...product};
				this.regimens.forEach(element => {
					if(this.product.regimen_id==element.code){
						this.product.regimen = element;
					}
				});  
				this.modalidads.forEach(element => {
					if(this.product.modalidad_id==element.code){
						this.product.modalidad = element;
					}
				});  
				this.carreras.forEach(element => {
					if(this.product.programa_id==element.code){
						this.product.programa = element;
					}
				});  
				this.tipos.forEach(element => {
					if(this.product.tipo_id==element.code){
						this.product.tipo = element;
					}
				});  
				this.escalas.forEach(element => {
					if(this.product.escala_id==element.code){
						this.product.escala = element;
					}
				});  
				this.productDialog = true;
			},
			confirmDeleteProduct(product) {
				this.product = product;
				this.deleteProductDialog = true;
			},
			deleteProduct() {
				this.products = this.products.filter(val => val.id !== this.product.id);
				Consulta.Procesar('DEL',{
					id: 	this.product.id
				}).then(response => {
					this.$store.commit('Loading');
					if (response.result) {
						this.$toast.add({
							severity:'success', 
							summary: 'Éxito', 
							detail: 'Información Procesada', 
							life: 3000
						});
						this.Mostrar();
					} else {
						this.$toast.add({
							severity:'warn', 
							summary: 'Advertencia', 
							detail: 'No fue procesada la solicitud', 
							life: 3000
						});
					}
				});
				this.deleteProductDialog = false;
				this.product = {};
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
