<template>
	<div class="p-grid panel-demo">
		<div class="p-col-12 p-lg-12 ">
			<i class="bi bi-journal-bookmark" style="color: #d46900;fontSize: 1.5rem"> Preinscripción</i>
		</div>
	
		<div class="p-col-12 p-md-4">
			<div class="card">
				<Card>
					<template #title>
						<h5>Introduzca su número de Cédula de Identidad</h5>
					</template>
					<template #content>
						<span class="p-input-icon-left">
							<i class="pi pi-user" />
							<InputText v-model="cedula" type="number" placeholder="Número" min="1"/>
						</span>
					</template>
					<template #footer>
						<Button label="Siguiente" icon="pi pi-arrow-right" class="p-button-secondary" @click="Buscar"/>
					</template>
				</Card>
			</div>
		</div>
        <div class="p-col-12 p-md-12" v-if="Data.length>0"> 
			<div class="p-col-12 p-md-12" v-if="Ver==null">
				<Card>
					<template #title>
						<h5>Preinscripción</h5>
						<Button label="Nueva Preinscripción" icon="pi pi-plus-circle" class="p-button-danger" @click="otra"/>
					</template>
					<template #content>
						<DataTable :value="Data" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
							<Column header="Fecha">	
								<template #body="{data}">
									{{data.fecha}}
								</template>
							</Column>
							<Column header="Nombres y Apellidos">	
								<template #body="{data}">
									{{data.nombre}} {{data.apellido}}
								</template>
							</Column>
							<Column header="Extensión">	
								<template #body="{data}">
									{{data.nucleo}}
								</template>
							</Column>
							<Column header="Carrera">	
								<template #body="{data}">
									{{data.plan}}
								</template>
							</Column>
							<Column header="Ver preincripción">
								<template #body="{data}">
									<Button icon="pi pi-eye" class="p-button-rounded p-button-info" @click="Mostrar(data)"/>
								</template>
							</Column>
						</DataTable>
					</template>
				</Card>
			</div>
			<div class="p-col-12 p-md-12" v-if="Ver">
				<div class="card">
					<Card>
						<template #title>
							<h5>Datos Personales</h5>
						</template>
						<template #content>
							<div class="p-fluid p-formgrid p-grid">
								<div class="p-field p-col-12 p-md-2">
									Nº Cedula: <strong>{{Ver.nac}}{{Ver.cedula}}</strong>
								</div>
								<div class="p-field p-col-12 p-md-2">
									Sexo: <strong v-if="Ver.sexo">Masculino</strong>
									<strong v-else>Femenino</strong>
								</div>
								<div class="p-field p-col-12 p-md-8">
									Nombres y Apellidos: <strong>{{Ver.nombre}} {{Ver.apellido}}</strong>
								</div>
								<div class="p-field p-col-12 p-md-8">
									Correo: <strong>{{Ver.correo}}</strong>
								</div>
								<div class="p-field p-col-12 p-md-4">
									Fecha de Nacmiento: <strong>{{convfech(Ver.fecha_nac)}}</strong>
								</div>
								<div class="p-field p-col-12 p-md-8">
									Lugar de Nacimiento: <strong>{{Ver.lugar_nac}} ({{Ver.pais_nac}})</strong>
								</div>		
							</div>
						</template>
					</Card>
				</div>
			</div>
			<div class="p-col-12 p-md-12" v-if="Ver">
				<div class="card">
					<Card>
						<template #title>
							<h5>Extensión y Carrera</h5>
						</template>
						<template #content>
							<div class="p-fluid p-formgrid p-grid">
								<div class="p-field p-col-12 p-md-8">
									Extensión: <strong>{{Ver.nucleo}}</strong>
								</div>		
								<div class="p-field p-col-12 p-md-4">
									Fecha de Registro: <strong>{{Ver.fecha}}</strong>
								</div>		
								<div class="p-field p-col-12 p-md-8">
									Carrera: <strong>{{Ver.plan}}</strong>
								</div>		
								<div class="p-field p-col-12 p-md-4">
									Turno: <strong>{{Ver.turno}}</strong>
								</div>		
							</div>
						</template>
					</Card>
				</div>
			</div>
			<div class="p-col-12 p-md-12" v-if="Ver">
				<div class="card">
					<Card>
						<template #title>
							<h5>Factura</h5>
						</template>
						<template #content>
							<DataTable :value="Ver.factura" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
								<Column header="Fecha">	
									<template #body="{data}">
										{{data.fechareg}}
									</template>
								</Column>
								<Column header="Descripción">	
									<template #body="{data}">
										{{data.descripcion}}
									</template>
								</Column>
								<Column header="Monto">	
									<template #body="{data}">
										<strong v-if="data.moneda==1">Ref.</strong>
										<img :src="'images/moneda'+data.moneda+'.png'" width="15"/>
										{{data.monto}}
									</template>
								</Column>
								<Column header="Monto Bs">	
									<template #body="{data}">
										<div v-if="data.moneda==0">Bs. {{data.monto}}</div>
										<div v-if="data.moneda==1">Bs. {{data.monto}}</div>
										<div v-if="data.moneda==2">Bs. {{new Intl.NumberFormat("de-DE").format((data.monto*$store.state.petro).toFixed(2))}}</div>
									</template>
								</Column>
							</DataTable>
						</template>
						<template #footer>
							<h5>Monto Bs. <strong>{{new Intl.NumberFormat("de-DE").format((Ver.montobs))}}</strong></h5>
						</template>
					</Card>
				</div>
			</div>
			<div class="p-col-12 p-md-12" v-if="Ver">
				<div class="card">
					<Card>
						<template #title>
							<h5>Pagos</h5>
						</template>
						<template #content>
							<DataTable :value="Ver.pagos" :scrollable="true" scrollHeight="500px" class="p-datatable-sm">
								<Column header="Fecha">	
									<template #body="{data}">
										{{convfech(data.fecha)}}
									</template>
								</Column>
								<Column header="Referencia">	
									<template #body="{data}">
										{{data.referencia}}
									</template>
								</Column>
								<Column header="Monto">	
									<template #body="{data}">
										<strong v-if="data.moneda==1">Ref.</strong>
										<img :src="'images/moneda'+data.moneda+'.png'" width="15"/>
										{{data.monto}}
									</template>
								</Column>
								<Column header="Monto Bs">	
									<template #body="{data}">
										Bs. {{new Intl.NumberFormat("de-DE").format((data.montobs))}}
									</template>
								</Column>
								<Column header="Estatus">	
									<template #body="{data}">
										<span class="p-tag p-tag-success" v-if="data.verificado">Verificado</span>
										<span class="p-tag p-tag-danger" v-else>Por Verificar</span>
									</template>
								</Column>
							</DataTable>
						</template>
						<template #footer>
							<h5>Monto Bs. <strong>{{new Intl.NumberFormat("de-DE").format((Ver.recibidobs))}}</strong></h5>
						</template>
					</Card>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import API from "../service/API";
	const Consulta = new API('Preinscripcion','Procesar');

	export default {
		data() {
			return {
				cedula: null,
				correo: null,
				codigo: null,
				token: null,
				size: '30vw',
				Dialog1: false,
				Nuevo: false,
				Data: [],
				Ver: null,
			}
		},
		created() {
			this.Nuevo = false;
			this.Verificacion = false;
			if(!this.$store.state.preinscripcion){
				this.$router.push({ path: '/' });
			} 
			if (this.$store.state.mobile){this.size = '90vw';}
			this.$store.commit('Loading');
		},
		methods: {
			Buscar() {
				this.Data = [];
				this.Ver = null;
				if (this.cedula == null || this.cedula == 0) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Introduzca el Número de Cédula', life: 5000});
				} else {
					this.$store.commit('Loading');
					this.$store.commit('Matricula',{matricula: this.cedula});
					Consulta.Procesar('Cedula',{
						cedula: this.cedula,
					}).then(response => {
						//this.$store.state.error =response;
						if(response.result.length>0){
							this.Data = response.result;
						} else {
							this.$router.push({ path: '/preinscripcion_paso1' });
						}
						this.$store.commit('Loading');
					});
				}
			},
			Mostrar(data){
				this.Ver=data;
			},
			convfech(fecha){
				return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
			},
			otra(){
				this.$router.push({ path: '/preinscripcion_paso1' });
			}

		}
	}
</script>

