<template>
	<div class="p-grid crud-demo" >
		<div class="p-col-12" v-if="!seleccion">
			<div class="card">
				<DataTable :value="products" :scrollable="true" scrollHeight="400px"  v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Plan de Evaluación: {{products.length}}</h5>
							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="periodo" header="Período" :sortable="true">	
						<template #body="slotProps">
							<strong style="color: blue;" v-if="slotProps.data.periodo_act">{{slotProps.data.periodo}}</strong>
							<strong style="color: red;" v-else>{{slotProps.data.periodo}}</strong>						
						</template>
					</Column>
					<Column header="Plan de Estudio" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.plan}} {{slotProps.data.codplan}}
						</template>
					</Column>
					<Column field="cod" header="Código" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.cod}}
						</template>
					</Column>
					<Column field="asignatura" header="Asignatura" :sortable="true">
						<template #body="slotProps">
							{{slotProps.data.asignatura}}
						</template>
					</Column>
					<Column field="secf" header="Sección" :sortable="true">	
						<template #body="slotProps">
							{{slotProps.data.secf}}
						</template>
					</Column>
					<Column field="porcent" header="Porcentaje" :sortable="true">	
						<template #body="slotProps">
							{{slotProps.data.porcent}}%<br>
							<span class="p-tag p-tag-warning" v-if="slotProps.data.porcent<100">Pendiente {{parseFloat(100-slotProps.data.porcent)}}%</span>
						</template>
					</Column>
					<Column header="Ver">
						<template #body="slotProps">
							<Button icon="pi pi-file-pdf" class="p-button-rounded p-button-info p-mr-2" v-tooltip.bottom="'Plan de Evaluación'" @click="VerPDF(slotProps.data)"/>
							<Button icon="pi pi-arrow-right" class="p-button-rounded p-button-success p-mr-2" @click="Editar(slotProps.data)" v-tooltip.bottom="'Seleccionar'" v-if="slotProps.data.inscritostotal>0 && slotProps.data.cerradas==0 && !slotProps.data.cerradas"/>
							<i class="pi pi-lock" style="fontSize: 2rem" v-if="slotProps.data.cerradas"></i>
						</template>
					</Column>
				</DataTable>
				<br>
				<Sidebar v-model:visible="verfusionDialog" position="top">
					<h4>Principal de la Fusión en la Carrera: <strong>{{fusion.codplan}} {{fusion.plan}}</strong></h4>
					<h5>Sección: <strong>S{{fusion.sem}}{{fusion.secc}}</strong>,  Asignatura: <strong>{{fusion.asignatura}} ({{fusion.cod}})</strong></h5>
				</Sidebar>
			</div>
		</div>
		<div class="p-col-12" v-if="seleccion">
			<div class="card">
				<Card>
					<template #title>
						<h5>Plan de Evaluación</h5>
					</template>
					<template #content>
						<div class="p-fluid p-formgrid p-grid">
							<div class="p-field p-col-12 p-md-2">
								Período: <strong>{{seleccion.periodo}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-8">
								Plan de Estudio: <strong>{{seleccion.plan}} {{seleccion.codplan}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-2">
								Porcentaje: <strong>{{totalporcentaje}}%</strong>
							</div>
							<div class="p-field p-col-12 p-md-2">
								Código: <strong>{{seleccion.cod}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-8">
								Asignatura: <strong>{{seleccion.asignatura}}</strong>
							</div>
							<div class="p-field p-col-12 p-md-2">
								Sección: <strong>{{seleccion.secf}}</strong>
							</div>
						</div>
					</template>
				</Card>
			</div>
		</div>
		<div class="p-col-12" v-if="seleccion">
			<div class="card">
				<DataTable :value="seleccion.evaluaciones"   v-model:selection="selectedCustomers" :filters="filters" class="p-datatable-sm">
					<template #header>
						<div class="table-header">
							<h5 class="p-m-0">Evaluaciones: {{seleccion.evaluaciones.length}}</h5>
							<Button label="Agregar" icon="pi pi-plus" class="p-button-info p-mr-2 p-mb-2" @click="Agregar()" style="width: 200px;" :disabled="seleccion.etapa==4"/>

							<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Buscar..." />
                            </span>
						</div>
					</template>
					<Column field="corte" header="Corte" :sortable="true">
						<template #body="slotProps">
							<Dropdown id="state" v-model="slotProps.data.corte" :options="cortes" optionLabel="nombre" placeholder="Seleccione..." :disabled="seleccion.etapa==4"></Dropdown>
						</template>
					</Column>
					<Column field="actividad" header="Actividad" :sortable="true">
						<template #body="slotProps">
							<InputText v-model="slotProps.data.actividad" type="text" :disabled="seleccion.etapa==4"/>
						</template>
					</Column>
					<Column field="fecha" header="Fecha" :sortable="true">
						<template #body="slotProps">
							<InputText v-model="slotProps.data.fecha" type="date" :disabled="seleccion.etapa==4"/>
						</template>
					</Column>
					<Column field="porcentaje" header="Porcentaje %" :sortable="true">
						<template #body="slotProps">
							<InputNumber v-model="slotProps.data.porcentaje" required="true" :minFractionDigits="0" min="0" @keyup="Calcular()" :disabled="seleccion.etapa==4"/>
						</template>
					</Column>
					<Column header="Eliminar">
						<template #body="slotProps"> 
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="Eliminar(slotProps.data)" :disabled="seleccion.etapa==4"/>
						</template>
					</Column>
					<template #footer>
						<Button label="Guardar" style="width: 110px;" icon="pi pi-save" class="p-button-secondary p-mr-2" @click="Guardar()" v-if="seleccion.evaluaciones.length>0" :disabled="seleccion.etapa==4"/>
						<Button label="Cancelar" style="width: 110px;" icon="pi pi-times" class="p-button-danger p-mr-2" @click="Mostrar()"/>
					</template>
				</DataTable>
			</div>
		</div>


	</div>

</template>

<script>
	import API from "../service/API";
	import PDF from "../service/PDF";
	const Consulta = new API('AreaDocente','DocPlan');

	export default {
		data() {
			return {
				insert: false,
				edit: false,
				del: false,
				titulo: null,
				products: [],
				productDialog: false,
				productDialog2: false,
				deleteProductDialog: false,
				deleteProductsDialog: false,
				HoraDocDialog: false,
				product: {},
				selectedProducts: null,
				filters: {},
				submitted: false,
				size: '80vw',
				horariosec: [],
				asignaturas: [],
				horario: [],
				horariodoc: [],
				hora: null,
				cortes: [
					{nombre: '01', code: 1},
					{nombre: '02', code: 2},
					{nombre: '03', code: 3},
					{nombre: '04', code: 4},
				],
				fusionDialog: false,
				seleccion: null,
				fusion: null,				
				verfusionDialog: false,
				totalporcentaje: 0,
				corte1: 0,
				corte2: 0,
				corte3: 0,
				corte4: 0,
			}
		},
		productService: null,
		created() {
			this.$store.commit('Validar',this.$route);
			if (this.$store.state.mobile){this.size = '90vw';}
			this.Mostrar();
		},
		mounted() {
			
		},
		methods: {
			Mostrar() {
				this.products = [];
				this.$store.state.loading = true;	
				this.seleccion = null;
				Consulta.Ini().then(result => {
					this.$store.commit('Loading');
					//this.$store.state.error = result;
					this.insert = result.insert;
					this.edit = result.edit;
					this.del = result.delete;
					this.products = result.consult.oferta;
					
				}); 
			},
			VerPDF(data){
				Consulta.Procesar('Ver',{
					oferta: data.id
					}).then(response => {
						//this.$store.state.error =response;
						if (response.result) {
							var dat = data;
							dat['evaluac'] = response.result;
							this.$store.commit('Loading');
							const doc = new PDF();
							doc.PlanEvaluacion(dat);
						} 
					});
			},
			Editar(data) {
				Consulta.Procesar('Editar',{
					oferta: data.id
					}).then(response => {
						//this.$store.state.error =response;
						if (response.result) {
							var dat = data;
							dat['evaluaciones'] = response.result;
							this.seleccion = dat;
							for (var i = 0; i < this.seleccion.evaluaciones.length; i++) {
								this.cortes.forEach(element => {
									if(element.code == this.seleccion.evaluaciones[i].corte){
										this.seleccion.evaluaciones[i].corte = element;
									}
								});
							}
							this.Calcular();
							this.$store.commit('Loading');
						} 
					});
			},
			Calcular(){
				this.totalporcentaje = 0;
				this.corte1 = 0; this.corte2 = 0; this.corte3 = 0; this.corte4 = 0;
				for (var i = 0; i < this.seleccion.evaluaciones.length; i++) {
					this.totalporcentaje = parseFloat(this.totalporcentaje) + parseFloat(this.seleccion.evaluaciones[i].porcentaje);
					if (this.seleccion.evaluaciones[i].corte.code == 1){
						this.corte1 = parseFloat(this.corte1) + parseFloat(this.seleccion.evaluaciones[i].porcentaje);}
					if (this.seleccion.evaluaciones[i].corte.code == 2){
						this.corte2 = parseFloat(this.corte2) + parseFloat(this.seleccion.evaluaciones[i].porcentaje);}
					if (this.seleccion.evaluaciones[i].corte.code == 3){
						this.corte3 = parseFloat(this.corte3) + parseFloat(this.seleccion.evaluaciones[i].porcentaje);}
					if (this.seleccion.evaluaciones[i].corte.code == 4){
						this.corte4 = parseFloat(this.corte4) + parseFloat(this.seleccion.evaluaciones[i].porcentaje);}
				}
			},
			Agregar() {
				this.seleccion.evaluaciones.push({
					id: 0,
					oferta: this.seleccion.id,
					actividad: '',
					fecha: null,
					porcentaje: 0,
					corte: {nombre: '01', code: 1}
				});
				this.Calcular();
			},
			Eliminar(data) {
				this.seleccion.evaluaciones = this.seleccion.evaluaciones.filter(val => val !== data);
				this.Calcular();
			},
			Guardar(){
				this.Calcular();
				if (this.totalporcentaje == 0) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Debe carga al menos una actividad', life: 5000});
				} else if (this.totalporcentaje > 100) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'No puede exceder del 100%', life: 5000});
				} else if (this.corte1 > 25 || this.corte2 > 25 || this.corte3 > 25 || this.corte4 > 25) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Los cortes no pueden exceder de 25%', life: 5000});
				} else if (this.totalporcentaje > 0 && this.totalporcentaje < 100) {
					this.$toast.add({severity:'error', summary: 'Mensaje', detail: 'Falta cargar '+parseFloat(100-this.totalporcentaje)+'%', life: 5000});
				} else {
					Consulta.Procesar('UPD',{
						seleccion: this.seleccion,
						total: this.totalporcentaje,
						}).then(response => {
							//this.$store.state.error =response;
							if (response.result) {
								this.$toast.add({
									severity:'success', 
									summary: 'Éxito', 
									detail: 'Información Procesada', 
									life: 3000
								});
								this.$store.commit('Loading');
								this.Mostrar();
							} else {
								this.$toast.add({
									severity:'warn', 
									summary: 'Advertencia', 
									detail: 'No fue procesada la solicitud', 
									life: 3000
								});
								this.$store.commit('Loading');
							}
						});
					}
			},
			hideDialog() {
				this.productDialog = false;
				this.submitted = false;
			},
			verFusion(data) {
				this.fusion = data[0];
				this.verfusionDialog = true;
			},
			findIndexById(id) {
				let index = -1;
				for (let i = 0; i < this.products.length; i++) {
					if (this.products[i].id === id) {
						index = i;
						break;
					}
				}

				return index;
			},
			createId() {
				let id = '';
				var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
				for ( var i = 0; i < 5; i++ ) {
					id += chars.charAt(Math.floor(Math.random() * chars.length));
				}
				return id;
			},
			exportCSV() {
				this.$refs.dt.exportCSV();
			},
				convfech(fecha){
					return fecha.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
				},

		}
	}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 40em) {
    ::v-deep(.p-datatable) {
        &.p-datatable-responsive-demo {
            .p-datatable-thead > tr > th,
            .p-datatable-tfoot > tr > td {
                display: none !important;
            }

            .p-datatable-tbody > tr > td {
                text-align: left;
                display: block;
                width: 100%;
                float: left;
                clear: left;
                border: 0 none;

                .p-column-title {
                    padding: .4rem;
                    min-width: 30%;
                    display: inline-block;
                    margin: -.4em 1em -.4em -.4rem;
                    font-weight: bold;
                }

                &:last-child {
					border-bottom: 1px solid var(--surface-d);
					text-align: center;
				}
				
				.p-rating {
					display: inline-block;
				}
            }
        }
    }
}
</style>
